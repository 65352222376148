import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@material-ui/styles";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import moment from "moment";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  success: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  warning: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
  },
  secondary: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  },
}));

export default forwardRef(function Tables(props, ref) {
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else gridApi.exportDataAsCsv();
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 610, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
      >
        <AgGridColumn headerName="Name" field="name" width={300} />
        <AgGridColumn headerName="Type" field="amsModule" />
        <AgGridColumn headerName="Targeting Type" field="targetingType"/>
        <AgGridColumn headerName="Cost" field="cost" width={150} comparator={integerComparatorWithCurrency} />
        <AgGridColumn headerName="Clicks" field="clicks" />
        <AgGridColumn headerName="Impressions" field="impressions" />
        <AgGridColumn headerName="Sales" field="sales" comparator={integerComparatorWithCurrency} />
        <AgGridColumn headerName="ACOS %" field="acos" comparator={integerComparatorWithPerc} />
        <AgGridColumn headerName="Start Date" field="startDate" valueFormatter = {dateFormat}/>
        <AgGridColumn headerName="End Date" field="endDate" valueFormatter = {dateFormat}/>
        <AgGridColumn headerName="Serving Status" field="servingStatus" />
        <AgGridColumn headerName="State" field="state" />
        <AgGridColumn headerName="Ad Format" field="adFormat" />
        <AgGridColumn headerName="Bid Optimization" field="bidOptimization" />
        <AgGridColumn headerName="Budget" field="budget" />
        <AgGridColumn headerName="Budget Type" field="budgetType" />
        <AgGridColumn headerName="Creative Brand Logo Asset Id" field="creativeBrandLogoAssetID" />
        <AgGridColumn headerName="Creative Brand Logo" field="creativeBrandLogoUrl" />
        <AgGridColumn headerName="Creative Brand Name" field="creativeBrandName" />
        <AgGridColumn headerName="Creative Headline" field="creativeHeadline" />
        <AgGridColumn headerName="Creative Page Type" field="creativeLandingPagePageType" />
        <AgGridColumn headerName="Creative Landing Page" field="creativeLandingPageUrl" />
        <AgGridColumn headerName="Creative Optimize Asins" field="creativeShouldOptimizeAsins" />
      </AgGridReact>
    </div>
  );
});

const dateFormat = params => params.value && moment(params.value, "YYYYMMDD").format("MM-DD-YYYY");
const integerComparatorWithPerc = (valueA, valueB) => parseInt(valueA.replace("%","")) - parseInt(valueB.replace("%",""));
const integerComparatorWithCurrency = (valueA, valueB) => parseInt(valueA.replace("$","")) - parseInt(valueB.replace("$",""));