import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { noCurve } from "../../../../healpers/noCurve";
import { Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

//components
import Dot from "../../../../components/Sidebar/components/Dot";

//helpers
import { numberWithCommas } from "../../../../healpers/utilityFunctions";

// styles
const useStyles = makeStyles((theme) => ({
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },    
}));

export default function App({ data, fieldList, colors }) {

  //global
  const classes = useStyles();

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length!==0) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
          }}
        >
          <Typography style={{ whiteSpace: "nowrap", fontSize: 16 }}><b><i><u>{payload[0].payload["Date"]}</u></i></b></Typography>
          {
            payload.map((item, index)=>(
              <div key={colors[index]} className={classes.legendItemContainer}>
                <Dot color={colors[index]} legend />
                <Typography style={{ whiteSpace: "nowrap", fontSize: 14 }} >
                &nbsp;{item.dataKey}:&nbsp;
                {
                  (item.dataKey==="Glance Views") ? "" : "$"
                }
                {numberWithCommas(item.value)}
                </Typography>
              </div>
            ))
          }
        </div>
      );
    }
  
    return null;
  };

  const customLegend = ({payload}) => {
    return (
      <div style={{
        display: "flex",
        alignItems: "center",
        textAlign: "center",
        margin:'0 40%',
        marginTop:20
       }}>
        {
          payload.map((item) =>{
              return (
                <div key={item.color} className={classes.legendItemContainer}>
                  <Dot color={item.color} legend />
                  <Typography style={{ whiteSpace: "nowrap", fontSize: 14 }} >
                  &nbsp;{item.dataKey}&nbsp;
                  </Typography>
                </div>
              )                  
            } 
          )
        }
      </div>
    );
  }

  return (
    <LineChart
      width={1250}
      height={500}
      data={data}
      margin={{
        top: 30,
        right: 0,
        left: 0,
        bottom: 30,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="Date"/>
      <YAxis axisLine={false} />
      <Tooltip content={<CustomTooltip/>} />
      <Legend content={customLegend}/>
      {
        fieldList ? fieldList.map((item, index)=>{
          return (
            <Line
              type={noCurve}
              dataKey={item}
              stroke={colors[index]}
              isAnimationActive={false}
            />
          )
        }) : <></>
      }
    </LineChart>
  );
}
