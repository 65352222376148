import React, { useEffect, useState } from "react";
import { Container, Breadcrumbs, Grid, Typography } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import PageTitle from "../../components/PageTitle/PageTitle";
import { fetchDapiStatistics } from "../../http/api";
import Table from "./components/Table/Table";
import { numberWithCommas } from "../../healpers/utilityFunctions";
import Refresh from "@material-ui/icons/Refresh";

export default function DapiStatistics() {
  const [dapiStatistics, setDapiStatistics] = useState([]);
  const [date, setDate] = useState(moment());

  const loadDapiStatistics = async () => {
    try {
      const { status, message } = await fetchDapiStatistics(
        date.get("month") + 1,
        date.get("year")
      );
      if (status === "success") {
        setDapiStatistics(
          message.days?.map((item) => {
            return {
              day: item.day,
              calls: item.calls.day_totals,
            };
          })
        );
      } else {
        setDapiStatistics([]);
      }
    } catch (err) {
      console.log(err);
      setDapiStatistics([]);
    }
  };

  useEffect(() => {
    loadDapiStatistics();
  }, [date]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="D API Statistics"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="primary">D API Statistics</Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Select Year & Month"
                views={["year", "month"]}
                value={date}
                maxDate={new Date()}
                onChange={(date) => {
                  setDate(moment(date));
                }}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          }
        />
        <Grid container spacing={4}>
          {dapiStatistics.length > 0 && (
            <Grid item xs={12} style={{ backgroundColor: "white" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <Typography color="textPrimary">
                  {`Total number of request made on ${date.format(
                    "MMMM YYYY"
                  )}:`}
                </Typography>
                <Typography color="primary">
                  {`${numberWithCommas(
                    dapiStatistics
                      .map((item) => item.calls)
                      .reduce((prev, curr) => prev + curr, 0)
                  )}`}
                </Typography>
                <Refresh
                  onClick={loadDapiStatistics}
                  style={{ cursor: "pointer" }}
                  fontSize="small"
                  titleAccess="Refresh"
                />
              </div>
            </Grid>
          )}
          <Grid item xs={12} style={{ backgroundColor: "white" }}>
            <Table rows={dapiStatistics} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
