import React, { useLayoutEffect } from 'react';
import moment from 'moment';

import Button from "@material-ui/core/Button";
import LeftArrow from "@material-ui/icons/ArrowBackSharp";
import RightArrow from "@material-ui/icons/ArrowForwardSharp";

import { 
    calculateIntervals,
    filterDataByDate
} from "../../../../healpers/orderMetrics.helpers";

// styles
import useStyles from "../../styles";



function DayHeatMap({ heatmapData, dateRange, totalData, setmainTableData }) {

    var classes = useStyles();

    useLayoutEffect(() => {
        if(heatmapData && dateRange && dateRange.length && totalData) {
          // eslint-disable-next-line no-undef
          var cal = new CalHeatMap();
    
          cal.init({
            itemSelector: "#DailyHeatMap", previousSelector: "#PrevDomain-DailyHeatMap", nextSelector: "#NextDomain-DailyHeatMap",
            domain: "month", subDomain: "x_day",
            data: heatmapData,

            start: new Date(dateRange?.at(-1)),
            minDate: new Date(dateRange[0]), maxDate: new Date(dateRange[1]),

            cellSize: 30,
            cellPadding: 15,
            domainGutter: 50,
            range: 2,

            domainDynamicDimension: false,
            afterLoadPreviousDomain: (prevDate) => {
              let nextDate = moment(prevDate).add(2, 'M');
              let filteredData = filterDataByDate(totalData, {}, prevDate, nextDate);
              setmainTableData(filteredData)
            },
            afterLoadNextDomain: (nextDate) => {
              let prevDate = moment(nextDate).subtract(1, 'M');
              let newNextDate = moment(nextDate).add(1, 'D'); // since date is exclusive, we will have to add an extra day.
              let filteredData = filterDataByDate(totalData, {}, prevDate, newNextDate);
              setmainTableData(filteredData);
            },

            domainLabelFormat: function(date) {
              return moment(date).locale('en').format("MMMM-YYYY").toUpperCase();
            },
            legend: calculateIntervals(Object.values(heatmapData))
          });

          return () => {
            cal = cal.destroy();
          }
        }
      })

    function SmallButton(props) {
        return (
            <Button
                id={ props.type === 'prev' ? "PrevDomain-DailyHeatMap" : "NextDomain-DailyHeatMap"}
                variant="outlined"
                color="inherit"
                size="small"
                startIcon={ props.type === 'prev' ? <LeftArrow /> : <RightArrow />}
            />
        )
    }

    return (
        <div className={classes.heatmapContainer}>
            <div className={classes.heatmapButtons}>
                <SmallButton type = 'prev'/>
                <SmallButton type = 'next' />
            </div>
            <div className={classes.heatmap} id="DailyHeatMap"></div>
        </div>
    )
}

export default React.memo(DayHeatMap)