import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Breadcrumbs,
  Container,
  Link,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import PageTitle from "../../components/PageTitle/PageTitle";
import LineBarChart from "./components/lineBarChart/lineBarChart";
import Table from "./components/Table/Table";
import SmallTable from "./components/SmallTable/Table";

//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { getWeeksTillDate, lineChartParse } from "../../healpers/utilityFunctions";
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";

import { fetchReachTrendData } from "../../http/api";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

const weeks = cloneAndSortDescending([...getWeeksTillDate()]);


export default function ReachTrend() {
  var classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var vendorState = useVendorState();

  // local
  const [chartData, setChartData] = useState({});
  const [mainTableData, setMainTableData] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [selectFromDate, setselectFromDate] = React.useState(moment().subtract(2, 'months').toDate());
  const [selectToDate, setselectToDate] = React.useState(new Date());
  const [dateRange] = useState(weeks);
  const [selectedDateRange, setselectedDateRange] = useState(0);  
  useEffect(() => {
    async function loadData() {
      setFullPageLoader(true);
      try {
        const vendorCode = vendorState.selected;
        if(vendorCode) {
          const startDate = moment(selectFromDate).toDate();
          const endDate = moment(selectToDate).toDate();
          let response = await fetchReachTrendData(vendorCode, startDate, endDate);
          const parsedData = lineChartParse(response.data, { line: "clicks", line2: "orders", bar: "impressions", label: "date" });
          setChartData(parsedData);
          setMainTableData(response.data);
          setFullPageLoader(false);
        }
      } catch (error) {
        setChartData([]);
        setMainTableData([]);
        setFullPageLoader(false);
        console.log(error);
      }
    }

    loadData();
  }, [selectFromDate, selectToDate, vendorState.selected]);

  console.log("mainTableData", mainTableData);

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Reach Trends"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              AMS
            </Link>
            <Typography className={classes.link} color="primary">
              Reach Trends
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <>
          <MuiPickersUtilsProvider utils={DateFnsUtils} fullWidth>
              <FormControl
                variant="outlined"
                className={classes.dateFormControl}
              >
                <KeyboardDatePicker
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="startDate"
                  label="From"
                  value={selectFromDate}
                  onChange={(date) => setselectFromDate(date)}
                />
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.dateFormControl}
              >
                <KeyboardDatePicker
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="startDate"
                  label="To"
                  value={selectToDate}
                  onChange={(date) => setselectToDate(date)}
                  minDate={selectFromDate}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
            {/* <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Range
              </InputLabel>
              <Select
                value={selectedDateRange}
                onChange={(e) => {
                  setselectedDateRange(e.target.value);
                }}
                label="Select Range"
                disabled={dateRange && dateRange.length === 0 ? true : false}
              >
                {dateRange ? (
                  dateRange.map((range, index) => {
                    return (
                      <MenuItem value={index}>
                        {range.startdate + " - " + range.enddate}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Dates</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl> */}
          </>
        }
      />
      <Grid container spacing={4} className="reachTrend_performance_grid">
        <Grid item xs={12}>
          <Widget style={{ display: "flex", flexDirection: "row" }} upperTitle noBodyPadding bodyClass={classes.tableOverflow} disableWidgetMenu>
            <LineBarChart
              labels={chartData.labels}
              lineName="Orders"
              lineData={chartData.line2Data}
              // line2Name="Orders"
              // line2Data={chartData.line2Data}
              barName="Clicks"
              barData={chartData.lineData}
            />
            <SmallTable ref={exportFunctionRef} tableData={mainTableData.reverse()} />
          </Widget>
        </Grid>
      </Grid>
      <Grid container spacing={4} className="reachTrend_performance_grid">
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  Reach Trend Analysis
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<PrintIcon />}
                  onClick={() => exportFunctionRef.current.onBtnExport()}
                >
                  Export
                </Button>
              </div>
            }
          >
            <Table ref={exportFunctionRef} tableData={mainTableData.reverse()} />
          </Widget>
        </Grid>
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
