import { GET_API, POST_API } from "./axios"
import { filterDataByDate, modifyOrderMetricsForTable, generateHeatMapData, generateLineChartData } from "../healpers/orderMetrics.helpers";
import { modifyShareOfVoiceForTable } from "../healpers/sov.helpers";
import { modifyBrandSegmentsByDayData, filterBrandSegmentDataByDate } from "../healpers/brandSegmentsByDay.helpers";
import { modifyMerchantListingsReportData } from "../healpers/merchantListingsReport.helpers";
import { modifyPriceAndWarData, fetchPriceWarGraphData, modifyCompetitionSalesByASIN } from "../healpers/priceAndWarHelpers";
import { tokenConfig } from '../context/UserContext';
import { BASE_URL } from "../healpers/api";
import api from "../healpers/apiRoutes";
import moment from "moment";
import { modifyProductAndReviewData } from "../healpers/productAndReviews";
import { modifyProductAndReviewDataAll } from "../healpers/productAndReviews";
import { modifyMapViolatersData } from "../healpers/mapViolaters";

/**
 * Gets the duration parameter for moment
 * @param {string} val 
 * @returns {Object} Amount and Unit
 */
const getDuration = (val) => {
    switch (val) {
        case 'Day':
            return { amount: 2, unit: 'month' }

        case 'Hour':
            return { amount: 4, unit: 'day' }

        default:
            return { amount: 2, unit: 'month' }
    }
}

/**
 * Sanitizes the params and returns valid config for API
 * @param {Object} allParams 
 * @param {string} allParams.vendorCode - Vendor Code
 * @param {string} allParams.granularity - Day/Hour
 * @param {string} allParams.asin - ASIN stands for Amazon Standard Identification Number. Almost every product on our site has its own ASIN--a unique code we use to identify it
 * @returns {Array} URL and Query Parameters
 */
const fetchOrderMetricsConfigDetails = (allParams) => {
    const { asin, ...reqParams } = allParams;
    const url = BASE_URL + ((asin && asin !== 'All') ? api.getOrderMetricsASIN : api.getOrderMetrics);
    return (asin && asin !== 'All') ? [url, allParams] : [url, reqParams]
}

/**
 * Fetches Order Metrics from the server based on vendor code and granularity
 * @param {string} vendorCode 
 * @param {string} granularity - Day/Hour
 * @param {string} asin - ASIN stands for Amazon Standard Identification Number. Almost every product on our site has its own ASIN--a unique code we use to identify it
* @returns {Array} Array of Order Metrics
 */
export const fetchOrderMetrics = async (vendorCode, granularity, asin) => {
    try {

        const allParams = { vendorCode, granularity, asin: asin?.split("-")?.[0] }
        const [url, params] = fetchOrderMetricsConfigDetails(allParams);

        const result = await GET_API(url, tokenConfig(), params);
        const data = result?.data?.data || [];
        if (Array.isArray(data)) {
            if (!data.length) {
                return {};
            } else {
                const formattedData = modifyOrderMetricsForTable(data, granularity);
                const heatmapObj = generateHeatMapData(data);
                const dateRange = [data.at(0).startDate, data.at(-1).startDate];
                const filteredData = formattedData; //filterDataByDate(formattedData, getDuration(granularity))

                return { formattedData, heatmapObj, dateRange, filteredData };
            }
        } else {
            throw new Error("Failed to fetch Order Metrics data!");
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Order Metrics from the server based on vendor code and granularity
 * @param {string} vendorCode 
 * @param {string} granularity - Day/Hour
* @returns {Array} Array of Order Metrics
 */
export const fetchOrderMetricsHour = async (vendorCode) => {
    try {
        const granularity = 'Hour'
        const allParams = { vendorCode, granularity }
        const [url, params] = fetchOrderMetricsConfigDetails(allParams);

        const result = await GET_API(url, tokenConfig(), params);
        const data = result?.data?.data || [];
        if (Array.isArray(data)) {
            if (!data.length) {
                return {};
            } else {
                const formattedData = modifyOrderMetricsForTable(data, granularity);
                const filteredData = filterDataByDate(formattedData, getDuration(granularity))
                const lineChartData = generateLineChartData(data);
                return { formattedData, lineChartData, filteredData };
            }
        } else {
            throw new Error("Failed to fetch Order Metrics data!");
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches List of ASINs from the server based on vendor code and granularity
 * @param {string} vendorCode 
 * @param {string} asin - ASIN stands for Amazon Standard Identification Number. Almost every product on our site has its own ASIN--a unique code we use to identify it
 * @returns {Array} Array of ASINs
 */
export const fetchASINs = async (vendorCode, reportType = 'ordermetricsasinday') => {
    try {

        if(!vendorCode) {
            return [];
        }
        const url = BASE_URL + api.getASINs;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, reportType }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || '';

        if (!data.length) {
            return [];
        } else {
            return data.split(',');
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches SOV data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of SOV data
 */
export const fetchShareOfVoiceData = async (vendorCode, startDate, endDate, searchTerm) => {
    try {
        const url = BASE_URL + api.getShareOfVoice;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, startDate, endDate, searchTerm }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {
            const formattedData = modifyShareOfVoiceForTable(data);
            const filteredData = filterDataByDate(formattedData, {}, startDate, endDate)

            return { filteredData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}


/**
 * Fetches SOV data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of SOV data
 */
export const fetchSOVRanges = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getShareOfVoiceRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const ranges = result?.data?.data || {};
        if (!ranges.dates || !ranges.searchTerm) {
            throw new Error('Failed to fetch date ranges and search terms')
        } else {
            const dates = (ranges?.dates || []).map(ele => { return { startDate: moment(ele.startDate).format('MM/DD/YYYY'), endDate: moment(ele.endDate).format('MM/DD/YYYY') } })
            return { dates, sTerms: (ranges?.searchTerm || '').split(',') };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches date range data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of dates
 */
 export const fetchDateRanges = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getBrandSegmentByDayRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const ranges = result?.data?.data || {};
        if (!ranges?.dates) {
            throw new Error('Failed to fetch date ranges and search terms')
        } else {
            const dates = (ranges?.dates || []).map(ele => { return { date: moment(ele.date).format('MM/DD/YYYY') } })
            return { dates };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}


/**
 * Fetches Brand Segments from the server based on vendor code and date
 * @param {string} vendorCode 
 * @param {string} date
* @returns {Array} Array of Order Metrics
 */
export const fetchBrandSegmentsByDayData = async (vendorCode, date) => {
    try {
        const url = BASE_URL + api.getBrandSegmentByDay;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, date }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {
            const formattedData = modifyBrandSegmentsByDayData(data);
            // const filteredData = filterBrandSegmentDataByDate(formattedData, date)

            return { filteredData: formattedData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Keywords By ASINS ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of Keywords By ASINS ranges  */
 export const fetchKeywordsByASINRanges = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getKeywordsByASINRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const ranges = result?.data?.data || {};
        if (!ranges.months || !ranges.asins) {
            throw new Error('Failed to fetch date ranges and search terms')
        } else {
            const months = (ranges?.months || []).map(ele => ele.month)
            return { months, asins: (ranges?.asins || '').split(',') };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches SOV data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of SOV data
 */
 export const fetchKeywordsByASINData = async (vendorCode, month, asin) => {
    try {
        const url = BASE_URL + api.getKeywordsByASIN;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, month, asin }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {
            // const formattedData = modifyShareOfVoiceForTable(data);
            // const filteredData = filterDataByDate(formattedData, {}, month)

            return { filteredData: data };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Order Metrics from the server based on vendor code and granularity
 * @param {string} vendorCode 
 * @param {string} date
* @returns {Array} Array of Order Metrics
 */
export const fetchMerchantListingsFYPReport = async (vendorCode, date) => {
    try {
        const url = BASE_URL + api.getMerchantListingsReport;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {
        	 const filteredData = modifyMerchantListingsReportData(data);
            return { filteredData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Sales Estimate ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of asins and dates  */
 export const fetchSalesEstimateRanges = async (vendorCode) => {
    try {
        if(!vendorCode) {
            return {}
        }
        const url = BASE_URL + api.getSalesEstimateDataRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const ranges = result?.data?.data || {};
        if (!ranges.dates || !ranges.asins) {
            throw new Error('Failed to fetch date ranges and search terms')
        } else {
            const dates = (ranges?.dates || []).map(ele => moment(ele.date).format('MM/DD/YYYY'))
            return { dates, asins: (ranges?.asins || '').split(',') };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches SOV data from the server based on vendor code
 * @param {string} vendorCode 
 * @param {string} date 
 * @param {string} asin 
 * @returns {Array} Array of SOV data
 */
 export const fetchSalesEstimateData = async (vendorCode, date) => {
    try {
        const url = BASE_URL + api.getSalesEstimateData;

        let params = { vendorCode, date };
        const config = {
            ...tokenConfig(),
            params: params
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        const filteredData = data.map((ele) => {
            return { ...ele, lastKnownPrice: `$${ele?.lastKnownPrice}` }
        })
        if (!data) {
            return {};
        } else {
            return { filteredData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Price War data from the server based on vendor code
 * @param {string} date 
 * @param {string} segment 
 * @returns {Array} Array of SOV data
 */
 export const fetchPriceWarData = async (interval, segmentId, vendorCode) => {
    try {
        const url = BASE_URL + api.getPriceWarData;
        const [startDate, endDate] = interval.split("-");
        let params = { 
            startDate,
            endDate,
            vendorCode,
            segmentId };
        const config = {
            ...tokenConfig(),
            params: params
        }

        const result = await GET_API(url, config.headers, config.params);
        const { priceWarData, asins, productDetails } = result?.data?.data || [];
        const filteredData = modifyPriceAndWarData(asins, priceWarData, productDetails)
        const priceWarGraphData = fetchPriceWarGraphData(asins, priceWarData)
        if (!filteredData) {
            return {};
        } else {
            return { filteredData, priceWarGraphData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Price War ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of intervals and segment Ids  */
export const fetchPriceWarRanges = async (vendorCode) => {
    try {

        if(!vendorCode) {
            return {}
        }
        const url = BASE_URL + api.getPriceWarDataRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const ranges = result?.data?.data || {};
        if (!ranges.intervals) {
            throw new Error('Failed to fetch date ranges and search terms')
        } else {
            return { ...ranges };
        }
    } catch (err) {
        console.log('Price War Ranges', err)
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Brand Segments from the server based on vendor code and date
 * @param {string} vendorCode 
 * @returns {Array} Array of ASINs and their Projections
 */
export const fetchASINPIR = async (vendorCode, granularity, endDate) => {
    try {
        const url = BASE_URL + api.getOrderMetricsASINPIR;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, granularity, endDate }
        }

        const result = await GET_API(url, config.headers, config.params);
        let data = result?.data?.data || [];
        data = data.map(ele => { return { ...ele, pir: Math.round(+ele?.pir) } })
        if (!data) {
            return {};
        } else {

            return { filteredData: data };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Brand Segments from the server based on vendor code and date
 * @param {string} vendorCode 
 * @returns {Array} Array of Ranges
 */
 export const fetchASINPIRRanges = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getOrderMetricsASINPIRRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, granularity: 'week' }
        }

        const result = await GET_API(url, config.headers, config.params);
        let data = result?.data?.data?.dates || [];
        data = data.map(ele => moment(ele.startDate).format('MM/DD/YYYY'))
        if (!data) {
            return {};
        } else {
            return data;
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches SOV data from the server based on vendor code
 * @param {string} date 
 * @param {string} segment 
 * @returns {Array} Array of SOV data
 */
export const fetchCompetitionSalesByASIN = async (vendorCode, interval, segment) => {
    try {
        const url = BASE_URL + api.getCompetitionSalesByASIN;
        const [startDate, endDate] = interval.split("-");
        let params = {
            vendorCode,
            startDate,
            endDate,
            segment
        };
        const config = {
            ...tokenConfig(),
            params: params
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.aggData || [];
        const filteredData = modifyCompetitionSalesByASIN(data)
        if (!filteredData) {
            return {};
        } else {
            return { filteredData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

function compare(a, b) {
    if (a?.parentASIN < b?.parentASIN) {
        return -1;
    }
    if (a?.parentASIN > b?.parentASIN) {
        return 1;
    }
    return 0;
}

/**
 * Fetches Product and Reviews data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of SOV data
 */
 export const fetchProductAndReviews = async (vendorCode, interval, parentASIN = null) => {
    try {
        const url = BASE_URL + api.getProductReviews;
        // const [startDate, endDate] = interval.split("-");
        let params = { 
            // startDate,
            // endDate,
            dateRange: interval?.split(" - ").reverse(),
            parentASIN,
            vendorCode };
        const config = {
            ...tokenConfig(),
            params: params
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = (result?.data?.data || []).map(ele => { return { ...ele, parentASIN: ele?.asin?.original } }).sort(compare);
        const filteredData = modifyProductAndReviewData(data, parentASIN)
        if (!filteredData) {
            return [{}, {}];
        } else {
            return [filteredData, data];
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Price War ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of intervals and segment Ids  */
 export const fetchProductAndReviewsRanges = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getProductReviewsRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || {};

        const minDate = data?._min?.date || '';
        const maxDate = data?._max?.date || '';
        
        let endDate = moment(maxDate);
        let startDate = moment(minDate);

        const dates = [];
        while (startDate < endDate) {
            let interval = `${startDate.format('MM/DD/YYYY')} - ${moment(startDate).add(7, 'day').format('MM/DD/YYYY')}`
            dates.push(interval);
            startDate.add(7, 'day');
        }
        if (!data) {
            return {};
        } else {

            return dates.reverse();
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches all Product and Reviews data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of SOV data
 */
 export const fetchProductAndReviewsAll = async (vendorCode, parentASIN = null) => {
    try {
        const url = BASE_URL + api.getProductReviewsAll;
        let params = { 
            vendorCode };
        const config = {
            ...tokenConfig(),
            params: params
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        const filteredData = modifyProductAndReviewDataAll(data, parentASIN)
        if (!filteredData) {
            return [{}, {}];
        } else {
            return [filteredData, data ];
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Map Violaters ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of dates  */
export const fetchMapViolatersRange = async (vendorCode) => {
    try {

        if(!vendorCode) {
            return []
        }
        const url = BASE_URL + api.getMapViolaterRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {

            return data;
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Map Violaters data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of map violaters  */
 export const fetchMapViolaters = async (vendorCode, selectedDate) => {
    try {
        const url = BASE_URL + api.getMapViolaters;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, selectedDate }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return [];
        } else {
            return modifyMapViolatersData(data);
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Map Violaters data from the server based on vendor code
 * @param {Array} data 
 * @returns {Object} Array of map violaters  */
 export const writeASINSKUMapping = async (mapData) => {
    try {
        const url = BASE_URL + api.uploadASINSKUMapping;

        const config = {
            ...tokenConfig()
        }

        const result = await POST_API(url, config.headers, mapData);
        const data = result?.data?.message || [];
        if (!data) {
            return [];
        } else {
            return data
        }
    } catch (err) {
        return []
    }
}

/**
 * Fetches Map Violaters ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of dates  */
 export const fetchOrderMetricsStats = async (vendorCode, reportType) => {
    try {

        if(!vendorCode || !reportType) {
            return []
        }
        const url = BASE_URL + api.getOrderMetricsStats;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, reportType }
        }

        const result = await GET_API(url, config.headers, config.params);
        let data = result?.data?.data || {};
        data = {
            ...data,
            startDate: moment(data?._min?.startDate).format('MM/DD/YYYY'),
            endDate: moment(data?._max?.startDate).format('MM/DD/YYYY'),
            reportType 
        }
        if (!data || !data?._min?.startDate || !data?._max?.startDate) {
            return [];
        } else {

            return [[data], data?.maxDuration];
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Map Violaters ranges from the server based on vendor code
 * @param {string} vendorCode 
 * @param {string} reportType 
 * @param {string} startDate 
 * @param {string} endDate 
 * @returns {Object} Pulls Order MetricsData into DB  */
 export const generateOrderMetricsData = async (vendorCode, reportType, startDate, endDate) => {
    try {

        if(!vendorCode || !reportType || !startDate || !endDate) {
            return []
        }
        const url = BASE_URL + api.pullOrderMetricsData;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, reportType, startDate, endDate }
        }

        const result = await GET_API(url, config.headers, config.params);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Reach Trend from the server based on vendor code
 * @param {string} vendorCode 
 * @param {string} startDate 
 * @param {string} endDate 
 * @returns {Object} Pulls Order MetricsData into DB  */
 export const fetchReachTrendData = async (vendorCode, startDate, endDate) => {
    try {

        if(!vendorCode || !startDate || !endDate) {
            return []
        }
        const url = BASE_URL + api.getReachTrend;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, startDate, endDate }
        }

        const result = await GET_API(url, config.headers, config.params);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const getVendorSettings = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getVendorSettings;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const getSubCategories = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getSubCategories;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const saveVendorSettings = async (vendorSettings) => {
    try {
        const url = BASE_URL + api.saveVendorSettings;

        const config = {
            ...tokenConfig(),
        }

        const result = await POST_API(url, config.headers, vendorSettings);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const updateVendorSettings = async (vendorSettings) => {
    try {
        const url = BASE_URL + api.updateVendorSettings;

        const config = {
            ...tokenConfig(),
        }

        const result = await POST_API(url, config.headers, vendorSettings);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const saveProductCatalogs = async (body) => {
    try {
        const url = BASE_URL + api.saveProductCatalogs;

        const config = {
            ...tokenConfig(),
        }

        const result = await POST_API(url, config.headers, body);
        return result?.data || {};
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches SubCategories from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of SubCategories name  */
 export const fetchAMSSubCategories = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getAMSSubCategories;
        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
       
        return data;
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches ASINs from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Object} Array of SubCategories name  */
export const fetchCatalogASINs = async (vendorCode) => {
    try {
        const url = BASE_URL + api.catalogGetAPI;
        const config = {
            ...tokenConfig(),
            params: { vendorCode, marketplaceId: "ATVPDKIKX0DER" }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
       
        return data;
    } catch (err) {
        throw new Error(err.message || err)
    }
}
/**
 * Fetches Products from the server based on vendor code and subCategories
 * @param {string} vendorCode 
 * @param {Object} subCategories 
 * @returns {Object} Array of products */
 export const fetchAMSProducts = async (vendorCode,subCategories) => {
    try {
        const url = BASE_URL + api.getAMSProducts;
        const config = {
            ...tokenConfig(),
            params: { vendorCode ,subCategories}
        }
        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        
        return data;
    } catch (err) {
        throw new Error(err.message || err)
    }
}

export const fetch3PReport = async (vendorCode, startDate, endDate) => {
  try {
    const url = BASE_URL + api.pullThreePReport;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchCampaignReports = async () => {
  try {
    const url = BASE_URL + api.fetchCampaignReports;
    const config = {};
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchShareOfVoiceReport = async (vendorCode, startDate, endDate) => {
  try {
    const url = BASE_URL + api.pullShareOfVoiceReport;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchGeographicInsightsReport = async (
  vendorCode,
  startDate,
  endDate
) => {
  try {
    const url = BASE_URL + api.pullGeoReports;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchVendorSalesReport = async (
  vendorCode,
  startDate,
  endDate
) => {
  try {
    const url = BASE_URL + api.pullVendorSalesReport;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchJSSegmentHistory = async (
    segmentId,
    startDate,
    endDate
  ) => {
    try {
      const url = BASE_URL + api.pullJSSegmentReports;
      const config = { 
        ...tokenConfig(),
        params: { segmentId, startDate, endDate }
      };
      const result = await GET_API(url, config.headers, config.params);
      return result.data;
    } catch (err) {
      throw new Error(err.response?.data?.message || err.message);
    }
  };

export const fetchSalesTrafficReports = async (
  vendorCode,
  startDate,
  endDate
) => {
  try {
    const url = BASE_URL + api.fetchSalesTrafficReports;
    const config = { params: { vendorCode, startDate, endDate } };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const catalogRefresh = async (vendorCode) => {
  try {
    const url = BASE_URL + api.catalogRefresh;
    const config = { 
        ...tokenConfig(),
        params: { vendorCode } 
    };
    const result = await GET_API(url, config.headers, config.params);
    return result.data;
  } catch (err) {
    throw new Error(err.response?.data?.message || err.message);
  }
};

export const fetchSearchTermsReport = async () => {
    try {
        const url = BASE_URL + api.fetchSearchTermsReport;
        const config = tokenConfig();
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchKeywordsReport = async () => {
    try {
        const url = BASE_URL + api.fetchKeywordsReport;
        const config = tokenConfig();
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchDapiStatistics = async (month, year) => {
    try {
        const url = BASE_URL + api.fetchDapiStatistics;
        const config = {
            params: { month, year }
        };
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchCohesityOptions = async () => {
    try {
        const url = BASE_URL + api.fetchCohesityOptions;
        const config = tokenConfig();
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};

export const fetchCustomerConfig = async (vendorCode) => {
    try {
        const url = BASE_URL + api.fetchCustomerConfig;
        const config = {
            ...tokenConfig(),
            params: { vendorCode },
        }
        const result = await GET_API(url, config.headers, config.params);
        return result.data;
    } catch (err) {
        throw new Error(err.response?.data?.message || err.message);
    }
};
