import React, {
  useState,
  useRef,
  forwardRef,
} from "react";
import { makeStyles } from "@material-ui/styles";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  success: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  warning: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
  },
  secondary: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  },
}));

export default forwardRef(function Tables({ tableData }, ref) {
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const ISTdateFormatter = (params) => {
    if (!params.value) {
      return null
    }
    return new Date(params.value).toLocaleString("en-IE", {
      timeZone: "Asia/Calcutta",
      timeZoneName: "long"
    });
  };

  const PSTdateFormatter = (params) => {
    if (!params.value) {
      return null
    }
    return new Date(params.value).toLocaleString("en-IE", {
      timeZone: "America/Los_Angeles",
      timeZoneName: "long",
    });
  };

  const [columnDefs, setColumnDefs] = useState([
    { headerName: "User", field: "user" },
    {
      headerName: "Login Time (IST)",
      field: "login",
      width: 350,
      valueFormatter: ISTdateFormatter,
    },
    {
      headerName: "Logout Time (IST)",
      field: "logout",
      width: 350,
      valueFormatter: ISTdateFormatter,
    },
    {
      headerName: "Login Time (PST)",
      field: "login",
      width: 350,
      valueFormatter: PSTdateFormatter,
    },
    {
      headerName: "Logout Time (PST)",
      field: "logout",
      width: 350,
      valueFormatter: PSTdateFormatter,
    },
  ]);

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        columnDefs={columnDefs}
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
      ></AgGridReact>
    </div>
  );
});
