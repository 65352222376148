import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
  Backdrop,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import Piechart from "./components/Piechart/Piechart";

//context
import { useLayoutState } from "../../context/LayoutContext";
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

// styles
import useStyles from "./styles";

//helpers
import { encodeForProxy } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import pages from "../../healpers/pageCodes";
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";

export default function Charts() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var layoutState = useLayoutState();
  var vendorState = useVendorState();

  // local
  const [tableData, setTableData] = useState(null);
  const [piechart_1, setpiechart_1] = useState(null);
  const [piechart_2, setpiechart_2] = useState(null);
  const [fullPageLoader, setfullPageLoader] = useState(false);

  const [radioValue, setradioValue] = useState("weekly");
  const [dateRange, setdateRange] = useState(null);

  const [weekRange, setweekRange] = useState([]);
  const [monthRange, setmonthRange] = useState([]);
  const [quarterRange, setquarterRange] = useState([]);
  const [annualRange, setannualRange] = useState([]);
  const [selectedDateRange, setselectedDateRange] = useState(0);

  const handleRadioChange = (event) => {
    setradioValue(event.target.value);
    if (event.target.value === "weekly") {
      setdateRange(weekRange);  
      setselectedDateRange(0);    
    } else if (event.target.value === "monthly") {
      setdateRange(monthRange);
      setselectedDateRange(0); 
    } else if (event.target.value === "quarterly") {
      setdateRange(quarterRange);
      setselectedDateRange(0); 
    } else {
      setdateRange(annualRange);
      setselectedDateRange(0); 
    }
    // setselectedDateRange(0);
  };

  useEffect(() => {
    if (layoutState.dates) {
      const weekDateRange = cloneAndSortDescending(layoutState.dates["Chargebacks"].weekly);
      const monthDateRange = cloneAndSortDescending(layoutState.dates["Chargebacks"].monthly);
      const quarterDateRange = cloneAndSortDescending(layoutState.dates["Chargebacks"].quarterly);
      const annualDateRange = cloneAndSortDescending(layoutState.dates["Chargebacks"].annually);
      setdateRange(weekDateRange);
      if (weekDateRange.length !== 0)
        setselectedDateRange(0);

      setweekRange(weekDateRange);
      setmonthRange(monthDateRange);
      setquarterRange(quarterDateRange);
      setannualRange(annualDateRange);
    }
    return () => {
      setdateRange(null);
      setweekRange([]);
      setmonthRange([]);
      setquarterRange([]);
      setannualRange([]);
    };
  }, [layoutState.dates]);

  useEffect(() => {
    async function loadData(range) {
      try {
        setfullPageLoader(true);

        var encodedString = encodeForProxy(
          pages.chargebacks,
          vendorState.selected,
          dateRange
            ? moment(dateRange[selectedDateRange].startdate).format(
                "MM-DD-YYYY"
              )
            : moment(range[selectedDateRange].startdate).format("MM-DD-YYYY"),
          dateRange
            ? moment(dateRange[selectedDateRange].enddate).format("MM-DD-YYYY")
            : moment(range[selectedDateRange].enddate).format("MM-DD-YYYY")
        );

        axios
          .get(BASE_URL + api.getCache + encodedString, tokenConfig() )
          .then((result) => {
            if (result.data.data) {
              setpiechart_2(result.data.data.piechart_2);
              setpiechart_1(result.data.data.piechart_1);
              setTableData(result.data.data.tableData);
              setfullPageLoader(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setfullPageLoader(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

    if (dateRange && dateRange.length != 0) {
      loadData();
    }

    return () => {
      setTableData(null);
      setpiechart_1([]);
      setpiechart_2([]);
    };
  }, [radioValue, dateRange, selectedDateRange]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Chargebacks"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                Shortage Claims and Chargebacks
              </Link>
              <Typography className={classes.link} color="primary">
                Chargebacks
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl style={{ marginRight: "8%" }}>
                <RadioGroup value={radioValue} onChange={handleRadioChange}>
                  <FormControlLabel
                    value="weekly"
                    control={<Radio color="primary" />}
                    label="Weekly"
                  />
                  <FormControlLabel
                    value="monthly"
                    control={<Radio color="primary" />}
                    label="Monthly"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl style={{ marginRight: "10%" }}>
                <RadioGroup value={radioValue} onChange={handleRadioChange}>
                  <FormControlLabel
                    value="quarterly"
                    control={<Radio color="primary" />}
                    label="Quarterly"
                  />
                  <FormControlLabel
                    value="yearly"
                    control={<Radio color="primary" />}
                    label="Yearly"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Range
                </InputLabel>
                <Select
                  value={selectedDateRange}
                  onChange={(e) => {
                    setselectedDateRange(e.target.value);
                  }}
                  label="Select Range"
                  disabled={dateRange && dateRange.length === 0 ? true : false}
                >
                  {dateRange && dateRange.length != 0 ? (
                    dateRange.map((range, index) => {
                      return (
                        <MenuItem value={index}>
                          {range.startdate + " - " + range.enddate}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              // header={
              //   <div className={classes.mainChartHeader}>
              //     <Typography
              //       variant="h2"
              //       gutterBottom
              //       style={{
              //         fontWeight: 600,
              //         fontSize: 20,
              //         color: "black",
              //       }}
              //     >
              //       Total Chargebacks
              //     </Typography>
              //   </div>
              // }
            >
              <Piechart pieData={piechart_1} />
            </Widget>
          </Grid>
          <Grid item xs={12} md={6}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              // header={
              //   <div className={classes.mainChartHeader}>
              //     <Typography
              //       variant="h2"
              //       gutterBottom
              //       style={{
              //         fontWeight: 600,
              //         fontSize: 20,
              //         color: "black",
              //       }}
              //     >
              //       Disputed
              //     </Typography>
              //   </div>
              // }
            >
              <Piechart pieData={piechart_2} />
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    Recent Transactions
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export CSV
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={tableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
          // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
