import React from "react";
import axios from "axios";

import { BASE_URL } from "../healpers/api";
import api from "../healpers/apiRoutes";
import { tokenConfig } from './UserContext';

var LayoutStateContext = React.createContext();
var LayoutDispatchContext = React.createContext();

function layoutReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR":
      return { ...state, isSidebarOpened: !state.isSidebarOpened };
    case "GET_ALL_DATES":
      return { ...state, dates: action.list };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function LayoutProvider({ children }) {
  var [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: true,
    dates: null
  });
  return (
    <LayoutStateContext.Provider value={state} displayName="Layout Context">
      <LayoutDispatchContext.Provider value={dispatch}>
        {children}
      </LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  );
}

function useLayoutState() {
  var context = React.useContext(LayoutStateContext);
  if (context === undefined) {
    throw new Error("useLayoutState must be used within a LayoutProvider");
  }
  return context;
}

function useLayoutDispatch() {
  var context = React.useContext(LayoutDispatchContext);
  if (context === undefined) {
    throw new Error("useLayoutDispatch must be used within a LayoutProvider");
  }
  return context;
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar, fetchAllDates };

// ###########################################################
function toggleSidebar(dispatch) {
  dispatch({
    type: "TOGGLE_SIDEBAR",
  });
}

function fetchAllDates(dispatch, datesCode) {
  if(datesCode){
    axios.get( BASE_URL + api.getCache + datesCode + "_allDates" , tokenConfig() )
      .then((res)=>{
        dispatch({type:"GET_ALL_DATES",list:res.data.data})
      })
      .catch((err)=>{
        console.log(err)
      })
  }
}