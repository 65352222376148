import React, { useState, useEffect } from "react";
import { KeyboardTimePicker } from "@material-ui/pickers";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Modal } from "react-responsive-modal";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useVendorState } from "../../../context/VendorContext";
import { BASE_URL } from "../../../healpers/api";
import api from "../../../healpers/apiRoutes";
import { tokenConfig } from "../../../context/UserContext";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: 400,
  },
}));

export default function ScheduleDayPartingCampaign({
  open,
  close,
  campaignName,
  isCreate,
  campaignData,
}) {
  var vendorState = useVendorState();
  const classes = useStyles();

  const [selectedStartTime, setSelectedStartTime] = useState(null);
  const [selectedEndTime, setSelectedEndTime] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const [type, setType] = useState("CAMPAIGN");
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState([]);

  async function loadData() {
    try {
      let params = {
        vendorCode: vendorState?.selected,
        campaignState: "enabled",
        campaignType: "sp",
      };
      axios
        .get(BASE_URL + api.getAMSCampaigns, tokenConfig(params))
        .then((result) => {
          if (result.data.data) {
            setCampaigns(result.data.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (vendorState.selected != 0) {
      loadData();
    }
    return () => {
      setCampaigns(null);
    };
  }, [vendorState.selected, type]);

  useEffect(() => {
    if (!isCreate && campaignData) {
      setSelectedDays(campaignData.selectedDays);
      setSelectedStartTime(campaignData.startTime);
      setSelectedEndTime(campaignData.endTime);
    }
  }, [campaignData]);

  const createDayParting = async () => {
    try {
      const campaigns = selectedCampaign.map((item) => {
        return {
          campaignId: type == "campaign" ? item.campaignId : "-",
          campaignName: type == "campaign" ? item.name : "-",
          campaignStartTime: selectedStartTime && moment(selectedStartTime).format("HH:mm"),
          campaignEndTime: selectedEndTime && moment(selectedEndTime).format("HH:mm"),
          selectedDays: selectedDays,
        }
      })
      const body = {
        campaigns: campaigns,
        vendorCode: vendorState.selected,
        type: type.toUpperCase(),
        campaignStartTime: selectedStartTime && moment(selectedStartTime).format("HH:mm"),
        campaignEndTime: selectedEndTime && moment(selectedEndTime).format("HH:mm"),
        selectedDays: selectedDays,
      };
      await axios.post(BASE_URL + api.createDayParting, body);
    } catch (error) {
      console.log(error);
    } finally {
      close();
    }
  };

  const addDays = (item) => {
    const index = selectedDays.indexOf(item);
    if (index === -1) {
      setSelectedDays([...selectedDays, item]);
    } else {
      setSelectedDays(selectedDays.filter((day) => day !== item));
    }
  };

  const returnColor = (item) => {
    return selectedDays?.includes(item) ? "#4b50dc" : "#bdbdbd";
  };

  const returnTextColor = (item) => {
    return selectedDays?.includes(item) ? "white" : "black";
  };

  const weekDays = [
    { label: "S", value: "sunday" },
    { label: "M", value: "monday" },
    { label: "T", value: "tuesday" },
    { label: "W", value: "wednesday" },
    { label: "T", value: "thursday" },
    { label: "F", value: "friday" },
    { label: "S", value: "saturday" },
  ];

  const onModalClose = () => {
    setSelectedDays([]);
    setSelectedEndTime(null);
    setSelectedStartTime(null);
    setType(null);
    setSelectedCampaign([]);
    setCampaigns([]);
    // close
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          onModalClose();
          close();
        }}
        styles={{ modal: { width: 520, borderRadius: 10 } }}
        center
        closeOnOverlayClick
      >
        <Grid container>
          <div style={{ paddingBottom: "10px", fontSize: "18px" }}>
            Add Schedule
          </div>
        </Grid>
        <Grid container>
          <div style={{ paddingBottom: "20px", fontSize: "12px" }}>
            {campaignName}
          </div>
        </Grid>
        {isCreate && (
          <Grid>
            <Grid container style={{ paddingBottom: "20px" }}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="type-label"> Type</InputLabel>
                <Select
                  labelId="type-label"
                  value={type}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                  name="Type"
                  label=" Type"
                >
                  <MenuItem value={"vendor"}>Vendor</MenuItem>
                  <MenuItem value={"campaign"}>Campaigns</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {type == "campaign" && (
              <Grid container style={{ paddingBottom: "20px" }}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="account-type-label">
                    Select Campaigns
                  </InputLabel>
                  <Select
                    labelId="account-type-label"
                    // multiple
                    value={selectedCampaign[0]}
                    onChange={(e) => {
                      setSelectedCampaign([e.target.value]);
                      console.log("selectedCampaign", selectedCampaign);
                    }}
                    name="campaigns"
                    label="Select Campaigns"
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {campaigns ? (
                      campaigns.map((campaign) => {
                        return (
                          <MenuItem value={campaign}>
                            {campaign.name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <MenuItem value={0}>
                        <i>No Data</i>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        )}
        <Grid container style={{ gap: "20px" }} justifyContent="center">
          {weekDays.map((item) => (
            <Grid
              key={item.value}
              onClick={() => addDays(item.value)}
              fullWidth
              justifyContent="center"
              alignItems="center"
              style={{
                backgroundColor: returnColor(item.value),
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                color: returnTextColor(item.value),
              }}
            >
              {item.label}
            </Grid>
          ))}
        </Grid>
        <Grid container style={{ gap: "20px" }} justifyContent="center">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Start Time(PST)"
              value={selectedStartTime}
              onChange={(time) => {
                setSelectedStartTime(time);
              }}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="End Time(PST)"
              value={selectedEndTime}
              onChange={(time) => {
                setSelectedEndTime(time);
              }}
              KeyboardButtonProps={{
                "aria-label": "change time",
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          container
          fullWidth
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ padding: "30px" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => createDayParting()}
          >
            Add Schedule
          </Button>
        </Grid>
      </Modal>
    </div>
  );
}
