import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  Backdrop,
} from "@material-ui/core";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useVendorState } from "../../../context/VendorContext";
import useStyles from "../styles";
import { JS_SEGMENT_TYPES, REPORT_TYPES } from "../../../constant/jungleScout";
import api from "../../../healpers/apiRoutes";
import { BASE_URL } from "../../../healpers/api";
import axios from "axios";
import { tokenConfig } from "../../../context/UserContext";

export default function ImportSegmentModal({ open, close, fetchSegments }) {
  const vendorState = useVendorState();
  const classes = useStyles();

  const [reportTypes, setReportTypes] = useState([]);
  const [segmentId, setSegmentId] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);

  const onModalClose = () => {
    setReportTypes([]);
    setSegmentId("");
    setType("");
  };

  const importSegment = async () => {
    setLoading(true);
    const marketplace = (vendorState?.selected?.split("_").reverse())[1];
    try {
      let body = {
        segmentId: segmentId,
        reportTypes,
        vendorCode: vendorState?.selected,
        marketplace: marketplace.toLowerCase(),
        type
      };

      await axios.post(BASE_URL + api.importJSSegment, body, tokenConfig());
      close();
      onModalClose();
      fetchSegments();
    } catch (error) {
      console.error(error);
      alert("Failed to import segment");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          close();
          onModalClose();
        }}
        styles={{ modal: { width: 520, borderRadius: 10 } }}
        center
        closeOnOverlayClick
      >
        <Backdrop />
        <Grid container>
          <Typography style={{ fontSize: 16, fontWeight: 600 }}>
            Import JS Segment
          </Typography>
        </Grid>
        <Grid container style={{ paddingTop: "20px" }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="text"
              value={segmentId}
              id="js-segment-id"
              name="name"
              onChange={(event) => setSegmentId(event.target.value)}
              label="Segment ID"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid container style={{ paddingTop: "10px" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="advertising-goal-label">Type</InputLabel>
            <Select
              labelId="advertising-goal-label"
              value={type}
              onChange={(event) => setType(event.target.value)}
              name="type"
              label="Type"
            >
              <MenuItem value={JS_SEGMENT_TYPES.TEMPORARY}>Temporary Segment</MenuItem>
              <MenuItem value={JS_SEGMENT_TYPES.PERMANENT}>Permanent Segment</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid container style={{ paddingTop: "20px" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="screen-type-label">Screen Type</InputLabel>
            <Select
              labelId="screen-type-label"
              value={reportTypes}
              onChange={(event) => {
                setReportTypes(event.target.value);
              }}
              name="reportTypes"
              label="Report Types"
              multiple
            >
              {REPORT_TYPES.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        <Grid
          container
          fullWidth
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.importButtonContainer}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={loading}
            onClick={importSegment}
          >
            Import Segment
          </Button>
        </Grid>
      </Modal>
    </div>
  );
}
