import React, { useState, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import Alert from '@material-ui/lab/Alert';

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import Modal from "./components/Modal/Modal";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

//context
import { tokenConfig } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();

  // local
  const [userList, setuserList] = useState(null)
  const [showmodal, setshowmodal] = useState(false)
  const [selectedUser, setselectedUser] = useState(null)
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);

  useEffect(() => {
    axios.get( BASE_URL + api.listUsers , tokenConfig() )
      .then((res)=>{
        setuserList(res.data)
      })
      .catch((err)=>{
        console.log(err)
      })
  }, [])

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="All Users"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Users
              </Link>
              <Typography className={classes.link} color="primary">
                All Users
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
            >
              <Table tableData={userList} enableModal={()=>setshowmodal(true)} selectUser={(usr)=>setselectedUser(usr)}/>
            </Widget>
          </Grid>
        </Grid>
        {notification ? (
          <Alert
            severity="success"
            style={{ position: "absolute", bottom: "-35%", left: "45%" }}
          >
            {notification}
          </Alert>
        ) : (
          <></>
        )}
        {err ? (
          <Alert
            severity="error"
            style={{ position: "absolute", bottom: "-35%", left: "45%" }}
          >
            {err}
          </Alert>
        ) : (
          <></>
        )}
        <Modal showmodal={showmodal} closeModal={()=>{
          setshowmodal(false)
          setselectedUser(null)
        }} selectedUser={selectedUser} toggleError={(msg)=>seterr(msg)} toggleNotification={(msg)=>setnotification(msg)} />
      </Container>
    </>
  );
}
