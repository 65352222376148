import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },  
  success: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
}))

const states = {
  sent: "success",
  warning: "warning",
  secondary: "secondary",
};

export default forwardRef ( function Tables(props,ref) {
  
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
      console.log(params)
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  var checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };

  var headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
        return { background: '#F6F7FF' };
    }
  };

  const StatusRenderer = (props) => {
    return <Chip label={props.value} classes={{root: classes[states[props.value.toLowerCase()]]}} style={{width:100}}/>
  };

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{height: 610, width: '100%'}} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={props.tableData}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                },
            }}            
            onGridReady={onGridReady}
            frameworkComponents={{
              statusCellRenderer: StatusRenderer,
            }}
            pagination={true}
            paginationPageSize={10}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
        >
            <AgGridColumn headerName="Account Name" field="Account Name" checkboxSelection={checkboxSelection} headerCheckboxSelection={headerCheckboxSelection} width={210}></AgGridColumn>
            <AgGridColumn headerName="Dispute ID" field="Dispute ID" width={170}></AgGridColumn>
            <AgGridColumn headerName="Invoice" field="Invoice" width={140}></AgGridColumn>
            <AgGridColumn headerName="PO" field="PO" width={120}></AgGridColumn>
            <AgGridColumn headerName="Dispute Date" field="Dispute date" width={140}></AgGridColumn>
            <AgGridColumn headerName="Dispute status" field="Dispute status" width={150}></AgGridColumn>
            <AgGridColumn headerName="Total Disputed Amount" field="Total disputed amount" width={150}></AgGridColumn>
            <AgGridColumn headerName="Approved Amount" field="Approved Amount" cellStyle={cellStyleApprovedAmout} width={150}></AgGridColumn>
            <AgGridColumn headerName="Approved (Yes/No)" field="Approved (Yes/No)" ></AgGridColumn>
            <AgGridColumn headerName="Case ID (1st Attempt)" field="Case ID (1st Attempt)" ></AgGridColumn>
            <AgGridColumn headerName="Date (Case 1)" field="Date (Case 1)" ></AgGridColumn>
            <AgGridColumn headerName="Approved (Yes/No)" field="Approved (Yes/No) 2" ></AgGridColumn>
            <AgGridColumn headerName="Amount" field="Amount" ></AgGridColumn>
            <AgGridColumn headerName="Case ID (2nd Attempt)" field="Case ID (2nd Attempt)" ></AgGridColumn>
            <AgGridColumn headerName="Date (Case 2)" field="Date (Case 2)" ></AgGridColumn>
            <AgGridColumn headerName="Approved (Yes/No)" field="Approved (Yes/No) 3" ></AgGridColumn>
            <AgGridColumn headerName="Amount" field="Amount 2" ></AgGridColumn>
        </AgGridReact>
    </div>
  );
})

function cellStyleApprovedAmout(params) {
  var color;
    if(params.data["Approved Amount"] === "")
      color = null
    else if (params.data["Approved Amount"]===params.data["Total disputed amount"])
      color = '#d8e9d2'
    else color = '#fae4cd'
  return { 
    backgroundColor: color,
    borderBottom: '1px solid rgba(178,178,178,0.25)'
  };
}
