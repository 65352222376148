import moment from 'moment';

/**
 * Filters the data either based on duration / min date and max date
 * @param {Array} data - Array of objects.(startDate required for the object)
 * @param {Object} duration - Object with two keys(amount and unit).
 * @param {string} minDate - Custom minimum date, By Default data's first date is considered as min date.
 * @param {string} maxDate - Custom maximum date.
 * @returns 
 */
export const filterDataByDate = (data) => {

    return (data || []).map((ele) => {
        return {
            ...ele,
            startDate: moment(ele.startDate).format('MM/DD/YYYY'),
            endDate: moment(ele.endDate).format('MM/DD/YYYY'),
            searchTerm: `$${ele.searchTerm}`
        }
    });
}


/**
 * Changes the format for a few fields in the Order Metrics Response.
 * @param {Array} data - Array of order metrics.
 * @param {string} granularity - Hour/Day. Decides the format of the date in the table.
 * @returns {Array} Returns formatted order metrics in an array format.
 */
export const modifyShareOfVoiceForTable = (data) => {
    return (data || []).map((ele) => {
        return {
            ...ele,
            startDate: moment(ele.startDate).format('MM/DD/YYYY'),
            endDate: moment(ele.endDate).format('MM/DD/YYYY'),
            averagePrice: `$${ele?.averagePrice || 0.0}`,
            weightedSOV: `${Number(Number(ele?.weightedSOV || 0)*100).toFixed(2)}%`,
            basicSOV: `${Number(Number(ele?.basicSOV || 0)*100).toFixed(2)}%` ,
            brandName: `${ele.brandName}` 
        }
    });
}


// get serachTerms list
export const filterDataBySearchTerm = (data) => {

    return (data || []).map((ele) => {
        return {
            ...ele,
            searchTerm: `$${ele.searchTerm}`
        }
    });
}
