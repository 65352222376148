import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  header:{
    width:"100%",
    backgroundColor:"white",
    padding:"20px",
    marginBottom:"30px"
  },
  link: {
    display: 'flex',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: theme.palette.primary.main,
    backgroundColor:'rgba(255,255,255,0.5)',
  },
}));
