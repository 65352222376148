import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine
} from "recharts";
import { noCurve } from "../../../../healpers/noCurve";
import {
  numberWithCommas,
} from "../../../../healpers/utilityFunctions";

// components
import Dot from "../../../../components/Sidebar/components/Dot";

// context
import { useLayoutState } from "../../../../context/LayoutContext";

/*
const CustomTooltip = (props) => {
  if (props.active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffff",
          padding: "5px",
          border: "1px solid #cccc",
        }}
      >
        <label style={{ fontWeight: "bold" }}>
          <u>
            <i>{props.label}</i>
          </u>
        </label>
      </div>
    );
  }

  return null;
};
*/



export default function App({ colors, label, linechartData, asinList }) {
  const CustomTooltip = ({ active, payload, label: xLabel }) => {
    const labelStyle = { 
      fontWeight: 'bold',
    }
    if (active && payload && payload.length) {
      return (
        <div style={{ background: "white", padding: "10px 20px", border: "1px solid gray"}} className="custom-tooltip">
          <p style={labelStyle} className="label">{`${label} : ${xLabel}`}</p>
          <p style={labelStyle} className="label">{`Current Year(${payload[0].payload.CurrentYear}) : ${payload[0].value}`}</p>
          <p style={{ ...labelStyle, color: "blue" }} className="label">{`Previous Year(${payload[0].payload.PreviousYear}) : ${payload[1].value}`}</p>
        </div>
      );
    }
  
    return null;
  };
    return (
      <LineChart data={linechartData}  width={1200} height={600} margin={{ right: 300, bottom: 50 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="key" fontSize={9} minTickGap={-50} interval={0} angle={-45} textAnchor="end" label={{ value: label, position: 'insideBottom', offset: -40 }}  padding={{ left: 50, right: 40 }}/>
        <YAxis padding={{ left: 50, right: 40 }}/>
        <Tooltip content={<CustomTooltip />}/>
            <Line type="monotone" dataKey="CurrentYearSum" stroke="black" activeDot={{ r: 8 }} />
            <Line dataKey="PreviousYearSum" stroke="blue" activeDot={{ r: 8 }} />
      </LineChart>
    );
}


      