import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import greenTick from "../../../../images/Green-tick.jpg";
import redCross from "../../../../images/Red-cross.png";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  success: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  warning: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
  },
  secondary: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  },
}));

export default forwardRef(function Tables({ tableData, enableModal, selectUser}, ref) {
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else gridApi.exportDataAsCsv();
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  const ButtonRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize", width: 100 }}
        onClick={()=>{
          selectUser(props.data)
          enableModal()
        }}
      >
        Edit
      </Button>
    );
  };

  const ImageRenderer = (props) => {
    return (
      <img
        src={props.value ? greenTick : redCross}
        alt="True"
        style={{ width: 15, height: 15 }}
      />
    );
  };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        frameworkComponents={{
          buttonRenderer: ButtonRenderer,
          imageRenderer: ImageRenderer,
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
      >
        <AgGridColumn headerName="Email" field="email" width={200} />
        <AgGridColumn headerName="Permissions">
          <AgGridColumn
            headerName="Sales"
            cellRenderer="imageRenderer"
            field="permissions_module_level.sales.allowed"
            width={120}
          />
          <AgGridColumn
            headerName="Shortageclaims & Chargebacks"
            cellRenderer="imageRenderer"
            field="permissions_module_level.shortageclaimsAndChargebacks.allowed"
            width={170}
          />
          <AgGridColumn
            headerName="Business Intelligence"
            cellRenderer="imageRenderer"
            field="permissions_module_level.businessIntelligence.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Advertising"
            cellRenderer="imageRenderer"
            field="permissions_module_level.advertising.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Operations"
            cellRenderer="imageRenderer"
            field="permissions_module_level.operations.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Creative"
            cellRenderer="imageRenderer"
            field="permissions_module_level.creative.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Amazon Advertising"
            cellRenderer="imageRenderer"
            field="permissions_module_level.amazonAdvertising.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Catalog"
            cellRenderer="imageRenderer"
            field="permissions_module_level.catalog.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Users"
            cellRenderer="imageRenderer"
            field="permissions_module_level.users.allowed"
            width={150}
          />
          <AgGridColumn
            headerName="Crons Management"
            cellRenderer="imageRenderer"
            field="permissions_module_level.cronsManagement.allowed"
            width={150}
          />
        </AgGridColumn>
        <AgGridColumn
          cellRenderer="buttonRenderer"
          cellEditorParams={{
            cellRenderer: "buttonRenderer",
          }}
          width={200}
        />
      </AgGridReact>
    </div>
  );
});
