/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import ReviewBox from "./components/reviewBox/reviewBox";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchProductAndReviews, fetchProductAndReviewsRanges } from "../../http/api";

import useStyles from "./styles";

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

export default function Charts(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [dateRange, setDateRange] = useState([]);
  const [totalData, setTotalData] = useState([]);
  const [reviewBoxData, setReviewBoxData] = useState({});
  const [mainTableData, setmainTableData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState('');
  const [fullPageLoader, setfullPageLoader] = useState(false);

  useEffect(() => {
    fetchProductAndReviewsRanges(vendorState?.selected)
      .then((intervals) => {
        setDateRange(intervals);
        setSelectedInterval(intervals[0])
      }).catch((err) => {
        console.error('Failed to fetch the ranges')
      })

      return () => {
        setDateRange([]);
        setSelectedInterval('')
      }
  }, [vendorState?.selected, props?.match?.params?.asin])

  useEffect(() => {
    async function fetchData() {
      try {

        setfullPageLoader(true);
        let productAndReviewData = null, data = [];
        if (props?.match?.params?.asin) {
          [productAndReviewData, data ] = await fetchProductAndReviews(null, null, props?.match?.params?.asin)
        } else {
          [productAndReviewData, data ] = await fetchProductAndReviews(vendorState?.selected, selectedInterval)
        }
        setmainTableData(productAndReviewData);
        setTotalData(data);
        setReviewBoxData(data[0])

      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if (vendorState?.selected && (selectedInterval || props?.match?.params?.asin)) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
      setTotalData([]);
      setReviewBoxData('')
    };

  }, [selectedInterval, vendorState?.selected, props?.match?.params?.asin]);

  const updateData = (id, offset) => {

    const index = totalData.findIndex((ele, i) => ele.id === id)
    setReviewBoxData(totalData[index + offset])
  }

  const shouldDisplay = (icon) => {
    if (props?.match?.params?.asin) {
      return classes.hideArrowIcons
    }
    const index = totalData.findIndex((ele, i) => ele.id === reviewBoxData?.id)
    if (icon === 'left') {
      if (index <= 0) {
        return classes.arrowIconDisabled;
      }
    } else {
      if (index >= totalData?.length - 1) {
        return classes.arrowIconDisabled;
      }
    }
    return classes.arrowIcon;
  }

  const goBack = () => {
    props.history.goBack()
  }

  const getSubHeaderLinkClass = () => {
    if (props?.match?.params?.asin) {
      return classes.subHeaderlinkActive;
    } else {
      return classes.subHeaderlink;
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Ratings and Reviews"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                Sales
              </Link>
              <Typography className={getSubHeaderLinkClass()} st onClick={goBack}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Ratings and Reviews
              </Typography>
              {props?.match?.params?.asin &&
                <Typography className={classes.link}>
                  {props?.match?.params?.asin}
                </Typography>
              }
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              {!props?.match?.params?.asin && <FormControl variant="outlined" className={classes.campaignControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Interval
                </InputLabel>
                <Select
                  value={selectedInterval || ''}
                  onChange={(e) => {
                    setSelectedInterval(e.target.value);
                  }}
                  label="Select Interval"
                  disabled={dateRange && dateRange.length === 0 ? true : false}
                >
                  {dateRange && dateRange.length ? (
                    dateRange.map((range, index) => {
                      return (
                        <MenuItem key={index} value={range}>
                          {range}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>}
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {reviewBoxData && <Widget
              bodyClass={classes.reviewBoxBody}
              headerClass={classes.reviewBoxHeader}
              header={
                <>
                  <ChevronLeftIcon onClick={() => { updateData(reviewBoxData?.id, -1) }} className={shouldDisplay('left')} />
                  <ChevronRightIcon onClick={() => { updateData(reviewBoxData?.id, 1) }} className={shouldDisplay('right')} />
                </>}
            >
              <Typography className={classes.reviewBoxMeta}>{`ASIN - ${reviewBoxData?.asin?.original} | Title - ${reviewBoxData?.productTitle || 'NA'} | SKU - ${reviewBoxData?.sku || 'NA'}`} </Typography>
              <ReviewBox data={reviewBoxData} updateData={updateData} />
            </Widget>}
          </Grid>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef}
                history={props.history}
                reRoute={!!props?.match?.params?.asin}
                tableData={mainTableData} setReviewBoxData={setReviewBoxData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
