import React from "react";
import Grid from '@material-ui/core/Grid';

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Grid container xs={8} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.typo}  
            style={{
              fontWeight:600,
              fontSize:24,
              color:'black'
            }}>
              {props.title}
            </Typography>
        </Grid>
        <Grid item xs={12}>
          {props.breadCrump && props.breadCrump}
        </Grid>
      </Grid>      
      {props.button && props.button}
      {props.rightAlignComponent && props.rightAlignComponent}
    </div>
  );
}

