/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import moment from "moment";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import {
  fetchCohesityNotificationTypes,
  fetchCohesityNotifications,
  fetchEventNotificationsTypeData,
} from "../../http/eventapi";

import useStyles from "./styles";

export default function CohesityNotifications(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [searchTerms, setSearchTerms] = useState([]);
  const [mainTableData, setmainTableData] = useState([]);
  const [selectedSearchTerm, setselectedSearchTerm] = useState("");
  const [fullPageLoader, setfullPageLoader] = useState(false);

  useEffect(() => {
    fetchCohesityNotificationTypes(vendorState?.selected)
      .then((notificationTypes) => {
        setselectedSearchTerm(["searchTerms", ...notificationTypes]);
      })
      .catch((err) => {
        console.error(
          "Failed to fetch notificationTypes!!",
          err.message || err
        );
        setselectedSearchTerm([]);
      });
  }, [vendorState?.selected]);

  useEffect(() => {
    fetchCohesityNotifications(vendorState?.selected)
      .then(({ sTerms }) => {
        setSearchTerms(sTerms);
        setselectedSearchTerm(sTerms[0]);
      })
      .catch((err) => {
        console.error("Failed to fetch the search terms");
      });
  }, [vendorState?.selected]);

  useEffect(() => {
    async function fetchData() {
      try {
        setfullPageLoader(true);
        const { filteredData } = await fetchCohesityNotifications(
          vendorState.selected,
          selectedSearchTerm
        );

        setmainTableData(filteredData);
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    fetchData();

    return () => {
      setmainTableData([]);
    };
  }, [selectedSearchTerm]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Cohesity Notifications"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                <EqualizerIcon className={classes.icon} />
                Cohesity
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Notifications
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
          // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
