import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";

// context
import { useLayoutState } from "../../../../context/LayoutContext";

highchartsMore(Highcharts);

export default function App(props) {
  // global
  var layoutState = useLayoutState();

  //local
  const chartRef = useRef();

  // To make map responsive
  useEffect(() => {
    setTimeout(() => {
        if (chartRef.current && chartRef.current.chart && chartRef.current.chart.index) {
          Highcharts.charts[chartRef.current.chart.index].reflow();
        }
      }, 200);
  }, [layoutState.isSidebarOpened]);

  const MapOptions = {
    title: null,

    xAxis: {
      categories: props.labels,
    },

    yAxis: {
      visible: true,
    },

    series: [
      {
        type: "column",
        name: props.barName,
        data: props.barData,
      },
      {
        type: "spline",
        name: props.lineName,
        data: props.lineData,
        marker: {
          lineWidth: 2,
          lineColor: Highcharts.getOptions().colors[3],
          fillColor: "white",
        },
      },
    ],

    credits: {
      enabled: false,
    },
  };

  if (props.barData && props.barData.length)
  return (
    <div
      style={{
        width: "80%",
        margin: "auto",
      }}
    >
      <HighchartsReact constructorType="chart" highcharts={Highcharts} options={MapOptions} ref={chartRef} />
    </div>
  );
  else return <h3 style={{ textAlign: "center", margin: "16% 0" }}>No Data To Show</h3>;
}
