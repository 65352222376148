import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import * as XLSX from "xlsx";

//icons
import RefreshIcon from "@material-ui/icons/Refresh";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import BlockIcon from '@material-ui/icons/Block';
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import ConfirmDialog from "./components/ComfirmDialog/ConfirmDialog";
import Table from "./components/Table/Table";
import Modal from "./components/Modal/Modal"

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [file, setfile] = useState({ name: "No File uploaded" });
  const [confirmDialog, setconfirmDialog] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [estimatedTime, setestimatedTime] = useState(null);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [selectedStatus, setSelectedStatus] = React.useState('all');

  const handleSelectedStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  async function uploadExcelSheet(e) {
    console.log("uploadExcelSheet")
    const file = e.target.files[0];
    if (file.name.split(".")[1] !== "xlsx") {
      seterr("Invalid file type")
      setTimeout(() => {
        seterr(null)
      }, 2000)
      return
    }
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 2 });

      const errPromise = new Promise((resolve, reject) => {
        if (data.length === 0) {
          reject("Empty file");
        }
        data.forEach((obj, index) => {
          if (
            !obj["Domain"] ||
            !obj["Type"] ||
            !["included", "endswith"].includes(obj["Type"])
          ) {
            reject(`Error at line ${index + 2}`);
          }
        });
        resolve("No error found");
      });

      errPromise
        .then(() => {
          setfile(file);
          setconfirmDialog(true);
          convertSeconds((data.length - 1) * 5 * 2); //rough time to fetch API is 5 sec.
        })
        .catch((err) => {
          seterr(err);
          document.getElementById("upload-csv").value = "";
          setTimeout(() => {
            seterr(null);
          }, 2000);
          setfile({ name: "No File uploaded" });
          setconfirmDialog(false);
          setestimatedTime(null);
        });
    };
    reader.readAsBinaryString(file);
  }

  function convertSeconds(seconds) {
    var convert = function (x) {
      return x < 10 ? "0" + x : x;
    };
    setestimatedTime(
      convert(parseInt(seconds / (60 * 60))) +
        ":" +
        convert(parseInt((seconds / 60) % 60)) +
        ":" +
        convert(seconds % 60)
    );
  }

  const fetchData = () => {
    setfullPageLoader(true);
    axios
      .get(BASE_URL + api.listBlockedDomains, { params: { status: selectedStatus } }) // need to tokenize later
      .then((res) => {
        setmainTableData(res.data.data);
        setfullPageLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setfullPageLoader(false);
      });
  };

  useEffect(() => {
    if (userState.userData) {
      fetchData();
    }
  }, [userState.userData, selectedStatus]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Upload File"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Brand Finder
              </Link>
              <Typography className={classes.link} color="primary">
                Blocked Domains
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  defaultValue={selectedStatus}
                  value={selectedStatus}
                  onChange={handleSelectedStatusChange}
                  label="Select Type"
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"blocked"}>Blocked</MenuItem>
                  <MenuItem value={"skipped"}>Skipped</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  size="medium"
                  startIcon={<BlockIcon />}
                  onClick={handleOpen}
                >
                  Block domain
                </Button>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <label htmlFor="upload-csv">
                  <input
                    style={{ display: "none" }}
                    id="upload-csv"
                    name="upload-csv"
                    type="file"
                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={(e) => uploadExcelSheet(e)}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="medium"
                    startIcon={<PublishIcon />}
                  >
                    Upload domains
                  </Button>
                </label>
                <Typography
                  className={classes.link}
                  color="primary"
                  style={{ fontSize: 12, margin: "5% 0% 0% 1%" }}
                >
                  {file.name}
                </Typography>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                fetchData={fetchData}
                classes={classes}
                setnotification={setnotification}
                seterr={seterr}
              />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Modal
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        seterr={seterr}
        setnotification={setnotification}
        fetchData={fetchData}
      />
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog
        open={confirmDialog}
        toggleDialog={() => setconfirmDialog(!confirmDialog)}
        file={file}
        setFile={setfile}
        estimatedTime={estimatedTime}
        setnotification={setnotification}
        seterr={seterr}
        fetchData={fetchData}
      />
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
