import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import api from "../../healpers/apiRoutes";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import AreaChart from "./components/AreaChart/AreaChart";
import LeftColumn from "./components/LeftColumn/LeftColumn";
import { useVendorState } from "../../context/VendorContext";

//styles
import useStyles from "./styles";

import axios from "axios";
import { BASE_URL } from "../../healpers/api";
import { tokenConfig } from "../../context/UserContext";
import moment from "moment";
import { chart } from "highcharts";


const colors = [
  "#8884d8",
  "#82ca9d",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#e66959",
  "#eea065",
  "#edcb8b",
  "#d3e4a0",
  "#a6ddd3",
  "#79bae4",
  "#6f86ee",
  "#8a5a8c",
];

export default function Charts() {

  const classes = useStyles();

  //global
  var vendorState = useVendorState();

  // local
  const [selectFromDate, setselectFromDate] = React.useState(moment().subtract(2, 'years').toDate());
  const [selectToDate, setselectToDate] = React.useState(new Date());
  const [graphData, setgraphData] = useState(null);
  const [brandList, setbrandList] = useState([]);
  const [selectedBrandList, setSelectedBrandList] = useState([]);
  const [segmentNames, setsegmentNames] = useState(null);
  const [segmentId, setSegmentId] = useState(null);
  const [chartInterval, setChartInterval] = useState("month");
  const [reportType, setReportType] = useState("revenue");
  const [chartReportType, setChartReportType] = useState("revenue");
  const [fullPageLoader, setfullPageLoader] = useState(false);

  const fetchJSSegments = async () => {
    try{
      setfullPageLoader(true);
      const params = {
        vendorCode: vendorState?.selected,
      }
      const response = await axios(BASE_URL + api.fetchJSSegments, tokenConfig(params));
      setsegmentNames(response.data.data.map((item, index) => ({ code: index, name: item.name, segmentId: item.segmentId })));
      if (response.data.data.length > 0) {
        setSegmentId(response.data.data[0].segmentId);
      }
    } catch(error) {
      console.log(error)
    } finally {
      setfullPageLoader(false);
    }
  }

  const getCompetitionBenchmarkData = async () => {
    try{
      setfullPageLoader(true);
      const params = {
        segmentId,
        startDate: selectFromDate,
        endDate: selectToDate,
      }
      const response = await axios(BASE_URL + api.getCompetitionBenchmarkData, tokenConfig(params));
      const reports = parseReport(response.data.data);
      setgraphData(reports);
      setChartReportType(reportType);
      if (reports.length > 0) {
        delete reports[0]["Needed Date"];
        delete reports[0]["dateRange"];
        if(brandList.length === 0) {
          setbrandList(Object.keys(reports[0]))
        }
        setSelectedBrandList(Object.keys(reports[0]))
      }
    } catch(error) {
      console.log(error)
    } finally {
      setfullPageLoader(false);
    }
  };

  useEffect(() => {
    if (vendorState?.selected) {
      fetchJSSegments();
    }
  }, [vendorState?.selected])

  useEffect(() => {
    if (segmentId) {
      getCompetitionBenchmarkData(segmentId);
    }
  }, [segmentId, chartInterval])

  const parseReport = (reports) => {
    const competitionReports = {};
    // Using this object to calculate avgPrice without adding additional keys
    const calculateReports = {};
    reports.forEach(report => {
      // if (!selectedBrandList.length || selectedBrandList.find(item => item === report.brand)) {
        let key = moment(report.reportDate).format("YYYY-MM-DD");
        if (chartInterval === "week") {
          key = `${moment(report.reportDate).week()}-${moment(report.reportDate).year()}`;
        } else if (chartInterval === "month") {
          key = moment(report.reportDate).format("YYYY-MM");
        }
        if (!competitionReports[key]) {
          competitionReports[key] = {
            "Needed Date": key,
          }
        }
        if (!calculateReports[key]) {
          calculateReports[key] = {
            startDate: report.reportDate,
            endDate: report.reportDate,
          }
        }
        if (chartInterval !== "day") {

          calculateReports[key].startDate = moment(report.reportDate) < moment(calculateReports[key].startDate) ? report.reportDate : calculateReports[key].startDate;
          calculateReports[key].endDate = moment(report.reportDate) > moment(calculateReports[key].endDate) ? report.reportDate : calculateReports[key].endDate;
          competitionReports[key]["dateRange"] = `${moment(calculateReports[key].startDate).format("DD MMM YYYY")} - ${moment(calculateReports[key].endDate).format("DD MMM YYYY")}`;
        } else {
          competitionReports[key]["dateRange"] = `${moment(report.reportDate).format("DD MMM, YYYY")}`;
        }


        if (!competitionReports[key][report.brand]) {
          competitionReports[key][report.brand] = 0;
        }
        if (reportType === "avgPrice" || reportType === "reviews") {
          if (!calculateReports[key][report.brand + "total"]) {
            calculateReports[key][report.brand + "total"] = 0;
          }
          if (!calculateReports[key][report.brand + "count"]) {
            calculateReports[key][report.brand + "count"] = 0;
          }
          calculateReports[key][report.brand + "total"] += report[reportType];
          calculateReports[key][report.brand + "count"] += 1;
          competitionReports[key][report.brand] = calculateReports[key][report.brand + "total"] / calculateReports[key][report.brand + "count"];
        } else {
          competitionReports[key][report.brand] += report[reportType];
        }
        competitionReports[key][report.brand] = parseFloat(competitionReports[key][report.brand].toFixed(2));
      // }
    })
  return Object.values(competitionReports);

}

return (
  <>
    <Container maxWidth={false}>
      <PageTitle
        title="Competition Benchmarking"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              className={classes.link}
            >
              Business Intelligence
            </Link>
            <Typography className={classes.link} color="primary">
              Competition Benchmarking
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <>
            <FormControl variant="outlined" className={classes.formControl} >
              <InputLabel id="demo-simple-select-outlined-label">
                Report Type
              </InputLabel>
              <Select
                value={reportType}
                onChange={(e) => setReportType(e.target.value)}
                label="Report Type"
              >
                <MenuItem value="revenue">Revenue</MenuItem>
                <MenuItem value="revenue1P">Revenue 1P</MenuItem>
                <MenuItem value="revenue3P">Revenue 3P</MenuItem>
                <MenuItem value="unitSales">Unit Sales</MenuItem>
                <MenuItem value="unitSales1P">Unit Sales 1P</MenuItem>
                <MenuItem value="unitSales3P">Unit Sales 3P</MenuItem>
                <MenuItem value="avgPrice">Avg Price</MenuItem>
                <MenuItem value="reviews">Reviews</MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils} fullWidth>
              <FormControl
                variant="outlined"
                className={classes.dateFormControl}
              >
                <KeyboardDatePicker
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="startDate"
                  label="From"
                  value={selectFromDate}
                  onChange={(date) => setselectFromDate(date)}
                />
              </FormControl>
              <FormControl
                variant="outlined"
                className={classes.dateFormControl}
              >
                <KeyboardDatePicker
                  disableToolbar
                  inputVariant="outlined"
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="startDate"
                  label="To"
                  value={selectToDate}
                  onChange={(date) => setselectToDate(date)}
                  minDate={selectFromDate}
                />
              </FormControl>
            </MuiPickersUtilsProvider>
            <FormControl
              variant="outlined"
              className={classes.formControl}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ height: 53 }}
                onClick={getCompetitionBenchmarkData}
              >
                Apply
              </Button>
            </FormControl>
          </>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  Brand Trends
                </Typography>
              </div>
            }
          >
            <Grid container xs={12} spacing={1}>
              <Grid item xs={10} style={{ marginRight: 50 }}>
                <LeftColumn
                brandList={brandList}
                segmentNames={segmentNames}
                colors={colors}
                parentCallback={(index) => setSegmentId(segmentNames[index].segmentId)} 
                selectedBrandList={selectedBrandList}
                setSelectedBrandList={setSelectedBrandList}
                />
              </Grid>
              <Grid item xs={12}>
                <AreaChart
                  data={graphData}
                  brandList={selectedBrandList.sort((a,b) => a.localeCompare(b))}
                  colors={colors}
                  interval={chartInterval}
                  reportType={chartReportType}
                  changeInterval={(interval) => setChartInterval(interval)}
                />
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  </>
);
}
