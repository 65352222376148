import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleTrend = (params, key) => {
    return `${(params.data[key]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${params.data[key + "Trend"]}%)`;
  }

  const handleTrendColor = ({ data }, key) => {
    if (data[key + "Trend"] > 0) {
      return { color: "green" };
    } else if(data[key + "Trend"] < 0) {
      return { color: "red" };
    } else {
      return { color: "black" };
    }
  }

  const rowStyle = { background: 'white' };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
      >
        <AgGridColumn key={2} headerName={'Brand'} field={'brand'} width={150}/>
        <AgGridColumn key={3} headerName={'Revenue'} valueGetter={(params) => handleTrend(params, "revenue")} width={200} cellStyle={(data) => handleTrendColor(data, "revenue")}/>
        <AgGridColumn key={4} headerName={'Revenue 1P'} valueGetter={(params) => handleTrend(params, "revenue1P")} width={200} cellStyle={(data) => handleTrendColor(data, "revenue1P")} />
        <AgGridColumn key={5} headerName={'Revenue 3P'} valueGetter={(params) => handleTrend(params, "revenue3P")} width={200} cellStyle={(data) => handleTrendColor(data, "revenue3P")} />
        <AgGridColumn key={6} headerName={'Unit Sales'} valueGetter={(params) => handleTrend(params, "unitSales")} width={200} cellStyle={(data) => handleTrendColor(data, "unitSales")} />
        <AgGridColumn key={7} headerName={'Unit Sales 1P'} valueGetter={(params) => handleTrend(params, "unitSales1P")} width={200} cellStyle={(data) => handleTrendColor(data, "unitSales1P")} />
        <AgGridColumn key={8} headerName={'Unit Sales 3P'} valueGetter={(params) => handleTrend(params, "unitSales3P")} width={200} cellStyle={(data) => handleTrendColor(data, "unitSales3P")} />
      </AgGridReact>
    </div>
  );
})