import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';


export default function DraggableDialog({open, toggleDialog, setnotification, seterr, filesToMerge, setFilesToMerge, mergeFiles}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDialog}
        fullWidth={true}
      >
        <Paper>
        <DialogTitle>
          Confirm to merge
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           Following files will be merged: 
          </DialogContentText>
          <DialogContentText>
            {filesToMerge?.map((file) => <React.Fragment key={file?.name}>{file?.name}<br></br></React.Fragment>)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            autoFocus 
            variant="outlined"
            onClick={()=>{
              toggleDialog()
              setFilesToMerge([])
            }} color="primary">
            Cancel
          </Button>
          <Button 
            variant="contained"
            onClick={()=>{              
              mergeFiles(filesToMerge)
              toggleDialog()
            }} color="primary">
            Confirm
          </Button>
        </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}