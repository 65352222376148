import './styles.css'
import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { Rating } from '@material-ui/lab';
import { deepOrange } from '@material-ui/core/colors';
import './styles.css'

export default function ReviewBox(props) {


    if (props?.data) {
        return (
            <>
                <div className='header'>
                    <Avatar
                        sx={{ bgcolor: deepOrange[500] }}
                        alt={props?.data?.userName}
                        src="/broken-image.jpg" />
                    <Typography variant="h6" style={{ fontWeight: 500, paddingLeft: '1rem' }} component="legend">{props?.data?.userName}</Typography>
                </div>
                <div className='subHeader'>
                    <Rating className='starRating' name="read-only" value={props?.data?.rating || 0} readOnly />
                    <Typography style={{ fontWeight: 600, paddingTop: '0.75rem', paddingLeft: '1rem', paddingRight: '0.8rem' }} component="legend">{props?.data?.title}</Typography>
                </div>
                <Typography variant='body1' style={{ paddingTop: '1.5rem', paddingLeft: '0.25rem' }}>{props?.data?.review}</Typography>
            </>
        )
    } else {
        return (
            <></>
        )
    }
}