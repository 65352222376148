import {
  Box,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

function CampaignForm({
  campaignData,
  onValuesChange,
  onTypeChange,
  onBudgetChange,
  onSetCampaignData,
}) {
  return (
    <>
      <Grid
        container
        spacing={4}
        style={{
          paddingLeft: "4%",
          paddingRight: "4%",
          paddingBottom: "2%",
        }}
      >
        <Grid item xs={6}>
          <Select
            labelId="select-label"
            id="campaignType"
            value={campaignData.campaignType}
            label="Campaign Type"
            name="campaignType"
            onChange={onValuesChange}
            disabled={true}
          >
            <MenuItem value={"sponsoredProducts"}>Sponsored Products</MenuItem>
            <MenuItem d value={"sponsoredBrands"}>
              Sponsored Brands
            </MenuItem>
            <MenuItem value={"sponsoredDisplay"}>Sponsored Display</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={4}
        style={{
          paddingLeft: "4%",
          paddingRight: "4%",
          paddingBottom: "2%",
        }}
      >
        <Grid item xs={7}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Campaign Type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="AUTO"
              value={campaignData.type}
              onChange={onTypeChange}
              name="type"
            >
              <FormControlLabel value="AUTO" control={<Radio />} label="Auto" />
              <FormControlLabel
                value="MANUAL"
                control={<Radio />}
                label="Manual"
              />
              <FormControlLabel
                value="AUTO_AND_SCHEDULED_MANUAL"
                control={<Radio />}
                label="Auto & Scheduled Manual"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {campaignData.type === "AUTO_AND_SCHEDULED_MANUAL" && (
          <Grid item xs={5}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                type="number"
                value={campaignData.scheduledCampaignDays}
                id="outlined-adornment-amount"
                name="scheduledCampaignDays"
                onChange={onBudgetChange}
                label="Scheduled Manual Days"
                variant="outlined"
              />
            </FormControl>
          </Grid>
        )}
      </Grid>
      <Grid
        container
        spacing={4}
        style={{
          paddingLeft: "4%",
          paddingRight: "4%",
          paddingBottom: "2%",
        }}
      >
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              label="Start Date"
              value={campaignData.startDate}
              minDate={moment()}
              onChange={(date) => {
                onSetCampaignData({ startDate: date });
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              variant="inline"
              label="End Date"
              value={campaignData.endDate}
              defaultValue={null}
              disablePast={true}
              onChange={(date) => {
                onSetCampaignData({ endDate: date });
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={6}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Budget Type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="daily"
              value={campaignData.budgetType}
              onChange={onValuesChange}
              name="budgetType"
            >
              <FormControlLabel
                value="daily"
                control={<Radio />}
                label="Daily"
              />
              <FormControlLabel
                value="monthly"
                control={<Radio />}
                label="Monthly"
              />
              {/* <FormControlLabel value="manual" control={<Radio />} label="Manual" /> */}
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <TextField
              type="number"
              value={campaignData.budget}
              id="outlined-adornment-amount"
              name="budget"
              onChange={onBudgetChange}
              label="Budget"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              variant="outlined"
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">
              Targeting Type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="auto"
              value={campaignData.targetingType}
              onChange={onTypeChange}
              name="targetingType"
            >
              <FormControlLabel
                disabled
                value="AUTO"
                control={<Radio />}
                label="Auto"
              />
              <FormControlLabel
                disabled
                value="MANUAL"
                control={<Radio />}
                label="Manual"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {campaignData.type === "MANUAL" && (
          <Grid item xs={6}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                Manual Targeting Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="keywordTargeting"
                value={campaignData.manualTargetingType}
                onChange={onTypeChange}
                name="manualTargetingType"
              >
                <FormControlLabel
                  value="Keyword Targeting"
                  control={<Radio />}
                  label="Keyword Targeting"
                />
                <FormControlLabel
                  value="Product Targeting"
                  control={<Radio />}
                  label="Product Targeting"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        )}
      </Grid>

      {campaignData.campaignType === "sponsoredProducts" && (
        <Grid
          container
          spacing={4}
          style={{
            paddingLeft: "4%",
            paddingRight: "4%",
          }}
        >
          <Grid item xs={12}>
            <FormLabel id="demo-radio-buttons-group-label">
              Bid Adjustment
            </FormLabel>
            <Divider />
          </Grid>
          <Grid
            container
            spacing={4}
            style={{
              paddingLeft: "4%",
              paddingRight: "4%",
              paddingBottom: "2%",
            }}
          >
            <Grid item xs={6}>
              <TextField
                type="number"
                value={campaignData.topOfSearch}
                id="outlined-adornment-amount"
                name="topOfSearch"
                onChange={onValuesChange}
                label="Top of search (First page)"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>{" "}
            <Grid item xs={6}>
              <TextField
                type="number"
                value={campaignData.productPage}
                id="outlined-adornment-amount"
                name="productPage"
                onChange={onValuesChange}
                label="Product Pages"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {campaignData.manualTargetingType === "Keyword Targeting" &&
        campaignData.type === "MANUAL" && (
          <Grid
            container
            spacing={4}
            style={{
              paddingLeft: "4%",
              paddingRight: "4%",
            }}
          >
            <Grid item xs={12}>
              <FormLabel id="demo-radio-buttons-group-label">
                Keyword Targeting
              </FormLabel>
              <Divider />
            </Grid>
            <Grid
              container
              spacing={4}
              style={{
                paddingLeft: "4%",
                paddingRight: "4%",
                paddingBottom: "2%",
              }}
            >
              <Grid item xs={6}>
                <TextField
                  value={
                    campaignData?.keywords && campaignData.keywords.join(",")
                  }
                  onChange={onValuesChange}
                  label="List (Seperated by comma)"
                  variant="outlined"
                  rows={4}
                  name="keywords"
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component="div"
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  borderBottom="1px solid #f0f0f0"
                  marginBottom="5px"
                >
                  <Typography
                    component="span"
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    {`Total keywords: ${campaignData?.keywords?.length}`}
                  </Typography>
                  <Typography
                    component="span"
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "12px",
                      lineHeight: "20px",
                      cursor: "pointer",
                      color: "blue",
                    }}
                    onClick={() => {
                      onSetCampaignData({ keywords: [] });
                    }}
                  >
                    {`Remove all`}
                  </Typography>
                </Box>

                {campaignData?.keywords?.map((keyWord) => (
                  <Chip key={keyWord} style={{ marginRight: 5 }} label={keyWord} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}

      {campaignData.manualTargetingType === "Product Targeting" &&
        campaignData.type === "MANUAL" && (
          <Grid
            container
            spacing={4}
            style={{
              paddingLeft: "4%",
              paddingRight: "4%",
            }}
          >
            <Grid item xs={12}>
              <FormLabel id="demo-radio-buttons-group-label">
                Product Targeting
              </FormLabel>
              <Divider />
            </Grid>
            <Grid
              container
              spacing={4}
              style={{
                paddingLeft: "4%",
                paddingRight: "4%",
                paddingBottom: "2%",
              }}
            >
              <Grid item xs={6}>
                <TextField
                  value={
                    campaignData?.productTargetingAsins &&
                    campaignData.productTargetingAsins.join(",")
                  }
                  onChange={onValuesChange}
                  label="List (Seperated by comma)"
                  variant="outlined"
                  rows={4}
                  name="productTargetingAsins"
                  fullWidth
                  multiline
                />
              </Grid>
              <Grid item xs={6}>
                <Box
                  component="div"
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  borderBottom="1px solid #f0f0f0"
                  marginBottom="5px"
                >
                  <Typography
                    component="span"
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    {`Total ASINs: ${campaignData?.productTargetingAsins?.length}`}
                  </Typography>
                  <Typography
                    component="span"
                    style={{
                      fontFamily: "Helvetica",
                      fontSize: "12px",
                      lineHeight: "20px",
                      cursor: "pointer",
                      color: "blue",
                    }}
                    onClick={() => {
                      onSetCampaignData({ productTargetingAsins: [] });
                    }}
                  >
                    {`Remove all`}
                  </Typography>
                </Box>

                {campaignData?.productTargetingAsins?.map((asin) => (
                  <Chip key={asin} style={{ marginRight: 5 }} label={asin} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      {campaignData.campaignType === "sponsoredProducts" && (
        <>
          {(campaignData.type !== "MANUAL" ||
            campaignData.manualTargetingType === "Keyword Targeting") && (
            <Grid
              container
              spacing={4}
              style={{
                paddingLeft: "4%",
                paddingRight: "4%",
              }}
            >
              <Grid item xs={12}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Negative Keyword Targeting{" "}
                  <Typography variant="caption">Optional</Typography>
                </FormLabel>
                <Divider />
              </Grid>
              <Grid
                container
                spacing={4}
                style={{
                  paddingLeft: "4%",
                  paddingRight: "4%",
                  paddingBottom: "2%",
                }}
              >
                <Grid item xs={12}>
                  <Grid container alignItems="center">
                    <FormLabel id="negative-keyword-group-label">
                      Match type
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="negative-keyword-group-label"
                      defaultValue="Negative exact"
                      value={campaignData.negativeKeywordType}
                      onChange={onTypeChange}
                      name="negativeKeywordType"
                    >
                      <FormControlLabel
                        value="Negative exact"
                        control={<Radio />}
                        label="Negative exact"
                      />
                      <FormControlLabel
                        value="Negative phrase"
                        control={<Radio />}
                        label="Negative phrase"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={
                      campaignData.negativeKeywordType === "Negative phrase"
                        ? campaignData.negativeKeywordsPhrase &&
                          campaignData?.negativeKeywordsPhrase.join(",")
                        : campaignData.negativeKeywordsExact &&
                          campaignData?.negativeKeywordsExact.join(",")
                    }
                    onChange={onValuesChange}
                    label="List (Seperated by comma)"
                    variant="outlined"
                    rows={4}
                    name={
                      campaignData.negativeKeywordType === "Negative phrase"
                        ? "negativeKeywordsPhrase"
                        : "negativeKeywordsExact"
                    }
                    fullWidth
                    multiline
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box
                    component="div"
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    borderBottom="1px solid #f0f0f0"
                    marginBottom="5px"
                  >
                    <Typography
                      component="span"
                      style={{
                        fontFamily: "Helvetica",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {`Total Negative keywords: ${
                        campaignData?.negativeKeywordsExact?.length +
                        campaignData?.negativeKeywordsPhrase?.length
                      }`}
                    </Typography>
                    <Typography
                      component="span"
                      style={{
                        fontFamily: "Helvetica",
                        fontSize: "12px",
                        lineHeight: "20px",
                        cursor: "pointer",
                        color: "blue",
                      }}
                      onClick={() => {
                        onSetCampaignData({ negativeKeywordsExact: [] });
                        onSetCampaignData({ negativeKeywordsPhrase: [] });
                      }}
                    >
                      {`Remove all`}
                    </Typography>
                  </Box>
                  {campaignData?.negativeKeywordsExact?.length > 0 && (
                    <Typography component="div">{`Exact`}</Typography>
                  )}
                  {campaignData?.negativeKeywordsExact?.map((keyWord) => (
                    <Chip key={keyWord} style={{ marginRight: 5 }} label={keyWord} />
                  ))}

                  {campaignData?.negativeKeywordsPhrase?.length > 0 && (
                    <Typography component="div" style={{ marginTop: "10px" }}>
                      Phrase
                    </Typography>
                  )}
                  {campaignData?.negativeKeywordsPhrase?.map((keyWord) => (
                    <Chip key={keyWord} style={{ marginRight: 5 }} label={keyWord} />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}

          {(campaignData.type !== "MANUAL" ||
            campaignData.manualTargetingType === "Product Targeting") && (
            <Grid
              container
              spacing={4}
              style={{
                paddingLeft: "4%",
                paddingRight: "4%",
              }}
            >
              <Grid item xs={12}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Negative Product Targeting{" "}
                  <Typography variant="caption">Optional</Typography>
                </FormLabel>
                <Divider />
              </Grid>

              <Grid
                container
                spacing={4}
                style={{
                  paddingLeft: "4%",
                  paddingRight: "4%",
                  paddingBottom: "2%",
                }}
              >
                <Grid item xs={6}>
                  <TextField
                    value={
                      campaignData.negativeProducts &&
                      campaignData.negativeProducts.join(",")
                    }
                    onChange={onValuesChange}
                    label="List (Seperated by comma)"
                    variant="outlined"
                    rows={4}
                    name="negativeProducts"
                    fullWidth
                    multiline
                  />
                </Grid>

                <Grid item xs={6}>
                  <Box
                    component="div"
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    borderBottom="1px solid #f0f0f0"
                    marginBottom="5px"
                  >
                    <Typography
                      component="span"
                      style={{
                        fontFamily: "Helvetica",
                        fontSize: "16px",
                        lineHeight: "24px",
                      }}
                    >
                      {`Total Negative products: ${campaignData.negativeProducts.length}`}
                    </Typography>
                    <Typography
                      component="span"
                      style={{
                        fontFamily: "Helvetica",
                        fontSize: "12px",
                        lineHeight: "20px",
                        cursor: "pointer",
                        color: "blue",
                      }}
                      onClick={() => {
                        onSetCampaignData({ negativeProducts: [] });
                      }}
                    >
                      {`Remove all`}
                    </Typography>
                  </Box>

                  {campaignData.negativeProducts.map((product) => (
                    <Chip key={product} style={{ marginRight: 5 }} label={product} />
                  ))}
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
}

export default CampaignForm;
