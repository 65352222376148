import React, {useEffect, useRef} from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import accessibility from "highcharts/modules/accessibility.js";
// import CircularProgress from '@material-ui/core/CircularProgress';

// context
import { useLayoutState } from "../../../../context/LayoutContext";

//helpers
import { numberWithCommas } from "../../../../healpers/utilityFunctions";

highchartsMore(Highcharts);
accessibility(Highcharts);

export default function Piechart(props) {

  var COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];

  // global
  var layoutState = useLayoutState();

  //local
  const chartRef = useRef();

  // To make map responsive
  useEffect(() => {
      if(chartRef.current) {
        setTimeout(() => {
            Highcharts.charts[chartRef.current.chart.index].reflow()
        }, 200);
      }
  }, [layoutState.isSidebarOpened])

  const PieOptions = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: null
    },
    tooltip: {
      formatter: function () {
        return `<b>${this.point.name} - $${numberWithCommas((this.point.y).toFixed(2))} </b>`
      }
    },
    exporting: {
      enabled: false
    },      
    credits: {
      enabled: false
    },
    plotOptions: {
        pie: {
            center: ['50%', '50%'],
            size:'80%',
            colors:COLORS,
            dataLabels: {
                enabled: true,
                formatter: function () {
                  return `<b>${this.point.name} <br/> $${numberWithCommas((this.point.y).toFixed(2))} </b>`
                }
            },
        }
    },
    series: [{
        name: 'Brands',
        colorByPoint: true,
        data: props.pieData
    }]
  };

  if(props.pieData && props.pieData.length !== 0)
  return (
    <HighchartsReact highcharts={Highcharts} options={PieOptions} ref={chartRef}/>
  ) 
  // else if(!props.pieData) 
  // return (
  //   <div style={{textAlign: "center",margin:'25% 0'}}>
  //       <CircularProgress />
  //   </div>
  // ) 
  else return (
    <h3 style={{ textAlign: "center",margin:'25% 0'}}>
      ...No Data To Show...
    </h3>
  )
};
