import moment from 'moment';
/**
 * Changes the format for a few fields in the Order Metrics Response.
 * @param {Array} data - Array of order metrics.
 * @param {string} granularity - Hour/Day. Decides the format of the date in the table.
 * @returns {Array} Returns formatted order metrics in an array format.
 */
export const modifyMerchantListingsReportData = (data) => {
    return (data || []).map((ele) => {
        return {
            ...ele,
            issueDate: moment(ele?.issueDate).format('MM/DD/YYYY')
        }
    });
}
