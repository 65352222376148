//Authentication
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const AMAZON_AUTH_CREDS = "AMAZON_AUTH_CREDS";

//Error
export const ADD_ERROR = "ADD_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

//Notification
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
