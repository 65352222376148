import React, { useState } from "react";

import {
    Grid,
    InputLabel,
    Select,
    MenuItem,
    Button,
    FormControl,
    TextField
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import useStyles from "./style";
import moment from "moment";

export default function AddressForm(props) {
    var classes = useStyles();

    const reportOptions = [
        { name: 'Order Metrics Daily', type: 'ordermetricsday', for: ['seller', 'vendor'] },
        { name: 'Order Metrics Hourly', type: 'ordermetricshour', for: ['seller', 'vendor'] },
        { name: 'Order Metrics ASINs Weekly', type: 'ordermetricsasinweek', for: ['seller', 'vendor'] },
        { name: 'Order Metrics ASINs Daily', type: 'ordermetricsasinday', for: ['seller', 'vendor'] },
        { name: 'Order Metrics ASINs Hourly', type: 'ordermetricsasinhour', for: ['seller', 'vendor'] },
        { name: "3P Report", type: "3preport", for: ['seller', 'vendor'] },
        { name: "Share Of Voice", type: "shareofvoice", for: ['seller', 'vendor'] },
        { name: 'Geographic Insights', type: 'geographicinsights', for: ['seller'] },
        { name: 'Campaign Reports', type: 'campaignreports', for: ['seller', 'vendor'] },
        { name: 'Sales Traffic Report', type: 'salestraffic', for: ['seller'] },
        { name: "Vendor Sales Report", type: "vendorsalesreport", for: ['vendor'] },
        { name: "Catalog Items Refresh", type: "catalogRefresh", for: ['seller', 'vendor'] },
        // { name: 'Keywords By ASIN', type: 'jskeywordbyasin' },
        // { name: 'Sales Estimate Data', type: 'jssalesestimatedata' },
        // { name: 'Share of Voice', type: 'shareofvoice' },
        // { name: 'Products and Reviews', type: 'amazonproductreview' },
        // { name: 'Brand Segment by Day', type: 'jssegmentbyday' }
        { name: 'Brand Segment Reports', type: 'jssegmentreports', for: ['seller', 'vendor'] },
        { name: 'Search Terms Report', type: 'searchtermsreport', for: ['seller', 'vendor'] },
        { name: 'Keywords Report', type: 'keywordsreport', for: ['seller', 'vendor'] },
    ]
    const [reportList, setReportList] = useState(reportOptions);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isError, setIsError] = useState(false);

    const setDateError = (event) => setIsError(!!event)

    const getMaxDate = () => {
        let amount = 1, unit = 'M';
        [amount, unit] = props?.maxDuration?.split("") || [12,'M'];
        if (!amount || !unit) {
            amount = 12;
            unit = "M";
          }
        if(!amount || !unit) {
            console.error('Invalid Duration Found! Using Default Duration of one month')
        }
        let maxDate = moment(startDate).add(amount, unit);
        let currentDate = moment();
        return currentDate;
        // return currentDate < maxDate ? currentDate : maxDate;
    }

    return (
        <div className={classes.form}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={classes.campaignControl}>
                        <InputLabel id="demo-simple-select-outlined-label">
                            Select Vendor
                        </InputLabel>
                        <Select
                            value={props?.selectedVendor}
                            onChange={(e) => {
                                props?.setSelectedVendor(e.target.value);
                                const accountType = props.vendorList.find((vendor) => vendor.vendorcode === e.target.value).accounttype || 'All'
                                if (accountType === 'All') setReportList(reportOptions.filter((item) => item.name !== 'Catalog Items Refresh'))
                                else setReportList(reportOptions.filter((item) => item.for.includes(accountType)))
                            }}
                            label="Select Vendor"
                            disabled={props?.vendorList && props?.vendorList?.length === 0 ? true : false}
                        >
                            {
                                props?.vendorList ? (
                                    props?.vendorList.map((vendor, i) => {
                                        return (
                                            <MenuItem key={i} value={vendor?.vendorcode}>
                                                {vendor?.vendorname}
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem value={0}>
                                        <i>No Vendor</i>
                                    </MenuItem>
                                )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" className={classes.campaignControl}>
                        <InputLabel id="demo-simple-select-outlined-label">
                            Select Report Type
                        </InputLabel>
                        <Select
                            value={props?.selectedReportType || ''}
                            onChange={(e) => {
                                props?.setSelectedReportType(e.target.value);
                            }}
                            label="Select Report Type"
                            disabled={reportList && reportList.length === 0 ? true : false}
                        >
                            {
                                reportList ? (
                                    reportList.map((report, i) => {
                                        return (
                                            <MenuItem key={i} value={report?.type}>
                                                {report?.name}
                                            </MenuItem>
                                        );
                                    })
                                ) : (
                                    <MenuItem value={0}>
                                        <i>No Report Type</i>
                                    </MenuItem>
                                )}
                        </Select>
                        {
                            props?.selectedReportType === "jssegmentreports" &&
                            <>
                                <InputLabel id="demo-simple-select-outlined-label">
                                Segment ID
                                </InputLabel>
                                <TextField
                                    value={props.segmentId}
                                    onChange={(e) => props.setSegmentId(e.target.value)}
                                    label="Segment ID"
                                    variant="outlined"
                                    fullWidth
                                    style={{ marginTop: 10 }}
                                    required
                                />
                            </>
                        }
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingLeft: '24px' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            label="Start Date"
                            value={startDate}
                            disabled={!props?.selectedVendor || !props?.selectedReportType}
                            maxDate={moment()}
                            onError={setDateError}
                            onChange={setStartDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6} style={{ paddingLeft: '24px' }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            label="End Date"
                            value={endDate}
                            disabled={!props?.selectedVendor || !props?.selectedReportType}
                            onError={setDateError}
                            minDate={moment(startDate)}
                            maxDate={getMaxDate()}
                            onChange={setEndDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} style={{ textAlign: 'end', paddingRight: '36px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={isError}
                        onClick={() => props?.generateReport(
                            props?.selectedVendor,
                            props?.selectedReportType,
                            startDate,
                            endDate
                            )}
                    >
                        Generate Data
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}