/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context

//helpers
import useStyles from "./styles";
import moment from "moment";
import axios from "axios";
import { useVendorState } from "../../context/VendorContext";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { tokenConfig } from "../../context/UserContext";

export default function HealthCheck() {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  const vendorState = useVendorState();
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [mainTableData, setMainTableData] = useState([]);

  const fetchData = async () => {
    setfullPageLoader(true);
    try {
      const params = {
        vendorCode: vendorState.selected,
        startDate: moment().subtract(7, "days").format("MM-DD-YYYY"),
        endDate: moment().format("MM-DD-YYYY")
      }
      const result = await axios.get(BASE_URL + api.fetchForecastHealth, tokenConfig(params))
      setMainTableData(result.data.data);
    } catch (error) {
      console.log(error)
    } finally {
      setfullPageLoader(false);
    }
  }

  useEffect(() => {
    if(vendorState.selected) {
      fetchData();
    }
  }, [vendorState?.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Health Check"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                className={classes.link}
              >
                <EqualizerIcon className={classes.icon} />
                Business Intelligence
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Health Check
              </Typography>
            </Breadcrumbs>
          }
        />
        {!fullPageLoader && <Grid container spacing={4} className="js_product_grid">
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} vendorCode={vendorState?.selected} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>}
        <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
