import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  versionHeaderCellLabel: {
    display: "inlineBlock",
    fontSize: "14px",
    fontWeight: "normal",
    color: "grey",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  versionSubHeaderCellLabel: {
    display: "inlineBlock",
    fontSize: "14px",
    fontWeight: "normal",
    color: "grey",
    textTransform: "capitalize",
    display: "inline-block",
  },
  borderBottom: { borderBottom: "1px solid #e2e2e2" },
  borderLeftBottom: {
    borderLeft: "1px solid #e2e2e2",
    borderBottom: "1px solid #e2e2e2",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: theme.palette.primary.main,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}));
