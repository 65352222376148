import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import _ from "lodash";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
import { renderFavicon } from "../../../../healpers/utilityFunctions";

export default forwardRef(function Tables(props, ref) {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const rowStyle = { background: 'white' };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const FavIconRenderer = (iconProps) => {
    return (
      <img width={50} src={iconProps.data.favicon} alt={iconProps.data.asin}/>
    );
  };

  const formatPrice = (params) => {
    if(params.value) {
      return `$${params.value.toFixed(2)}`
    } else {
      return "-"
    }
  }

  const formatWithPercentage = (params) => {
    if (params.value && params.value !== null && params.value !== undefined) {
      return `${parseFloat(params.value).toFixed(2)}%`
    } else {
      return "-"
    }
  }

  const formatValue = (params) => {
    if(params.value) {
      return params.value
    } else {
      return "-"
    }
  }

  const columnDefs = [
    {
      headerName: "",
      children: [
        { headerName: '', field: 'favicon', cellRenderer:(params) => renderImage(params) },
        { headerName: 'Parent ASIN', field: 'parentAsin' },
        { headerName: 'ASIN', field: 'asin' },
      ],
      lockPosition: true
    },
    {
      headerName: props.tableKeys.lastPreviousYear,
      children: [
        { headerName: 'Revenue', field: `${props.tableKeys.lastPreviousYear}_revenue` },
        { headerName: '% Growth', field: `${props.tableKeys.lastPreviousYear}_growth` },
        { headerName: 'Total Units', field: `${props.tableKeys.lastPreviousYear}_totalUnits` },
        { headerName: 'ASP', field: `${props.tableKeys.lastPreviousYear}_asp` },
        { headerName: 'Change', field: `${props.tableKeys.lastPreviousYear}_change` },
        { headerName: '% Contribution', field: `${props.tableKeys.lastPreviousYear}_contribution` },
        { headerName: 'Top 100%', field: `${props.tableKeys.lastPreviousYear}_top100` },
        { headerName: 'Ranking', field: `${props.tableKeys.lastPreviousYear}_ranking` },
      ],
      lockPosition: true
    },
    {
      headerName: props.tableKeys.previousYear,
      children: [
        { headerName: 'Revenue', field: `${props.tableKeys.previousYear}_revenue` },
        { headerName: '% Growth', field: `${props.tableKeys.previousYear}_growth` },
        { headerName: 'Total Units', field: `${props.tableKeys.previousYear}_totalUnits` },
        { headerName: 'ASP', field: `${props.tableKeys.previousYear}_asp` },
        { headerName: 'Change', field: `${props.tableKeys.previousYear}_change` },
        { headerName: '% Contribution', field: `${props.tableKeys.previousYear}_contribution` },
        { headerName: 'Top 100%', field: `${props.tableKeys.previousYear}_top100` },
        { headerName: 'Ranking', field: `${props.tableKeys.previousYear}_ranking` },
      ],
      lockPosition: true

    },
    {
      headerName: props.tableKeys.currentYear,
      children: [
        { headerName: 'Revenue', field: `${props.tableKeys.currentYear}_revenue` },
        { headerName: '% Growth', field: `${props.tableKeys.currentYear}_growth` },
        { headerName: 'Total Units', field: `${props.tableKeys.currentYear}_totalUnits` },
        { headerName: 'ASP', field: `${props.tableKeys.currentYear}_asp` },
        { headerName: 'Change', field: `${props.tableKeys.currentYear}_change` },
        { headerName: '% Contribution', field: `${props.tableKeys.currentYear}_contribution` },
        { headerName: 'Top 100%', field: `${props.tableKeys.currentYear}_top100` },
        { headerName: 'Ranking', field: `${props.tableKeys.currentYear}_ranking` },
      ],
      lockPosition: true
    },
    {
      headerName: 'BCG Matrix',
      children: [
        { headerName:  `${props.tableKeys.previousYear} vs ${props.tableKeys.lastPreviousYear}`, field: `${props.tableKeys.previousYear}_vs_${props.tableKeys.lastPreviousYear}` },
        { headerName:  `${props.tableKeys.currentYear} vs ${props.tableKeys.previousYear}`, field: `${props.tableKeys.currentYear}_vs_${props.tableKeys.previousYear}` },
        { headerName: 'Final', field: 'finalBcgMatrix' },
      ],
      lockPosition: true
    },
  ];

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          faviconRenderer: FavIconRenderer,          
        }}
        // columnDefs={columnDefs}
        maintainColumnOrder={true}
      >
        <AgGridColumn colId="asin_col">
        <AgGridColumn headerName={''} field={'asin'} width={100} cellRenderer={({value}) => renderFavicon(value, props.vendorCode)} pinned='left'/>
          <AgGridColumn headerName={'Parent ASIN'} field={'parentAsin'} width={150} pinned='left'/>
          <AgGridColumn headerName={'ASIN'} field={'asin'} width={150} pinned='left'/>
        </AgGridColumn>
        {props.bcgData[props.tableKeys?.lastPreviousYear]?.length > 0 && 
        <AgGridColumn colId={props.tableKeys.lastPreviousYear} headerName={props.tableKeys.lastPreviousYear} cellStyle={{ alignItems: "center", justifyContent: "center"}}>
        <AgGridColumn headerName={'Revenue'} field={`${props.tableKeys.lastPreviousYear}_revenue`} width={150} valueFormatter={(params)=> formatPrice(params)} />
          <AgGridColumn headerName={'% Growth'} field={`${props.tableKeys.lastPreviousYear}_growth`} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'Total Units'} field={`${props.tableKeys.lastPreviousYear}_totalUnits`} width={150}/>
          <AgGridColumn headerName={'ASP'} field={`${props.tableKeys.lastPreviousYear}_asp`} width={150} valueFormatter={(params)=> formatPrice(params)} />
          <AgGridColumn headerName={'Change'} field={`${props.tableKeys.lastPreviousYear}_change`} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'% Contribution'} field={`${props.tableKeys.lastPreviousYear}_contribution `} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'Top 100%'} field={`${props.tableKeys.lastPreviousYear}_top100`} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'Ranking'} field={`${props.tableKeys.lastPreviousYear}_ranking`} width={150}/>
        </AgGridColumn>
        }
        {props.bcgData[props.tableKeys?.previousYear]?.length > 0 && 
         <AgGridColumn colId={props.tableKeys.previousYear} headerName={props.tableKeys.previousYear}>
          <AgGridColumn headerName={'Revenue'} field={`${props.tableKeys.previousYear}_revenue`} width={150} valueFormatter={(params)=> formatPrice(params)} />
          <AgGridColumn headerName={'% Growth'} field={`${props.tableKeys.previousYear}_growth`} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'Total Units'} field={`${props.tableKeys.previousYear}_totalUnits`} width={150} valueFormatter={(params) => formatValue(params)}/>
          <AgGridColumn headerName={'ASP'} field={`${props.tableKeys.previousYear}_asp`} width={150} valueFormatter={(params)=> formatPrice(params)}/>
          <AgGridColumn headerName={'Change'} field={`${props.tableKeys.previousYear}_change`} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'% Contribution'} field={`${props.tableKeys.previousYear}_contribution`} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'Top 100%'} field={`${props.tableKeys.previousYear}_top100`} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'Ranking'} field={`${props.tableKeys.previousYear}_ranking`} width={150} valueFormatter={(params) => formatValue(params)}/>
        </AgGridColumn>
        }       
        {props.bcgData[props.tableKeys?.currentYear]?.length > 0 && 
        <AgGridColumn colId={props.tableKeys.currentYear}   headerName={props.tableKeys.currentYear}>
          <AgGridColumn headerName={'Revenue'} field={`${props.tableKeys.currentYear}_revenue`} width={150} valueFormatter={(params)=> formatPrice(params)} />
          <AgGridColumn headerName={'% Growth'} field={`${props.tableKeys.currentYear}_growth`} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'Total Units'} field={`${props.tableKeys.currentYear}_totalUnits`} width={150} valueFormatter={(params) => formatValue(params)}/>
          <AgGridColumn headerName={'ASP'} field={`${props.tableKeys.currentYear}_asp`} width={150} valueFormatter={(params)=> formatPrice(params)}/>
          <AgGridColumn headerName={'Change'} field={`${props.tableKeys.currentYear}_change`} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'% Contribution'} field={`${props.tableKeys.currentYear}_contribution`} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'Top 100%'} field={`${props.tableKeys.currentYear}_top100`} width={150} valueFormatter={(params)=> formatWithPercentage(params)}/>
          <AgGridColumn headerName={'Ranking'} field={`${props.tableKeys.currentYear}_ranking`} width={150} valueFormatter={(params) => formatValue(params)}/>
        </AgGridColumn>
        }
        {props.bcgData[props.tableKeys?.currentYear]?.length > 0 && 
          <AgGridColumn coldId="BCGMatrix" headerName="BCG Matrix">
            {props.bcgData[props.tableKeys?.lastPreviousYear]?.length > 0 && <AgGridColumn headerName={`${props.tableKeys.previousYear} vs ${props.tableKeys.lastPreviousYear}`} field={`${props.tableKeys.previousYear}_vs_${props.tableKeys.lastPreviousYear}`} width={150} valueFormatter={(params) => formatValue(params)}/>}
            <AgGridColumn headerName={`${props.tableKeys.currentYear} vs ${props.tableKeys.previousYear}`} field={`${props.tableKeys.currentYear}_vs_${props.tableKeys.previousYear}`} width={150} valueFormatter={(params) => formatValue(params)}/>
            <AgGridColumn headerName={'Final'} field={'finalBcgMatrix'} width={150} valueFormatter={(params) => formatValue(params)}/>
          </AgGridColumn>
        }
      </AgGridReact>
    </div>
  );
})

const renderImage = ({ value }) => `<img style="height: 40px; width: 40px" src="${value}">`;