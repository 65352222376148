import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import axios from "axios";


//icons
import PrintIcon from "@material-ui/icons/Print"

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [dateError, setDateError] = useState()

  const fetchData = () => {
    setfullPageLoader(true);
    axios
      .get(BASE_URL + api.listBrands, { params: { startDate, endDate } })
      .then((res) => {
        setmainTableData(res.data);
        setfullPageLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setfullPageLoader(false);
        seterr(err?.response?.msg ?? "Something went wrong")
        setTimeout(() => seterr(null), 3000)
      });
  };

  useEffect(() => {
    if (userState.userData) {
      fetchData();
    }
  }, [userState.userData, startDate, endDate]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="All Brands"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Brand Finder
              </Link>
              <Typography className={classes.link} color="primary">
                All Brands
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl style={{ width: "30%" }}>
                <Grid item xs={6} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      variant="inline"
                      label="Start Date"
                      value={startDate}
                      maxDate={moment()}
                      onError={setDateError}
                      onChange={setStartDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </FormControl>

              <FormControl style={{ width: "30%" }}>
                <Grid item xs={6} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      variant="inline"
                      label="End Date"
                      value={endDate}
                      onError={setDateError}
                      minDate={moment(startDate)}
                      maxDate={moment()}
                      onChange={setEndDate}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
          <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
