import React, { useState } from "react";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Inbox as InboxIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Dot from "../Dot";

//context
import { useUserState } from "../../../../context/UserContext";

export default function SidebarLink({
  link,
  icon,
  label,
  pageCode,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
}) {
  var classes = useStyles();
  var { userData } = useUserState();
  var permissions = userData.permissions_module_level;
  // local
  var [isOpen, setIsOpen] = useState(false);
  var isLinkActive = link && location.pathname === link;
  
  if (
    type === "title" &&
    label === "MAIN" &&
    (permissions.sales ||
      permissions.shortageclaimsAndChargebacks ||
      permissions.businessIntelligence ||
      permissions.advertising ||
      permissions.operations ||
      permissions.creative ||
      permissions.catalog)
  ) {
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );
  }

  if (type === "title" && label === "SECONDARY" && permissions.users) {
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );
  }

  if (type === "divider") return <Divider className={classes.divider} />;

  if (!children && type !== "title")
    return (
      <ListItem
        button
        component={link && Link}
        to={link}
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
        </ListItemIcon>
        <ListItemText
          style={{
            whiteSpace: "break-spaces",
          }}
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
      </ListItem>
    );

  if (children && type !== "title")
    return (
      <>
        <ListItem
          button
          component={link && Link}
          onClick={toggleCollapse}
          className={classes.link}
          classes={{
            root: classnames(classes.linkRoot, {
              [classes.linkActive]: isLinkActive && !nested,
              [classes.linkNested]: nested,
            }),
          }}
          to={link}
          disableRipple
        >
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
          </ListItemIcon>
          <ListItemText
            style={{
              whiteSpace: "break-spaces",
            }}
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
        </ListItem>
        {children && (
          <Collapse
            in={isOpen && isSidebarOpened}
            timeout="auto"
            // unmountOnExit
            className={classes.nestedList}
          >
            <List component="div" disablePadding>
              {children.map((childrenLink, i) => {
                var permissionAllowed = false;
                if (pageCode === "settingsAndOptimisation") {
                  if (
                    permissions?.amazonAdvertising?.categories?.settingsAndOptimisation?.[childrenLink.pageCode]
                  ) 
                    permissionAllowed = true;
                }
                if (pageCode === "reports") {
                  if (
                    permissions?.amazonAdvertising?.categories?.reports?.[childrenLink.pageCode]
                  ) 
                    permissionAllowed = true;
                }
                if (pageCode === "bcg") {
                  if (
                    permissions?.businessIntelligence?.categories?.bcg?.[childrenLink.pageCode]
                  ) 
                    permissionAllowed = true;
                }
                if (pageCode === "forecast") {
                  if (
                    permissions?.businessIntelligence?.categories?.forecast?.[childrenLink.pageCode]
                  ) 
                    permissionAllowed = true;
                }
                if (pageCode === "vendorCentral") {
                  if (
                    permissions["shortageclaimsAndChargebacks"].categories[
                      "vendorCentral"
                    ]
                  )
                    permissionAllowed = true;
                } else if (pageCode === "cohesity") {
                  if (permissions["catalog"].categories["cohesity"])
                    permissionAllowed = true;
                } else {
                  if (
                    link &&
                    (link.pageCode === "none" ||
                      (permissions[pageCode] &&
                        permissions[pageCode].categories[
                          childrenLink.pageCode
                        ]))
                  )
                    permissionAllowed = true;
                }
                if (permissionAllowed)
                  return (
                    <SidebarLink
                      key={i + childrenLink + link}
                      location={location}
                      isSidebarOpened={isSidebarOpened}
                      classes={classes}
                      nested
                      {...childrenLink}
                    />
                  );
              })}
            </List>
          </Collapse>
        )}
      </>
    );

  return <></>;

  // ###########################################################

  function toggleCollapse(e) {
    if (isSidebarOpened) {
      e.preventDefault();
      setIsOpen(!isOpen);
    }
  }
}
