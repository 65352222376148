import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  toggle: {
    "& .MuiButtonBase-root": {
      marginLeft: 0,
    },
    "& .MuiSwitch-switchBase": {
      // color: theme.palette.success.main,
    },
    "& .MuiSwitch-colorSecondary.Mui-checked": {
      color: theme.palette.success.main,
    },

    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track ": {
      backgroundColor: theme.palette.success.light,
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: theme.palette.primary.main,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  containerWrapper: {
    paddingLeft: "4%",
    paddingRight: "4%",
    paddingBottom: "2%",
  },
  saveBtnWrapper:{
    display: "flex",
    flexDirection: "column",
    paddingRight: 10,
    paddingBottom: 10,
    width: "100%",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  segmentIconBtn:{
    position: "absolute",
    left: "0px",
    top: "25px",
  }
}));
