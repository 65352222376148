import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Typography,
  Switch,
  FormLabel,
  Divider,
  Button,
  Box,
  Backdrop,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import axios from "axios";
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { tokenConfig } from "../../context/UserContext";
import { useVendorState } from "../../context/VendorContext";
import { INITIAL_CRONS } from "./utils";
import useStyles from "./styles";
import { TextArea } from "./components/TextArea";
import { SegmentFields } from "./components/SegmentFields";
import { useUserState } from "../../context/UserContext";
import { fetchCohesityOptions, fetchCustomerConfig } from "../../http/api";

export default function Page() {
  const classes = useStyles();
  const vendorState = useVendorState();
  const { userData } = useUserState();
  const [crons, setCrons] = useState(INITIAL_CRONS);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const cronsManagementPermi =
    userData?.permissions_module_level?.cronsManagement;
  const [cohesityOptions, setCohesityOptions] = useState([]);
  const [configuredCohesityOptions, setConfiguredCohesityOptions] = useState([]);

  useEffect(() => {
    const loadCohesityOptions = async () => {
      const { data } = await fetchCohesityOptions();
      setCohesityOptions(data);
    }
    loadCohesityOptions();
  }, []);

  useEffect(() => {
    getApiConfigData();
  }, [vendorState.selected]);
  const getApiConfigData = () => {
    const params = {
      vendorCode: vendorState?.selected,
    };
    axios
      .get(BASE_URL + api.getApiConfig, tokenConfig(params))
      .then((res) => {
        let newCrons = INITIAL_CRONS.map((cron) => {
          let filteredCron = res.data.data.find(
            (apiConfig) => apiConfig.reportType === cron.reportType
          );
          if (filteredCron && Object.keys(filteredCron?.config).length == 0) {
            filteredCron.config = cron.config;
          }
          return { ...cron, ...filteredCron };
        });
        setCrons(newCrons);
      })
      .catch((err) => {
        console.log(err);
      });
    const loadCustomerCohesityOptions = async () => {
      const data = await fetchCustomerConfig(vendorState.selected);
      setConfiguredCohesityOptions(data.cohesityCheck || []);
    }
    loadCustomerCohesityOptions();
  };
  const handleChangeTextArea = ({ target: { name, value } }) => {
    setCrons((prev) =>
      prev.map((cron) => {
        if (cron.reportType === name)
          cron.config[Object.keys(cron.config)[0]] = value;
        return cron;
      })
    );
  };
  const handleChangeSegmentFields = (e, indx, reportType) => {
    const { name, value } = e.target;
    setCrons((prev) =>
      prev.map((cron) => {
        if (cron.reportType === reportType)
          cron.config["segments"][indx][name] = value;
        return cron;
      })
    );
  };
  const toggle = ({ target: { name, checked } }) => {
    setCrons((prev) =>
      prev.map((cron) => {
        if (cron.reportType === name)
          cron.status = checked ? "active" : "inactive";
        return cron;
      })
    );
  };
  const handleAddSegment = (name) => {
    setCrons((prev) =>
      prev.map((cron) => {
        if (cron.reportType === name)
          cron.config["segments"] = [
            ...cron.config["segments"],
            ...[{ id: "", name: "" }],
          ];
        return cron;
      })
    );
  };
  const handleRemoveSegment = (indx, reportType) => {
    setCrons((prev) =>
      prev.map((cron) => {
        if (cron.reportType === reportType)
          cron.config["segments"].splice(indx, 1);
        return cron;
      })
    );
  };

  const handleSubmit = async () => {
    try {
      setFullPageLoader(true);
      let validation = { validate: true, cron: "", field: "" };
      let postData = crons;
      postData = postData.map((cron) => {
        if (cron.id === "") delete cron.id;
        if (cron.vendorCode === "") cron.vendorCode = vendorState?.selected;
        if (
          cron.status === "active" &&
          (Object.keys(cron.config)[0] === "asins" ||
            Object.keys(cron.config)[0] === "searchTerms")
        ) {
          if (Object.values(cron.config)[0] === "") {
            validation = {
              validate: false,
              cron: cron.title,
              field:
                Object.keys(cron.config)[0] === "asins"
                  ? "ASINs are"
                  : "Search Terms are",
            };
          }
        }
        if (
          cron.status === "active" &&
          Object.keys(cron.config)[0] === "segments"
        ) {
          cron.config["segments"] = cron.config["segments"].filter(
            (segment) => segment.id !== "" || segment.name !== ""
          );
          cron.config["segments"].forEach((segment) => {
            if (segment.id !== "" && segment.name === "") {
              validation = {
                validate: false,
                cron: cron.title,
                field: "Segment Name is",
              };
            } else if (segment.id === "" && segment.name !== "") {
              validation = {
                validate: false,
                cron: cron.title,
                field: "Segment ID is",
              };
            }
          });
        }
        return cron;
      });
      postData = {
        cronsData: postData,
        cohesityData: {
          cohesity: configuredCohesityOptions,
          vendor: vendorState?.selected,
        },
      };
      if (validation?.validate) {
        axios
          .post(BASE_URL + api.postApiConfig, postData, tokenConfig())
          .then((result) => {
            getApiConfigData();
            setFullPageLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setFullPageLoader(false);
            alert(err.response.message || err.message);
          });
      } else {
        setFullPageLoader(false);
        alert(`${validation.cron} ${validation.field} missing`);
      }
    } catch (error) {
      setFullPageLoader(false);
      console.log(error);
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Crons Management"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Typography className={classes.link} color="primary">
                Crons Management
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
            >
              <Grid container spacing={6} className={classes.containerWrapper}>
                {crons.map((cron) => (
                  <Grid item xs={6} key={cron.reportType}>
                    <FormLabel id="cron-title">{cron.title}</FormLabel>
                    <Switch
                      className={classes.toggle}
                      checked={cron.status === "active"}
                      name={cron.reportType}
                      onChange={toggle}
                    />
                    <Divider />
                    {Object.keys(cron.config)[0] === "segments" ? (
                      <>
                        {Object.values(cron.config)[0].map((segment, indx) => (
                          <SegmentFields
                            key={indx}
                            indx={indx}
                            disabled={cron.status !== "active"}
                            segment={segment}
                            onRemoveSegment={handleRemoveSegment}
                            reportType={cron.reportType}
                            onValuesChange={handleChangeSegmentFields}
                          />
                        ))}
                        <Grid
                          component="div"
                          style={{
                            textAlign: "end",
                          }}
                        >
                          <Button
                            variant="contained"
                            style={{ marginTop: "15px" }}
                            color="primary"
                            size="small"
                            disabled={cron.status !== "active"}
                            onClick={() => handleAddSegment(cron.reportType)}
                          >
                            Add Segment
                          </Button>
                        </Grid>
                      </>
                    ) : Object.keys(cron.config)[0] === "asins" ||
                      Object.keys(cron.config)[0] === "searchTerms" ? (
                      <TextArea
                        cron={cron}
                        disabled={cron.status !== "active"}
                        onValuesChange={handleChangeTextArea}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                ))}
                <Box
                  sx={{
                    pt: 2,
                  }}
                >
                </Box>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '1rem' }}>
                  <Divider/>
                  <Typography color="textSecondary">Cohesity Options</Typography>
                  <div>
                    {cohesityOptions.map((option) => 
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={configuredCohesityOptions.includes(option)}
                            onChange={(event) => {
                              if (event.target.checked) {
                                setConfiguredCohesityOptions([...configuredCohesityOptions, option])
                              } else {
                                setConfiguredCohesityOptions([...configuredCohesityOptions.filter((item) => item !== option)])
                              }
                            }}
                            color="primary"
                          />
                        }
                        label={option}
                    />)}
                  </div>
                </div>
                <Box className={classes.saveBtnWrapper}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
          onClick={() => setFullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
