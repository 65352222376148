import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";

//icons
import RefreshIcon from "@material-ui/icons/Refresh";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);

  const fetchData = async () => {
    try {
      setfullPageLoader(true);
      const response = await axios.get(BASE_URL + api.listAmsProfiles);
      setmainTableData(response.data.data);
    } catch (error) {
      console.log(error);
      seterr("Failed to fetch ams profiles")
      setTimeout(() => seterr(null), 3000)
    } finally {
      setfullPageLoader(false);
    }
  };

  useEffect(() => {
    if (userState.userData) {
      fetchData();
    }
  }, [userState.userData]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="AMS Profiles"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                AMS
              </Link>
              <Typography className={classes.link} color="primary">
                Manage Profiles
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={<></>}
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <div />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                fetchData={fetchData}
                setnotification={setnotification}
                seterr={seterr}
                setfullPageLoader={setfullPageLoader}
              />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%", zIndex: 9999 }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%", zIndex: 9999 }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
