import { Box, Grid } from "@material-ui/core";
import { CAMPAIGN_KEYS, CAMPAIGN_LABELS, DATEFORMAT } from "../utils";
import useStyles from "../styles";

export default function Campaign({ campaignData }) {
  const classes = useStyles();
  return (
    <Grid container spacing={4}>
      {campaignData.map(([key, value]) => {
        if (CAMPAIGN_KEYS.includes(key)) {
          return (
            <Grid
              key={key}
              item
              sm={6}
              xs={12}
              className={classes.borderBottom}
            >
              <Box component="span" className={classes.versionHeaderCellLabel}>
                {Object.values(CAMPAIGN_LABELS.find((label) => label[key]))[0]}:{" "}
              </Box>
              <span style={{ overflowWrap: "anywhere" }}>
                {Array.isArray(value)
                  ? key === "biddingadjustments"
                    ? value.length &&
                      value.map(
                        (item, indx) =>
                          `${indx != 0 ? ", " : ""} ${
                            Object.values(item)[0] === "placementTop"
                              ? "Top placement:"
                              : "Product page:"
                          } ${Object.values(item)[1]}%`
                      )
                    : value.map((item, indx) => (
                        <span key={indx}>
                          {indx != 0 && ", "} {item}
                        </span>
                      ))
                  : ["scheduledCampaignStartDate", "startDate"].includes(key)
                  ? DATEFORMAT(value)
                  : value}
              </span>
            </Grid>
          );
        }
        return null;
      })}
    </Grid>
  );
}

// '1','2223123198821556','Test',NULL,'236921966521826','SD - Test Headphone - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220822',NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"10\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"20\"}]','SD - Test Headphone - SP - Auto','99378016346055','0.02','enabled','[\"B08232DXKL\"]','[\"180688809929497\"]','enabled','2022-09-11 18:30:00.000','0','active','0','2022-08-22 12:51:22.903','2022-08-22 12:51:22.903','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '2','2223123198821556','WOW Watersports',NULL,'163819029171851','SD - Test Headphone - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220822',NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"10\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"20\"}]','SD - Test Headphone - SP - Auto','32982785140051','0.675','enabled','[\"B08232DXKL\"]','[61002237256822]','enabled','2022-09-11 18:30:00.000','0','active','0','2022-08-22 13:03:32.265','2022-08-22 13:03:32.264','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '3','2223123198821556','WOW Watersports',NULL,'42276128277445','SD - Philips  Headphones - SP - Auto',NULL,NULL,'15','15','daily','enabled','20220822',NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"10\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"20\"}]','SD - Philips  Headphones - SP - Auto','248877806951756','1.35','enabled','[\"B08232DXKL\"]','[55062708089985]','enabled','2022-09-11 18:30:00.000','0','active','0','2022-08-22 13:07:20.847','2022-08-22 13:07:20.847','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '4','2223123198821556','WOW Watersports',NULL,'121507614345478','SD - Son Headphones - SP - Auto',NULL,NULL,'15','15','daily','enabled','20220822',NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"10\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"20\"}]','SD - Son Headphones - SP - Auto','162128219013048','1.37','enabled','[\"B0863TXGM3\",\"B09XS7JWHH\"]','[29456958795332,21043849722568]','enabled','2022-09-11 18:30:00.000','0','active','0','2022-08-22 13:09:20.454','2022-08-22 13:09:20.454','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '5','2223123198821556','WOW Watersports',NULL,'91980708164393','SD - Son Headphones - SP - Auto',NULL,NULL,'3.333333333333333','100','monthly','enabled','20220822',NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"10\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"20\"}]','SD - Son Headphones - SP - Auto','19803296620609','0.685','enabled','[\"B0863TXGM3\",\"B09XS7JWHH\"]','[15562090017358,204817342540435]','enabled','2022-09-11 18:30:00.000','0','active','0','2022-08-22 13:11:21.378','2022-08-22 13:11:21.378','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '6','2223123198821556','WOW Watersports',NULL,'269942129606554','SD - a  - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220822',NULL,'legacyForSales','[]','SD - a  - SP - Auto','184642714293154','0.755','enabled','[\"B09XS7JWHH\"]','[68135193109876]','enabled','2022-09-11 18:30:00.000','0','active','0','2022-08-22 13:43:35.860','2022-08-22 13:43:35.859','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '7','2223123198821556','WOW Watersports',NULL,'131369918035826','SD - Test Logs User Tes - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220822',NULL,'legacyForSales','[]','SD - Test Logs User Tes - SP - Auto','196407448864054','0.755','enabled','[\"B09XS7JWHH\"]','[101657112205078]','enabled','2022-09-11 18:30:00.000','0','active','0','2022-08-22 13:44:26.963','2022-08-22 13:44:26.963','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '8','2223123198821556','WOW Watersports',NULL,'24610879794622','SD - Test Logs  - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220822',NULL,'legacyForSales','[]','SD - Test Logs  - SP - Auto','118080831838027','0.755','enabled','[\"B09XS7JWHH\"]','[280502765454664]','enabled','2022-09-11 18:30:00.000','0','active','0','2022-08-22 13:48:33.241','2022-08-22 13:48:33.241','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '9','2223123198821556','WOW Watersports',NULL,'173975426478436','SD - Wow watersport Boatin - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220822',NULL,'legacyForSales','[]','SD - Wow watersport Boatin - SP - Auto','217886528298815','0.755','enabled','[\"B09XS7JWHH\"]','[34360906335981]','enabled','2022-09-11 18:30:00.000','0','active','0','2022-08-22 14:10:26.397','2022-08-22 14:10:26.396','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '10','2223123198821556','WOW Watersports',NULL,'280927578133118','SD - Sony Headphone - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220822',NULL,'legacyForSales','[]','SD - Sony Headphone - SP - Auto','123882029451073','0.755','enabled','[\"B09XS7JWHH\"]','[261681306481471]','enabled','2022-09-11 18:30:00.000','0','active','0','2022-08-22 14:12:54.824','2022-08-22 14:12:54.824','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '11','2223123198821556','WOW Watersports',NULL,'191813136227718','SD - Sony Headphone - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220822',NULL,'legacyForSales','[]','SD - Sony Headphone - SP - Auto','234791264598151','0.755','enabled','[\"B09XS7JWHH\"]','[171746211261376]','enabled','2022-09-11 18:30:00.000','0','active','0','2022-08-22 14:14:52.838','2022-08-22 14:14:52.838','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '12','2223123198821556','WOW Watersports',NULL,'138885092068454','SD - a  - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220826',NULL,'legacyForSales','[]','SD - a  - SP - Auto','89204184480512','0.775','enabled','[\"B097B85RPL\"]','[169868407659022]','enabled','2022-09-15 18:30:00.000','0','active','0','2022-08-26 10:39:58.142','2022-08-26 10:39:58.142','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '13','2223123198821556','WOW Watersports',NULL,'44420277023727','SD - a  - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220826',NULL,'legacyForSales','[]','SD - a  - SP - Auto','212632000128774','0.775','enabled','[\"B097B85RPL\"]','[237278930884227]','enabled','2022-09-15 18:30:00.000','0','active','0','2022-08-26 10:43:56.591','2022-08-26 10:43:56.591','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '14','2223123198821556','WOW Watersports',NULL,'112931111990219','SD - SONI HEADPHONE - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220826',NULL,'legacyForSales','[]','SD - SONI HEADPHONE - SP - Auto','225494549375844','0.775','enabled','[\"B097B85RPL\"]','[190950020540454]','enabled','2022-09-15 18:30:00.000','0','active','0','2022-08-26 10:48:28.622','2022-08-26 10:48:28.622','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '15','2223123198821556','WOW Watersports',NULL,'49820751072318','SD - NEW Cam - SP - Auto',NULL,NULL,'10','300','monthly','enabled','20220826',NULL,'legacyForSales','[]','SD - NEW Cam - SP - Auto','182731419442244','1.53','enabled','[\"B097B85RPL\",\"B0B4RZNJG9\"]','[227913461679078,271029188716971]','enabled','2022-09-15 18:30:00.000','0','active','0','2022-08-26 10:50:57.825','2022-08-26 10:50:57.825','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '16','2223123198821556','WOW Watersports',NULL,'85955494514302','SD - Philips Headphone - SP - Auto',NULL,NULL,'5','5','daily','enabled','20220826',NULL,'legacyForSales','[]','SD - Philips Headphone - SP - Auto','231044782385204','0.775','enabled','[\"B097B85RPL\"]','[43952031818936]','enabled','2022-09-15 18:30:00.000','0','active','0','2022-08-26 13:52:37.379','2022-08-26 13:52:37.379','enabled',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '18','2223123198821556','WOWC',NULL,'246571639621900','SD - WOWC 6007 Glue & Adhesives - SP - Auto',NULL,NULL,'5','5','daily','20221011',NULL,NULL,'legacyForSales','[]','SD - WOWC 6007 Glue & Adhesives - SP - Auto','261993320115495','0.265','enabled','[\"B003BNH65U\",\"B003BN9VB2\"]','[249897592934899,202772398745825]','enabled',NULL,'0','active','0','2022-10-11 03:18:48.010','2022-10-11 03:18:48.010','enabled',NULL,'auto',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '19','2223123198821556','WOWC',NULL,'175677410957589','SD - WOWC 6007 Glue & Adhesives - SP - Auto',NULL,NULL,'5','5','daily','20221011',NULL,NULL,'legacyForSales','[]','SD - WOWC 6007 Glue & Adhesives - SP - Auto','218560508229503','0.265','enabled','[\"B003BNH65U\",\"B003BN9VB2\"]','[201589462957841,266253575557694]','enabled',NULL,'0','active','0','2022-10-11 03:22:40.631','2022-10-11 03:22:40.631','enabled',NULL,'auto',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '20','2223123198821556','WOWC',NULL,'197113340590647','SD - WOWC 6007 Glue & Adhesives - SP - Auto',NULL,NULL,'5','5','daily','20221011',NULL,NULL,'legacyForSales','[]','SD - WOWC 6007 Glue & Adhesives - SP - Auto','100859122140526','0.265','enabled','[\"B003BNH65U\",\"B003BN9VB2\"]','[277328943360678,158008462503678]','enabled',NULL,'0','active','0','2022-10-11 03:26:02.447','2022-10-11 03:26:02.447','enabled',NULL,'auto',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '21','2223123198821556','WOWC',NULL,'200238305679934','SD - WOWC 6007 Glue & Adhesives - SP - Auto',NULL,NULL,'5','5','daily','20221011',NULL,NULL,'legacyForSales','[]','SD - WOWC 6007 Glue & Adhesives - SP - Auto','185185940645647','0.265','enabled','[\"B003BNH65U\",\"B003BN9VB2\"]','[219668687289820,258007602397189]','enabled',NULL,'0','active','0','2022-10-11 03:29:22.330','2022-10-11 03:29:22.330','enabled',NULL,'auto',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '22','2223123198821556','WOWC',NULL,'166161883755650','SD - WOWC 6007 Glue & Adhesives - SP - Auto',NULL,NULL,'5','5','daily','20221011',NULL,NULL,'legacyForSales','[]','SD - WOWC 6007 Glue & Adhesives - SP - Auto','233037273161302','0.265','enabled','[\"B003BNH65U\",\"B003BN9VB2\"]','[171179148839145,104519726260395]','enabled',NULL,'0','active','0','2022-10-11 03:30:29.258','2022-10-11 03:30:29.258','enabled',NULL,'auto',NULL,NULL,NULL,NULL,NULL,NULL,NULL,NULL
// '23','2223123198821556','WOWC',NULL,'155924139159875','SD - WOWC 8006 Chalks & Crayons - SP - Auto',NULL,NULL,'5','5','daily','20221012',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 8006 Chalks & Crayons - SP - Auto','152342341843727','0.525','enabled','[\"B07Z4KHT19\",\"B07J352QST\"]','[259329391703039,262288342937039]','enabled',NULL,'0','active','0','2022-10-12 01:41:57.633','2022-10-12 01:41:57.633','enabled',NULL,'auto',NULL,'Increase Sales','20',NULL,NULL,NULL,NULL,NULL
// '24','2223123198821556','WOWC',NULL,'73060502724472','SD - WOWC 6007 Glue & Adhesives - SP - Auto',NULL,NULL,'5','5','daily','20221012',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 6007 Glue & Adhesives - SP - Auto','120697995360772','0.465','enabled','[\"B003BN9VB2\",\"B004Y4HAO2\"]','[34338734216107,13654805695699]','enabled',NULL,'0','active','0','2022-10-12 05:07:47.131','2022-10-12 05:07:47.131','enabled',NULL,'auto',NULL,'Increase Sales','30',NULL,NULL,NULL,NULL,NULL
// '25','2223123198821556','WOWC',NULL,'107709064071751','SD - WOWC 6007 Glue & Adhesives - SP - Auto',NULL,NULL,'5',NULL,'daily','20221012',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 6007 Glue & Adhesives - SP - Auto','202203324320990','0.465','enabled','[\"B003BN9VB2\",\"B004Y4HAO2\"]','[140697177921821,254486375490923]','enabled',NULL,'0','active','0','2022-10-12 11:01:34.100','2022-10-12 11:01:34.100','enabled',NULL,'manual',NULL,'Increase Sales','30',NULL,NULL,NULL,NULL,NULL
// '26','2223123198821556','WOWC',NULL,'140773564775552','SD - WOWC 5011 Labeling Tape & Roll Refills - SP - Auto',NULL,NULL,'5','5','daily','20221012',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 5011 Labeling Tape & Roll Refills - SP - Auto','52301822947567','0.24','enabled','[\"B003BNBUSE\",\"B003BNH5IS\"]','[225314858198039,31256104109750]','enabled',NULL,'0','active','0','2022-10-12 11:51:53.518','2022-10-12 11:51:53.518','enabled',NULL,'auto',NULL,'Increase Sales','20',NULL,NULL,NULL,NULL,NULL
// '27','2223123198821556','WOWC',NULL,'102418308342561','SD - WOWC 6007 Glue & Adhesives - SP - Manual - Keyword Targeting',NULL,NULL,'5','5','daily','20221012',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 6007 Glue & Adhesives - SP - Manual - Keyword Targeting','30853744216571',NULL,'enabled','[\"B004Y4HAO2\",\"B004Y4HAKQ\"]','[140347421844694,171564271858784]','enabled',NULL,'0','active','0','2022-10-13 06:21:43.716','2022-10-13 06:21:43.716','enabled',NULL,'manual',NULL,'Increase Sales','30',NULL,NULL,'[80537112096219, 36452649636454, 266676159347630, 12025480819932, 252178965608989, 10547079597976]','Keyword Targeting',NULL
// '28','2223123198821556','WOWC',NULL,'35378297079196','SD - WOWC 6003 Staplers & Punches - SP - Auto',NULL,NULL,'5','5','daily','20221013',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 6003 Staplers & Punches - SP - Auto','15524655022030','0.415','enabled','[\"B0026K2BGI\",\"B003AHF4DS\"]','[66351374555997,184322879669890]','enabled',NULL,'0','active','0','2022-10-13 06:43:44.604','2022-10-13 06:43:44.604','enabled',NULL,'auto',NULL,'Increase awareness','10',NULL,NULL,NULL,'',NULL
// '29','2223123198821556','WOWC',NULL,'215157204426707','SD - WOWC 8006 Chalks & Crayons - SP - Manual - Product Targeting',NULL,NULL,'5','5','daily','20221013',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"40\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"50\"}]','SD - WOWC 8006 Chalks & Crayons - SP - Manual - Product Targeting','255650953524704',NULL,'enabled','[\"B07Z4KHT19\",\"B07J352QST\"]','[8459372839529,55129993875473]','enabled',NULL,'0','active','0','2022-10-13 08:33:31.122','2022-10-13 08:33:31.122','enabled',NULL,'manual',NULL,'Increase Sales','40',NULL,NULL,NULL,'Product Targeting','[null]'
// '30','2223123198821556','WOWC',NULL,'17615183066076','SD - WOWC 8006 Chalks & Crayons - SP - Manual - Product Targeting',NULL,NULL,'5','5','daily','20221013',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"40\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"50\"}]','SD - WOWC 8006 Chalks & Crayons - SP - Manual - Product Targeting','86491952817265',NULL,'enabled','[\"B07Z4KHT19\",\"B07J352QST\"]','[197904946900754,99156185188351]','enabled',NULL,'0','active','0','2022-10-13 08:50:41.137','2022-10-13 08:50:41.137','enabled',NULL,'manual',NULL,'Increase Sales','40',NULL,NULL,NULL,'Product Targeting','[153146646032023]'
// '31','2223123198821556','WOWC',NULL,'27760325062626','SD - WOWC 8006 Chalks & Crayons - SP - Manual - Product Targeting',NULL,NULL,'5','5','daily','20221013',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"40\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"50\"}]','SD - WOWC 8006 Chalks & Crayons - SP - Manual - Product Targeting','267686849883925',NULL,'enabled','[\"B07Z4KHT19\",\"B07J352QST\"]','[157931202189574,14737285578089]','enabled',NULL,'0','active','0','2022-10-13 08:52:42.141','2022-10-13 08:52:42.141','enabled',NULL,'manual',NULL,'Increase Sales','40',NULL,NULL,NULL,'Product Targeting','[140518398536766]'
// '32','2223123198821556','WOWC',NULL,'141244783853066','SD - WOWC 5010 Labelers - SP - Manual - Product Targeting',NULL,NULL,'5','5','daily','20221013',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 5010 Labelers - SP - Manual - Product Targeting','158852564169956',NULL,'enabled','[\"B004Y4HBLE\",\"B004Y4HBFA\"]','[216950753992594,226878909069859]','enabled',NULL,'0','active','0','2022-10-13 08:54:21.839','2022-10-13 08:54:21.839','enabled',NULL,'manual',NULL,'Increase Sales','50',NULL,NULL,NULL,'Product Targeting','[86558932602744]'
// '33','2223123198821556','WOWC',NULL,'267045176884387','SD - WOWC 7010 Casepack Cut Sheet Paper - SP - Manual - Product Targeting',NULL,NULL,'10','10','daily','20221015',NULL,NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 7010 Casepack Cut Sheet Paper - SP - Manual - Product Targeting','153622954532178',NULL,'enabled','[\"B001AZ81E6\"]','[114644180150471]','enabled',NULL,'0','active','0','2022-10-13 08:59:07.667','2022-10-13 08:59:07.667','enabled',NULL,'manual',NULL,'Increase Sales','50',NULL,NULL,NULL,'Product Targeting','[159249460456987]'
// '34','2223123198821556','WOWC',NULL,'220257256322544','SD - WOWC 7010 Casepack Cut Sheet Paper - SP - Manual - Keyword Targeting',NULL,NULL,'20','20','daily','20221015',NULL,NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 7010 Casepack Cut Sheet Paper - SP - Manual - Keyword Targeting','57433923395359',NULL,'enabled','[\"B001AZ81E6\"]','[238509360066091]','enabled',NULL,'0','active','0','2022-10-13 09:00:52.182','2022-10-13 09:00:52.182','enabled',NULL,'manual',NULL,'Increase Sales','50',NULL,'[15021434846496]','[198988092006636, 89745904562090, 255828044214831]','Keyword Targeting',NULL
// '35','2223123198821556','WOWC',NULL,'211836175836136','SD - WOWC 7010 Casepack Cut Sheet Paper - SP - Manual - Product Targeting',NULL,NULL,'50','50','daily','20221015',NULL,NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 7010 Casepack Cut Sheet Paper - SP - Manual - Product Targeting','269961105646418',NULL,'enabled','[\"B001AZ81E6\"]','[214717600479065]','enabled',NULL,'0','active','0','2022-10-13 09:03:10.120','2022-10-13 09:03:10.120','enabled',NULL,'manual',NULL,'Increase Sales','50','[85949350515837]',NULL,NULL,'Product Targeting','[80584271346363]'
// '36','2223123198821556','WOWC',NULL,'140747122596673','SD - WOWC 7010 Casepack Cut Sheet Paper - SP - Auto',NULL,NULL,'16.66666666666667','500','monthly','20221015',NULL,NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 7010 Casepack Cut Sheet Paper - SP - Auto','246316623359017','1.34','enabled','[\"B001AZ81E6\"]','[166208342007269]','enabled',NULL,'0','active','0','2022-10-13 09:19:26.031','2022-10-13 09:19:26.031','enabled',NULL,'auto',NULL,'Increase Sales','50','[225061439448677]','[49060234732442]',NULL,'Keyword Targeting',NULL
// '37','2223123198821556','WOWC',NULL,'243830764418953','SD - WOWC 2570 Supplies - SP - Auto',NULL,NULL,'13.33333333333333','400','monthly','20221015',NULL,NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 2570 Supplies - SP - Auto','140009440191750','1.11','enabled','[\"B07L69C2HP\",\"B00PEF2P4U\",\"B00PEF2R8Y\",\"B00PEF2SY2\",\"B01GNAVT0U\",\"B0B397YHC4\",\"B0B3977GYB\"]','[267691688919263,19837406623642,88543214091669,204707968491475,258247091587366,45048799600320,198719439786964]','enabled',NULL,'0','active','0','2022-10-13 09:21:51.057','2022-10-13 09:21:51.057','enabled',NULL,'auto',NULL,'Increase Sales','50','[204064429162794]','[252670628417473]',NULL,'Keyword Targeting',NULL
// '38','2223123198821556','WOWC',NULL,'185696969830575','SD - WOWC 16060 Stains & Sealers - SP - Auto',NULL,NULL,'60','60','daily','20221015',NULL,NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 16060 Stains & Sealers - SP - Auto','6068304249181','0.89','enabled','[\"B09NYLKYNL\",\"B01N0EB9Z4\"]','[153364815997250,36835014370866]','enabled',NULL,'0','active','0','2022-10-13 09:43:47.824','2022-10-13 09:43:47.824','enabled',NULL,'auto_and_scheduled_manual',NULL,'Increase Sales','50','[268102120280972]','[265425111720653]',NULL,'',NULL
// '39','2223123198821556','WOWC',NULL,'234611149178903','SD - WOWC 16060 Stains & Sealers - SP - Auto',NULL,NULL,'41','41','daily','20221015',NULL,NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 16060 Stains & Sealers - SP - Auto','116704892948064','0.89','enabled','[\"B09NYLKYNL\",\"B01N0EB9Z4\"]','[215840161043608,216174358825062]','enabled',NULL,'0','active','0','2022-10-13 11:33:07.031','2022-10-13 11:33:07.031','enabled',NULL,'auto_and_scheduled_manual',NULL,'Increase Sales','50','[197623506897672]','[117117257785777]',NULL,'',NULL
// '40','2223123198821556','WOWC',NULL,'83623036456220','SD - WOWC 16060 Stains & Sealers - SP - Auto',NULL,NULL,'22','22','daily','20221015',NULL,NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 16060 Stains & Sealers - SP - Auto','145620234169520','0.89','enabled','[\"B09NYLKYNL\",\"B01N0EB9Z4\"]','[40131987189049,74650008935513]','enabled',NULL,'0','active','0','2022-10-13 11:38:39.681','2022-10-13 11:38:39.681','enabled',NULL,'auto_and_scheduled_manual',NULL,'Increase Sales','50','[214424864875539]','[49103020193392]',NULL,'',NULL
// '41','2223123198821556','WOWC',NULL,'177294007461988','SD - WOWC 16060 Stains & Sealers - SP - Auto',NULL,NULL,'22','22','daily','20221015',NULL,NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 16060 Stains & Sealers - SP - Auto','143892461230114','0.89','enabled','[\"B09NYLKYNL\",\"B01N0EB9Z4\"]','[157846192429660,179992467630263]','enabled',NULL,'0','active','0','2022-10-13 11:40:19.920','2022-10-13 11:40:19.920','enabled',NULL,'auto_and_scheduled_manual',NULL,'Increase Sales','50','[87570850730721]','[233250077244297]',NULL,'',NULL
// '42','2223123198821556','WOWC',NULL,'60782199562968','SD - WOWC 16060 Stains & Sealers - SP - Auto',NULL,NULL,'11','11','daily','20221015',NULL,NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 16060 Stains & Sealers - SP - Auto','245558804360648','0.89','enabled','[\"B09NYLKYNL\",\"B01N0EB9Z4\"]','[274948595618180,281368370727808]','enabled',NULL,'0','active','0','2022-10-13 11:51:03.451','2022-10-13 11:51:03.451','enabled',NULL,'auto_and_scheduled_manual',NULL,'Increase Sales','50','[32556777008102]','[242158384797692]',NULL,'',NULL
// '43','2223123198821556','WOWC',NULL,'224099517554145','SD - WOWC 8004 Sharpener - SP - Auto',NULL,NULL,'50','50','daily','20221013',NULL,NULL,'autoForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"40\"}]','SD - WOWC 8004 Sharpener - SP - Auto','127009993903460','0.84','enabled','[\"B00PJB5Z5K\",\"B00CKGO2W4\",\"B003BNJBVC\",\"B0027IXKSM\",\"B00CKGO47C\",\"B003BN9S9M\",\"B0033IBHV2\",\"B003AHF4C4\",\"B00CKGO2VU\",\"B00CKGO490\",\"B003AHIFU2\",\"B000KCY78E\",\"B000NJT14E\",\"B003BNH57E\"]','[55630846851892,48850734152068,88903712265366,263628711121248,280851019292024,47752692623140,23814008588565,280834145228817,155932835614234,131792435951944,184058679628022,166604909093396,253796285236178,203569438258869]','enabled',NULL,'0','active','0','2022-10-13 12:00:20.243','2022-10-13 12:00:20.243','enabled','2022-10-26 18:30:00.000','auto_and_scheduled_manual',NULL,'Increase Sales','100','[60642432226123]','[232034281647248]',NULL,'',NULL
// '44','2223123198821556','WOWC',NULL,'50535624254503','SD - WOWC 6007 Glue & Adhesives - SP - Manual - Keyword Targeting',NULL,NULL,'5','5','daily','20221013',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"40\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"50\"}]','SD - WOWC 6007 Glue & Adhesives - SP - Manual - Keyword Targeting','158986452734978',NULL,'enabled','[\"B003BNH65U\",\"B003BN9VB2\",\"B004Y4HAO2\",\"B004Y4HAKQ\",\"B003BN9V5I\",\"B003BN9V7Q\",\"B004Y4HAKG\",\"B003BN9UHM\",\"B003BNH6BE\",\"B004Y4HAJM\",\"B004XG97ZG\",\"B004Y4HAII\",\"B003A007TG\",\"B004Y4HAV0\",\"B004Y4HAVA\",\"B003BN9T3M\",\"B003BNBWO6\",\"B004Y4HBQ4\",\"B004Y4HBR8\",\"B003BNH5EM\",\"B004Y4HAOC\",\"B004Y4HAFG\",\"B004Y4HAOM\",\"B004Y4HARE\",\"B004Y4HARO\",\"B003BNBWA0\",\"B003BN9UKY\",\"B003BNH7PY\",\"B004Y4HAK6\"]','[133812598061755,103384772688923,63660549537682,59515342951477,180356466902447,278692362836795,138429874419595,260053569092192,18112633943271,119428812804797,140527712325054,9260513462982,504312658220,137807858559605,151642624848505,25109031410868,118036074968004,50078804798426,64709566069402,63872206005715,193468822289138,235304917025388,52822056903756,161424283255505,65298676041635,12796586643902,229971411339011,232805674669003,205342681093627]','enabled',NULL,'0','active','0','2022-10-13 13:01:08.219','2022-10-13 13:01:08.219','enabled','2022-10-26 18:30:00.000','manual',NULL,'Reduce ACOS','10',NULL,NULL,'[25616731813393, 176920622945406, 213627181513965]','Keyword Targeting',NULL
// '45','2223123198821556','WOWC',NULL,'62370820249829','SD - WOWC 8006 Chalks & Crayons - SP - Manual - Keyword Targeting',NULL,NULL,'5','5','daily','20221013',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":\"50\"},{\"predicate\":\"placementProductPage\",\"percentage\":\"50\"}]','SD - WOWC 8006 Chalks & Crayons - SP - Manual - Keyword Targeting','20959186474260',NULL,'enabled','[\"B07Z4KHT19\",\"B07J352QST\",\"B001UE6MI4\"]','[48763781103488,116383776911236,77326919651344]','enabled',NULL,'0','active','0','2022-10-13 13:03:06.403','2022-10-13 13:03:06.403','enabled','2022-10-26 18:30:00.000','manual',NULL,'Increase Sales','10',NULL,NULL,'[109796087606814, 229823657843405, 66322267548854]','Keyword Targeting',NULL
// '46','2223123198821556','WOWC',NULL,'188612683721731','SD - WOWC 3002 Desk Accessories & Workspace Organizers - SP - Auto',NULL,NULL,'2.333333333333333','70','monthly','20221024',NULL,NULL,'legacyForSales','[{\"predicate\":\"placementTop\",\"percentage\":82},{\"predicate\":\"placementProductPage\",\"percentage\":54}]','SD - WOWC 3002 Desk Accessories & Workspace Organizers - SP - Auto','68080157006902','0.385','enabled','[\"B00CKGO10C\",\"B08KZ4BTV7\",\"B08KWMMZ45\",\"B0B785HDR3\",\"B0B785YYD7\",\"B0B7859W1Y\",\"B00CKGNY3W\",\"B08KWPNGQR\",\"B08KWMB1L6\",\"B08KYHXCDP\",\"B08KWN7LLN\",\"B08KYH66PP\",\"B00CKGNY6Y\",\"B004Y4HAR4\"]','[216673432566797,108377921213361,256498645727022,199431844636974,176536326542908,69924061624374,171530977052675,262164219213018,188743657209414,259446457686888,185321275229973,15844602008239,200112271076994,null]','enabled',NULL,'0','active','0','2022-10-24 08:57:00.180','2022-10-24 08:57:00.180','enabled','2022-11-06 19:00:00.000','auto',NULL,'Increase awareness','10',NULL,NULL,NULL,'Keyword Targeting',NULL
// '47','2223123198821556','WOWC',NULL,'13579335290293','SD - WOWC 3002 Desk Accessories & Workspace Organizers - SP - Auto',NULL,NULL,'5','5','daily','20221024','20221024',NULL,'legacyForSales','[]','SD - WOWC 3002 Desk Accessories & Workspace Organizers - SP - Auto','62610306642558','0.385','enabled','[\"B00CKGO10C\",\"B08KZ4BTV7\",\"B08KWMMZ45\",\"B0B785HDR3\",\"B0B785YYD7\",\"B0B7859W1Y\",\"B00CKGNY3W\",\"B08KWPNGQR\",\"B08KWMB1L6\",\"B08KYHXCDP\",\"B08KWN7LLN\",\"B08KYH66PP\",\"B00CKGNY6Y\",\"B004Y4HAR4\"]','[167702991570339,23911795737433,214481687137743,187590001726372,87360804761846,195131766514683,9181795815865,99852890672732,258643906758891,28088247271714,279327772971218,60341750565256,253343264714250,null]','enabled',NULL,'0','active','0','2022-10-24 12:28:51.680','2022-10-24 12:28:51.680','enabled','2022-11-06 19:00:00.000','auto',NULL,'Increase awareness','10','[]','[261556469080697, 180367223050968, 219425533061363, 83109759437805]',NULL,'',NULL
// '48','2223123198821556','WOWC',NULL,'264275192817352','SD - WOWC 3002 Desk Accessories & Workspace Organizers - SP - Manual - Keyword Targeting',NULL,NULL,'5','5','daily','20221025','20221025',NULL,'legacyForSales','[]','SD - WOWC 3002 Desk Accessories & Workspace Organizers - SP - Manual - Keyword Targeting','145384858741028',NULL,'enabled','[\"B00CKGO10C\",\"B08KZ4BTV7\",\"B08KWMMZ45\",\"B0B785HDR3\",\"B0B785YYD7\",\"B0B7859W1Y\",\"B00CKGNY3W\",\"B08KWPNGQR\",\"B08KWMB1L6\",\"B08KYHXCDP\",\"B08KWN7LLN\",\"B08KYH66PP\",\"B00CKGNY6Y\",\"B004Y4HAR4\"]','[274563575933323,58986649272747,202412976018014,6061788600438,127164728944797,77879330282331,207458228142999,211528230890969,127891343896274,215348141129748,122075103066823,70994625951756,70012681386284,null]','enabled',NULL,'0','active','0','2022-10-25 01:17:12.635','2022-10-25 01:17:12.635','enabled','2022-11-07 19:00:00.000','manual',NULL,'Increase awareness','10',NULL,NULL,'[null, null, null, null, null, null, null, null, null]','Keyword Targeting',NULL
