import React, { useState , useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  Link
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';

// styles
import useStyles from "./styles";

// logo
import google from "../../images/google.svg";

// context
import { useUserDispatch, useUserState, loginUser , setAmazonCredentials } from "../../context/UserContext";
import { usePopupState , usePopupDispatch } from "../../context/PopupContext";

function getParameterByName(name, url) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function Login(props) {
  var classes = useStyles();

  // global
  var userState = useUserState();
  var userDispatch = useUserDispatch();
  var popupDispatch = usePopupDispatch();
  var { err, notification } = usePopupState();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  const setAmazon = () => {
    var amazon_callback_uri = getParameterByName("amazon_callback_uri",window.location.href);
    var amazon_state = getParameterByName("amazon_state", window.location.href);
    var selling_partner_id = getParameterByName("selling_partner_id", window.location.href);
    var state = getParameterByName("state", window.location.href);

    console.log({state,amazon_callback_uri, amazon_state, selling_partner_id});

    setAmazonCredentials(userDispatch, amazon_callback_uri, amazon_state, selling_partner_id, state)
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <Typography className={classes.logotypeText}>
          Dashboard Login
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Typography variant="h2" className={classes.greeting}>
              Welcome
            </Typography>
            <div className={classes.formDivider} />

            {/* <Button size="large" className={classes.googleButton}>
              <img src={google} alt="google" className={classes.googleIcon} />
              &nbsp;Sign in with Google
            </Button> */}
            {/* <div className={classes.formDividerContainer}>
              <div className={classes.formDivider} />
              <Typography className={classes.formDividerWord}>or</Typography>
              <div className={classes.formDivider} />
            </div> */}
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={loginValue}
              onChange={(e) => setLoginValue(e.target.value)}
              margin="normal"
              placeholder="Email Adress"
              type="email"
              fullWidth
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Password"
              type="password"
              fullWidth
            />
            <div className={classes.forgotText}>
            <Link href="forgot_password">Forgot Password?</Link>
            </div>
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={
                    loginValue.length === 0 || passwordValue.length === 0
                  }
                  onClick={() => {
                    setAmazon();
                    loginUser(
                      userDispatch,
                      userState,
                      popupDispatch,
                      loginValue,
                      passwordValue,
                      props.history,
                      setIsLoading,
                    )
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Login
                </Button>
              )}
              <Button
                color="primary"
                size="large"
                className={classes.forgetButton}
                onClick={() => props.history.push("/register")}
              >
                Register
              </Button>
            </div>
          </React.Fragment>
        </div>
      </div>
      {
        notification ? 
          <Alert severity="success" style={{position:'absolute', bottom: 30, left: '48%'}}>{notification}</Alert>
        :
        <></>
      }      
      {
        err ? 
          <Alert severity="error" style={{position:'absolute', bottom: 30, left: '48%'}}>{err}</Alert>
        :
        <></>
      }     
    </Grid>
  );
}

export default withRouter(Login);
