import axios from "axios"

/**
 * Makes an axios get request to the database.
 * @param {string} url - URL
 * @param {Object} headers - Headers
 * @param {Object} params - Parameters 
 * @returns {*} Response from the server
 */
export const GET_API = (url, headers, params) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            headers,
            params
        }).then((data) => {
            resolve(data);
        }).catch((err) => {
            console.error(`Failed to fetch url: ${url}`, err);
            reject(err);
        })
    })
}

/**
 * Makes an axios post request to the database.
 * @param {string} url - URL
 * @param {Object} headers - Headers
 * @param {Object} body - Body 
 * @returns {*} Response from the server
 */
 export const POST_API = (url, headers, body) => {
    return new Promise((resolve, reject) => {
        axios.post(url, body, {
            headers
        }).then((data) => {
            resolve(data);
        }).catch((err) => {
            console.error(`Failed to fetch url: ${url}`, err);
            reject(err);
        })
    })
}