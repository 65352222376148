import React, {
  useState,
  useRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@material-ui/styles";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";

// context
import { useLayoutState } from "../../../../context/LayoutContext";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  success: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  warning: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
  },
  secondary: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  },
}));

export default forwardRef(function Tables(props, ref) {
  const classes = useStyles();
  const gridRef = useRef(null);

  // global
  var layoutState = useLayoutState();

  //local
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);



  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    //export table to csv
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else gridApi.exportDataAsCsv();
    },
  }));


  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        
        rowHeight={40}
        onGridReady={onGridReady}
        paginationPageSize={8}
        suppressDragLeaveHidesColumns={true}
      >
        <AgGridColumn headerName="KPIs" field="KPIs"/>
        <AgGridColumn headerName="Reported" field="Reported"/>
        <AgGridColumn
          headerName="Prior Period"
          field="Prior Period"
          cellStyle={cellStyleApprovedAmout}
        />
        <AgGridColumn
          headerName="Last Year"
          field="Last Year"
          cellStyle={cellStyleApprovedAmout}
        />
      </AgGridReact>
    </div>
  );
});

// cell color on basis of Rep OOS & Lost Buy Box (LBB) Price
function cellStyleApprovedAmout(params) {
  var color;
  if (
    params?.data?.KPIs === "Rep OOS" ||
    params?.data?.KPIs === "Lost Buy Box (LBB) Price"
  ) {
    if (params?.value?.charAt(0) === "-") color = "#d8e9d2";
    else color = "#e6b7af";
  } else {
    if (params?.value?.charAt(0) === "-") color = "#e6b7af";
    else color = "#d8e9d2";
  }
  return {
    // backgroundColor: color,
    borderBottom: '1px solid rgba(178,178,178,0.25)'
  };
}
