import moment from 'moment';

/**
 * Filters the data either based on duration / min date and max date
 * @param {Array} data - Array of objects.(startDate required for the object)
 * @param {Object} date - Object with two keys(amount and unit).
 * @returns 
 */
// eslint-disable-next-line eqeqeq
export const filterBrandSegmentDataByDate = (data, date) => data.filter((ele) =>(moment(ele.date) == moment(date)))


/**
 * Changes the format for a few fields in the Order Metrics Response.
 * @param {Array} data - Array of order metrics.
 * @param {string} granularity - Hour/Day. Decides the format of the date in the table.
 * @returns {Array} Returns formatted order metrics in an array format.
 */
export const modifyBrandSegmentsByDayData = (data) => {
    return (data || []).map((ele) => {
        return {
            ...ele,
            date: moment(ele.date).format('MM/DD/YYYY'),
            averagePrice: `$${ele?.averagePrice}`,
            revenue: `$${ele?.revenue}`,
            revenue1p: `$${ele?.revenue1p}`,
            revenue3p: `$${ele?.revenue3p}`,
            unitSales: `${Math.round(ele?.unitSales || 0)}`,
        }
    });
}
