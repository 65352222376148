import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import useStyles from "./styles";

export default function Page({
  location: {
    state: { path, subPath },
  },
}) {
  const classes = useStyles();
  return (
    <Container maxWidth={false}>
      <PageTitle
        title={subPath ? subPath : path}
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            {subPath ? (
              <Link color="inherit" className={classes.link}>
                {path}
              </Link>
            ) : null}
            <Typography className={classes.link} color="primary">
              {subPath ? subPath : path}
            </Typography>
          </Breadcrumbs>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            bodyClass={classes.tableOverflow}
            disableWidgetMenu
          >
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5">Unauthorized Access</Typography>
              <Typography variant="subtitle1">
                You don't have permissions to access{" "}
                <b>{subPath ? subPath : path}</b> page.
              </Typography>
            </Box>
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}
