import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  borderBottom: { borderBottom: "1px solid #e2e2e2", padding: "6px" },
  versionHeaderCellLabel: {
    display: "inline-block",
    fontSize: "1rem",
    fontWeight: "normal",
    color: "grey",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginRight: "5px",
  },
  versionSubHeaderCellLabel: {
    display: "inline-block",
    fontSize: "1rem",
    fontWeight: "normal",
    color: "grey",
    textTransform: "capitalize",
    display: "inline-block",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: theme.palette.primary.main,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
  accordionDetails: {
    display: "inline-grid",
    "grid-template-columns": "auto auto",
    "column-gap": "20px",
    "row-gap": "31px",
  },
}));
