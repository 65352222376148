import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ResponsiveContainer
} from "recharts";
import { Typography } from "@material-ui/core";
import { noCurve } from "./noCurve";
import { makeStyles } from '@material-ui/core/styles';

//components
import Dot from "../../../../components/Sidebar/components/Dot";

//helpers
import { numberWithCommas } from "../../../../healpers/utilityFunctions";
import { useUserState } from "../../../../context/UserContext";

// styles
const useStyles = makeStyles((theme) => ({
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
}));

export default function Chart({ data, brandList, colors, changeInterval, interval, reportType }) {

  //global
  const classes = useStyles();
  const userState = useUserState();

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length !== 0) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#ffff",
            padding: "5px",
            border: "1px solid #cccc",
          }}
        >
          <Typography style={{ whiteSpace: "nowrap", fontSize: 14 }}><b><u>{payload[0].payload["dateRange"]}</u></b></Typography>
          {
            payload.map((item, index) => (
              <div key={colors[index]} className={classes.legendItemContainer}>
                <Dot color={colors[index]} legend />
                <Typography style={{ whiteSpace: "nowrap", fontSize: 14 }} >
                  &nbsp;{item.dataKey}:&nbsp;{reportType.includes("revenue") ? "$" : null}{numberWithCommas(item.value)}&nbsp;
                </Typography>
              </div>
            ))
          }
          {/* <label style={{ fontWeight: "bold" }}>
          <u>
            <i>{`${toProperCase(payload[0].name)}`}</i>
          </u>
        </label>
        <br />
        <label>{`Count : ${payload[0].payload.count}`}</label>
        <br />
        <label>{`Value : $${payload[0].payload.price.toFixed(2)}`}</label> */}
        </div>
      );
    }

    return null;
  };
  return (
    <div style={{ position: "relative", width: "100%" }}>
      <ResponsiveContainer width="100%" height={600} >
        {
          reportType.includes("revenue") ?
            <AreaChart
              data={data}
              stackOffset="expand"
              margin={{
                top: 20,
                right: 0,
                left: -40,
                bottom: 30
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Needed Date"/>
              <YAxis tick={false} />
              <Tooltip content={<CustomTooltip />} />
              {
                brandList ? brandList.map((item, index) => {
                  return (
                    <Area
                      type={noCurve}
                      dataKey={item}
                      stackId="1"
                      stroke={colors[index]}
                      fill={colors[index]}
                    />
                  )
                }) : <></>
              }
            </AreaChart>
            :
            <LineChart
              data={data}
              margin={{
                top: 20,
                right: 0,
                bottom: 30
              }}
            >
              <XAxis dataKey="Needed Date" />
              <YAxis type="number" />
              <Tooltip content={<CustomTooltip />} />
              {
                brandList ? brandList.map((item, index) => {
                  return (
                    <Line
                      type="monotone"
                      dataKey={item}
                      stackId="1"
                      stroke={colors[index]}
                    />
                  )
                }) : <></>
              }
            </LineChart>

        }
      </ResponsiveContainer>
      {
        userState?.userData?.email?.includes("salesduo.com") ?
        <div style={{ position: 'absolute', bottom: '10px', right: '0px', zIndex: 1, display: "flex" }}>
          <div style={{ borderRight: "1px solid grey", width: 20, textAlign: "center", textDecoration: `${interval === "day" ? "underline" : "none"}`, cursor: "pointer" }} onClick={() => changeInterval("day")}>D</div>
          <div style={{ borderRight: "1px solid grey", width: 20, textAlign: "center", textDecoration: `${interval === "week" ? "underline" : "none"}`, cursor: "pointer" }} onClick={() => changeInterval("week")}>W</div>
          <div style={{ width: 20, textAlign: "center", textDecoration: `${interval === "month" ? "underline" : "none"}`, cursor: "pointer" }} onClick={() => changeInterval("month")}>M</div>
        </div>
        : null
      }
    </div>
  );
}
