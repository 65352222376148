import moment from 'moment';
import marketplaces from "../../src/data/spMarketPlaceIds.json"

function toProperCase(str) {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

function camelToProperCase(str) {
  const text = str;
  const result = text.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

function convertNumberToShorthand (n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
};

function numberWithCommas(x, decimal) {
  if(decimal) {
    return x.toFixed(decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}

function sortByDate(array,dateTobefiltered) {
  return array.sort((a,b) => moment(a[dateTobefiltered]).diff(moment(b[dateTobefiltered])))
}

function ascendingSort(array) {
  return array.sort((a,b) => a - b);
}

function encodeForProxy(pagename,vendorcode,startdate,enddate){
  return `${pagename}_${vendorcode}_${startdate}_${enddate}`
}

// for metrics pages
function encodeForProxyCustom(pagename, vendorcode, duration, year) {
  return `${pagename}_${vendorcode}_${duration}_${moment(year).format("YYYY")}`;
}

function encodeForProxyCustomAll(pagename, vendorcode, duration) {
  return `${pagename}_${vendorcode}_${duration}_ALL`;
}

const sortObjectByKeys = o => Object.keys(o).sort().reduce((r, k) => (r[k] = o[k], r), {})

function encodeForCacheWithoutDates(pagename, vendorcode) {
  return `${pagename}_${vendorcode}`;
}

function removeDollarAndCommaFromAmount (str) {
  if(str[0]==="-")
    return str.includes("$") ? parseFloat(str.slice(2).replace(/,/g, '')) : parseFloat(str.replace(/,/g, ''))
  else 
    return str.includes("$") ? parseFloat(str.slice(1).replace(/,/g, '')) : parseFloat(str.replace(/,/g, ''))
}

  /**
   * Date must be of the form YYYY-MM-DD
   * @param {string} date - Date string
   * @param {string} format - Format of the date string
   * @returns 
   */
   const changeDateFormat = (date, format = 'YYYY-MM-DD') => {

    switch (format) {
      case 'YYYY-MM-DD':
        const [year, month, day] = date.substring(0, 10).split("-");
        return `${month}/${day}/${year}`;
    
      default:
        break;
    }

  }

  /**
   * @param {string} data - Data array
   * @param {string} format - Format of the line bar chart
   * @returns 
   */
   const lineChartParse = (chartData, format) => {
    let result = {
      lineData: [],
      line2Data: [],
      barData: [],
      labels: [],
    };
    chartData.forEach(data => {
      result.labels.push(data[format.label]);
      result.lineData.push(data[format.line]);
      result.line2Data.push(data[format.line2]);
      result.barData.push(data[format.bar]);
    })
    return result;
  }

  export const dateTimeDiff = (startDate, endDate = new Date()) => {
    startDate = new Date(startDate);
    endDate = new Date(endDate);
    const seconds = Math.floor(((endDate) - startDate) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const years = Math.floor(days / 365);
    return {
      seconds,
      minutes,
      hours,
      days,
      years
    }
  }

  function getWeeksTillDate(year = new Date().getFullYear()) {
    const weeks = [];
    let currentDay = moment([year, 1]).startOf("year");
    const dayOfWeek = moment(currentDay).day() + 1; // For sunday as start of the week
    const lastDay = moment([year, 1]).endOf("year");
    const weeksInYear = moment(`${year}-01-01`).isoWeeksInYear();
    let daysToAdd = 7 - dayOfWeek;

    for (let weekNumber = 1; weekNumber < weeksInYear + 1; weekNumber++) {
      let endOfWeek = moment(currentDay).add(daysToAdd, "days");
      if (moment(endOfWeek).year() !== year) {
        endOfWeek = lastDay;
      }

      if (endOfWeek > new Date()) {
        break;
      } else 
      if (dateTimeDiff(currentDay, endOfWeek).days === 6) {
        weeks.push({ startdate: currentDay.format("MM/DD/YYYY"), enddate: endOfWeek.format("MM/DD/YYYY") });
      }
      currentDay = endOfWeek.add(1, "day");
      daysToAdd = 6;
    }
    return weeks;
  }

  const arrayFromString = (str) => {
    const arr =str?  str.split(","):[];
    return arr;
  }

  function splitString(str) {
    if(str) {
      const strList = str.split(/[\s,]+/);
      const filteredList = strList.filter((item) => item !== "");
      return filteredList;
    }
    return [];
  }

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  }

  function getMonthDatesBetween(startDate, endDate) {
    const dates = [];
  
    const startYear = startDate.getFullYear();
    const startMonth = startDate.getMonth();
  
    const endYear = endDate.getFullYear();
    const endMonth = endDate.getMonth();
  
    for (let year = startYear; year <= endYear; year++) {
      for (let month = 0; month < 12; month++) {
        if ((startYear === endYear && month >= startMonth && month <= endMonth) || (startYear !== endYear && year === startYear && month >= startMonth) || (startYear !== endYear && year === endYear && month <= endMonth) || (year > startYear && year < endYear)) {
          dates.push(getMonthDates(year, month));
        }
      }
    }
  
    return dates;
  }

  function getMonthDates(year, month) {
    const date = new Date(year, month, 1);
  
    const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  
    const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  
    return {
      startdate: moment(startDate).format("MM/DD/YYYY"),
      enddate: moment(endDate).format("MM/DD/YYYY")
    };
  }

  function getWeekDatesBetween(startDate, endDate) {
    const dates = [];
  
    startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() - startDate.getDay());
  
    endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + (6 - endDate.getDay()));
  
    while (startDate <= endDate) {
      const weekStart = startDate;
      const weekEnd = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6);
  
      dates.push({ startdate: moment(weekStart).format("MM/DD/YYYY"), enddate:moment(weekEnd).format("MM/DD/YYYY") });
  
      startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 7);
    }
  
    return dates;
  }

  function getQuarterDatesBetween(startDate, endDate) {
    const dates = [];
  
    startDate = new Date(startDate.getFullYear(), Math.floor(startDate.getMonth() / 3) * 3, 1);
  
    endDate = new Date(endDate.getFullYear(), Math.floor(endDate.getMonth() / 3) * 3 + 2, 31);
  
    while (startDate <= endDate) {
      const quarterStart = startDate;
      const quarterEnd = new Date(startDate.getFullYear(), startDate.getMonth() + 2, 31);
  
      dates.push({ startdate: moment(quarterStart).format("MM/DD/YYYY"), enddate: moment(quarterEnd).format("MM/DD/YYYY") });
  
      startDate = new Date(startDate.getFullYear(), startDate.getMonth() + 3, 1);
    }
  
    return dates;
  }

  function getYearDatesBetween(startDate, endDate) {
    const dates = [];
  
    startDate = new Date(startDate.getFullYear(), 0, 1);
  
    endDate = new Date(endDate.getFullYear(), 11, 31);
  
    while (startDate <= endDate) {
      const yearStart = startDate;
      const yearEnd = new Date(startDate.getFullYear(), 11, 31);
  
      dates.push({ startdate: moment(yearStart).format("MM/DD/YYYY"), enddate: moment(yearEnd).format("MM/DD/YYYY") });
  
      startDate = new Date(startDate.getFullYear() + 1, 0, 1);
    }
  
    return dates;
  }

const calcACOS = (value) => value ? `${(parseFloat(value) * 100).toFixed(2)}%` : "0%";
 
const getDifferenceInDays = (startDate, endDate = new Date()) => {
  const providedDate = new Date(startDate);
  const currentDate = new Date();
  const timeDifferenceInMilliseconds = (providedDate-currentDate);
  const timeDifferenceInDays = Math.round(timeDifferenceInMilliseconds / (1000 * 60));
  return timeDifferenceInDays
}

const checkIfImageExists = async (url) => {
  const img = new Image();
  img.src = url;
  img.onload = () => { return url };
  img.onerror = () => { return false };
};

const renderFavicon = (asin, vendorCode) => {
  let url = `https://sd-catalog-images.s3.amazonaws.com/${asin}.jpg`;
  // if((await checkIfImageExists(url))) {
  // } else {
  //   url = `https://sd-catalog-images.s3.amazonaws.com/${vendorCode}_${asin}.jpg`;
  // }
  return `<img style="height: 40px; width: 40px" src="${url}">`
}

const findMarketplace = (vendorCode) => {
  if (!vendorCode) {
    return;
  }
  const countryCode = vendorCode.split("_")[1];
  const marketplace = marketplaces.find(marketplace => marketplace.countryCode === countryCode);
  return marketplace.id;
}


export {
  toProperCase,
  convertNumberToShorthand,
  numberWithCommas,
  sortByDate,
  encodeForProxy,
  encodeForCacheWithoutDates,
  removeDollarAndCommaFromAmount,
  changeDateFormat,
  lineChartParse,
  getWeeksTillDate,
  camelToProperCase,
  ascendingSort,
  encodeForProxyCustom,
  encodeForProxyCustomAll,
  sortObjectByKeys,
  arrayFromString,
  onlyUnique,
  getMonthDatesBetween,
  getWeekDatesBetween,
  getQuarterDatesBetween,
  getYearDatesBetween,
  calcACOS,
  splitString,
  getDifferenceInDays,
  checkIfImageExists,
  renderFavicon,
  findMarketplace
};
