/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  InputLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress
} from "@material-ui/core";
import "date-fns";
import moment from "moment";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context

//helpers
import useStyles from "./styles";
import BCGMatrixData from "../../data/bcgMatrix.json"
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";
import { getMonthDatesBetween, getQuarterDatesBetween, getWeekDatesBetween, getYearDatesBetween } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { useVendorState } from "../../context/VendorContext";
import axios from 'axios';
import { tokenConfig } from "../../context/UserContext";
import _ from "lodash";


export default function BCGMatrix() {
  const exportFunctionRef = useRef();
  //global
  var vendorState = useVendorState();
  const classes = useStyles();
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [radioValue, setRadioValue] = useState("monthly");
  const [dateRange, setDateRange] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(1);
  const [weekRange, setWeekRange] = useState(null);
  const [monthRange, setMonthRange] = useState(null);
  const [quarterRange, setQuarterRange] = useState(null);
  const [annualRange, setAnnualRange] = useState(null);
  const [range, setRange] = useState({
    startDate: new Date(moment("01-01-2023", "DD-MM-YYYY")),
    endDate: new Date(),
  });
  const [tableData, setTableData] = useState([])
  const [tableKeys, setTableKeys] = useState({
    currentYear: moment().year(),
    previousYear: moment().subtract(1,'year').year(),
    lastPreviousYear: moment().subtract(2,'year').year()
  })
  const [bcgData, setBcgData] = useState({
    [tableKeys.currentYear]:[],
    [tableKeys.previousYear]:[],
    [tableKeys.lastPreviousYear]:[]
  })

  useEffect(() => {
    if (range) {
      const weekDateRange = cloneAndSortDescending([...getWeekDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const monthDateRange = cloneAndSortDescending([...getMonthDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const quarterDateRange = cloneAndSortDescending([...getQuarterDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const annualDateRange = cloneAndSortDescending([...getYearDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      setDateRange(monthDateRange);
      if (monthDateRange.length !== 0) setSelectedDateRange(1);

      setWeekRange(weekDateRange);
      setMonthRange(monthDateRange);
      setQuarterRange(quarterDateRange);
      setAnnualRange(annualDateRange);
    }
    return () => {
      setDateRange(null);
      setWeekRange([]);
      setMonthRange([]);
      setQuarterRange([]);
      setAnnualRange([]);
    };
  }, [range]);

  useEffect(() => {
    if(vendorState.selected && !fullPageLoader) {
      getBcgData()
    }
  }, [vendorState.selected, selectedDateRange, dateRange])

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    if (event.target.value === "weekly") {
      setDateRange(weekRange);
      setSelectedDateRange(1);
    } else if (event.target.value === "monthly") {
      setDateRange(monthRange);
      setSelectedDateRange(1);
    } else if (event.target.value === "quarterly") {
      setDateRange(quarterRange);
      setSelectedDateRange(1);
    } else {
      setDateRange(annualRange);
      setSelectedDateRange(0);
    }
  };


  const getBcgData = async () => {
    try {
      setFullPageLoader(true)
      const params = {
        vendorCode: vendorState?.selected,
        startDate: dateRange[selectedDateRange].startdate,
        endDate: dateRange[selectedDateRange].enddate
      }
      const result = await axios.get(BASE_URL + api.getBcgMatrixData, tokenConfig(params))
      let reports = []
      let data = {}
      Object.values(result.data.data).map((item) => reports = [...reports,...item])
      reports.map((item)=> {
        data[item.asin] = {...data[item.asin],...item}
      })
      // console.log("data",Object.values(data))
      // console.log("reports",_.sortBy(reports, ['asin']))
      // setTableData(_.sortBy(reports, ['asin']))
      const yearsArray = Object.keys(result.data.data);
      const currentYear = parseInt(yearsArray[2]);
      const previousYear = parseInt(yearsArray[1]);
      const lastPreviousYear = parseInt(yearsArray[0]);
      const yearObject = {
        currentYear,
        previousYear,
        lastPreviousYear,
      };
      setTableData(Object.values(data))
      setTableKeys(yearObject)
      setBcgData(result.data.data)
      setFullPageLoader(false)
    } catch (error) {
      setFullPageLoader(false)
      console.log("error")
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="BCG Matrix"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                className={classes.link}
              >
                <EqualizerIcon className={classes.icon} />
                Business Intelligence
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                BCG Matrix
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
          <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "nowrap" }}>
            <FormControl>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="weekly"
                  control={<Radio color="primary" />}
                  label="Weekly"
                />
                <FormControlLabel
                  value="monthly"
                  control={<Radio color="primary" />}
                  label="Monthly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl >
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="quarterly"
                  control={<Radio color="primary" />}
                  label="Quarterly"
                />
                <FormControlLabel
                  value="yearly"
                  control={<Radio color="primary" />}
                  label="Yearly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Range
              </InputLabel>
              <Select
                value={selectedDateRange}
                onChange={(e) => {
                  setSelectedDateRange(e.target.value);
                }}
                label="Select Range"
                disabled={dateRange && dateRange.length === 0 ? true : false}
              >
                {dateRange ? (
                  dateRange.map((range, index) => {
                    return (
                      <MenuItem value={index}>
                        {range.startdate + " - " + range.enddate}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Dates</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
        }
        />
        {!fullPageLoader && <Grid container spacing={4} className="bcgMatrixGrid">
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableKeys={tableKeys} bcgData={bcgData} tableData={tableData} vendorCode={vendorState?.selected} />
            </Widget>
          </Grid>
        </Grid>}
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
