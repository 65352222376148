import React, { useState, useRef, useEffect } from "react";
import {
  Breadcrumbs,
  Select,
  FormControl,
  MenuItem,
  Container,
  CircularProgress,
  Backdrop,
  InputLabel,
  Link,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
  Button,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";


//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table";
import Widget from "../../components/Widget/Widget";

//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";
import { getMonthDatesBetween, getQuarterDatesBetween, getWeekDatesBetween, getYearDatesBetween } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import _ from "lodash";

export default function PerformingCampaigns(props) {
  var classes = useStyles();
  const topPerformingExportRef = useRef();
  const lowPerformingExportRef = useRef();
  const exportFunctionRef = useRef();


  //global
  var vendorState = useVendorState();
  // local
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [campaignTypes] = useState(["SP", "SB", "SD"]);
  const [radioValue, setRadioValue] = useState("monthly");
  const [dateRange, setDateRange] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(1);
  const [weekRange, setWeekRange] = useState(null);
  const [monthRange, setMonthRange] = useState(null);
  const [quarterRange, setQuarterRange] = useState(null);
  const [annualRange, setAnnualRange] = useState(null);
  const [range, setRange] = useState({
    startDate: new Date(moment("01-01-2023", "DD-MM-YYYY")),
    endDate: new Date(),
  });
  const [campaignData, setCampaignData] = useState(null);
  const [selectedCampaignType, setSelectedCampaignType] = useState("SP");
  const [selectedCampaignState, setSelectedCampaignState] = useState("ENABLED");
  const [topPerformingCampaignData, setTopPerformingCampaignData] = useState(null)
  const [lowPerformingCampaignData, setLowPerformingCampaignData] = useState(null)

  useEffect(() => {
    if (range) {
      const weekDateRange = cloneAndSortDescending([...getWeekDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const monthDateRange = cloneAndSortDescending([...getMonthDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const quarterDateRange = cloneAndSortDescending([...getQuarterDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const annualDateRange = cloneAndSortDescending([...getYearDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      setDateRange(monthDateRange);
      if (monthDateRange.length !== 0) setSelectedDateRange(1);

      setWeekRange(weekDateRange);
      setMonthRange(monthDateRange);
      setQuarterRange(quarterDateRange);
      setAnnualRange(annualDateRange);
    }
    return () => {
      setDateRange(null);
      setWeekRange([]);
      setMonthRange([]);
      setQuarterRange([]);
      setAnnualRange([]);
    };
  }, [range]);

  useEffect(() => {
    if(vendorState.selected && !fullPageLoader) {
      loadData()
    }
  }, [selectedCampaignType, selectedCampaignState, vendorState.selected, selectedDateRange, dateRange])

  async function loadData() {
    try {
      setFullPageLoader(true);
      let params = {
        vendorCode: vendorState?.selected,
        campaignType: selectedCampaignType.toLowerCase(),
        campaignState: selectedCampaignState,
        startDate: moment(dateRange[selectedDateRange].startdate).format("MM-DD-YYYY"),
        endDate: moment(dateRange[selectedDateRange].enddate).format("MM-DD-YYYY"),
      };
      const result = await axios.get(BASE_URL + api.getAMSCampaignReports, tokenConfig(params))
      result.data.map((item) => {
        if (!item.report) {
          item.report = {}
          item.report["ACOS"] = "0"
        }
      })
      handlePerformingData(result.data)
      setCampaignData(result.data.reverse())
    } catch (error) {
      console.log(error);
    } finally {
      setFullPageLoader(false);
    }
  }

  const handlePerformingData = (data) => {
    data = data.filter((item) => item["Spend(USD)"] > 0);
    // const highPerforming = data.filter((item) => item["ACOS"] > 0.3);
    setTopPerformingCampaignData(_.orderBy(data, ['Sales(USD)'], ['desc']).slice(0, 5))

    const lowPerforming = _.orderBy(data, ['ACOS'], ['desc']).slice(0, 5)
    setLowPerformingCampaignData(_.orderBy(lowPerforming, ['ACOS'], ['desc']))
  }

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    if (event.target.value === "weekly") {
      setDateRange(weekRange);
      setSelectedDateRange(1);
    } else if (event.target.value === "monthly") {
      setDateRange(monthRange);
      setSelectedDateRange(1);
    } else if (event.target.value === "quarterly") {
      setDateRange(quarterRange);
      setSelectedDateRange(1);
    } else {
      setDateRange(annualRange);
      setSelectedDateRange(0);
    }
  };

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Campaigns Performance"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              AMS Reports
            </Link>
            <Typography className={classes.link} color="primary">
              Campaigns Performance
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <Grid container style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", flexWrap: "nowrap" }}>
            <FormControl>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="weekly"
                  control={<Radio color="primary" />}
                  label="Weekly"
                />
                <FormControlLabel
                  value="monthly"
                  control={<Radio color="primary" />}
                  label="Monthly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl >
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="quarterly"
                  control={<Radio color="primary" />}
                  label="Quarterly"
                />
                <FormControlLabel
                  value="yearly"
                  control={<Radio color="primary" />}
                  label="Yearly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Range
              </InputLabel>
              <Select
                value={selectedDateRange}
                onChange={(e) => {
                  setSelectedDateRange(e.target.value);
                }}
                label="Select Range"
                disabled={dateRange && dateRange.length === 0 ? true : false}
              >
                {dateRange ? (
                  dateRange.map((range, index) => {
                    return (
                      <MenuItem value={index}>
                        {range.startdate + " - " + range.enddate}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Dates</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Campaign Type</InputLabel>
              <Select
                defaultValue={selectedCampaignType}
                value={selectedCampaignType}
                onChange={(e) => setSelectedCampaignType(e.target.value)}
                label="Campaign Type"
                disabled={campaignTypes && campaignTypes.length === 0 ? true : false}
                className={classes.select}
              >
                {campaignTypes ? (
                  campaignTypes.map((type) => {
                    return <MenuItem value={type}>{type}</MenuItem>;
                  })
                ) : null }
              </Select>
            </FormControl>
          </Grid>
        }
      />
      <Grid container style={{ marginBottom: 30 }} className="campaign_performance_grid">
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  Top Performing Campaigns
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => topPerformingExportRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              </div>
            }
          >
            <Table ref={topPerformingExportRef} tableData={topPerformingCampaignData} height={350} />
          </Widget>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: 30 }} className="campaign_performance_grid">
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  Low Performing Campaigns
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => lowPerformingExportRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              </div>
            }
          >
            <Table ref={lowPerformingExportRef} tableData={lowPerformingCampaignData} height={350} />
          </Widget>
        </Grid>
      </Grid>

      <Grid container className="campaign_performance_grid" >
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  Campaigns
                </Typography>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              </div>
            }
          >
            <Table ref={exportFunctionRef} tableData={campaignData} height={600} />
          </Widget>
        </Grid>
      </Grid>

      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        onClick={() => setFullPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
