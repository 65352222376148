import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  TextField,
  Box,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";

//icons
import PrintIcon from "@material-ui/icons/Print";
import SearchIcon from "@material-ui/icons/Search";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [searchValue, setSearchValue] = useState();

  const fetchContacts = () => {
    setfullPageLoader(true);
    axios
      .get(BASE_URL + api.searchContactsFromApolloApi, {
        params: { q: searchValue },
      })
      .then((res) => {
        setmainTableData(res.data?.data);
        setfullPageLoader(false);
      })
      .catch((err) => {
        console.log(err);
        seterr(err.response?.data?.msg ?? "Something went wrong");
        setTimeout(() => seterr(null), 3000);
        setfullPageLoader(false);
      });
  };

  useEffect(() => {
    if (userState.userData && searchValue) {
      fetchContacts();
    }
  }, [userState.userData]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Search Contacts"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Brand Finder
              </Link>
              <Typography className={classes.link} color="primary">
                Search Contacts
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <form
                className={classes.root}
                noValidate
                autoComplete="off"
                style={{ minWidth: 700 }}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <TextField
                    id="outlined-search"
                    label="Search"
                    variant="outlined"
                    fullWidth
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    size="small"
                    style={{ width: "50%", marginRight: 50 }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<SearchIcon />}
                    size="small"
                    disabled={!searchValue}
                    onClick={fetchContacts}
                  >
                    SEARCH
                  </Button>
                </Box>
              </form>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
