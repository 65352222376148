import {
  Backdrop,
  Box,
  Breadcrumbs,
  CircularProgress,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
// styles
import useStyles from "./styles";
import api from "../../healpers/apiRoutes";
// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";

//context
import { useVendorState } from "../../context/VendorContext";
import moment from "moment";
import { BASE_URL } from "../../healpers/api";
import axios from "axios";
import { tokenConfig } from "../../context/UserContext";
import CategoriesForm from "./components/CategoriesForm/CategoriesForm";
import CampaignReview from "./components/CampaignReview/CampaignReview";
import CampaignForm from "./components/CampaignForm/CampaignForm";
import { getVendorSettings } from "../../http/api";
import _ from "lodash";

//hooks
import useQuery from "../../hooks/useQuery"
import { arrayFromString, onlyUnique } from "../../healpers/utilityFunctions";

const INITIAL_FORM_STATE = {
  advertisingGoal: "Increase Awareness",
  target: 0,
  campaignType: "sponsoredProducts",
  targetingType: "AUTO",
  accountType: "Seller",
  manualTargetingType: "",
  state: "enabled",
  dailyBudget: 0,
  scheduledCampaignDays: "14",
  budget: 5,
  startDate: moment(),
  endDate: null,
  type: "AUTO_AND_SCHEDULED_MANUAL",
  budgetType: "daily",
  premiumBidAdjustment: false,
  topOfSearch: 0,
  productPage: 0,
  keywords: [],
  productASINs: [],
  negativeKeywordType: "Negative exact",
  negativeKeywordsExact: [],
  negativeKeywordsPhrase: [],
  negativeProducts: [],
  vendorDefaultKeywords: [],
  vendorDefaultProductTargetingAsins: [],
  vendorDefaultNegativeKeywords: [],
  vendorDefaultNegativeProductTargetingAsins: [],
  defaultSelectedProducts: [],
  productType: "asin"
};


export default function AMSCreateCmapaign(props) {
  const classes = useStyles();
  const query = useQuery();
  const campaignId = query.get("campaignId");

  const [fullPageLoader, setfullPageLoader] = useState(false);

  const steps = ["Categories", "Campaign", "Preview Details"];

  var vendorState = useVendorState();

  const [activeStep, setActiveStep] = useState(0);
  const [apiResponse, setApiResponse] = useState({});

  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedAllProducts, setSelectedAllProducts] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const [campaignData, setCampaignData] = useState(INITIAL_FORM_STATE);

  const [skipped, setSkipped] = useState(new Set());

  const getCampaign = async() => {
    try{
      if (campaignId) {
        setfullPageLoader(true);
        const { data: { data: campaign } } = await axios.get(
          BASE_URL + api.getCampaign,
          tokenConfig({
            campaignId
          })
        );
        setSelectedSubCategories(campaign.subCategories);
        setCampaignData({
          ...INITIAL_FORM_STATE,
          type: campaign.campaignType,
          targetingType: campaign.campaignType === "AUTO_AND_SCHEDULED_MANUAL" ? "AUTO" : campaign.campaignType,
          manualTargetingType: campaign.manualTargetingType,
          budget: campaign.budget,
          target: campaign.advertisingTarget,
          advertisingGoal: campaign.advertisingGoal,
          accountType: vendorState?.accounttype,
          budgetType: campaign.budgetType,
          scheduledCampaignDays: campaign.scheduledCampaignDays,
          productPage: campaign?.biddingadjustments[0]?.percentage,
          topOfSearch: campaign?.biddingadjustments[1]?.percentage,
          subcategories: campaign.subCategories,
          defaultSelectedProducts: campaign.productAsins,
          productTargetingAsins: campaign.productTargets.map(target => target?.expression[0]?.value),
          negativeProducts: campaign.negativeProductTargets.map(target => target?.expression[0]?.value),
          keywords: campaign.keywords.map(keyword => keyword.keywordText).filter(onlyUnique),
          negativeKeywordsExact: campaign.negativeKeywords.filter(keyword => keyword.matchType === "NEGATIVE_EXACT").map(keyword => keyword.keywordText).filter(onlyUnique),
          negativeKeywordsPhrase: campaign.negativeKeywords.filter(keyword => keyword.matchType === "NEGATIVE_PHRASE").map(keyword => keyword.keywordText).filter(onlyUnique),
        })
        setfullPageLoader(false);
      }
    }catch(error){
      console.log(error)
      setfullPageLoader(false);
    }
  }


  useEffect(() => {
    async function loadData() {
      try {
        const vendorCode = vendorState.selected;
        if (vendorCode) {
          await fetchVendorSettings(vendorCode);
        }
      } catch (error) {
        console.log(error);
      }
    }
    loadData();
    getCampaign()
  }, [vendorState.selected]);

  useEffect(() => {
    let keywords = [];
    let productTargetingAsins = [];
    let negativeKeywordsExact = [];
    let negativeKeywordsPhrase = [];
    let negativeProducts = [];
    selectedSubCategories.forEach((subcategory) => {
      keywords = [
        ...keywords,
        ...(campaignData.vendorDefaultKeywords?.[subcategory]
          ? campaignData.vendorDefaultKeywords?.[subcategory]
          : []),
      ];
      productTargetingAsins = [
        ...productTargetingAsins,
        ...(campaignData.vendorDefaultProductTargetingAsins?.[subcategory]
          ? campaignData.vendorDefaultProductTargetingAsins?.[subcategory]
          : []),
      ];

      negativeKeywordsExact = [
        ...negativeKeywordsExact,
        ...(campaignData.vendorDefaultNegativeKeywords?.exacts?.[subcategory]
          ? campaignData.vendorDefaultNegativeKeywords?.exacts?.[subcategory]
          : []),
      ];
      negativeKeywordsPhrase = [
        ...negativeKeywordsPhrase,
        ...(campaignData.vendorDefaultNegativeKeywords?.phrases?.[subcategory]
          ? campaignData.vendorDefaultNegativeKeywords?.phrases?.[subcategory]
          : []),
      ];
      negativeProducts = [
        ...negativeProducts,
        ...(campaignData.vendorDefaultNegativeProductTargetingAsins?.[
          subcategory
        ]
          ? campaignData.vendorDefaultNegativeProductTargetingAsins?.[
              subcategory
            ]
          : []),
      ];
    });
    if(!campaignId) {
      setCampaignData((prev) => ({
        ...prev,
        keywords,
        productTargetingAsins,
        negativeProducts,
        negativeKeywordsExact,
        negativeKeywordsPhrase,
      }));
    }
  }, [selectedSubCategories]);

  const fetchVendorSettings = async (vendorCode) => {
    let { data } = await getVendorSettings(vendorCode);
    if(!_.isEmpty(data)) {
      setCampaignData((prevState) => ({
        ...prevState,
        vendorDefaultKeywords: data.keywords,
        vendorDefaultProductTargetingAsins: data.productTargetingAsins,
        vendorDefaultNegativeKeywords: data.negativeKeywords,
        vendorDefaultNegativeProductTargetingAsins:
          data.negativeProductTargetingAsins,
      }));
    }
  };

  const isStepOptional = (step) => {
    return null;
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (
      ["target", "dailyBudget", "topOfSearch", "productPage"].includes(name)
    ) {
      value = parseInt(value.replace(/[^\d]/, ""), 10) || null;
    }
    setCampaignData((prevState) => ({
      ...prevState,
      [name]: [
        "keywords",
        "productTargetingAsins",
        "negativeKeywordsPhrase",
        "negativeKeywordsExact",
        "negativeProducts",
      ].includes(name)
        ? formatStringtoArray(value)
        : value,
    }));
    if (name === "budgetType") calculateBudgetChanges();
  };

  const handleSetCampaignData = (campaignValue) => {
    setCampaignData((prevState) => ({
      ...prevState,
      ...campaignValue,
    }));
  };

  const formatStringtoArray = (string) => {
    const arr = string ? string.replace(/\n/g, ",").split(",") : [];
    const filtered = arr.filter((item, index) => {
      if (!!item || index === arr.length - 1) {
        return true;
      }
      return false;
    });
    return filtered;
  };

  const handleType = (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      switch (value) {
        case "AUTO":
          setCampaignData((prevState) => ({
            ...prevState,
            targetingType: value,
            manualTargetingType: "Keyword Targeting",
          }));
          break;
        case "MANUAL":
          setCampaignData((prevState) => ({
            ...prevState,
            targetingType: value,
            manualTargetingType: "Keyword Targeting",
          }));
          break;

        default:
          setCampaignData((prevState) => ({
            ...prevState,
            targetingType: "AUTO",
            manualTargetingType: "",
          }));
          break;
      }
    }
    setCampaignData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const calculateBudgetChanges = () => {
    if (campaignData.budgetType === "monthly") {
      let dailyBudget = campaignData.budget ? campaignData.budget / 30 : 0;
      setCampaignData((prevState) => ({
        ...prevState,
        dailyBudget: parseFloat(dailyBudget),
      }));
    } else {
      setCampaignData((prevState) => ({
        ...prevState,
        dailyBudget: campaignData.budget ? parseFloat(campaignData.budget) : 0,
      }));
    }
  };

  const handleBudgetChange = (e) => {
    const { name, value } = e.target;
    setCampaignData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    calculateBudgetChanges();
  };

  const validateForm = (step) => {
    console.log({ first: campaignData.budget });
    if (step === 0) {
      if (campaignData.advertisingGoal === "") {
        alert("Please select advertising goal");
        return false;
      } else if (isNotPositiveNumber(campaignData.target)) {
        alert("Please enter valid target percentage (%)");
        return false;
      } else if (selectedProducts.length === 0) {
        alert("Please select product ASINs");
        return false;
      } else {
        return true;
      }
    } else if (step === 1) {
      if (!campaignData.startDate) {
        alert("Please fill all details");
        return false;
      } else if (Number(campaignData.budget) < 1) {
        alert("Please enter valid daily budget");
        return false;
      } else if (
        campaignData.type === "MANUAL" &&
        campaignData.manualTargetingType === "Keyword Targeting" &&
        campaignData.keywords.length === 0
      ) {
        alert("Please enter targeting keywords");
        return false;
      } else if (
        campaignData.type === "MANUAL" &&
        campaignData.manualTargetingType === "Product Targeting" &&
        campaignData.productTargetingAsins.length === 0
      ) {
        alert("Please enter targeting product ASIN's");
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  const isNotPositiveNumber = (num) => {
    if (num) {
      return Number(num) < 0 ? true : false;
    }
    return true;
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (validateForm(activeStep)) {
      if (activeStep === 2) {
        const createCampaignPayload = {
          vendorCode: vendorState.selected,
          campaignType: campaignData.type,
          accountType: campaignData.accountType,
          targetType: campaignData.targetingType,
          manualTargetingType: campaignData.manualTargetingType,
          startDate: moment(campaignData.startDate).format("YYYY-MM-DD"),
          endDate: campaignData.endDate
            ? moment(campaignData.endDate).format("YYYY-MM-DD")
            : null,
          budgetType: campaignData.budgetType,
          budget: campaignData.budget,
          subCategories: selectedSubCategories,
          productASINs: selectedProducts.map((asin) => asin.asin),
          advertisingGoal: campaignData.advertisingGoal,
          advertisingTarget: parseInt(campaignData.target, 10),
          placementTop: campaignData.topOfSearch,
          placementProductPage: campaignData.productPage,
          keywords: campaignData.keywords,
          productTargetingASINs: campaignData.productTargetingAsins,
          negativeKeywords: {
            exact: campaignData.negativeKeywordsExact,
            phrase: campaignData.negativeKeywordsPhrase,
          },
          negativeProductASINs: campaignData.negativeProducts,
          scheduledCampaignDays: campaignData.scheduledCampaignDays,
        };
        createCampaign(createCampaignPayload);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        setSkipped(newSkipped);
      }
    } else {
      console.log("Else");
    }
  };

  const createCampaign = (campaignData) => {
    try {
      setfullPageLoader(true);
      let createCampaignURL = BASE_URL + api.amsCreateAutoCampaign;
      if (campaignData.campaignType === "MANUAL") {
        createCampaignURL = BASE_URL + api.amsCreateManualCampaign;
      }

      let postData = {
        campaign: campaignData,
        vendorCode: vendorState?.selected,
      };
      axios
        .post(createCampaignURL, postData, tokenConfig())
        .then((result) => {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setfullPageLoader(false);
          setApiResponse(result.data.data);
          alert("Ad Created Successfully. Showing Preview on Next Screen");
        })
        .catch((err) => {
          console.log(err);
          setfullPageLoader(false);
          alert("Failed to create camapign");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setCampaignData(INITIAL_FORM_STATE);
    setSelectedAllProducts(false);
    setSelectedSubCategories([]);
    setSelectedProducts([]);
    setActiveStep(0);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const handleProductSelectionChange = (e, product) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedProducts([...selectedProducts, product]);
    } else {
      setSelectedProducts((prev) => prev.filter((item) => item !== product));
    }
  };

  const handleProductsInputChange = (products) => {
    setSelectedProducts(arrayFromString(products));
  };

  const handleDefaultProductSelection = (e, product) => {
    const { checked } = e.target;
    setSelectedProducts(product);
    setSelectedAllProducts(checked);
  };

  const handleChangeAllProduct = (checked, products) => {
    if (checked) {
      setSelectedProducts(products);
      setSelectedAllProducts(checked);
    } else {
      setSelectedProducts([]);
      setSelectedAllProducts(checked);
    }
  };

  const handleSubCategoriesChange = async (event, values, i, t) => {
    setSelectedSubCategories(values);
    if (i === "remove-option") {
      setSelectedProducts((prev) => {
        let filtered = prev.filter(
          (product) => product.subcategoryName !== t.option
        );
        if (filtered.length === 0) {
          setSelectedAllProducts(false);
        }
        return filtered;
      });
    }
  };

  const handleRemoveSubCategory = async (subCategoryName) => {
    const filteredSubCategories = selectedSubCategories.filter(
      (item) => item !== subCategoryName
    );
    setSelectedSubCategories(filteredSubCategories);

    setSelectedProducts((prev) => {
      let filtered = prev.filter(
        (product) => product.subcategoryName !== subCategoryName
      );
      if (filtered.length === 0) {
        setSelectedAllProducts(false);
      }
      return filtered;
    });
  };

  const handleProductType = (e) => {
    setCampaignData((prevState) => ({
      ...prevState,
      productType: e.target.value,
    }));
    setSelectedProducts([]);
  }

  return (
    <>
      <Container>
        <PageTitle
          title="Create Campaigns"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                AMS
              </Link>
              <Typography className={classes.link} color="primary">
                Create Campaigns
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
              <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = { completed: null };
                    const labelProps = { optional: null };
                    if (isStepOptional(index)) {
                      labelProps.optional = (
                        <Typography variant="caption">Optional</Typography>
                      );
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>

                {activeStep === steps.length ? (
                  <React.Fragment>
                    <CampaignReview
                      title="AMS Response"
                      apiResponse={apiResponse}
                      campaignData={campaignData}
                      selectedProducts={selectedProducts}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        padding: 10,
                      }}
                    >
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleReset}
                      >
                        New Campaign
                      </Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {activeStep === 0 && (
                      <CategoriesForm
                        campaignData={campaignData}
                        onValuesChange={handleChange}
                        selectedSubCategories={selectedSubCategories}
                        onRemoveSubCategory={handleRemoveSubCategory}
                        onSubCategoriesChange={handleSubCategoriesChange}
                        selectedProducts={selectedProducts}
                        defaultSelectedProducts={campaignData.defaultSelectedProducts}
                        onDefaultProductSelection={handleDefaultProductSelection}
                        onProductSelectionChange={handleProductSelectionChange}
                        selectedAllProducts={selectedAllProducts}
                        onSelectAllProducts={handleChangeAllProduct}
                        handleProductsInputChange={handleProductsInputChange}
                        handleProductType={handleProductType}
                      />
                    )}
                    {activeStep === 1 && (
                      <CampaignForm
                        campaignData={campaignData}
                        onValuesChange={handleChange}
                        onTypeChange={handleType}
                        onBudgetChange={handleBudgetChange}
                        onSetCampaignData={handleSetCampaignData}
                      />
                    )}
                    {activeStep === 2 && (
                      <CampaignReview
                        campaignData={campaignData}
                        selectedProducts={selectedProducts}
                      />
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        pt: 2,
                        padding: 10,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />

                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? "Create Campaign"
                          : "Next"}
                      </Button>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
          onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
