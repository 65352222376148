/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import _ from "lodash";
import "date-fns";
import axios from "axios";

//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

import useStyles from "./styles";

export default function StrandedInventory(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [headerNames, setHeaderNames] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        setfullPageLoader(true);
        const response = await axios.get(
          BASE_URL + api.strandedInventory,
          tokenConfig({
            vendorCode: vendorState.selected,
          })
        );

        if (response?.data?.data) {
          const data = response.data.data;
          const filteredKeys = ["id", "createdAt", "vendorCode"];

          const headerNames = Object.keys(data[0])
            .filter((key) => filteredKeys.indexOf(key) < 0)
            .map((key) => {
              return {
                headerName: key
                  .replaceAll(/[A-Z]/g, (x) => ` ${x}`)
                  .replace(/^\w/g, (x) => x.toUpperCase()),
                field: key,
              };
            });
          setHeaderNames(headerNames);
          setmainTableData(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if (vendorState.selected) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
    };
  }, [vendorState.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Stranded Inventory"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                SP Reports
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Stranded Inventory
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              header={
                <>
                  <div className={classes.mainChartHeader}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<PrintIcon />}
                      onClick={() => exportFunctionRef.current.onBtnExport()}
                    >
                      Export
                    </Button>
                  </div>
                </>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                headerNames={headerNames}
                classes={classes}
              />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
