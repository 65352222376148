import React from "react";
import axios from "axios";

import { BASE_URL } from "../healpers/api";
import api from "../healpers/apiRoutes";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  AMAZON_AUTH_CREDS,
  ADD_ERROR,
  CLEAR_ERRORS,
  ADD_NOTIFICATION,
  CLEAR_NOTIFICATION,
  USER_LOADED,
  AUTH_ERROR,
} from "./types";

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case USER_LOADED:
      return{
          ...state,
          isAuthenticated:true,
          isLoading:false,
          userData:action.payload,
          token:localStorage.getItem("id_token")
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.data.token,
        userData: action.payload.data.user,
      };
    case AMAZON_AUTH_CREDS:
      return {
        ...state,
        amazon:{
          amazon_callback_uri: action.payload.amazon_callback_uri,
          amazon_state: action.payload.amazon_state,
          selling_partner_id: action.payload.selling_partner_id,
          state: action.payload.state,
        }
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
      localStorage.removeItem("id_token")
      return { ...state, isAuthenticated: false, token: null, userData: null ,amazon: null};
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
    token: null,
    userData: null,
    isLoading: false,
    amazon: {
      amazon_callback_uri: null,
      amazon_state: null,
      selling_partner_id: null,
      state: null,
    }
  });

  return (
    <UserStateContext.Provider value={state} displayName="User Context">
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  loadUser,
  setAmazonCredentials,
  updateCurrentIntegration,
  forgotPassword,
  resetPassword
};

// ###########################################################

function setAmazonCredentials(dispatch, amazon_callback_uri, amazon_state, selling_partner_id, state) {
  dispatch({ type: AMAZON_AUTH_CREDS, payload: { amazon_callback_uri, amazon_state, selling_partner_id, state} });
}

function loginUser(
  dispatch,
  userState,
  popupDispatch,
  login,
  password,
  history,
  setIsLoading
) {
  setIsLoading(true);

  const checkInitialRoute = (userData) => {
    if(!userData.sellerCentralIntegration){
      history.push('/app/integration');
    } else if(userData.permissions.sales){
      //history.push('/app/sales/shippedCOGS');
      history.push('/app/sales/sellerCentral');
    } else if(userData.permissions.shortageclaimsAndChargebacks){
      history.push('/app/sc_cb/claims');
    } else if(userData.permissions.businessIntelligence) {
      history.push('/app/bi/voice');
    } else if(userData.permissions.advertising) {
      history.push('/app/adv/cp');
    } else if(userData.permissions.operations) {
      history.push('/app/operations/avaorbuy');
    } else if(userData.permissions.creative) {
      history.push('/app/creative/dp');
    } else if(userData.permissions.users) {
      history.push('/app/users/register');
    }
  }

  axios({
    method: "post",
    url: BASE_URL + api.loginUser,
    headers: { "Access-Control-Allow-Origin": "*" },
    data: {
      email: login,
      password: password,
    },
  })
    .then((res) => {
      if (res.data.token) {
        localStorage.setItem("id_token", res.data.token);
        dispatch({ type: LOGIN_SUCCESS, payload: { data: res.data } });
        popupDispatch({ type: ADD_NOTIFICATION, payload: res.data.msg });
        checkInitialRoute(res.data.user)        
        setTimeout(() => {
          popupDispatch({ type: CLEAR_NOTIFICATION });
        }, 2000);
      } else {
        dispatch({ type: LOGIN_FAIL });
        setIsLoading(false);
        popupDispatch({ type: ADD_ERROR, payload: res.data.msg });
        setTimeout(() => {
          popupDispatch({ type: CLEAR_ERRORS });
        }, 2000);
      }
    })
    .catch((err) => {
      dispatch({ type: LOGIN_FAIL });
      setIsLoading(false);
      console.log(err);
    });
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  dispatch({ type: LOGOUT_SUCCESS });
  history.push("/login");
}

function loadUser(dispatch) {
  axios
    .get(`${BASE_URL}/api/user/fromToken`, tokenConfig())
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: AUTH_ERROR,
      });
    });
}

export const tokenConfig = (params) => {
  const token = localStorage.getItem("id_token");
  const config = {
    headers: {
      "Content-type": "application/json",
    },
    params
  };
  if (token) {
    config.headers["auth-token"] = token;
  }
  return config;
};

async function updateCurrentIntegration(data, popupDispatch, setIsLoading) {
  try {
    setIsLoading(true)
    const response = await axios.post(`${BASE_URL}/api/user/updateCurrentIntegration`, data);
    setIsLoading(false)
  } catch (error) {
    setIsLoading(false)
    popupDispatch({ type: ADD_ERROR, payload: "Failed to Integrate"});
    setTimeout(() => popupDispatch({ type: CLEAR_NOTIFICATION }),2000)
  }
}
async function forgotPassword(data, popupDispatch, history, setIsLoading) {
  try {
    setIsLoading(true)
    const response = await axios({
      method: "post",
      url: BASE_URL + api.forgotPassword,
      headers: { "Access-Control-Allow-Origin": "*" },
      data,
    })
    popupDispatch({ type: ADD_NOTIFICATION, payload: response.data.msg });
    setTimeout(() => {
      popupDispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
    setIsLoading(false)
  } catch (error) {
    setIsLoading(false)
  
  }
}
async function resetPassword(data, popupDispatch, history, setIsLoading) {
  try {
    setIsLoading(true)
    if(data.passwordValue !== data.confirmPasswordValue) {
      popupDispatch({ type: ADD_ERROR, payload: "Passwords do not match"});
      setTimeout(() => {
        popupDispatch({ type: CLEAR_NOTIFICATION });
      }, 2000);
    } else {   
      const response = await axios({
        method: "post",
      url: BASE_URL + api.resetPassword,
      headers: { "Access-Control-Allow-Origin": "*" },
      data: {password: data.passwordValue, resetPasswordHash: data.resetPasswordHash},
    })
    popupDispatch({ type: ADD_NOTIFICATION, payload: response.data.msg });
    setTimeout(() => {
      popupDispatch({ type: CLEAR_NOTIFICATION });
    }, 2000);
    history.push("/login");
  }
    setIsLoading(false)
  } catch (error) {
    console.log(error)
    setIsLoading(false)
  }
}
