/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context

//helpers
import useStyles from "./styles";
import api from "../../healpers/apiRoutes";
import axios from "axios";
import { BASE_URL } from "../../healpers/api";
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

export default function SegmentForecastHealth() {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  const [forecastData, setForecastData] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);

  useEffect(() => {
    if (vendorState.selected && !fullPageLoader) {
      getSegmentForecastHealth();
    }
  }, [vendorState.selected]);

  const getSegmentForecastHealth = async () => {
    try {
      setFullPageLoader(true);
      const params = {
        vendorCode: vendorState?.selected,
        date: new Date("2022-10-01")
      };
      const result = await axios.get(
        BASE_URL + api.getSegmentForecastHealth,
        tokenConfig(params)
      );
      setForecastData(result.data.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      setFullPageLoader(false);
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Segment Forecast Health"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                <EqualizerIcon className={classes.icon} />
                Business Intelligence
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Segment Forecast Health
              </Typography>
            </Breadcrumbs>
          }
        />
        {!fullPageLoader && <Grid container spacing={4} className="js_product_grid">
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                    className={classes.mainChartHeaderButton}
                  >
                    Export
                  </Button>
                </div>
                
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={forecastData}
                vendorCode={vendorState?.selected}
              />
            </Widget>
          </Grid>
        </Grid>}
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
