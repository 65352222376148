import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  selectAllLabel: {
    fontFamily: "Helvetica",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "16px",
    cursor: "pointer",
    color:'black'

  },
  cardFont: {
    fontFamily: "Helvetica",
    fontSize: "16px",
    lineHeight: "24px",
    cursor: "pointer",
    color:'black'

  },
  boxWrapper: {
    component: "div",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #f0f0f0",
  },
  productsList: {
    maxHeight: "50vh",
    overflow: "auto",
  },
  backdrop: {
    position: "relative",
    width: "100%",
    height: "60px",
    zIndex: theme.zIndex.drawer + 100,
    color: theme.palette.primary.main,
    backgroundColor:'rgba(255,255,255,0.5)',
  },
}));
