import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  imageContainer : {
    width: "100%",
    height: "100%"
  }
}));