import { useEffect, useState } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import SelectList from "../SelectList/SelectList";
import SelectProducts from "../SelectProducts/SelectProducts";
import { useVendorState } from "../../../../context/VendorContext";
import { fetchAMSProducts, fetchAMSSubCategories, fetchCatalogASINs } from "../../../../http/api";
import _ from "lodash";

function CategoriesForm({
  campaignData,
  onValuesChange,
  selectedSubCategories,
  onRemoveSubCategory,
  onSubCategoriesChange,
  selectedProducts,
  onProductSelectionChange,
  onSelectAllProducts,
  selectedAllProducts,
  defaultSelectedProducts,
  onDefaultProductSelection,
  handleProductsInputChange,
  handleProductType
}) {
  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);

  const vendorState = useVendorState();

  useEffect(() => {
    const getAMSSubCategories = async () => {
      try {
        const data = await fetchAMSSubCategories(vendorState.selected);
        setSubCategories(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (vendorState.selected !== 0) {
      getAMSSubCategories();
    }
  }, [vendorState]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        setfullPageLoader(true);
        const data = await fetchAMSProducts(
          vendorState?.selected,
          selectedSubCategories
        );
        setProducts(data);
        const selectedProducts = data.filter(product => defaultSelectedProducts.includes(product.asin));
        if(selectedProducts.length) {
          const checked = data.length === selectedProducts.length ? true : false;
          onDefaultProductSelection({ target: { checked: checked } }, selectedProducts);
        }
        setfullPageLoader(false);
      } catch (error) {
        console.log(error);
        setfullPageLoader(false);
      }
    };

    if (selectedSubCategories || !!selectedSubCategories.length) {
      getProducts();
    } else {
      setProducts([]);
    }
  }, [vendorState, selectedSubCategories]);

  return (
    <Grid
      container
      spacing={4}
      style={{
        paddingLeft: "4%",
        paddingRight: "4%",
        paddingBottom: "2%",
      }}
    >
      <Grid item xs={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="advertising-goal-label">Advertising Goal</InputLabel>
          <Select
            labelId="advertising-goal-label"
            value={campaignData.advertisingGoal}
            onChange={onValuesChange}
            name="advertisingGoal"
            label="Advertising Goal"
          >
            <MenuItem value={"Increase Awareness"}>Increase Awareness</MenuItem>
            <MenuItem value={"Increase Sales"}>Increase Sales</MenuItem>
            <MenuItem value={"Reduce ACOS"}>Reduce ACOS</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Target(%)"
          value={campaignData.target}
          onChange={onValuesChange}
          name="target"
          type="number"
          variant="outlined"
          fullWidth
          required
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl>
          <FormLabel id="demo-radio-buttons-group-label">
            Type
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="daily"
            value={campaignData.productType}
            onChange={handleProductType}
            name="budgetType"
          >
            <FormControlLabel
              value="asin"
              control={<Radio />}
              label="ASIN"
            />
            <FormControlLabel
              value="subcategories"
              control={<Radio />}
              label="Sub Category"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {
        campaignData.productType === "asin" ?
        <>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <TextField
                label="ASINs"
                value={selectedProducts}
                onChange={(e) => handleProductsInputChange(e.target.value)}
                name="asins"
                type="text"
                variant="outlined"
                fullWidth
                required
              />
            </FormControl>
          </Grid>
        </>
        : 
        <>
          <Grid item xs={6}>
            <SelectList
              label="Sub Categories"
              options={subCategories}
              selectedList={selectedSubCategories}
              onRemoveItem={onRemoveSubCategory}
              onChangeSelection={onSubCategoriesChange}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedSubCategories.length > 0 && (
              <SelectProducts
                options={products}
                fullPageLoader={fullPageLoader}
                selectedProducts={selectedProducts}
                selectedAllProducts={selectedAllProducts}
                onChangeProducts={onProductSelectionChange}
                onSelectAllProducts={(e) => {
                  const { checked } = e.target;
                  onSelectAllProducts(checked, products);
                }}
              />
            )}
          </Grid>
        </>
      }
    </Grid>
  );
}

export default CategoriesForm;
