import React, { useState , useEffect } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";

//context
import { useLayoutState } from "../../context/LayoutContext";
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import WordChart from "./components/WordChart/WordChart";
import Table from "./components/Table/Table";

//styles
import useStyles from "./styles";

//helpers
import { encodeForProxy } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import pages from "../../healpers/pageCodes";

export default function SearchQueryPerformance() {

  const classes = useStyles();

  //global
  var layoutState = useLayoutState();
  var vendorState = useVendorState();
  
  //local
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [dateRange, setdateRange] = useState(null);
  const [selectedDateRange, setselectedDateRange] = useState(0);
  const [pageData, setpageData] = useState(null);
  const [campaignRange, setcampaignRange] = useState(null);
  const [selectedCampaignRange, setselectedCampaignRange] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [wordCloudData, setwordCloudData] = useState([]);

  useEffect(() => {
    if (layoutState.dates) {
      setdateRange(layoutState.dates["Search Query Performance"].weekly);
      if (layoutState.dates["Search Query Performance"].weekly.length !== 0)
        setselectedDateRange(0);
    }
    return () => {
      setdateRange(null);
    };
  }, [layoutState.dates]);

  useEffect(() => {
    async function loadData() {
      try {
        setfullPageLoader(true);
        var encodedString = encodeForProxy(
          pages.searchqueryperformance,
          vendorState.selected,
          moment(dateRange[selectedDateRange].startdate).format("MM-DD-YYYY"),
          moment(dateRange[selectedDateRange].enddate).format("MM-DD-YYYY")
        );

        axios
          .get(BASE_URL + api.getCache + encodedString, tokenConfig() )
          .then((result) => {
            if (result.data.data) {              
              setpageData(result.data.data);
              setcampaignRange(Object.keys(result.data.data));
              setselectedCampaignRange(Object.keys(result.data.data)[0]);
              setTableData(result.data.data[Object.keys(result.data.data)[0]].mainTableData);
              setwordCloudData(result.data.data[Object.keys(result.data.data)[0]].wordCloudData)
              setfullPageLoader(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setfullPageLoader(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

    if (dateRange && dateRange.length != 0) {
      loadData();
    }

    return () => {
      setTableData([]);
    };
  }, [dateRange, selectedDateRange]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Search Query Performance"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                Advertising
              </Link>
              <Typography className={classes.link} color="primary">
                Search Query Performance
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Date
                </InputLabel>
                <Select
                  value={selectedDateRange}
                  onChange={(e) => {
                    setselectedDateRange(e.target.value);
                  }}
                  label="Select Range"
                  disabled={dateRange && dateRange.length === 0 ? true : false}
                >
                  {dateRange ? (
                    dateRange.map((range, index) => {
                      return (
                        <MenuItem value={index}>
                          {range.startdate + " - " + range.enddate}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.campaignControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Campaign
                </InputLabel>
                <Select
                  value={selectedCampaignRange}
                  onChange={(e) => {
                    setselectedCampaignRange(e.target.value);
                    setTableData(pageData[e.target.value].mainTableData);
                    setwordCloudData(pageData[e.target.value].wordCloudData);
                  }}
                  label="Select Campaign"
                  disabled={campaignRange && campaignRange.length === 0 ? true : false}
                >
                  {campaignRange ? (
                    campaignRange.map((name) => {
                      return (
                        <MenuItem value={name}>
                          {name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Campaigns</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12} >
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.barChartOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    Search Queries
                  </Typography>
                </div>
              }
            >
              <WordChart wordCloudData={wordCloudData}/>
            </Widget>
          </Grid>
          <Grid item xs={12} md={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
            >
              <Table tableData={tableData}/>
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
          onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
