import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    form: {
        padding: 24
    },
  campaignControl: {
    margin: theme.spacing(1),
    minWidth: 290,
  },
}));
