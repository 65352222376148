import { useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import { Button } from "@material-ui/core";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import { useCallback } from "react";

export default ({ mainTableData, handleOpen, setImages, setOpenedRow }) => {
  const rowStyle = useMemo(() => {
    return { background: "white" };
  }, []);

  const onRowClickedListener = (event) => {
    setOpenedRow(event.data);
    setImages(event.data);
  };

  const EditButton = useCallback(() => {
    return (
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={handleOpen}
      >
        Edit Media
      </Button>
    );
  }, []);

  const FavIconRenderer = (props) => {
    return (
      <img
        style={{ height: "20px", width: "40px" }}
        src={props.value}
        alt="favicon"
      />
    );
  };

  const frameworkComponents = useMemo(() => {
    return { editButton: EditButton, favIconRenderer: FavIconRenderer };
  }, []);

  const columnDefs = useMemo(() => {
    return [
      { field: "edit", headerName: "Edit", cellRenderer: "editButton" },
      { field: "id", headerName: "Id" },
      {
        field: "favIcon",
        headerName: "Fav Icon",
        cellRenderer: "favIconRenderer",
      },
      { field: "asin", headerName: "ASIN" },
      { field: "sku", headerName: "SKU" },
      { field: "vendorCode", headerName: "Vendor Code" },
      { field: "title", headerName: "Product Title", width: 300 },
      { field: "bullet1", headerName: "Bullet1", width: 300 },
      { field: "bullet2", headerName: "Bullet2", width: 300 },
      { field: "bullet3", headerName: "Bullet3", width: 300 },
      { field: "bullet4", headerName: "Bullet4", width: 300 },
      { field: "bullet5", headerName: "Bullet5", width: 300 },
      {
        field: "productDescription",
        headerName: "Product Description",
        width: 300,
      },
    ];
  }, []);

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        rowData={mainTableData}
        suppressExcelExport={true}
        rowSelection="single"
        defaultColDef={{
          width: 150,
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        frameworkComponents={frameworkComponents}
        columnDefs={columnDefs}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
        animateRows={true}
        onRowClicked={onRowClickedListener}
      ></AgGridReact>
    </div>
  );
};
