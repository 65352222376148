import React, { useState, useRef , useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import axios from "axios";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

//context
import { useLayoutState } from "../../context/LayoutContext";
import { useVendorState } from "../../context/VendorContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

export default function InventoryManagementPage() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var layoutState = useLayoutState();
  var vendorState = useVendorState();

  // local
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [dateRange, setdateRange] = useState(null);
  const [selectedDateRange, setselectedDateRange] = useState(0);
  const [tableData, setTableData] = useState([]);

  async function loadData() {
    try {
      setfullPageLoader(true);
      axios
        .get(BASE_URL + api.listInventoryReports, {params: {vendorCode: vendorState?.selected}})
        .then((result) => {
          if (result?.data?.data) {
            const {mainTableData, startDate, endDate }= result.data.data              
            setTableData(mainTableData);
            // Only because we are not selecting the data range, it's fixed
            setdateRange([{ startdate: startDate, enddate: endDate }]);
            setfullPageLoader(false);
          } 
        })
        .catch((err) => {
          console.log(err);
          setfullPageLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    if(vendorState?.selected) {
      loadData()
    }
    return () => {
      setTableData([]);
    };
  }, [vendorState?.selected]);

  return (
    <>
      <Container>
        <PageTitle
          title="Inventory Management"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                Operations
              </Link>
              <Typography className={classes.link} color="primary">
                Inventory Management
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Range
                </InputLabel>
                <Select
                  value={selectedDateRange}
                  onChange={(e) => {
                    setselectedDateRange(e.target.value);
                  }}
                  label="Select Range"
                  // disabled={dateRange && dateRange.length === 0 ? true : false}
                  // Because the dateRange is fixed
                  disabled={true}
                >
                  {dateRange ? (
                    dateRange.map((range, index) => {
                      return (
                        <MenuItem value={index}>
                          {range.startdate + " - " + range.enddate}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <div/>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export CSV
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={tableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
          onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
