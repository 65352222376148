import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import {
  Backdrop,
  Box,
  Button,
  Fade,
  FormControl,
  Modal,
  TextField,
  Typography,
} from "@material-ui/core";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import api from "../../../../healpers/apiRoutes";
import axios from "axios";
import { BASE_URL } from "../../../../healpers/api";
import moment from "moment"

export default forwardRef(function Tables(
  {
    tableData,
    classes,
    fetchEmptyDomains,
    setfullPageLoader,
    setnotification,
    seterr,
  },
  ref
) {
  const gridRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedRowData(null);
    fetchEmptyDomains();
  };

  const onEditButtonClick = (event) => {
    setSelectedRowData(event.data);
    handleModalOpen();
  };

  async function updateDomainComment() {
    const { comment, id } = selectedRowData;
    axios
      .post(BASE_URL + api.updateDomainComment, { comment, id })
      .catch((err) => {
        console.log(err);
        setfullPageLoader(false);
      });
  }

  async function changeDomainCommentHandler(event) {
    const comment = event.target.value;
    setSelectedRowData((prevState) => {
      return { ...prevState, comment };
    });
  }

  async function updateDomainStatus() {
    const { status, id } = selectedRowData;
    axios
      .post(BASE_URL + api.updateDomainStatus, { status, id })
      .catch((err) => {
        console.log(err);
        setfullPageLoader(false);
      });
  }

  async function changeDomainStatusHandler(event) {
    const newStatus = event.target.value;
    setSelectedRowData((prevState) => {
      return { ...prevState, status: newStatus };
    });
  }

  async function saveChangesHandler() {
    try {
      setfullPageLoader(true);
      await updateDomainStatus();
      await updateDomainComment();
      setnotification("Saved");
      setTimeout(() => setnotification(null), 3000);
    } catch (error) {
      console.log(error);
      seterr("Error saving");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullPageLoader(false);
    }
  }

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const columnKeys = ["domain", "status", "createdDate", "comment"];
  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({
          onlySelected: true,
          columnKeys,
        });
      else gridApi.exportDataAsCsv({ columnKeys });
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  const EditButtonRenderer = () => {
    return (
      <Button color="primary" variant="contained">
        EDIT
      </Button>
    );
  };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        onCellClicked={(event) => {
          if (event.colDef.headerName === "Edit") {
            onEditButtonClick(event);
          }
        }}
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          editButtonRenderer: EditButtonRenderer,
        }}
      >
        <AgGridColumn
          headerName="Domain"
          field="domain"
          width={250}
          checkboxSelection={true}
          headerCheckboxSelection={true}
        />
        <AgGridColumn headerName="Status" field="status" width={150} />
        <AgGridColumn
          headerName="Created Date"
          field="createdDate"
          valueFormatter={(params) => moment(params.value.split("T")[0]).format("MM/DD/YYYY")}
        />
        <AgGridColumn headerName="Comment" field="comment" />
        <AgGridColumn cellRenderer={"editButtonRenderer"} headerName="Edit" />
      </AgGridReact>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <div className={classes.paper}>
            <Box display={"flex"} flexDirection="column">
              <Typography
                variant="h6"
                gutterBottom
                align="center"
                color="textPrimary"
              >
                {selectedRowData?.domain}
              </Typography>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                color="primary"
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  label="Status"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select"
                  value={selectedRowData?.status}
                  onChange={(event) => changeDomainStatusHandler(event)}
                >
                  <MenuItem value={"Default"}>Default</MenuItem>
                  <MenuItem value={"Valid"}>Valid</MenuItem>
                  <MenuItem value={"Invalid"}>Invalid</MenuItem>
                  <MenuItem value={"Blocked"}>Blocked</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="outlined-basic"
                label="Comment"
                variant="outlined"
                margin="normal"
                value={selectedRowData?.comment}
                onChange={changeDomainCommentHandler}
              />
              <Button
                variant="contained"
                color="primary"
                size="medium"
                style={{ marginTop: 10 }}
                onClick={saveChangesHandler}
              >
                SAVE CHANGES
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                style={{ marginTop: 10 }}
                onClick={handleModalClose}
              >
                CLOSE
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
});
