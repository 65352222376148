import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { makeStyles } from "@material-ui/styles";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
  success: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.success.main,
    color: "#fff",
  },
  warning: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.warning.main,
    color: "#fff",
  },
  secondary: {
    height: 25,
    marginTop: -2,
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
  },
}));

export default forwardRef(function Tables(props, ref) {
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else gridApi.exportDataAsCsv();
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 610, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
      >
        <AgGridColumn headerName={''} field={'favicon'} width={100} cellRenderer={renderImage}/>
        <AgGridColumn headerName="ASIN1" field="ASIN1" width={140} />
        <AgGridColumn headerName="Seller SKU" field="Seller SKU" width={150}/>
        <AgGridColumn headerName="Date" field="Date" width={140} />
        <AgGridColumn headerName="Item Name" field="Item Name" />
        <AgGridColumn
          headerName="Vendor Central Average Sales"
          field="Vendor Central Average Sales"
          width={170}
        />
        <AgGridColumn
          headerName="Seller Central Average Sales"
          field="Seller Central Average Sales"
          width={170}
        />
        <AgGridColumn
          headerName="Sell Through"
          field="Sell Through"
          width={150}
          cellStyle={cellStyleSellThrough}
        />
        <AgGridColumn
          headerName="VC Inventory"
          field="VC Inventory"
          width={150}
          cellStyle={cellStyleVcInventory}
        />
        <AgGridColumn
          headerName="SC Inventory"
          field="SC Inventory"
          width={150}
          cellStyle={cellStyleScInventory}
        />
        <AgGridColumn
          headerName="Total Inventory"
          field="Total Inventory"
          width={150}
          cellStyle={cellStyleTotalInventory}
        />
        <AgGridColumn
          headerName="Sell Through (weeks)"
          field="Sell Through (weeks)"
          width={160}
        />
      </AgGridReact>
    </div>
  );
});

function cellStyleVcInventory(params) {
  if(parseInt(params.data["VC Inventory"])/parseInt(params.data["Vendor Central Average Sales"])<4){
    return { 
      backgroundColor: `rgb(230,108,111)`,
      borderBottom: '1px solid rgba(178,178,178,0.25)'
    };  
  }
}

function cellStyleScInventory(params) {
  if(parseInt(params.data["SC Inventory"])/parseInt(params.data["Seller Central Average Sales"])<4){
    return { 
      backgroundColor: `rgb(230,108,111)`,
      borderBottom: '1px solid rgba(178,178,178,0.25)'
    };  
  }
}

function cellStyleTotalInventory(params) {
  if(parseInt(params.data["Total Inventory"])/parseInt(params.data["Sell Through"])<4){
    return { 
      backgroundColor: `rgb(230,108,111)`,
      borderBottom: '1px solid rgba(178,178,178,0.25)'
    };  
  }
}

function cellStyleSellThrough(params) {
  if(parseInt(params.data["Sell Through"])<4){
    return { 
      backgroundColor: `rgb(230,108,111)`,
      borderBottom: '1px solid rgba(178,178,178,0.25)'
    };  
  }
}

const renderImage = ({ value }) => `<img style="height: 40px; width: 40px" src="${value}"`;