import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  link: {
    display: "flex",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: theme.palette.primary.main,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}));
