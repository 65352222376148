import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },  
  success: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
}))

const states = {
  sent: "success",
  warning: "warning",
  secondary: "secondary",
};

export default forwardRef ( function Tables(props,ref) {
  
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  var checkboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };

  var headerCheckboxSelection = function (params) {
    return params.columnApi.getRowGroupColumns().length === 0;
  };

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
        return { background: '#F6F7FF' };
    }
  };

  const StatusRenderer = (props) => {
    return <Chip label={props.value} classes={{root: classes[states[props.value.toLowerCase()]]}} style={{width:100}}/>
  };

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{height: 610, width: '100%'}} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={props.tableData || []}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                },
            }}            
            onGridReady={onGridReady}
            frameworkComponents={{
              statusCellRenderer: StatusRenderer,
            }}
            pagination={true}
            paginationPageSize={20}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
        >
            <AgGridColumn headerName="Campaign Name" field="Campaign Name" width={300}></AgGridColumn>
            <AgGridColumn headerName="Campaign Status" field="Campaign Status" width={260}></AgGridColumn>
            <AgGridColumn headerName="Campaign State" field="State"></AgGridColumn>
            <AgGridColumn headerName="Campaign Type" field="Campaign Type"></AgGridColumn>
            <AgGridColumn headerName="Targeting" field="Targeting"></AgGridColumn>            
            <AgGridColumn headerName="Campaign Bid Strategy" field="Campaign Bid Strategy" width={220} />                   
            <AgGridColumn headerName="Campaign Start Date" field="Campaign Start Date"></AgGridColumn>
            <AgGridColumn headerName="Campaign End Date" field="Campaign End Date"></AgGridColumn>           
            <AgGridColumn headerName="Portfolio" field="Portfolio"></AgGridColumn>
            <AgGridColumn headerName="Budget" field="Budget(USD)"></AgGridColumn>            
            <AgGridColumn headerName="Top-of-search IS" field="Top-of-search IS"></AgGridColumn>            
            <AgGridColumn headerName="Cost type" field="Cost Type" ></AgGridColumn>            
            <AgGridColumn headerName="Impressions" field="Impressions" width={160} />            
            <AgGridColumn headerName="Clicks" field="Clicks" width={140} comparator={integerComparator} />
            <AgGridColumn headerName="CTR" field="CTR" width={140} comparator={decimalComparator} />
            <AgGridColumn headerName="Spend" field="Spend(USD)" width={130} comparator={decimalComparator}/>
            <AgGridColumn headerName="CPC" field="CPC(USD)" width={140} comparator={decimalComparator} />          
            <AgGridColumn headerName="Orders" field="Orders" width={140} />  
            <AgGridColumn headerName="Sales" field="Sales(USD)" width={140} comparator={decimalComparator} />                     
            <AgGridColumn headerName="ACOS" field="ACOS" width={140} comparator={integerComparator} />
            <AgGridColumn headerName="RAOS" field="RAOS" width={140} comparator={integerComparator} />
            <AgGridColumn headerName="NTB Orders" field="NTB Orders" ></AgGridColumn>
            <AgGridColumn headerName="% of Orders NTB" field="% of Orders NTB" comparator={decimalComparator} />
            <AgGridColumn headerName="NTB sales" field="NTB sales(USD)" ></AgGridColumn>            
            <AgGridColumn headerName="% of Sales NTB" field="% of Sales NTB" ></AgGridColumn>            
            <AgGridColumn headerName="Viewable Impressions" field="Viewable Impressions" ></AgGridColumn>
            <AgGridColumn headerName="VCPM" field="VCPM(USD)" width={140} />            
        </AgGridReact>
    </div>
  );
})

const decimalComparator = (valueA, valueB) => parseFloat(valueA) - parseFloat(valueB);

const integerComparator = (valueA, valueB) => parseInt(valueA) - parseInt(valueB);
