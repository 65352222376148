import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../styles.js'

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{ height: props.height, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
        suppressLoadingOverlay
      >
        <AgGridColumn headerName="Keyword" field="keyword" width={150} />
        <AgGridColumn headerName="Campaign" field="campaignName" width={300} />
        <AgGridColumn headerName="ACOS" field="acos" width={130} valueFormatter={calcACOS}/>
        <AgGridColumn headerName="Sales" field="sales" width={130} valueFormatter = {(params) => floatParseWithSymbol(params, "$")} />
        <AgGridColumn headerName="Spend" field="cost" width={130} valueFormatter = {(params) => floatParseWithSymbol(params, "$")} />
      </AgGridReact>
    </div>
  );
})

const calcACOS = (params) => params.value ? `${(parseFloat(params.value) * 100).toFixed(2)}%` : "-";
const floatParseWithSymbol = (params, symbol) => params.value ? (symbol + Number(parseFloat(params.value)).toFixed(2)) : "$0";