/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import moment from "moment";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchEventNotificationTypes, fetchEventNotifications, fetchEventNotificationsTypeData } from "../../http/eventapi";

import useStyles from "./styles";

export default function Charts(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [searchTerms, setSearchTerms] = useState([]);
  const [mainTableData, setmainTableData] = useState([]);
  const [selectedSearchTerm, setselectedSearchTerm] = useState('');
  const [fullPageLoader, setfullPageLoader] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {

        setfullPageLoader(true);
        const { formattedData, filteredData } = await fetchEventNotifications(vendorState.selected)

        setmainTableData(formattedData);
        
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }
    
    fetchData();

    return () => {
      setmainTableData([]);
    };

  }, [vendorState?.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Event Notifications"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
				Operations
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Event Notifications
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.formControl} style={{ display: "none" }} >
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Notification Type
                </InputLabel>
                <Select
                  value={selectedSearchTerm}
                  onChange={(e) => {
                    setselectedSearchTerm(e.target.value);
                  }}
                  label="Select Notification Type"
                  disabled={searchTerms && searchTerms.length === 0 ? true : false}
                >
                  {searchTerms ? (
                    searchTerms.map((name, i) => {
                      return (
                        <MenuItem key={i} value={name}>
                          {name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Notifications</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
