/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchKeywordsByASINData, fetchKeywordsByASINRanges } from "../../http/api";

import useStyles from "./styles";

export default function Charts(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [monthRanges, setMonthRanges] = useState([]);
  const [asinsList, setAsinsList] = useState([]);
  const [mainTableData, setmainTableData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState({});
  const [selectedAsin, setSelectedAsin] = useState('');
  const [fullPageLoader, setfullPageLoader] = useState(false);
  
  useEffect(() => {
    fetchKeywordsByASINRanges(vendorState?.selected)
      .then(({ months, asins }) => {

        setMonthRanges(months);
        setAsinsList(asins);
        setSelectedMonth(months?.at(-1))
        setSelectedAsin(asins[0])
      }).catch((err) => {
        console.error('Failed to fetch the ranges')
      })

      return () => {
        setMonthRanges([]);
        setAsinsList([]);
        setSelectedMonth('')
        setSelectedAsin('')
      }
  }, [ vendorState?.selected ])

  useEffect(() => {
    async function fetchData() {
      try {

        setfullPageLoader(true);
        const { filteredData } = await fetchKeywordsByASINData(vendorState.selected, selectedMonth, selectedAsin)
        setmainTableData(filteredData);
        
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if(selectedMonth && selectedAsin) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
    };

  }, [selectedMonth, selectedAsin]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Keywords By ASIN"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                <EqualizerIcon className={classes.icon} />
                Business Intelligence
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Keywords By ASIN
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.campaignControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Date
                </InputLabel>
                <Select
                  value={selectedMonth}
                  onChange={(e) => {
                    setSelectedMonth(e.target.value);
                  }}
                  label="Select Range"
                  disabled={monthRanges && monthRanges.length === 0 ? true : false}
                >
                  {monthRanges && monthRanges.length ? (
                    monthRanges.map((range, index) => {
                      return (
                        <MenuItem value={range}>
                          {range}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.campaignControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select ASIN
                </InputLabel>
                <Select
                  value={selectedAsin}
                  onChange={(e) => {
                    setSelectedAsin(e.target.value);
                  }}
                  label="Select ASIN"
                  disabled={asinsList && asinsList.length === 0 ? true : false}
                >
                  {asinsList && asinsList.length ? (
                    asinsList.map((name) => {
                      return (
                        <MenuItem value={name}>
                          {name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No ASINs</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
