import { Checkbox, TextField, Chip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from "@material-ui/icons";

export default function SelectList({
  label,
  options,
  selectedList,
  onRemoveItem,
  onChangeSelection,
}) {
  const UnCheckedIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const CheckedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <Autocomplete
      multiple
      options={options}
      disableCloseOnSelect
      getOptionLabel={(option) => option}
      value={selectedList}
      fullWidth
      onChange={onChangeSelection}
      renderInput={(params) => {
        const InputProps = { ...params.InputProps };
        InputProps.endAdornment = null;
        return (
          <TextField
            {...params}
            variant="outlined"
            label={`Select ${label}`}
            placeholder={label}
            InputProps={InputProps}
          />
        );
      }}
      renderTags={(values) =>
        values.map((value) => (
          <Chip
            key={value}
            label={value}
            onDelete={() => {
              onRemoveItem(value);
            }}
          />
        ))
      }
      getOptionSelected={(option, value) => option === value}
      renderOption={(option, { selected }) => (
        <>
          <Checkbox
            icon={UnCheckedIcon}
            checkedIcon={CheckedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option}
        </>
      )}
    />
  );
}
