import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const CustomTooltip = (props) => {
  if (props.active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffff",
          padding: "5px",
          border: "1px solid #cccc",
        }}
      >
        <label style={{ fontWeight: "bold" }}>
          <u>
            <i>{props.label}</i>
          </u>
        </label>
        <br />
        <p>Count: {props.payload && props.payload[0].value}</p>        
      </div>
    );
  }
  return null;
};

export default function Chart(props) {
  if(props.barChartData && props.barChartData.length !== 0)
  return (
    <BarChart
      width={1300}
      height={400}
      data={props.barChartData}
      margin={{
        top: 25,
        right: 10,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis yAxisId="left" orientation="left" axisLine={false}/>
      <Tooltip content={<CustomTooltip />} />
      <Bar yAxisId="left" dataKey="count" fill="#8884d8" />
    </BarChart>
  )
  else return (
    <h3 style={{ textAlign: "center",margin:'25% 0'}}>
      ...No Data To Show...
    </h3>
  )
}
