import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Breadcrumbs,
  Container,
  Link,
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Backdrop,
  CircularProgress,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  Chip,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment
} from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";

//helpers

import {
  fetchReachTrendData,
  getSubCategories,
  getVendorSettings,
  saveProductCatalogs,
  saveVendorSettings,
  updateVendorSettings,
} from "../../http/api";
import { arrayFromString } from "../../healpers/utilityFunctions";
import csvtojson from "csvtojson";
import { AttachMoney } from "@material-ui/icons";

export default function ReachTrend() {
  var classes = useStyles();
  const hiddenFileInput = useRef(null);

  //global
  var vendorState = useVendorState();

  // pop up a notificationType
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [opensubcategorylist, setOpenSubCategoryList] = useState(false);
  const [openkeyword, setOpenkeyword] = useState(false);
  const [openNegativeKeyword, setOpenNegativeKeyword] = useState(false);
  const [openNegativeProduct, setOpenNegativeProduct] = useState(false);
  const [opencompetitor, setOpenCompetitor] = useState(false);
  const [openproducttarget, setOpenProductTarget] = useState(false);

  // local
  const [settingsId, setSettingsId] = useState(null);
  const [dailyBudget, setDailyBudget] = useState(0);
  const [monthlyBudget, setMonthlyBudget] = useState(0);
  const [defaultBid, setDefaultBid] = useState(5);
  const [subCategories, setSubCategories] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [newSubCategory, setNewSubCategory] = useState("");
  const [keyword, setKeyword] = useState([]);
  const [keywords, setKeywords] = useState({});
  const [competitor, setCompetitor] = useState([]);
  const [excludeNegativeKeywords, setExcludeNegativeKeywords] = useState([]);
  const [competitors, setCompetitors] = useState({});
  const [productTargetingAsin, setProductTargetingAsin] = useState([]);
  const [productTargetingAsins, setProductTargetingAsins] = useState({});
  const [
    negativeProductTargetingAsin,
    setNegativeProductTargetingAsin,
  ] = useState([]);
  const [
    negativeProductTargetingAsins,
    setNegativeProductTargetingAsins,
  ] = useState({});
  const [negativeKeywords, setNegativeKeywords] = useState({
    negativeKeywordType: "Negative exact",
    exact: [],
    exacts: {},
    phrase: [],
    phrases: {},
  });
  const handleFile = () => {
    hiddenFileInput.current.click();
  };

  const resetState = () => {
    setDailyBudget(0);
    setMonthlyBudget(0);
    setDefaultBid(5);
    setSettingsId(null);
    setSubCategory("");
    setNewSubCategory("");
    setKeyword([]);
    setKeywords({});
    setCompetitor([]);
    setCompetitors({});
    setProductTargetingAsin([]);
    setProductTargetingAsins({});
    setExcludeNegativeKeywords([]);
    setNegativeProductTargetingAsin([]);
    setNegativeProductTargetingAsins({});
    setNegativeKeywords({
      negativeKeywordType: "Negative exact",
      exact: [],
      exacts: {},
      phrase: [],
      phrases: {},
    });
  }

  // csv to json
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const filename = file.name.split(".").pop();
    const catalogs = [];
    if (filename === "csv") {
      const json = await csvToJson(file);
      json.forEach((catelog) => {
        const catalogObj = {
          asin: catelog.asin,
          categoryId: catelog.categoryId,
          categoryName: catelog.categoryName,
          productGroupType: catelog.productGroupType,
          productName: catelog.productName,
          subcategoryId: catelog.subcategoryId,
          subcategoryName: catelog.subcategoryName,
          sku: catelog.sku,
        };
        catalogs.push(catalogObj);
      });
      const vendorCode = vendorState.selected;
      if (vendorCode) {
        const body = {
          vendorCode: vendorCode,
          catalogs,
        };
        let { data } = await saveProductCatalogs(body);
        console.log("data", data);
        alert("Product Catelogs Saved");
      }
      console.log("json", json);
    } else {
      console.log("Upload only csv files");
    }
  };
  const csvToJson = (csvfile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(csvfile, "UTF-8");
      reader.onload = () => {
        csvtojson()
          .fromString(reader.result)
          .then(async (jsonArrayObj) => {
            resolve(jsonArrayObj);
          })
          .catch((err) => reject(err));
      };
    });
  };

  const handleClickOpen = (data) => {
    if (data === "subcategorylist") {
      setOpenSubCategoryList(true);
    } else if (data === "keyword") {
      setOpenkeyword(true);
    } else if (data === "competitor") {
      setOpenCompetitor(true);
    } else if (data === "negativeProductTargetingAsin") {
      setOpenNegativeProduct(true);
    } else if (data === "negativeKeyword") {
      setOpenNegativeKeyword(true);
    } else {
      setOpenProductTarget(true);
    }
  };
  const handleClose = (data) => {
    if (data === "subcategorylist") {
      setOpenSubCategoryList(false);
    } else if (data === "keyword") {
      setOpenkeyword(false);
    } else if (data === "competitor") {
      setOpenCompetitor(false);
    } else if (data === "negativeKeyword") {
      setOpenNegativeKeyword(false);
    } else if (data === "negativeProductTargetingAsin") {
      setOpenNegativeProduct(false);
    } else {
      setOpenProductTarget(false);
    }
  };

  const handleVendorSettings = async () => {
    const newNegativeKeywords = negativeKeywords;
    delete newNegativeKeywords.negativeKeywordType;
    delete newNegativeKeywords.exact;
    delete newNegativeKeywords.phrase;
    const vendorSettings = {
      vendorCode: vendorState.selected,
      keywords: keywords,
      competitors: competitors,
      productTargetingAsins: productTargetingAsins,
      negativeProductTargetingAsins: negativeProductTargetingAsins,
      negativeKeywords: newNegativeKeywords,
      excludeNegativeKeywords: excludeNegativeKeywords,
      subCategories: subCategories,
      dailyBudget: parseInt(dailyBudget, 10),
      monthlyBudget: parseInt(monthlyBudget, 10),
      defaultBid: parseInt(defaultBid, 10),
    };
    try {
      if (settingsId) {
        vendorSettings.id = settingsId;
        await updateVendorSettings(vendorSettings);
      } else {
        await saveVendorSettings(vendorSettings);
      }
      alert("Vendor Settings Saved");
    } catch (error) {
      alert("Failed to save vendor settings");
      console.log(error);
    }
  };

  useEffect(() => {
    resetState();
    async function loadData() {
      setFullPageLoader(true);
      try {
        const vendorCode = vendorState.selected;
        if (vendorCode) {
          await fetchSubCategories(vendorCode);
          await fetchVendorSettings(vendorCode);
        }
        setFullPageLoader(false);
      } catch (error) {
        setFullPageLoader(false);
        console.log(error);
      }
    }

    loadData();
  }, [vendorState.selected]);

  const fetchVendorSettings = async (vendorCode) => {
    let { data } = await getVendorSettings(vendorCode);
    setSettingsId(data.id);
    setDailyBudget(data.dailyBudget);
    setMonthlyBudget(data.monthlyBudget);
    setDefaultBid(data.defaultBid);
    setKeywords(data.keywords);
    setKeyword(data.keywords[subCategories[0]]);
    setCompetitors(data.competitors);
    setCompetitor(data.competitors[subCategories[0]]);
    setProductTargetingAsins(data.productTargetingAsins);
    setExcludeNegativeKeywords(data.excludeNegativeKeywords);
    setProductTargetingAsin(data.productTargetingAsins[subCategories[0]]);
    setNegativeKeywords((prevState) => ({
      ...prevState,
      ...data.negativeKeywords,
    }));
    setNegativeProductTargetingAsins(data.negativeProductTargetingAsins);
    setNegativeProductTargetingAsin(
      data.negativeProductTargetingAsins[subCategories[0]]
    );
  };

  const fetchSubCategories = async (vendorCode) => {
    let { data } = await getSubCategories(vendorCode);
    setSubCategories(data);
    setSubCategory(data[0]);
  };

  const saveNewSubCategory = () => {
    handleClose("subcategorylist");
    setSubCategories([...subCategories, newSubCategory]);
    setNewSubCategory("");
  };

  const saveKeyword = () => {
    handleClose("keyword");
    // const newKeywords = arrayFromString(keyword);
    setKeywords({ ...keywords, ...{ [subCategory]: keyword } });
  };

  const saveCompetitors = () => {
    handleClose("competitor");
    // const newCompetitors = arrayFromString(competitor);
    setCompetitors({ ...competitors, ...{ [subCategory]: competitor } });
  };
  const saveNegativeProductTargetingAsin = () => {
    handleClose("negativeProductTargetingAsin");
    setNegativeProductTargetingAsins({
      ...negativeProductTargetingAsins,
      ...{ [subCategory]: negativeProductTargetingAsin },
    });
  };

  const saveProductTargetAsin = () => {
    handleClose("producttarget");
    // const newproductTargetingAsins = arrayFromString(productTargetingAsin);
    setProductTargetingAsins({
      ...productTargetingAsins,
      ...{ [subCategory]: productTargetingAsin },
    });
  };

  const handleSubCatetoryChange = (value) => {
    setSubCategory(value);
    setKeyword(keywords[value]);
    setCompetitor(competitors[value]);
    setProductTargetingAsin(productTargetingAsins[value]);
    setNegativeProductTargetingAsin(negativeProductTargetingAsins[value]);
  };

  const handleType = (e) => {
    const { name, value } = e.target;
    setNegativeKeywords((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setNegativeKeywords((prevState) =>
      prevState.negativeKeywordType === "Negative phrase"
        ? {
            ...prevState,
            phrase: arrayFromString(value),
          }
        : {
            ...prevState,
            exact: arrayFromString(value),
          }
    );
  };

  const saveNegativeKeyword = () => {
    handleClose("negativeKeyword");
    setNegativeKeywords((prevState) => ({
      ...prevState,
      phrases: {
        ...prevState.phrases,
        ...{ [subCategory]: prevState.phrase },
      },
      exacts: {
        ...prevState.exacts,
        ...{ [subCategory]: prevState.exact },
      },
    }));
  };

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Account Settings"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              AMS
            </Link>
            <Typography className={classes.link} color="primary">
              Account Settings
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={handleFile}
            style={{ marginRight: "10px", width: 300, height: 40 }}
          >
            Import Product Catalog
          </Button>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            bodyClass={classes.tableOverflow}
            disableWidgetMenu
          >
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <TextField
                  label="Daily budget"
                  value={dailyBudget}
                  onChange={(e) => {
                    setDailyBudget(e.target.value);
                  }}
                  type="number"
                  variant="outlined"
                  fullWidth
                  required
                  InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Monthly budget"
                  value={monthlyBudget}
                  onChange={(e) => {
                    setMonthlyBudget(e.target.value);
                  }}
                  type="number"
                  variant="outlined"
                  fullWidth
                  required
                  InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={6}>
                  <TextField
                    label="Default bid"
                    value={defaultBid}
                    onChange={(e) => {
                      setDefaultBid(e.target.value);
                    }}
                    type="number"
                    variant="outlined"
                    fullWidth
                    required
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoney />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              {/* sub Category list */}
              <Grid item xs={6}>
                <Grid item xs={12} className={classes.subcategorylist}>
                  <Typography color="textPrimary" variant="h6">
                    Sub Category:
                  </Typography>
                  <Grid style={{ textAlign: "right" }}>
                    <TextField
                      InputProps={{ disableUnderline: true }}
                      color="primary"
                      inputProps={{ ref: hiddenFileInput }}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      type="file"
                      required
                      id="files"
                    />
                    <Button
                      size="small"
                      color="primary"
                      type="submit"
                      variant="contained"
                      onClick={() => handleClickOpen("subcategorylist")}
                    >
                      Add Sub Category
                    </Button>
                    <Dialog
                      open={opensubcategorylist}
                      onClose={() => handleClose("subcategorylist")}
                    >
                      <DialogTitle></DialogTitle>
                      <DialogContent>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "600px",
                            }}
                          >
                            <Grid item xs={4}>
                              <Typography color="textPrimary" variant="h6">
                                Sub Category:
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <TextField
                                label=" "
                                style={{ width: "380px" }}
                                value={newSubCategory}
                                onChange={(e) => {
                                  setNewSubCategory(e.target.value);
                                }}
                                type="text"
                                variant="outlined"
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleClose("subcategorylist")}>
                          Cancel
                        </Button>
                        <Button onClick={saveNewSubCategory}>Save</Button>
                      </DialogActions>
                    </Dialog>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.inputwrapper}
                    >
                      <InputLabel id="demo-simple-select-outlined-label">
                        Sub Category
                      </InputLabel>
                      <Select
                        value={subCategory}
                        onChange={(e) => {
                          handleSubCatetoryChange(e.target.value);
                        }}
                        label="Sub Category "
                      >
                        {subCategories.map((subcategory) => (
                          <MenuItem value={subcategory}>{subcategory}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              {/* keyword */}
              <Grid item xs={6}>
                <Grid item xs={12} className={classes.keyword}>
                  <Typography size={16} color="textPrimary" variant="h6">
                    Keyword:
                  </Typography>
                  <Button
                    size="small"
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={() => handleClickOpen("keyword")}
                  >
                    Manage Keyword
                  </Button>
                  <Dialog
                    open={openkeyword}
                    onClose={() => handleClose("keyword")}
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xs={6}>
                            <Typography color="textPrimary" variant="h6">
                              Sub Category:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Sub Category
                              </InputLabel>
                              <Select
                                value={subCategory}
                                onChange={(e) => {
                                  handleSubCatetoryChange(e.target.value);
                                }}
                                label="Sub Category"
                              >
                                {subCategories.map((subcategory) => (
                                  <MenuItem value={subcategory}>
                                    {subcategory}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "30px",
                          }}
                        >
                          <Grid item xs={6}>
                            <Typography color="textPrimary" variant="h6">
                              Keyword:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                            <TextareaAutosize
                              maxRows={4}
                              placeholder=""
                              value={keyword && keyword.join(",")}
                              onChange={(e) => {
                                setKeyword(arrayFromString(e.target.value));
                              }}
                              className={classes.textarea}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => handleClose("keyword")}>
                        Cancel
                      </Button>
                      <Button onClick={saveKeyword}>Save</Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "15px" }}>
                  <Grid item xs={12} className={classes.showbox}>
                    {keywords[subCategory] ? (
                      keywords[subCategory].map((key) => (
                        <Chip style={{ marginRight: 5 }} label={key} />
                      ))
                    ) : (
                      <Typography color="textPrimary" >
                        No Data Found
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Competitor list */}
              <Grid item xs={6}>
                <Grid item xs={12} className={classes.competitor}>
                  <Typography color="textPrimary" variant="h6">
                    Competitors:
                  </Typography>
                  <Button
                    size="small"
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={() => handleClickOpen("competitor")}
                  >
                    Manage Competitors
                  </Button>
                  <Dialog
                    open={opencompetitor}
                    onClose={() => handleClose("competitor")}
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xs={6}>
                            <Typography color="textPrimary" variant="h6">
                              Sub Category:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Sub Category
                              </InputLabel>
                              <Select
                                value={subCategory}
                                onChange={(e) => {
                                  handleSubCatetoryChange(e.target.value);
                                }}
                                label="Sub Category"
                              >
                                {subCategories.map((subcategory) => (
                                  <MenuItem value={subcategory}>
                                    {subcategory}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "30px",
                          }}
                        >
                          <Grid item xs={6}>
                            <Typography color="textPrimary" variant="h6">
                              Competitors:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                            <TextareaAutosize
                              maxRows={4}
                              placeholder=""
                              value={competitor && competitor.join(",")}
                              onChange={(e) => {
                                setCompetitor(arrayFromString(e.target.value));
                              }}
                              className={classes.textarea}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => handleClose("competitor")}>
                        Cancel
                      </Button>
                      <Button onClick={saveCompetitors}>Save</Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "15px" }}>
                  <Grid item xs={12} className={classes.showbox}>
                    {competitors[subCategory] ? (
                      competitors[subCategory].map((value) => (
                        <Chip style={{ marginRight: 5 }} label={value} />
                      ))
                    ) : (
                      <Typography color="textPrimary" >
                        No Data Found
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Product targetings */}
              <Grid item xs={6}>
                <Grid
                  item
                  xs={12}
                  className={classes.producttarget}
                >
                  <Typography color="textPrimary" variant="h6">
                    Product Targetings Asins:
                  </Typography>
                  <Button
                    size="small"
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={() => handleClickOpen("producttarget")}
                  >
                    Manage Product Targeting Asins
                  </Button>
                  <Dialog
                    open={openproducttarget}
                    onClose={() => handleClose("producttarget")}
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xs={6}>
                            <Typography color="textPrimary" variant="h6">
                              Sub Category:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Sub Category
                              </InputLabel>
                              <Select
                                value={subCategory}
                                onChange={(e) => {
                                  handleSubCatetoryChange(e.target.value);
                                }}
                                label="Sub Category"
                              >
                                {subCategories.map((subcategory) => (
                                  <MenuItem value={subcategory}>
                                    {subcategory}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "30px",
                          }}
                        >
                          <Grid item xs={6}>
                            <Typography color="textPrimary" variant="h6">
                              Product Targetings Asins:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                            <TextareaAutosize
                              maxRows={4}
                              placeholder=""
                              value={
                                productTargetingAsin &&
                                productTargetingAsin.join(",")
                              }
                              onChange={(e) => {
                                setProductTargetingAsin(
                                  arrayFromString(e.target.value)
                                );
                              }}
                              className={classes.textarea}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => handleClose("producttarget")}>
                        Cancel
                      </Button>
                      <Button onClick={saveProductTargetAsin}>Save</Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "15px" }}>
                  <Grid item xs={12} className={classes.showbox}>
                    {productTargetingAsins[subCategory] ? (
                      productTargetingAsins[subCategory].map((value) => (
                        <Chip style={{ marginRight: 5 }} label={value} />
                      ))
                    ) : (
                      <Typography color="textPrimary" >
                        No Data Found
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* negative keyword */}
              <Grid item xs={6}>
                <Grid item xs={12} className={classes.keyword}>
                  <Typography color="textPrimary" variant="h6">
                    Negative Keyword:
                  </Typography>
                  <Button
                    size="small"
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={() => handleClickOpen("negativeKeyword")}
                  >
                    Manage Negative Keywords
                  </Button>
                  <Dialog
                    open={openNegativeKeyword}
                    onClose={() => handleClose("negativeKeyword")}
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xs={6}>
                            <Typography color="textPrimary" variant="h6">
                              Sub Category:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Sub Category
                              </InputLabel>
                              <Select
                                value={subCategory}
                                onChange={(e) => {
                                  handleSubCatetoryChange(e.target.value);
                                }}
                                label="Sub Category"
                              >
                                {subCategories.map((subcategory) => (
                                  <MenuItem value={subcategory}>
                                    {subcategory}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "30px",
                          }}
                        >
                          <Grid item xs={6}>
                            <Typography color="textPrimary" variant="h6">
                              Negative Keyword Type:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                            <RadioGroup
                              row
                              aria-labelledby="negative-keyword-type"
                              defaultValue="Negative exact"
                              value={negativeKeywords.negativeKeywordType}
                              onChange={handleType}
                              name="negativeKeywordType"
                            >
                              <FormControlLabel
                                value="Negative exact"
                                control={<Radio />}
                                label="Negative exact"
                              />
                              <FormControlLabel
                                value="Negative phrase"
                                control={<Radio />}
                                label="Negative phrase"
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "30px",
                          }}
                        >
                          <Grid item xs={6}>
                            <Typography color="textPrimary" variant="h6">
                              Negative Keywords:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                            <TextareaAutosize
                              maxRows={4}
                              placeholder=""
                              value={
                                negativeKeywords.negativeKeywordType ===
                                "Negative phrase"
                                  ? negativeKeywords.phrase &&
                                    negativeKeywords?.phrase.join(
                                      ","
                                    )
                                  : negativeKeywords.exact &&
                                    negativeKeywords?.exact.join(
                                      ","
                                    )
                              }
                              onChange={handleChange}
                              className={classes.textarea}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => handleClose("negativeKeyword")}>
                        Cancel
                      </Button>
                      <Button onClick={saveNegativeKeyword}>Save</Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "15px" }}>
                  <Grid item xs={12} className={classes.showbox}>
                    {negativeKeywords?.exacts[subCategory]
                      ?.length > 0 ||
                    negativeKeywords?.phrases[subCategory]
                      ?.length > 0 ? (
                      <>
                        {negativeKeywords?.exacts[subCategory]
                          ?.length > 0 && (
                          <Typography component="div">{`Exact`}</Typography>
                        )}
                        {negativeKeywords?.exacts[
                          subCategory
                        ]?.map((keyWord) => (
                          <Chip style={{ marginRight: 5 }} label={keyWord} />
                        ))}

                        {negativeKeywords?.phrases[subCategory]
                          ?.length > 0 && (
                          <Typography
                            component="div"
                            style={{ marginTop: "10px" }}
                          >
                            Phrase
                          </Typography>
                        )}
                        {negativeKeywords?.phrases[
                          subCategory
                        ]?.map((keyWord) => (
                          <Chip style={{ marginRight: 5 }} label={keyWord} />
                        ))}
                      </>
                    ) : (
                      <Typography color="textPrimary" >
                        No Data Found
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* negative negativeProductTargetingAsin */}
              <Grid item xs={6}>
                <Grid item xs={12} className={classes.producttarget}>
                  <Typography color="textPrimary" variant="h6">
                    Negative Product Targeting Asins:
                  </Typography>
                  <Button
                    size="small"
                    color="primary"
                    type="submit"
                    variant="contained"
                    onClick={() =>
                      handleClickOpen("negativeProductTargetingAsin")
                    }
                  >
                    Manage Negative Product Asins
                  </Button>
                  <Dialog
                    open={openNegativeProduct}
                    onClose={() => handleClose("negativeProductTargetingAsin")}
                  >
                    <DialogTitle></DialogTitle>
                    <DialogContent>
                      <Grid container>
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Grid item xs={6}>
                            <Typography color="textPrimary" variant="h6">
                              Sub Category:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                            <FormControl
                              variant="outlined"
                              style={{ width: "100%" }}
                            >
                              <InputLabel id="demo-simple-select-outlined-label">
                                Sub Category
                              </InputLabel>
                              <Select
                                value={subCategory}
                                onChange={(e) => {
                                  handleSubCatetoryChange(e.target.value);
                                }}
                                label="Sub Category"
                              >
                                {subCategories.map((subcategory) => (
                                  <MenuItem value={subcategory}>
                                    {subcategory}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "30px",
                          }}
                        >
                          <Grid item xs={6}>
                            <Typography color="textPrimary" variant="h6">
                              Negative Product Targeting Asins:
                            </Typography>
                          </Grid>
                          <Grid item xs={12} style={{ paddingLeft: "20px" }}>
                            <TextareaAutosize
                              maxRows={4}
                              placeholder=""
                              value={
                                negativeProductTargetingAsin &&
                                negativeProductTargetingAsin.join(",")
                              }
                              onChange={(e) => {
                                setNegativeProductTargetingAsin(
                                  arrayFromString(e.target.value)
                                );
                              }}
                              className={classes.textarea}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={() =>
                          handleClose("negativeProductTargetingAsin")
                        }
                      >
                        Cancel
                      </Button>
                      <Button onClick={saveNegativeProductTargetingAsin}>
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "15px" }}>
                  <Grid item xs={12} className={classes.showbox}>
                    {negativeProductTargetingAsins[subCategory] ? (
                      negativeProductTargetingAsins[subCategory].map((key) => (
                        <Chip style={{ marginRight: 5 }} label={key} />
                      ))
                    ) : (
                      <Typography color="textPrimary" >
                        No Data Found
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12} className={classes.producttarget}>
                  <Typography color="textPrimary" variant="h6">
                    Exclude Negative Keywords:
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextareaAutosize
                    maxRows={4}
                    placeholder=""
                    value={excludeNegativeKeywords && excludeNegativeKeywords.join(",")}
                    onChange={(e) => {
                      setExcludeNegativeKeywords(arrayFromString(e.target.value));
                    }}
                    className={classes.textarea}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginLeft: "35%", marginRight: "35%" }}
              >
                <Button
                  color="primary"
                  fullWidth
                  size="small"
                  type="submit"
                  variant="contained"
                  onClick={handleVendorSettings}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
      </Grid>

      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
