import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
// components

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },  
  success: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
}))

const states = {
  sent: "success",
  warning: "warning",
  secondary: "secondary",
};

export default forwardRef ( function Tables(props,ref) {
  
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={props.tableData}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                },              
            }}            
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={10}
            rowStyle={rowStyle}
            suppressDragLeaveHidesColumns={true}
        >
            <AgGridColumn headerName={''} field={'favicon'} width={100} cellRenderer={renderImage}/>
            <AgGridColumn headerName="ASIN" field="ASIN" width={150}/>
            <AgGridColumn headerName="Product Title" field="Product Title" width={300}/>
            <AgGridColumn headerName="Shipped Revenue" field="Shipped Revenue"width={160} comparator={integerComparatorWithComma} />
            <AgGridColumn headerName="Shipped Revenue - Prior Period" field="Shipped Revenue - Prior Period" cellStyle={cellStyle} width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Shipped Revenue - Last Year" field="Shipped Revenue - Last Year"  cellStyle={cellStyle} width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Shipped COGS" field="Shipped COGS"width={160} comparator={integerComparatorWithComma} />
            <AgGridColumn headerName="Shipped COGS - Prior Period" field="Shipped COGS - Prior Period" cellStyle={cellStyle} width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Shipped COGS - Last Year" field="Shipped COGS - Last Year"  cellStyle={cellStyle} width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Shipped Units" field="Shipped Units" width={150} comparator={integerComparatorWithComma} />
            <AgGridColumn headerName="Shipped Units - Prior Period" field="Shipped Units - Prior Period" cellStyle={cellStyle} width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Shipped Units - Last Year" field="Shipped Units - Last Year"  cellStyle={cellStyle} width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Customer Returns" field="Customer Returns" cellStyle={cellStyle} width={150} comparator={decimalComparator} />
            <AgGridColumn headerName="Customer Returns - Prior Period" field="Customer Returns - Prior Period" cellStyle={cellStyle} width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Customer Returns - Last Year" field="Customer Returns - Last Year"  cellStyle={cellStyle} width={170} comparator={decimalComparator} />
            <AgGridColumn headerName="Start Date" field="startdate" width={150}/>
            <AgGridColumn headerName="End Date" field="enddate" width={150}/>
        </AgGridReact>
    </div>
  );
})

function cellStyle(params) {
  var color;
  if(params.value && params.column["colId"]=="Customer Returns"){
    if(parseInt(params.value) > "0")
      color = '#e6b7af' //red
  } else {
    if(params.value.charAt && params.value.charAt(0) === "-")
      color = '#e6b7af'
    else if(params.value === "")
      color = null
    else color = '#d8e9d2'//green
  }
  return { 
    backgroundColor: color,
    borderBottom: '1px solid rgba(178,178,178,0.25)'
  };
}

const decimalComparator = (valueA, valueB) => parseFloat(valueA) - parseFloat(valueB);

const decimalComparatorWithDollar = (valueA, valueB) => parseFloat(valueA.includes("-") ? valueA.replace("$", "") : valueA.slice(1)) - parseFloat(valueB.includes("-") ? valueB.replace("$", "") : valueB.slice(1));

const integerComparatorWithComma = (valueA, valueB) => parseInt(valueA.replace(",","")) - parseInt(valueB.replace(",",""));

const renderImage = ({ value }) => `<img style="height: 40px; width: 40px" src="${value}"`;
