import React, { useState, useRef, useEffect } from "react";
import { Grid, Button, CircularProgress, Typography , Breadcrumbs, Link } from "@material-ui/core";
import useStyles from "./styles.js";
import Table from "./components/table.js";
import JsSegmentModal from "./components/jsSegmentModal.js";
import Confirmation from "./components/confirmation.js";
import { BASE_URL } from "../../healpers/api.js";
import axios from "axios";
import api from "../../healpers/apiRoutes";
import { tokenConfig } from "../../context/UserContext.js";
import { useVendorState } from "../../context/VendorContext.js";
import ImportSegmentModal from "./components/importSegmentModal.js";

export default function JsSegment(props) {
  const vendorState = useVendorState();
  const classes = useStyles();
  const exportFunctionRef = useRef();

  const [createSegmentModal, setCreateSegmentModal] = useState(false);
  const [deleteSegmentModal, setDeleteSegmentModal] = useState(false);
  const [jsSegmentId, setJSSegmentId] = useState(null);
  const [jsSegmentName, setJSSegmentName] = useState(null);
  const [jsSegments, setJSSegments] = useState([]);
  const [edit, setEdit] = useState(false);
  const [importSegmentModal, setImportSegmentModal] = useState(false);

  const fetchJSSegments = async () => {
    const params = {
      vendorCode: vendorState?.selected,
    }
    const response = await axios(BASE_URL + api.fetchJSSegments, tokenConfig(params));
    setJSSegments(response.data.data);
    console.log("response", response);
  }

  const deleteJSSegment = async () => {
    try{
      const params = {
        segmentId: jsSegmentId
      }
      await axios.delete(BASE_URL + api.deleteJSSegment, tokenConfig(params));
      setDeleteSegmentModal(false);
      fetchJSSegments();
    }catch(error){
      console.error(error);
      alert("Failed to delete segement");
    }
  }

  useEffect(() => {
    if(vendorState?.selected) {
      fetchJSSegments();
    }
  }, [vendorState?.selected]);
  

  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.header}>
        <Grid>
        <Typography variant="h4" weight="medium" style={{paddingBottom:"20px"}}>
          JS Segment
        </Typography>
        <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              Operations
            </Link>
            <Typography className={classes.link} color="primary">
              Js Segment
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid className={classes.buttonContainer} >
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setCreateSegmentModal(true);
            }}
          >
            Create
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setImportSegmentModal(true);
            }}
          >
            import
          </Button>
        </Grid>
        </Grid>
      </Grid>
      <Table
        ref={exportFunctionRef}
        tableData={jsSegments}
        openEditModel={(data) => {
          setCreateSegmentModal(true);
          setJSSegmentId(data.segmentId);
          setJSSegmentName(data.name);
          setEdit(true);
        }}
        deleteConfirmation={(data) => {
          setDeleteSegmentModal(true);
          setJSSegmentId(data.segmentId);
          setJSSegmentName(data.name);
        }}
      ></Table>
      <JsSegmentModal
        open={createSegmentModal}
        close={() => {
          setCreateSegmentModal(false);
          setEdit(false);
        }}
        fetchSegments={fetchJSSegments}
        segmentId={jsSegmentId}
        edit={edit}
      ></JsSegmentModal>
      <Confirmation
        open={deleteSegmentModal}
        jsName={jsSegmentName}
        onCancel={() => setDeleteSegmentModal(false)}
        onConfirm={deleteJSSegment}
      />
      <ImportSegmentModal 
      open={importSegmentModal}
      close={()=>{setImportSegmentModal(false)}}
      fetchSegments={fetchJSSegments}
      />
    </div>
  );
}
