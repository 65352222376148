/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
//context

//helpers
import useStyles from "./styles";
import bsgMatrix from "../../images/bsgMatrix.jpg";

export default function BcgOverview(props) {
  //global
  const classes = useStyles();

  // local

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="BCG Overview"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                <EqualizerIcon className={classes.icon} />
                Business Intelligence
              </Link>
              <Typography className={classes.link}>
                {/* <EqualizerIcon color="primary" className={classes.icon} /> */}
                BCG Overview
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className={classes.imageContainer}>
            <img src={bsgMatrix} className={classes.imageContainer} alt="bsgMatrix" />
               </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
