import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@material-ui/styles";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },  
  success: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
}))

export default forwardRef ( function Tables(props,ref) {
  
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [table, settable] = useState([]);
  const [max, setmax] = useState(-1);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useEffect(() => {
    for (let i = 0; i < props.tableData.length; i++) {
      const row = props.tableData[i];
      if(max<parseInt(row["Total time not serving"]))
        setmax(parseInt(row["Total time not serving"]))
    }
    settable(props.tableData)
  }, [props.tableData])

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
        return { background: '#F6F7FF' };
    }
  };

  const rowStyle = { background: 'white' };

  function cellStyle(params) {
    return { 
      backgroundColor: `rgba(230,108,111,${(1-(max-parseFloat(params.value))/100)})`,
      borderBottom: '1px solid rgba(178,178,178,0.25)'
    };
  }

  return (
    <div className="ag-theme-material" style={{height: 610, width: '100%'}} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={table}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                },
            }}            
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={10}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
        >
            <AgGridColumn headerName="ASIN (product)" field="ASIN (product)" width={150}/>
            <AgGridColumn headerName="Headline" field="Headline" />
            <AgGridColumn headerName="Marketplace" field="Marketplace" width={150}/>
            <AgGridColumn headerName="Ad missing decoration" field="Ad missing decoration" width={140} comparator={decimalComparator}/>
            <AgGridColumn headerName="Ad missing image" field="Ad missing image" width={140} comparator={decimalComparator}/>
            <AgGridColumn headerName="Ad not in buy box" field="Ad not in buy box" width={140} comparator={decimalComparator}/>
            <AgGridColumn headerName="Ad out of stock" field="Ad out of stock" width={140} comparator={decimalComparator} />
            <AgGridColumn headerName="Approval required from Amazon" field="Approval required from Amazon" width={160} comparator={decimalComparator} />
            <AgGridColumn headerName="Category closed for advertising" field="Category closed for advertising" width={150} comparator={decimalComparator} />
            <AgGridColumn headerName="Checkout By Amazon offers not supported" field="Checkout By Amazon offers not supported" width={170} comparator={decimalComparator} />            
            <AgGridColumn headerName="Ineligible" field="Ineligible" comparator={decimalComparator} />
            <AgGridColumn headerName="Ineligible (adult product)" field="Ineligible (adult product)" />
            <AgGridColumn headerName="Ineligible (variation parent product)" field="Ineligible (variation parent product)" comparator={decimalComparator} />
            <AgGridColumn headerName="Landing page unavailable" field="Landing page unavailable" comparator={decimalComparator} />
            <AgGridColumn headerName="Missing/incorrect info" field="Missing/incorrect info" comparator={decimalComparator} />
            <AgGridColumn headerName="Not buyable" field="Not buyable" comparator={decimalComparator} />
            <AgGridColumn headerName="Offer removed" field="Offer removed" comparator={decimalComparator} />
            <AgGridColumn headerName="Product inventory info incomplete" field="Product inventory info incomplete" comparator={decimalComparator} />
            <AgGridColumn headerName="Removed from inventory" field="Removed from inventory" comparator={decimalComparator} />
            <AgGridColumn headerName="SKU tagged as defective" field="SKU tagged as defective" comparator={decimalComparator} />
            <AgGridColumn headerName="Total time not serving" field="Total time not serving" cellStyle={cellStyle} comparator={decimalComparator} />
        </AgGridReact>
    </div>
  );
})

const decimalComparator = (valueA, valueB) => parseFloat(valueA.substring(0,valueA.length-1)) - parseFloat(valueB.substring(0,valueB.length-1));