import { Grid, Chip, Divider, Typography } from "@material-ui/core";
import moment from "moment";
import ProductsPreview from "../ProductsPreview/ProductsPreview";

function CampaignReview({
  title,
  apiResponse,
  campaignData,
  selectedProducts,
}) {
  return (
    <Grid container spacing={3} style={{ padding: "4%" }}>
      <Grid item xs={12}>
        <Typography color="textPrimary" variant="h5">
          {title ? title : "Ad Preview"}
        </Typography>
        <Divider />
      </Grid>
      {apiResponse && (
        <>
          <Grid item xs={6}>
            <Typography mt={1}>
              <b>Campaign Id: </b> {apiResponse ? apiResponse.campaignId : ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography mt={1}>
              <b>Campaign Name: </b> {apiResponse ? apiResponse.name : ""}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography mt={1}>
              <b>Ad Group Id: </b> {apiResponse ? apiResponse.adGroupId : ""}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography mt={1}>
              <b>Ad Group Name: </b>{" "}
              {apiResponse ? apiResponse.adGroupName : ""}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={6}>
        <Typography mt={1}>
          <b>Advertising Goal:</b> {campaignData.advertisingGoal}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography mt={1}>
          <b>Target:</b> {campaignData.target}%
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography mt={1} style={{ textTransform: "capitalize" }}>
          <b>Campaign Type:</b> {campaignData.type}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography mt={1} style={{ textTransform: "capitalize" }}>
          <b>Targeting Type:</b> {campaignData.targetingType}
        </Typography>
      </Grid>
      {campaignData.type === "AUTO_AND_SCHEDULED_MANUAL" &&
        campaignData.scheduledCampaignDays && (
          <Grid item xs={6}>
            <Typography mt={1}>
              <b>Scheduled Campaign Days:</b>{" "}
              {campaignData.scheduledCampaignDays}
            </Typography>
          </Grid>
        )}
      <Grid item xs={6}>
        <Typography mt={1}>
          <b>Start Date: </b>
          {moment(campaignData.startDate).format("YYYY-MM-DD")}
        </Typography>
      </Grid>
      {campaignData.endDate && (
        <Grid item xs={6}>
          <Typography mt={1}>
            <b>End Date:</b> {moment(campaignData.endDate).format("YYYY-MM-DD")}
          </Typography>
        </Grid>
      )}

      <Grid item xs={6}>
        <Typography mt={1} style={{ textTransform: "capitalize" }}>
          <b>Budget Type:</b> {campaignData.budgetType}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography mt={1}>
          <b>Budget:</b> ${campaignData.budget}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography mt={1}>
          <b> Top of search:</b> {campaignData.topOfSearch}
        </Typography>
      </Grid>

      <Grid item xs={6}>
        <Typography mt={1}>
          <b> Product Page: </b>
          {campaignData.productPage}
        </Typography>
      </Grid>

      {campaignData.type === "MANUAL" && (
        <Grid item xs={12}>
          <Typography mt={1}>
            <b>Manual Targeting Type: </b>
            {campaignData.manualTargetingType}
          </Typography>
          <Divider />
          <br />
          {campaignData.manualTargetingType === "Keyword Targeting" ? (
            <>
              {campaignData?.keywords?.map((keyword) => (
                <Chip
                  key={keyword}
                  style={{ marginRight: 5 }}
                  label={keyword}
                />
              ))}
            </>
          ) : (
            <>
              {campaignData?.productTargetingAsins?.map((asin) => (
                <Chip key={asin} style={{ marginRight: 5 }} label={asin} />
              ))}
            </>
          )}
        </Grid>
      )}
      {(campaignData.type !== "MANUAL" ||
        campaignData.manualTargetingType === "Keyword Targeting") &&
        campaignData?.negativeKeywordsExact?.length +
          campaignData?.negativeKeywordsPhrase?.length >
          0 && (
          <Grid item xs={12}>
            <Typography mt={1}>
              <b> Negative Keywords Targeting</b>
            </Typography>
            <Divider />
            <br />
            {campaignData?.negativeKeywordsExact?.length>0 && (
              <Typography>{`Exact`}</Typography>
            )}
            {campaignData?.negativeKeywordsExact?.map((negativeKeyword) => (
              <Chip
                key={negativeKeyword}
                style={{ marginRight: 5, marginTop: 10, marginBottom: 5 }}
                label={negativeKeyword}
              />
            ))}

            {campaignData?.negativeKeywordsPhrase?.length >0&& (
              <Typography>{`Phrase`}</Typography>
            )}
            {campaignData?.negativeKeywordsPhrase?.map((negativeKeyword) => (
              <Chip
                key={negativeKeyword}
                style={{ marginRight: 5, marginTop: 10, marginBottom: 5 }}
                label={negativeKeyword}
              />
            ))}
          </Grid>
        )}

      {(campaignData.type !== "MANUAL" ||
        campaignData.manualTargetingType === "Product Targeting") &&
        campaignData.negativeProducts.length >0&& (
          <Grid item xs={12}>
            <Typography mt={1}>
              <b> Negative Product Targeting</b>
            </Typography>
            <Divider />
            <br />
            {campaignData.negativeProducts.map((product) => (
              <Chip key={product} style={{ marginRight: 5 }} label={product} />
            ))}
          </Grid>
        )}
      <Grid item xs={12}>
        {/* <ProductsPreview products={selectedProducts} /> */}
      </Grid>
    </Grid>
  );
}

export default CampaignReview;
