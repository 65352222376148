import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { DATEFORMAT, VERSION_LABELS } from "../utils";
import useStyles from "../styles";
import ArrayFormatting from "./ArrayFormatting";
import NegativeKeywordsFormatting from "./NegativeKeywordsFormatting";
import { dateTimeDiff } from "../../../../healpers/utilityFunctions";
import RollbackConfirmation from "./RollbackConfirmation.js";

export default function Versions({
  actualVersion,
  oldVersion,
  editCampaign,
  rollbackDisabled,
  navigate
}) {
  const jsondiffpatch = require("jsondiffpatch").create();
  const classes = useStyles();
  const [delta, setDelta] = useState(null);
  const [rollbackModal, setRollbackModal] = useState(false)
  const [versionKeys, setVersionKeys] = useState([
    "campaignState",
    "endDate",
    "dailyBudget",
    "keywords",
    "productTargets",
    "negativeKeywords",
    "negativeProductTargets",
    "versionCreatedDate",
    "placementTop",
    "placementProductPage",
    "targetGroups"
  ]);
  useEffect(() => {
    setDelta(jsondiffpatch.diff(actualVersion, oldVersion));
    if (actualVersion?.campaignType === "MANUAL") {
      if (actualVersion.manualTargetingType === "Keyword Targeting") {
        setVersionKeys([
          "campaignState",
          "endDate",
          "dailyBudget",
          "keywords",
          "negativeKeywords",
          "versionCreatedDate",
          "placementTop",
          "placementProductPage"
        ]);

      }
      else {
        setVersionKeys([
          "campaignState",
          "endDate",
          "dailyBudget",
          "productTargets",
          "negativeProductTargets",
          "versionCreatedDate",
          "placementTop",
          "placementProductPage"
        ]);

      }
    } else {
      setVersionKeys([
        "campaignState",
        "endDate",
        "dailyBudget",
        "negativeKeywords",
        "negativeProductTargets",
        "versionCreatedDate",
        "placementTop",
        "placementProductPage",
        "targetGroups"
      ]);
    }
  }, []);

  return (
    <Grid container spacing={4}>
      <Grid item sm={6} className={classes.borderBottom}>
        <Box
          component="div"
          className={classes.versionHeaderCellLabel}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <b>Actual</b>{" "}
          <div>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => navigate(`/app/ams/createCampaign?campaignId=${actualVersion.campaignId}`)}
            >
              Create campaign
            </Button>
            {
              (dateTimeDiff(actualVersion.versionCreatedDate).minutes > 5 && actualVersion.version > 1) &&
              <Button
                style={{ marginLeft: 10 }}
                variant="contained"
                color="primary"
                size="small"
                disabled={rollbackDisabled}
                onClick={() => setRollbackModal(true)}
              >
                Roll Back
              </Button>
            }

          </div>
        </Box>
        <Typography>
          Version: {actualVersion.version}
        </Typography>
        {Object.entries(actualVersion).map(([key, value]) => {
          if (versionKeys.includes(key)) {
            return (
              <Typography key={key}>
                <span className={classes.versionHeaderCellLabel}>
                  {Object.values(VERSION_LABELS.find((label) => label[key]))[0]}
                  :{" "}
                </span>
                <span style={{ overflowWrap: "anywhere" }}>
                  {/* {key === "negativeKeywords" ? ( */}
                    {/* null// <NegativeKeywordsFormatting value={value} actual={true} /> */}
                  {/* ) :  */}
                  { ["placementTop", "placementProductPage"].includes(key) ? (
                      value || 0
                  ): ["endDate", "versionCreatedDate"].includes(key) ? (
                    DATEFORMAT(value)
                  ) : Array.isArray(value) ? (
                    value.map((item, indx) => (
                      <>
                        <br/>
                          <tr>
                          <td style={{ width: 200, paddingRight: 10}}>
                            {item.split("-")[0]}
                          </td>
                          <td style={{ width: 100, paddingRight: 10}}>
                            {item.split("-")[1]}
                          </td>
                          <td>
                            {item.split("-")[2]}
                          </td>
                        </tr>
                      </>
                    ))
                  ) : (
                    value
                  )}
                </span>
              </Typography>
            );
          }

          return null;
        })}
      </Grid>
      <Grid item sm={6} className={classes.borderLeftBottom}>
        <Box component="div" className={classes.versionHeaderCellLabel}>
          <b> Changed</b>
        </Box>
        <Box>
          {delta &&
            Object.entries(delta).map(([key, value]) => {
              if(["versionCreatedDate"].includes(key)) return null;
              if (versionKeys.includes(key)) {
                return (
                  <Typography key={key}>
                    <span className={classes.versionHeaderCellLabel}>
                      {
                        Object.values(
                          VERSION_LABELS.find((label) => label[key])
                        )[0]
                      }
                      :{" "}
                    </span>
                    <span style={{ overflowWrap: "anywhere" }}>
                      {/* {key === "negativeKeywords" ? (
                        null// <NegativeKeywordsFormatting value={value} />
                      ) :  */}
                      {[
                          "keywords",
                          "productTargets",
                          "negativeKeywords",
                          "negativeProductTargets",
                          "targetGroups",
                        ].includes(key) ? (
                        <ArrayFormatting value={value}/>
                      ) : ["endDate"].includes(key) ? (
                        <>
                          <strike style={{ backgroundColor: "lightpink" }}>
                            {DATEFORMAT(value[1])}
                          </strike>{" "}
                          <span style={{ backgroundColor: "lightgreen" }}>
                            {DATEFORMAT(value[0])}
                          </span>
                        </>
                      ) : (
                        <>
                          <strike style={{ backgroundColor: "lightpink" }}>
                            {value[1]}
                          </strike>{" "}
                          <span style={{ backgroundColor: "lightgreen" }}>
                            {value[0]}
                          </span>
                        </>
                      )}
                    </span>
                  </Typography>
                );
              }
              return null;
            })}
        </Box>
      </Grid>
      <RollbackConfirmation open={rollbackModal} onCancel={()=> setRollbackModal(false)} onConfirm={()=>{editCampaign(actualVersion)}} />
    </Grid>
  );
}
