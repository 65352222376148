/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import useStyles from "./styles";
import api from "../../healpers/apiRoutes";
import { BASE_URL } from "../../healpers/api";
import { tokenConfig } from "../../context/UserContext";
import axios from "axios";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

export default function JSProductsAndBrands(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [segments, setSegments] = useState([]);
  const [selectedSegment, setSelectedSegment] = useState('');
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(moment().subtract(1, 'months')));
  const [toDate, setToDate] = useState(new Date());

  const fetchJSSegments = async () => {
    setfullPageLoader(true);
    try{
      const params = {
        vendorCode: vendorState?.selected,
      }
      const response = await axios(BASE_URL + api.fetchJSSegments, tokenConfig(params));
      setSegments(response.data.data.map((item, index) => ({ code: index, name: item.name, segmentId: item.segmentId })));
      if (response.data.data.length > 0) {
        setSelectedSegment(response.data.data[0].segmentId);
        getProductsAndBrands(response.data.data[0].segmentId);
      }
    }catch(error){
      console.log(error);
      alert("Failed to load segments");
    } finally {
      setfullPageLoader(false);
    }
  }

  const getProductsAndBrands = async (segment) => {
    setfullPageLoader(true);
    try{
      const params = {
        segmentId: segment || selectedSegment,
        startDate: fromDate,
        endDate: toDate,
      }
      const response = await axios(BASE_URL + api.getProductsAndBrands, tokenConfig(params));
      setmainTableData(response.data.data);
    }catch(error){
      alert("Failed to load products and brands data");
      console.log(error);
    } finally {
      setfullPageLoader(false);
    }
  };

  useEffect(() => {
    if (vendorState?.selected) {
      fetchJSSegments();
    }
  }, [vendorState?.selected])

  useEffect(() => {
    if (selectedSegment) {
      getProductsAndBrands();
    }
  }, [vendorState?.selected])

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Products & Brands"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                className={classes.link}
              >
                <EqualizerIcon className={classes.icon} />
                Business Intelligence
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Products & Brands
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.campaignControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Segment
                </InputLabel>
                <Select
                  value={selectedSegment}
                  onChange={(e) => setSelectedSegment(e.target.value)}
                  label="Select Segment"
                  disabled={segments && segments.length === 0 ? true : false}
                >
                  {segments && segments.length ? (
                    segments.map((segment) => {
                      return (
                        <MenuItem value={segment?.segmentId}>
                          {segment?.name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Segments</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils} fullWidth>
                <FormControl
                  variant="outlined"
                  className={classes.dateFormControl}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="startDate"
                    label="From"
                    value={fromDate}
                    onChange={(date) => setFromDate(date)}
                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.dateFormControl}
                >
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id="startDate"
                    label="To"
                    value={toDate}
                    onChange={(date) => setToDate(date)}
                    minDate={fromDate}
                  />
                </FormControl>
              </MuiPickersUtilsProvider>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: 53 }}
                  onClick={() => getProductsAndBrands()}
                >
                  Apply
                </Button>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        >
        <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
