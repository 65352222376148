import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
// components

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },  
  success: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
}))

const states = {
  sent: "success",
  warning: "warning",
  secondary: "secondary",
};

export default forwardRef ( function Tables(props,ref) {
  
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
        return { background: '#F6F7FF' };
    }
  };

  const StatusRenderer = (props) => {
    return <Chip label={props.value} classes={{root: classes[states[props.value.toLowerCase()]]}} style={{width:100}}/>
  };

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={props.tableData}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                }, 
            }}            
            onGridReady={onGridReady}
            frameworkComponents={{
              statusCellRenderer: StatusRenderer,
            }}
            pagination={true}
            paginationPageSize={10}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
        >
            <AgGridColumn headerName="Account Name" field="Account Name" width={160}></AgGridColumn>
            <AgGridColumn headerName="Financial Charge" field="Financial charge" valueFormatter={currencyFormatter} width={130}></AgGridColumn>
            <AgGridColumn headerName="Quantity" field="Quantity" width={130} ></AgGridColumn>
            <AgGridColumn headerName="Vendor Code" field="Vendor code" width={130} ></AgGridColumn>
            <AgGridColumn headerName="Sub Type" field="Sub Type" width={130} ></AgGridColumn>
            <AgGridColumn headerName="Creation Date" field="Creation Date" width={160}></AgGridColumn>
            <AgGridColumn headerName="Dispute By" field="Dispute By" width={140} ></AgGridColumn>
            <AgGridColumn headerName="Purchase order #" field="Purchase order #" width={140}></AgGridColumn>
            <AgGridColumn headerName="ASIN" field="ASIN" width={140} ></AgGridColumn>
            <AgGridColumn headerName="Action" field="Action" width={180} ></AgGridColumn>
            <AgGridColumn headerName="Dispute Status" field="Dispute Status" cellStyle={cellStyleBasedOnDisputeStatus} width={160}></AgGridColumn>
            <AgGridColumn headerName="Comments" field="Comments" width={180}></AgGridColumn>
        </AgGridReact>
    </div>
  );
})

function currencyFormatter(params) {
  return `$${params.value}`;
}

function cellStyleBasedOnDisputeStatus(params) {
  var color;
    if(params.data["Dispute Status"].length !=0  && params.data["Dispute Status"].toLowerCase().includes("approved"))
      color = '#d8e9d2'
    else 
    color = null
  return { 
    backgroundColor: color,
    borderBottom: '1px solid rgba(178,178,178,0.25)',
  };
}
