import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';

export default function DraggableDialog({open, toggleDialog, file, setFile, count, setnotification, seterr}) {

  const confirmSubmission = async (finalFile) => {
      toggleDialog();
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDialog}
        fullWidth={true}
      >
        <Paper>
        <DialogTitle>
          Confirm upload
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            File Name: {file.name}
          </DialogContentText>
          <DialogContentText>
            Created Count : {count}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            autoFocus 
            variant="outlined"
            onClick={()=>{
              toggleDialog()
              setFile({name:"No File uploaded"})
              document.getElementById('upload-csv').value = '';
            }} color="primary">
            Cancel
          </Button>
          <Button 
            variant="contained"
            onClick={()=>{              
              confirmSubmission(file)
            }} color="primary">
            Confirm
          </Button>
        </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}