import React from "react";

import { ADD_ERROR, CLEAR_ERRORS, ADD_NOTIFICATION, CLEAR_NOTIFICATION } from "./types";

var PopupStateContext = React.createContext();
var PopupDispatchContext = React.createContext();

function popupReducer(state, action) {
  switch (action.type) {
    case ADD_ERROR:
      return { ...state, err: action.payload };
    case CLEAR_ERRORS:
      return { ...state, err: null };
    case ADD_NOTIFICATION:
      return { ...state, notification: action.payload };
    case CLEAR_NOTIFICATION:
      return { ...state, notification: null };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function PopupProvider({ children }) {
  var [state, dispatch] = React.useReducer(popupReducer, {
    err: null,
    notification: null,
  });

  return (
    <PopupStateContext.Provider value={state} displayName="Popup Context">
      <PopupDispatchContext.Provider value={dispatch}>
        {children}
      </PopupDispatchContext.Provider>
    </PopupStateContext.Provider>
  );
}

function usePopupState() {
  var context = React.useContext(PopupStateContext);
  if (context === undefined) {
    throw new Error("usePopupState must be used within a PopupProvider");
  }
  return context;
}

function usePopupDispatch() {
  var context = React.useContext(PopupDispatchContext);
  if (context === undefined) {
    throw new Error("usePopupDispatch must be used within a PopupProvider");
  }
  return context;
}

export { PopupProvider, usePopupState, usePopupDispatch };
