import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
// import Loading from "../pages/loading/Loading";
import Login from "../pages/login";
import Register from "../pages/register/Register";

// context
import { useUserState, useUserDispatch, loadUser } from "../context/UserContext";
import ConnectSellerCentral from "../pages/connectSellerCentral/connectSellerCentral";
import ForgotPassword from "../pages/forgotPassword/forgotPassword";
import ResetPassword from "../pages/resetPassword/resetPassword";
// var allowedRoutes = { // key:route in the address bar &` value: permission key from backend
//   sales: "sales",
//   sc_cb: "shortageclaimsAndChargebacks",
//   bi: "businessIntelligence",
//   adv: "advertising",
//   operations: "operations",
//   creative: "creative",
//   users: "users",
//   catalog: "productdetails",
// };

export default function App() {
  
  // global
  var userState = useUserState();
  var userDispatch = useUserDispatch();

  useEffect(() => {
    loadUser(userDispatch)
  }, [])

  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/sales/goals" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/sales/goals" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/forgot_password" component={ForgotPassword} />
        <PublicRoute path="/reset_password" component={ResetPassword} />
        <PublicRoute path="/register" component={Register} />

        <Route component={Error} />
      </Switch>
    </Router>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          userState.isAuthenticated ? (
            //userState.userData && userState.userData.permissions[allowedRoutes[window.location.href.split("/")[4]]] ? 
            (userState.amazon && userState.amazon.amazon_callback_uri ?
              window.location.href = `${userState.amazon.amazon_callback_uri}?state=${userState.amazon.state}&amazon_state=${userState.amazon.amazon_state}&redirect_uri=https://bidashboard.salesduo.com/api/amzn/redirect` : React.createElement(component, props) ) //: React.createElement(Error, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    )
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          userState.isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
