import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import axios from "axios";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";


export default function Page() {
  const classes = useStyles();

  // local
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [usersActivity, setUsersActivity] = useState([]);

  useEffect(() => {
    setFullPageLoader(true);
    axios
      .get(BASE_URL + api.loginDetails)
      .then((response) => {
        setUsersActivity(response.data);
        setFullPageLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setFullPageLoader(false);
      });
  }, []);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="User Activity"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Users
              </Link>
              <Typography className={classes.link} color="primary">
                User Activity
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
            >
              <Table tableData={usersActivity} />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
