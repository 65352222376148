import { Grid, TextField } from "@material-ui/core";

export const TextArea = ({ cron, onValuesChange ,disabled}) => {
  return (
    <Grid
      container
      spacing={4}
      style={{
        paddingTop: "3%",
      }}
    >
      <Grid item xs={12}>
        <TextField
          onChange={onValuesChange}
          value={Object.values(cron.config)[0]}
          disabled={disabled}
          label={`${Object.keys(cron.config)[0]==="asins"?"ASINs":"Search Terms"} (separated by comma)`}
          variant="outlined"
          rows={4}
          name={cron.reportType}
          fullWidth
          multiline
        />
      </Grid>
    </Grid>
  );
};
