import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import { makeStyles } from "@material-ui/styles";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
// components

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },  
  success: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
}))

const states = {
  sent: "success",
  warning: "warning",
  secondary: "secondary",
};

export default forwardRef ( function Tables(props,ref) {
  
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };
  return (
    <div className="ag-theme-material" style={{ margin: '0 auto', height: 400, width: 670 }} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={props.tableData}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                },              
            }}            
            onGridReady={onGridReady}
            rowStyle={rowStyle}
            suppressDragLeaveHidesColumns={true}
            suppressLoadingOverlay
        >
            <AgGridColumn headerName="Range" field="range" width={250}/>
            <AgGridColumn headerName="Week" field="week" width={120}/>
            <AgGridColumn headerName="Total Clicks" field="clicks" width={150} comparator={integerComparatorWithComma}/>
            <AgGridColumn headerName="Total Orders" field="orders" width={150} comparator={integerComparatorWithComma}/>
        </AgGridReact>
    </div>
  );
})



const integerComparatorWithComma = (valueA, valueB) => parseInt(valueA.replace(",","")) - parseInt(valueB.replace(",",""));
