import React, { useLayoutEffect } from 'react';
import moment from 'moment';

import Button from "@material-ui/core/Button";
import LeftArrow from "@material-ui/icons/ArrowBackSharp";
import RightArrow from "@material-ui/icons/ArrowForwardSharp";

import { 
    calculateIntervals,
    filterDataByDate
} from "../../../../healpers/orderMetrics.helpers";

// styles
import useStyles from "../../styles";



function HourHeatMap({ heatmapData, dateRange, totalData, setmainTableData }) {

    var classes = useStyles();

    useLayoutEffect(() => {
        if(heatmapData && dateRange && dateRange.length && totalData) {          
          // eslint-disable-next-line no-undef
          var cal = new CalHeatMap();

          cal.init({
            itemSelector: "#HourHeatMap", previousSelector: "#PrevDomain-HourHeatMap", nextSelector: "#NextDomain-HourHeatMap",
            domain: "day", subDomain: "hour",
            data: heatmapData,
            cellSize: 15,
            cellPadding: 10,
            domainGutter: 20,
            start: new Date(dateRange?.at(-1)),
            minDate: new Date(dateRange[0]), maxDate: new Date(dateRange[1]),
            range: 5,
            domainDynamicDimension: false,
            afterLoadPreviousDomain: (prevDate) => {
              let nextDate = moment(prevDate).add(5, 'day');
              let filteredData = filterDataByDate(totalData, {}, prevDate, nextDate);
              setmainTableData(filteredData)
            },
            afterLoadNextDomain: (nextDate) => {
              let prevDate = moment(nextDate).subtract(4, 'day');
              let newNextDate = moment(nextDate).add(1, 'day'); // since date is exclusive, we will have to add an extra day.
              let filteredData = filterDataByDate(totalData, {}, prevDate, newNextDate);
              setmainTableData(filteredData);
            },
            domainLabelFormat: function(date) {
            //   moment.locale("en");
              return moment(date).locale('en').format("DDMMM").toUpperCase();
            },
            browsing: true,
            legend: calculateIntervals(Object.values(heatmapData))
          });

          return () => {
            cal = cal.destroy();
          }
        }
      })

    function SmallButton(params) {
        return (
            <Button
                id={ params.type === 'prev' ? "PrevDomain-HourHeatMap" : "NextDomain-HourHeatMap"}
                variant="outlined"
                color="inherit"
                size="small"
                startIcon={ params.type === 'prev' ? <LeftArrow /> : <RightArrow />}
            />
        )
    }

    return (
        <div className={classes.heatmapContainer}>
            <div className={classes.heatmapButtons}>
                <SmallButton type = 'prev'/>
                <SmallButton type = 'next' />
            </div>
            <div className={classes.heatmap} id="HourHeatMap"></div>
        </div>
    )
}

export default React.memo(HourHeatMap)