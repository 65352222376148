import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
  Backdrop,
  Box,
} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import axios from "axios";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export default function AMSSalesAnalysis() {
  const classes = useStyles();
  const exportFunctionRef = useRef();
  var vendorState = useVendorState();

  // local
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [tableData, setTableData] = useState([]);
  const [dropdownArray, setDropdownArray] = useState([]);
  const [highPeformingCampaign, sethighPeformingCampaign] = useState('');
  const [lowPeformingCampaign, setlowPeformingCampaign] = useState([]);


  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isError, setIsError] = useState(false);

  const setDateError = (event) => setIsError(!!event)


  useEffect(() => {
    try {
      setfullPageLoader(true);
      let amsReportUrl = BASE_URL + api.amsScreenOne + '?vendorCode=' + vendorState?.selected
      if(selectedValue && selectedValue !== 'ALL Campaigns') amsReportUrl += '&campaignType=' + selectedValue.toLowerCase()
      if(startDate) amsReportUrl += '&startDate=' + moment(startDate).format('YYYY-MM-DD')
      if(endDate) amsReportUrl += '&endDate=' + moment(endDate).format('YYYY-MM-DD')
      axios
        .get(amsReportUrl, tokenConfig())
        .then((result) => {
          if (result.data && result.data.data) {
            setTableData(result.data.data);
            sethighPeformingCampaign(result.data.highPeformingCampaign)
            setlowPeformingCampaign(result.data.lowPeformingCampaign)
            setDropdownArray(result.data.campaignTypes)
            
            setfullPageLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setfullPageLoader(false);
        });
    } catch (error) {
      console.log(error);
    }
  }, [selectedValue, startDate, endDate]);

  return (
    <>
      <Container>
        <PageTitle
          title="Campaigns"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              // href="/#/app/dashboard"
              className={classes.link}
            >
              AMS
            </Link>
            <Typography className={classes.link} color="primary">
              Sales Analysis
            </Typography>
            </Breadcrumbs>
          }

          rightAlignComponent={
            <>

              <FormControl style={{ width: '30%' }}>

              <Grid item xs={6} sm={6} >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            label="Start Date"
                            value={startDate}
                            maxDate={moment()}
                            onError={setDateError}
                            onChange={(e)=> {
                              setStartDate(e)
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                </FormControl>

            <FormControl style={{ width: "30%" }}>
                <Grid item xs={6} sm={6} >
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            variant="inline"
                            label="End Date"
                            value={endDate}
                            onError={setDateError}
                            minDate={moment(startDate)}
                            maxDate={moment()}
                            onChange={setEndDate}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                </FormControl>

            <FormControl style={{ width: "30%" }}>
  
                <InputLabel >
                  Select Campaign Type
                </InputLabel>
                <Select
                  value={selectedValue || 'ALL Campaigns'}
                  // label="End Date"
                  onChange={(e) => {
                    setSelectedValue(e.target.value)
                  }}
                  label="Select Campaign Type"
                >
                  {dropdownArray && dropdownArray.length ? (
                    dropdownArray.map((ele, index) => {
                      return (
                        <MenuItem key={index} value={ele.amsModule}>
                          {ele.amsModule.toUpperCase()}
                        </MenuItem>
                      );
                    })
                  ) : ( 
                    <MenuItem value={0}>
                      <i>No Campaign Types</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              bodyClass={classes.reviewBoxBody}
              headerClass={classes.reviewBoxHeader}
              disableWidgetMenu={true}
            >
              <Grid container>
                <Grid item xs={3}>
                <Typography><Box className={classes.reviewBoxMeta} sx={{ fontWeight: 'bold' }}>Top Performing Campaign:</Box></Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className={classes.reviewBoxMeta}>{highPeformingCampaign} </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={3}>
                <Typography><Box className={classes.reviewBoxMeta} sx={{ fontWeight: 'bold' }}>Low Performing Campaign:</Box></Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography className={classes.reviewBoxMeta}>{lowPeformingCampaign} </Typography>
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu={true}
            >
              <Table ref={exportFunctionRef} tableData={tableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
          onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
