import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {Link} from 'react-router-dom'
import { Backdrop, Box, Button, Fade, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@material-ui/core";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";
import axios from "axios";

export default forwardRef(function Tables({tableData, fetchData, classes, setnotification, seterr}, ref) {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedRowData(null);
    fetchData();
  };

  const onEditButtonClick = (event) => {
    setSelectedRowData(event.data);
    handleModalOpen();
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({
          onlySelected: true,
          columnKeys: [
            "id",
            "domain",
            "type",
            "createdDate",
            "status",
            "validity",
            "count",
          ],
        });
      else gridApi.exportDataAsCsv();
    },
  }));

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const EditButtonRenderer = () => {
    return (
      <Button color="primary" variant="contained">
        EDIT
      </Button>
    );
  };


  function changeDomainStatusHandler(event) {
    const newStatus = event.target.value;
    setSelectedRowData((prevState) => {
      return { ...prevState, status: newStatus };
    });
  }

  function changeValidityHandler(event) {
    const newValue = event.target.value;
    console.log({ newValue });
    setSelectedRowData((prevState) => {
      return { ...prevState, validity: newValue };
    });
  }

  async function saveChangesHandler() {
    const { id, status, validity } = selectedRowData;
    axios
      .post(BASE_URL + api.updateBlockedDomains, {id, validity, status })
      .then((response) => setnotification(response?.data?.msg ?? "Saved"))
      .catch((error) => seterr(error?.response?.data?.msg ?? "Failed to save"))
      .finally(() => {
        setTimeout(() => setnotification(null), 3000);
        setTimeout(() => seterr(null), 3000);
      });
  }



  const rowStyle = { background: "white" };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 620, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        onCellClicked={(event) => {
          if (event.colDef.headerName === "Edit") {
            onEditButtonClick(event);
          }
        }}
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        getRowStyle={getRowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          editButtonRenderer: EditButtonRenderer,
        }}
        suppressRowClickSelection={true}
      >
        <AgGridColumn
          headerName="Id"
          field="id"
          checkboxSelection={true}
          headerCheckboxSelection={true}
          width={195}
        />
        <AgGridColumn headerName="Domain" field="domain" />
        <AgGridColumn headerName="Type" field="type" width={195} />
        <AgGridColumn headerName="Created Date" field="createdDate" />
        <AgGridColumn headerName="Status" field="status" />
        <AgGridColumn headerName="Validity" field="validity" />
        <AgGridColumn
          headerName="Count"
          field="count"
          filter="agNumberColumnFilter"
          filterParams={{ buttons: ["clear"] }}
        />
        <AgGridColumn cellRenderer={"editButtonRenderer"} headerName="Edit" />
      </AgGridReact>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isModalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isModalOpen}>
          <div className={classes.paper}>
            <Box display={"flex"} flexDirection="column">
              <Typography
                variant="h6"
                gutterBottom
                align="center"
                color="textPrimary"
              >
                {selectedRowData?.domain}
              </Typography>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                color="primary"
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  label="Status"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select"
                  value={selectedRowData?.status}
                  onChange={(event) => changeDomainStatusHandler(event)}
                >
                  <MenuItem value={"skipped"}>Skipped</MenuItem>
                  <MenuItem value={"blocked"}>Blocked</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                color="primary"
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Validity
                </InputLabel>
                <Select
                  label="Status"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select"
                  value={selectedRowData?.validity}
                  onChange={(event) => changeValidityHandler(event)}
                >
                  <MenuItem value={"valid"}>Valid</MenuItem>
                  <MenuItem value={"invalid"}>Invalid</MenuItem>
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                style={{ marginTop: 10 }}
                onClick={saveChangesHandler}
              >
                SAVE CHANGES
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="medium"
                style={{ marginTop: 10 }}
                onClick={handleModalClose}
              >
                CLOSE
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
});
