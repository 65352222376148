import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import axios from "axios";

//helpers
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";

export default function DraggableDialog({
  setfullpageloader,
  setnotification,
  seterr,
  open,
  closeDialog,
  deleteVendor,
  fetchData
}) {
  async function deleteFromAms() {
    try {
      setfullpageloader(true);
      const { vendorCode } = deleteVendor;
      await axios.post(BASE_URL + api.deleteFromAms, { vendorCode });
      setnotification("AMS Profile Removed");
      closeDialog();
      fetchData();
      setTimeout(() => setnotification(null), 3000);
    } catch (err) {
      console.log(err);
      seterr("Failed to delete from AMS");
      setTimeout(() => seterr(null), 3000);
    } finally {
      setfullpageloader(false);
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={closeDialog} fullWidth={true}>
        <Paper>
          <DialogTitle>Confirm delete</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure to remove this vendor from AMS?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              variant="outlined"
              onClick={closeDialog}
              color="primary"
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={deleteFromAms} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}
