import { useEffect } from "react";
import { useRef } from "react";

const VideoCard = ({ src }) => {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [src]);

  return (
    <video
      hidden={!src}
      key={src}
      style={{ height: 200, width: 450, marginTop: 30 }}
      controls
      src={src}
      title="product video"
    ></video>
  );
};

export default VideoCard;
