import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';

// styles
import useStyles from "./styles";

// context
import { resetPassword } from "../../context/UserContext";
import { usePopupState , usePopupDispatch } from "../../context/PopupContext";
import useQuery from "../../hooks/useQuery";
import { getDifferenceInDays } from "../../healpers/utilityFunctions";
import { ADD_ERROR,CLEAR_NOTIFICATION } from "../../context/types";
import moment from 'moment'
function ResetPassword(props) {
  var classes = useStyles();
  const query = useQuery();
  const resetPasswordHash = query.get('hash')
  const expiry = query.get('expiry')
  console.log(new Date(expiry))
  // global
  var popupDispatch = usePopupDispatch();
  var { err, notification } = usePopupState();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [passwordValue, setPasswordValue] = useState("");
  var [confirmPasswordValue, setConfirmPasswordValue] = useState("");

  useEffect(() => {
    checkLinkExpiry()
  },[])

  const checkLinkExpiry = () => {
    const differenceInMinutes = getDifferenceInDays(expiry, new Date())
    if(differenceInMinutes > 0) {
    }else{
      popupDispatch({ type: ADD_ERROR, payload: "Link expired"});
      setTimeout(() => {
        popupDispatch({ type: CLEAR_NOTIFICATION });
      }, 2000);
      props.history.push("/login")
    }
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <Typography className={classes.logotypeText}>
          Reset Password
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            {/* <Typography variant="subtitle1" className={classes.greeting}>
              Enter your Email Address to receive reset link.
            </Typography> */}
            {/* <div className={classes.formDivider} /> */}

            {/* <Button size="large" className={classes.googleButton}>
              <img src={google} alt="google" className={classes.googleIcon} />
              &nbsp;Sign in with Google
            </Button> */}
            {/* <div className={classes.formDividerContainer}>
              <div className={classes.formDivider} />
              <Typography className={classes.formDividerWord}>or</Typography>
              <div className={classes.formDivider} />
            </div> */}
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={passwordValue}
              onChange={(e) => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Password"
              type="password"
              fullWidth
            />
            <TextField
              id="confirmPassword"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={confirmPasswordValue}
              onChange={(e) => setConfirmPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Confirm Password"
              type="password"
              fullWidth
            />
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={
                    passwordValue.length === 0 || 
                    confirmPasswordValue.length === 0
                  }
                  onClick={() => {
                    resetPassword(
                      {passwordValue, confirmPasswordValue, resetPasswordHash, expiry},
                      popupDispatch,
                      props.history,
                      setIsLoading,
                    )
                  }}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Reset
                </Button>
              )}
              {/* <Button
                color="primary"
                size="large"
                className={classes.forgetButton}
                onClick={() => props.history.push("/login")}
              >
                Back to Login
              </Button> */}
            </div>
          </React.Fragment>
        </div>
      </div>
      {
        notification ? 
          <Alert severity="success" style={{position:'absolute', bottom: 30, left: '48%'}}>{notification}</Alert>
        :
        <></>
      }      
      {
        err ? 
          <Alert severity="error" style={{position:'absolute', bottom: 30, left: '48%'}}>{err}</Alert>
        :
        <></>
      }     
    </Grid>
  );
}

export default withRouter(ResetPassword);
