import React from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import { numberWithCommas } from "../../../../healpers/utilityFunctions";

export default function Table(props) {
  return (
    <>
      <div className="ag-theme-material" style={{ height: 620, width: "100%" }}>
        <AgGridReact
          defaultColDef={{
            filter: "agNumberColumnFilter",
            resizable: true,
            sortable: true,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
            flex: 1,
          }}
          rowData={props.rows || []}
          headerHeight={50}
        >
          <AgGridColumn headerName={"Day"} field={"day"} />
          <AgGridColumn
            headerName={"Number of Requests"}
            field={"calls"}
            valueFormatter={(param) => numberWithCommas(param.value)}
          />
        </AgGridReact>
      </div>
    </>
  );
}
