import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import { Button} from "@material-ui/core";
import moment from "moment";
import { toProperCase } from "../../../healpers/utilityFunctions";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles'

export default forwardRef ( function Tables(props,ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if(gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({onlySelected: true});
      else 
      gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  const EditButtonRenderer = (buttonProps) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={() => {props.openEditModel(buttonProps?.data)}}
      >
        Edit
      </Button>
    );
  };

  const DeleteButtonRenderer = (buttonProps) => {
    console.log("buttonProps", buttonProps);
    return (
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={() => props.deleteDayPartingConfirmation(buttonProps?.data?.id)}
      >
        Delete
      </Button>
    );
  };

  const onSelectionChanged = () => {
    const selectedRows = gridApi.getSelectedRows();
    props.selectedRowData(selectedRows)
  };

  return (
    <div className="ag-theme-material" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
      <AgGridReact
        onSelectionChanged={onSelectionChanged}
        // headerCheckboxSelectionCurrentPageOnly={true} 
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        // rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
        //   sortable:true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={50}
        rowStyle={rowStyle}
        frameworkComponents={{
          editButtonRenderer: EditButtonRenderer,
          deleteButtonRenderer: DeleteButtonRenderer,
        }}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
        suppressLoadingOverlay
      >
            <AgGridColumn headerName="Type" field="type" width={120} valueFormatter={properCase}/>
            <AgGridColumn headerName="Campaign Name" field="campaignName" width={250}/>
            <AgGridColumn headerName="Selected Days" field="selectedDays" width={300} valueFormatter={weekDayFormat}/>
            <AgGridColumn headerName="Start Time" field="campaignStartTime" width={120}/>
            <AgGridColumn headerName="End Time" field="campaignEndTime" width={120}/>
            <AgGridColumn width={100} cellRenderer="deleteButtonRenderer" cellEditorParams={{cellEditorParams:"deleteButtonRenderer"}} />
      </AgGridReact>
    </div>
  );
})

const timeFormat = (params) => moment(params.value).format('LT');
const weekDayFormat = (params) => params.value.map(day => day.slice(0, 3).toUpperCase());
const properCase = (params) => params.value && toProperCase(params.value);


