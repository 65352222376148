import moment from 'moment';

/**
 * Filters the data either based on duration / min date and max date
 * @param {Array} data - Array of objects.(startDate required for the object)
 * @param {Object} date - Object with two keys(amount and unit).
 * @returns 
 */
// eslint-disable-next-line eqeqeq
export const filterThreePDataByDate = (data, date) => data.filter((ele) =>(moment(ele.reportDate) == moment(date)))


/**
 * Changes the format for a few fields in the Order Metrics Response.
 * @param {Array} data - Array of order metrics.
 * @param {string} granularity - Hour/Day. Decides the format of the date in the table.
 * @returns {Array} Returns formatted order metrics in an array format.
 */
export const modifyThreePData = (data) => {
    const { threePData, productDetails=[] } = data;
    
    /*
    const threepObj = {}
    productDetails.forEach((ele, index) => {
        threepObj[ele?.asin] = ele?.productTitle
    })
    */
    
    return threePData.map(ele => {
        return {
            date: moment(ele.reportDate).format('MM/DD/YYYY'),
            asin: ele?.asin,
            offerPrice: ele?.offerPrice,
            sellerId: ele?.sellerId,
            condition: ele?.condition,
            sellerUrl: `https://www.amazon.com/sp?seller=`+ele?.sellerId,
            title: productDetails.find(elem => elem?.asin === ele?.asin)?.productTitle,
            sku: productDetails.find(elem => elem?.asin === ele?.asin)?.sku
        }
    })
}
