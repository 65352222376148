import React, { useState, useRef, useEffect } from "react";
import { Grid, Container, Button, Backdrop, CircularProgress, Snackbar } from "@material-ui/core";
import api from "../../healpers/apiRoutes";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table";
import ScheduleDayPartingCampaign from "../amsCampaigns/components/ScheduleDayParting";
import { useVendorState } from "../../context/VendorContext";
import axios from "axios";
import { BASE_URL } from "../../healpers/api";
import { tokenConfig } from "../../context/UserContext";
import Confirmation from "./components/Confirmation";
import { Alert } from "@material-ui/lab";

export default function DayParting(props) {
  var classes = useStyles();
  const exportFunctionRef = useRef();
  const vendorState = useVendorState();

  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [editCampaignModal, setEditCampaignModal] = useState(false);
  const [createCampaignModal, setCreateCampaignModal] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [campaignData, setCampaignData] = useState([]);
  const [mainTableData, setMainTableData] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deleteAllConfirmation, setDeleteAllConfirmation] = useState(false);
  const [dayPartingId, setDayPartingId] = useState(null);
  const [snackBar, setSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  useEffect(() => {
    fetchDayPartings();
  }, [vendorState?.selected]);

  const fetchDayPartings = async () => {
    setfullPageLoader(true);
    try {
      const params = {
        vendorCode: vendorState?.selected,
      }
      const response = await axios.get(BASE_URL + api.getAMSDayPartings, tokenConfig(params));
      setMainTableData(response.data.data);
    } catch (error) {
      console.log(error)
      setSnackBar(true);
      setSnackBarMessage(error?.response?.data?.message || "Something went wrong");
    } finally {
      setOpenConfirmation(false);
      setfullPageLoader(false);
    }
  }

  const deleteDayParting = async () => {
    try {
      const body = {
        dayPartingId: dayPartingId,
        type: "CAMPAIGN"
      }
      await axios.post(BASE_URL + api.removeDayParting, body,  tokenConfig());
    } catch (error) {
      console.error(error);
      setSnackBar(true);
      setSnackBarMessage(error?.response?.data?.message || "Something went wrong");
    } finally {
      setOpenConfirmation(false);
      fetchDayPartings();
    }
  }

  const deleteAllDayParting = async () => {
    try {
      const body = {
        vendorCoce: vendorState?.selected,
        type: "VENDOR"
      }
      await axios.post(BASE_URL + api.removeDayParting, body,  tokenConfig());
    } catch (error) {
      console.error(error);
      setSnackBar(true);
      setSnackBarMessage(error?.response?.data?.message || "Something went wrong");
    } finally {
      setDeleteAllConfirmation(false);
      fetchDayPartings();
    }
  }

  return (
    <Container maxWidth={false}>
      <Backdrop className={classes.backdrop} open={fullPageLoader} onClick={() => setfullPageLoader(false)}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        fullWidth
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.header}
      >
        <Grid>
          <Typography variant="h4" weight="medium">
            Day Parting Rules
          </Typography>
        </Grid>
        <Grid>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            style={{
              textTransform: "capitalize",
              padding: "10px",
              marginRight: "20px",
              height: "max-content",
            }}
            onClick={() => {
              setCreateCampaignModal(true);
            }}
          >
            Create
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            style={{
              textTransform: "capitalize",
              padding: "10px",
              height: "max-content",
            }}
            onClick={() => {
              setDeleteAllConfirmation(true);
            }}
          >
            Delete All
          </Button>
        </Grid>
      </Grid>
      <Table
        ref={exportFunctionRef}
        tableData={mainTableData}
        openEditModel={(data) => {
          setCampaignName(data.name);
          setCampaignData(data);
          setEditCampaignModal(true);
        }}
        deleteDayPartingConfirmation={(id) => {
          setDayPartingId(id)
          setOpenConfirmation(true)
        }}
        selectedRowData={() => {}}
      />
      <ScheduleDayPartingCampaign
        campaignName={campaignName}
        open={editCampaignModal}
        campaignData={campaignData}
        close={() => {
          setEditCampaignModal(false)
          fetchDayPartings()
        }}
      />
      <ScheduleDayPartingCampaign
        isCreate
        open={createCampaignModal}
        close={() => {
          setCreateCampaignModal(false)
          fetchDayPartings()
        }}
      />
      <Confirmation
        open={openConfirmation}
        onCancel={() => setOpenConfirmation(false)}
        onConfirm={deleteDayParting}
      />
      <Confirmation
        open={deleteAllConfirmation}
        onCancel={() => setDeleteAllConfirmation(false)}
        onConfirm={deleteAllDayParting}
        isVendor={true}
      />
      <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} open={snackBar} autoHideDuration={6000} onClose={setSnackBar}>
        <Alert severity="failure" sx={{ width: '100%' }}>
            {snackBarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
