import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const CustomTooltip = (props) => {
  if (props.active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffff",
          padding: "5px",
          border: "1px solid #cccc",
        }}
      >
        <label style={{ fontWeight: "bold" }}>
          <u>
            <i>{props.label}</i>
          </u>
        </label>
        <br />
        <p>ROI: {props.payload[0] ? parseFloat(props.payload[0].value).toFixed(2) + "%" : ""}</p>
      </div>
    );
  }
  return null;
};

export default function Chart(props) {
  if(props.barChartData && props.barChartData.length !== 0)
  return (
    <BarChart
      width={1250}
      height={500}
      data={props.barChartData}
      margin={{
        top: 25,
        right: 10,
        left: 20,
        bottom: 35
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="Product ID" angle={-30} dy={25} dx={-25} interval={0} />
      <YAxis yAxisId="left" orientation="left" axisLine={false} domain={[0, 100]} />
      <Tooltip content={<CustomTooltip />} />
      <Bar yAxisId="left" dataKey="ROI" fill="#8884d8" barSize={20}/>
    </BarChart>
  )
  else return (
    <h3 style={{ textAlign: "center",margin:'25% 0'}}>
      ...No Data To Show...
    </h3>
  )
}
