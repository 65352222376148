/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
import moment from "moment"
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchMapViolatersRange, fetchMapViolaters } from "../../http/api";

import { changeDateFormat } from "../../healpers/utilityFunctions";

import useStyles from "./styles";

export default function Charts(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [dates, setDates] = useState([]);
  const [mainTableData, setmainTableData] = useState([]);
  const [selectedDate, setselectedDate] = useState('');
  const [fullPageLoader, setfullPageLoader] = useState(false);

  useEffect(() => {
    fetchMapViolatersRange(vendorState?.selected)
      .then(({ dates }) => {
        const datesArray = dates.map(ele => changeDateFormat(ele.reportDate));
        setDates(datesArray);
        setselectedDate(datesArray[datesArray.length-1])
      }).catch((err) => {
        console.log('Error', err)
        console.error('Failed to fetch the ranges')
      })
  }, [vendorState?.selected])

  useEffect(() => {
    async function fetchData() {
      try {

        setfullPageLoader(true);
        const filteredData = await fetchMapViolaters(vendorState.selected, selectedDate);
        setmainTableData(filteredData);

      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if (vendorState?.selected && selectedDate) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
    };

  }, [selectedDate, vendorState?.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Map Violators"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
				Operations
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Map Violators
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.campaignControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Date
                </InputLabel>
                <Select
                  value={selectedDate || ''}
                  onChange={(e) => {
                    setselectedDate(e.target.value);
                  }}
                  label="Select Range"
                  disabled={dates && dates.length === 0 ? true : false}
                >
                  {dates && dates.length ? (
                    dates.map((range, index) => {
                      return (
                        <MenuItem key={index} value={range}>
                          {range}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
