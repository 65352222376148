import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },  
  success: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
}))

export default forwardRef ( function Tables(props,ref) {
  
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  const getRowStyle = params => {
    if (params.node.rowIndex % 2 === 0) {
        return { background: '#F6F7FF' };
    }
  };

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{height: 610, width: '100%'}} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={props.tableData}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                },
            }}            
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={10}
            rowStyle={rowStyle}
            getRowStyle={getRowStyle}
        >
            <AgGridColumn headerName="Campaign Name" field="Campaign Name" width={160} />
            <AgGridColumn headerName="Headline" field="Headline" width={150} />
            <AgGridColumn headerName="Ad Type" field="Ad Type" />
            <AgGridColumn headerName="AdGroup Status" field="AdGroup Status" width={140} />
            <AgGridColumn headerName="Campaign ID" field="Campaign ID" width={140} />
            <AgGridColumn headerName="Campaign Status" field="Campaign Status" width={140} />
            <AgGridColumn headerName="Product ID" field="Product ID" width={140} />
            <AgGridColumn headerName="Serving Error" field="Serving Error" width={140} />
            <AgGridColumn headerName="Status" field="Status" width={140} />
            <AgGridColumn headerName="Avg CPC" field="Avg CPC" width={140} comparator={decimalComparator}/>
            <AgGridColumn headerName="Avg Pos." field="Avg Pos" width={140} comparator={decimalComparator}/>
            <AgGridColumn headerName="Clicks" field="Clicks" width={140} comparator={integerComparator} />
            <AgGridColumn headerName="Conv. Rate" field="Conversion Rate" width={140} comparator={decimalComparator} />
            <AgGridColumn headerName="Conversions" field="Conversions" width={140}  comparator={integerComparator} />
            <AgGridColumn headerName="Cost" field="Cost" width={140}  comparator={decimalComparator} />
            <AgGridColumn headerName="Cost/Conv." field="Cost/Conversion" width={140} comparator={decimalComparator} />
            <AgGridColumn headerName="CTR" field="CTR" width={140} comparator={decimalComparator} />
            <AgGridColumn headerName="Impr. (Abs.Top)%" field="Impr (Abs Top)%" width={140} comparator={decimalComparator} />
            <AgGridColumn headerName="Impr. (Top)%" field="Impr (Top)%" width={140} comparator={decimalComparator} />
            <AgGridColumn headerName="Impressions" field="Impressions" width={160} comparator={integerComparatorWithoutComma} />
            <AgGridColumn headerName="Profit" field="Profit" width={140} comparator={decimalComparator} />
            <AgGridColumn headerName="Rev." field="Revenue" width={140} comparator={decimalComparator} />
            <AgGridColumn headerName="Rev./Conv." field="Rev/Conv" width={140} comparator={decimalComparator} />
            <AgGridColumn headerName="ROI" field="ROI" width={140} cellStyle={cellStyle} comparator={decimalComparator} />
        </AgGridReact>
    </div>
  );
})

function cellStyle(params) {
  return { 
    backgroundColor: `rgba(216,233,210,${parseFloat(params.value/100)})`,
    borderBottom: '1px solid rgba(178,178,178,0.25)'
  };
}

const decimalComparator = (valueA, valueB) => parseFloat(valueA) - parseFloat(valueB);

const integerComparator = (valueA, valueB) => parseInt(valueA) - parseInt(valueB);

const integerComparatorWithoutComma = (valueA, valueB) => parseInt(valueA.replace(",","")) - parseInt(valueB.replace(",",""));
