import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  Box,
} from "@material-ui/core";
import axios from "axios";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import PeformingKeywordsTable from "./components/Table/PeformingKeywordsTable";
import NonPeforCustomerSearchTable from "./components/Table/NonPeforCustomerSearchTable";
import AsinSpendTable from "./components/Table/AsinSpendTable";
import KeywordsSpendTable from "./components/Table/KeywordsSpendTable";
import BudgetAllocationTable from "./components/Table/BudgetAllocationTable";
import KeywordsHighAcosTable from "./components/Table/KeywordsHighAcosTable";
import AsinsTopPeformingTable from "./components/Table/AsinsTopPeformingTable";
//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

export default function AMSTopPerformingKeywords() {
  const classes = useStyles();
  const exportFunctionRef = useRef();
  var vendorState = useVendorState();

  // local
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [campaignSnapshottableData, setCampaignSnapshottableData] = useState([]);
  const [keywordsWithHighSpend, setkeywordsWithHighSpend] = useState([]);
  const [lowPeformingKeywords, setlowPeformingKeywords] = useState([]);
  const [topPeformingKeywords, settopPeformingKeywords] = useState([]);
  const [keywordsWithHighAcosTableData, setkeywordsWithHighAcosTableData] = useState([]);
  const [asinsTableData, setAsinsTableData] = useState([]);
  const [asinsPeformedWellTableData, setAsinsPeformedWellTableData] = useState([]);


  const [topPeformingCampValue, setTopPeformingCampValue] = useState([])
  const [lowPeformingCampValue, setLowPeformingCampValue] = useState('')
  const [topPeformingAsinValue, setTopPeformingAsinValue] = useState('')
  const [isVendor, setIsVendor] = useState(false)

  useEffect(() => {
    try {
      setfullPageLoader(true);
      let amsReportUrl = BASE_URL + api.amsScreenTwo + '?vendorCode=' + vendorState?.selected
      axios
        .get(amsReportUrl, tokenConfig())
        .then((result) => {
          if (result.data && result.data.status) {
            setfullPageLoader(false);
            setkeywordsWithHighSpend(result.data.keywordsData)
            setCampaignSnapshottableData(result.data.campaignBudgetAllocation);
            setlowPeformingKeywords(result.data.lowPeformingKeywords);
            settopPeformingKeywords(result.data.peformingKeywords);
            setkeywordsWithHighAcosTableData(result.data.keywordsWithHighAcos);
            setAsinsTableData(result.data.asinsReportFilterData);
            setAsinsPeformedWellTableData(result.data.topPeformingAsins);
            setAsinsPeformedWellTableData(result.data.topPeformingAsins);
            setTopPeformingAsinValue(result.data.topPeformingAsinsValue)
            setIsVendor(result.data.isVendor)
            if (result.data.lowPeformingKeywords && result.data.lowPeformingKeywords.length > 0) {
              let tempResData = result.data.lowPeformingKeywords
              let holder = {};
              tempResData.forEach(function (d) {
                if (holder.hasOwnProperty(d.campaignId)) {
                  holder[d.campaignId] = {
                    cost: parseFloat(holder[d.campaignId].cost) + parseFloat(d.cost),
                    sales: parseFloat(holder[d.campaignId].sales) + parseFloat(d.sales)
                  };
                } else {
                  holder[d.campaignId] = {
                    cost: parseFloat(d.cost),
                    sales: parseFloat(d.sales)
                  };
                }
              })
              let cost = 0
              let sales = 0
              for (var prop in holder) {
                cost = parseFloat(holder[prop].cost) + parseFloat(cost)
                sales = parseFloat(holder[prop].sales) + parseFloat(sales)
              }
              setLowPeformingCampValue('Total amount spent on keywords without any sales - $' + cost.toFixed(2))
            }
            if (result.data.peformingKeywords && result.data.peformingKeywords.length > 0) {
              let tempResData = result.data.peformingKeywords
              let holder = {};
              tempResData.forEach(function (d) {
                if (holder.hasOwnProperty(d.campaignId)) {
                  holder[d.campaignId] = {
                    cost: parseFloat(holder[d.campaignId].cost) + parseFloat(d.cost),
                    sales: parseFloat(holder[d.campaignId].sales) + parseFloat(d.sales)
                  };
                } else {
                  holder[d.campaignId] = {
                    cost: parseFloat(d.cost),
                    sales: parseFloat(d.sales)
                  };
                }
              })
              let cost = 0
              let sales = 0
              let acos = 0
              for (var newprop in holder) { 
                cost = parseFloat(holder[newprop].cost) + parseFloat(cost)
                sales = parseFloat(holder[newprop].sales) + parseFloat(sales)
              }
              acos = ((cost / sales) * 100).toFixed(2)
              setTopPeformingCampValue([sales, acos])
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setCampaignSnapshottableData([]);
          setkeywordsWithHighSpend([]);
          setlowPeformingKeywords([]);
          settopPeformingKeywords([]);
          setkeywordsWithHighAcosTableData([]);
          setAsinsTableData([]);
          setAsinsPeformedWellTableData([]);
          setfullPageLoader(false);
          setTopPeformingCampValue([]);
          setLowPeformingCampValue('');
          setTopPeformingAsinValue('');
        });
    } catch (error) {
      console.log(error);
    }
  }, [vendorState?.selected]);

  return (
    <>
      <Container>
        <PageTitle
          title="Top Performing Keywords"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                className={classes.link}
              >
                AMS
              </Link>
              <Typography className={classes.link} color="primary">
              Top Performing Keywords
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography><Box className={classes.reviewBoxMeta} sx={{ fontWeight: 'bold' }}>Top Performing keywords</Box></Typography>
                </div>
              }
            >
              <PeformingKeywordsTable ref={exportFunctionRef} tableData={topPeformingKeywords} />
              {
                topPeformingCampValue.length ?
                <>
                  <Typography style={{ marginLeft: "24px", marginBottom: "10px", fontWeight: "bold" }}>Sales Generated: ${topPeformingCampValue[0]}</Typography>
                  <Typography style={{ marginLeft: "24px", marginBottom: "20px", fontWeight: "bold" }}>Average ACOS: ${topPeformingCampValue[1]}</Typography>
                </>
                : null
              }
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography><Box className={classes.reviewBoxMeta} sx={{ fontWeight: 'bold',  marginBottom: "20px" }}>Non-performing Customer search terms</Box></Typography>
                </div>
              }
            >
              <NonPeforCustomerSearchTable ref={exportFunctionRef} tableData={lowPeformingKeywords} />
              <Typography style={{ marginLeft: "24px", marginBottom: "20px", fontWeight: "bold" }}>{lowPeformingCampValue}</Typography>
            </Widget>
          </Grid>

          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography><Box className={classes.reviewBoxMeta} sx={{ fontWeight: 'bold' }}>Keywords with high spend &amp; no sales (Optimization change/recommendation)</Box></Typography>
                </div>
              }
            >
              <KeywordsSpendTable ref={exportFunctionRef} tableData={keywordsWithHighSpend} />
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography><Box className={classes.reviewBoxMeta} sx={{ fontWeight: 'bold' }}>Keywords running on high ACOS</Box></Typography>
                </div>
              }
            >
              <KeywordsHighAcosTable ref={exportFunctionRef} tableData={keywordsWithHighAcosTableData} />
            </Widget>
          </Grid>

          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography><Box className={classes.reviewBoxMeta} sx={{ fontWeight: 'bold' }}>Budget Allocation - Campaigns</Box></Typography>
                </div>
              }
            >
              <BudgetAllocationTable ref={exportFunctionRef} tableData={campaignSnapshottableData} />
            </Widget>
          </Grid>
          {!isVendor ? 
          (
            <>
            <Grid item xs={12}>
              <Widget
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
                header={
                  <div className={classes.mainChartHeader}>
                    <Typography><Box className={classes.reviewBoxMeta} sx={{ fontWeight: 'bold' }}>ASIN/Category Targeting Performed Well</Box></Typography>
                  </div>
                }
              >
                <PeformingKeywordsTable ref={exportFunctionRef} tableData={asinsPeformedWellTableData} />
                <Typography style={{ marginLeft: "3%" }}>{topPeformingAsinValue}</Typography>
              </Widget>
            </Grid>
            <Grid item xs={12}>
              <Widget
                upperTitle
                noBodyPadding
                bodyClass={classes.tableOverflow}
                header={
                  <div className={classes.mainChartHeader}>
                    <Typography><Box className={classes.reviewBoxMeta} sx={{ fontWeight: 'bold' }}>ASIN/Category target with High spend &amp; no sales</Box></Typography>
                  </div>
                }
              >
                <AsinSpendTable ref={exportFunctionRef} tableData={asinsTableData} />
              </Widget>
            </Grid>
            </>
          )
         : (<></>)}
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
          onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
