import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  TextareaAutosize,
  Backdrop,
} from "@material-ui/core";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { useVendorState } from "../../../context/VendorContext";
import axios from "axios";
import api from "../../../healpers/apiRoutes";
import useStyles from "../styles"
import { splitString } from "../../../healpers/utilityFunctions";
import { tokenConfig } from "../../../context/UserContext";
import { BASE_URL } from "../../../healpers/api";
import { JS_SEGMENT_TYPES, REPORT_TYPES } from "../../../constant/jungleScout";

export default function JSSegmentModal({ open, close, fetchSegments, edit, segmentId }) {
  const vendorState = useVendorState();
  const classes = useStyles();

  const [name, setName] = useState("");
  const [segmentName, setSegmentName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [type, setType] = useState("");
  const [customerAsin, setCustomerAsin] = useState("");
  const [competitorAsin, setCompetitorAsin] = useState("");
  const [topPerformingAsin, setTopPerformingAsin] = useState("");
  const [reportTypes, setReportTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const onModalClose = () => {
    setName("");
    setType("");
    setCustomerAsin("");
    setCompetitorAsin("");
    setTopPerformingAsin("");
  };

  const createJSSegment = async () => {
    setLoading(true);
    const marketplace = (vendorState?.selected?.split("_").reverse())[1];
    try {
      let body = {
        name: segmentName,
        type: type,
        customerASINs: customerAsin,
        competitorASINs: competitorAsin,
        topPerformingASINs: topPerformingAsin,
        vendorCode: vendorState?.selected,
        marketplace: marketplace.toLowerCase(),
      };

      await axios.post(BASE_URL + api.createJSSegment, body, tokenConfig());
      close();
      onModalClose();
      fetchSegments();
    } catch (error) {
      console.error(error);
      alert("Failed to create segment");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (edit) {
      getJSSegment();
    }
  }, [edit]);

  const getJSSegment = async () => {
    try {
      const params = {
        segmentId: segmentId,
      };
      const response = await axios.get(BASE_URL + api.getJSSegment, tokenConfig(params));
      const data = response.data.data;
      setName(data.name);
      setSegmentName(data.name);
      setCustomerAsin(data.customerASINs);
      setCompetitorAsin(data.competitorASINs);
      setTopPerformingAsin(data.topPerformingASINs);
    } catch (error) {
      console.error(error);
      alert("Failed to get segment");
    }
  }

  const editJSSegment = async () => {
    try {
      let body = {
        segmentId,
        customerASINs: customerAsin,
        competitorASINs: competitorAsin,
        topPerformingASINs: topPerformingAsin,
        reportTypes
      };

      await axios.post(BASE_URL + api.editJSSegment, body, tokenConfig());
      close();
      onModalClose();
      fetchSegments();
      console.log("body", body);
    } catch (error) {
      console.error(error);
      alert("Failed to create segment");
    }
  };

  const handleName = async (name) => {
    const segmentName = `SD_${type === JS_SEGMENT_TYPES.TEMPORARY ? "TEMP_" : ""}${vendorState?.selected}_${name.toUpperCase()}`;
    try {
      let body = {
        name: segmentName,
      };
      setName(name);
      setSegmentName(segmentName);
      await axios.post(BASE_URL + api.checkJSSegment, body, tokenConfig());
      setNameError(false);
    } catch (error) {
      console.error(error);
      setName(name);
      setSegmentName(segmentName);
      setNameError(true);
    }
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          close();
          onModalClose();
        }}
        styles={{ modal: { width: 520, borderRadius: 10 } }}
        center
        closeOnOverlayClick
      >
        <Backdrop />
        <Grid container>
          <Typography style={{ fontSize: 16, fontWeight: 600 }}>
            Create JS Segment
          </Typography>
        </Grid>
        {
          edit ? null :
          <Grid container style={{ paddingTop: "10px" }}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="advertising-goal-label">Type</InputLabel>
              <Select
                labelId="advertising-goal-label"
                value={type}
                onChange={(event) => {
                  setType(event.target.value);
                }}
                name="type"
                label="Type"
              >
                <MenuItem value={JS_SEGMENT_TYPES.TEMPORARY}>Temporary Segment</MenuItem>
                <MenuItem value={JS_SEGMENT_TYPES.PERMANENT}>Permanent Segment</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        }
        {
        edit ? null : 
          <Grid container style={{ paddingTop: "10px" }}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                type="text"
                value={name}
                id="ja-segment-name"
                name="name"
                onChange={(event) => handleName(event.target.value)}
                label="Enter the Segment Name"
                variant="outlined"
              />
            </FormControl>
          </Grid>
        }
        {
          <>
            { segmentName &&
              <Typography style={{ marginTop: 5, fontSize: 16, fontWeight: 600 }}>
                Segment Name: {segmentName}
              </Typography>
            }
            {
              nameError && 
              <Typography style={{ marginTop: 5, fontSize: 16, fontWeight: 600, color: "red" }}>
                Name already exists
              </Typography>
            }
          </>
        }
        {/* <Grid container style={{ paddingTop: "10px" }}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="advertising-goal-label">Report Type</InputLabel>
            <Select
              labelId="advertising-goal-label"
              value={reportTypes}
              onChange={(event) => {
                setReportTypes(event.target.value);
              }}
              name="reportTypes"
              label="Report Types"
              multiple
            >
              {REPORT_TYPES.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}
        <Grid container style={{ paddingTop: "10px" }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <Grid>
              <Typography color="textPrimary" variant="subtitle1">
                Customer ASINs:
              </Typography>
            </Grid>
            <TextareaAutosize
              maxRows={4}
              placeholder="Enter the Customer’s ASINs"
              value={customerAsin}
              onChange={(event) => {
                setCustomerAsin(splitString(event.target.value));
              }}
              className={classes.textarea}
            />
          </FormControl>
        </Grid>
        <Grid container style={{ paddingTop: "10px" }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <Grid>
              <Typography color="textPrimary" variant="subtitle1">
                Competitor ASINs:
              </Typography>
            </Grid>
            <TextareaAutosize
              maxRows={4}
              placeholder="Enter the Competitor’s ASINs"
              value={competitorAsin}
              onChange={(event) => {
                setCompetitorAsin(splitString(event.target.value));
              }}
              className={classes.textarea}
            />
          </FormControl>
        </Grid>
        <Grid container style={{ paddingTop: "10px", paddingBottom: "30px" }}>
          <FormControl fullWidth sx={{ m: 1 }}>
            <Grid>
              <Typography color="textPrimary" variant="subtitle1">
                Top Performing ASINs:
              </Typography>
            </Grid>
            <TextareaAutosize
              maxRows={4}
              placeholder="Enter the Top 10 selling ASINs for the sub-category"
              value={topPerformingAsin}
              onChange={(event) => {
                setTopPerformingAsin(splitString(event.target.value));
              }}
              className={classes.textarea}
            />
          </FormControl>
        </Grid>
        <Grid
          container
          fullWidth
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={nameError || loading}
            onClick={edit ? editJSSegment : createJSSegment }
          >
            {edit ? "Edit" : "Create"} Segment
          </Button>
        </Grid>
      </Modal>
    </div>
  );
}
