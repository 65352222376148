import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";

//icons
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("All");

  const fetchEmptyDomains = () => {
    setfullPageLoader(true);
    axios
      .get(BASE_URL + api.listEmptyDomains, {
        params: { status: selectedStatus },
      })
      .then((res) => {
        setmainTableData(res.data?.data);
        setfullPageLoader(false);
      })
      .catch((err) => {
        console.log(err);
        seterr(err.response?.data?.msg);
        setTimeout(() => seterr(null), 3000);
        setfullPageLoader(false);
      });
  };

  useEffect(() => {
    fetchEmptyDomains();
  }, [userState.userData, selectedStatus]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Empty Domains"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Brand Finder
              </Link>
              <Typography className={classes.link} color="primary">
                Empty Domains
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                color="primary"
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Status
                </InputLabel>
                <Select
                  label="Status"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select"
                  value={selectedStatus}
                  onChange={(event) => setSelectedStatus(event.target.value)}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Default"}>Default</MenuItem>
                  <MenuItem value={"Valid"}>Valid</MenuItem>
                  <MenuItem value={"Invalid"}>Invalid</MenuItem>
                  <MenuItem value={"Blocked"}>Blocked</MenuItem>
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={mainTableData}
                classes={classes}
                fetchEmptyDomains={fetchEmptyDomains}
                setfullPageLoader={setfullPageLoader}
                setnotification={setnotification}
                seterr={seterr}
              />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", top: 800, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", top: 800, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}
