import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
import moment from 'moment';
import { numberWithCommas, renderFavicon } from "../../../../healpers/utilityFunctions";
import { Button } from "@material-ui/core";

export default forwardRef(function Tables({ tableData, openASINRecalculateModal, vendorCode }, ref) {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const rowStyle = { background: 'white' };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const FavIconRenderer = (iconProps) => {
    return (
      <img width={50} src={iconProps.data.favicon} alt={iconProps.data.asin} valueFormatter={removeDecimals} />
    );
  };

  const findForecasthealth = ({ data }, field) => {
    if(data?.forecastHealth) {
      const forecastHealth = data?.forecastHealth.find(item => item.forecastMonth === field);
      return `${numberWithCommas(forecastHealth?.unitsSold || 0)} (${numberWithCommas(forecastHealth?.projection || 0)})`
    } else {
      return 0;
    }
  }

  const findCellColor = ({ data }, field) => {
    const cellColor = { color: "red"};
    if(data?.forecastHealth) {
      const forecastHealth = data?.forecastHealth.find(item => item.forecastMonth === field);
      if(forecastHealth?.unitsSold > forecastHealth?.projection) {
        cellColor.color = "green";
      }
    }
    return cellColor;
  }

  const renderForecastProjection = () => {
    let array =[]
    for (let i = 1; i < 12; i++) {
    const field = moment().subtract(i, "month").format("MM/YYYY");
    const headerName = moment().subtract(i,'month').format('MMMM')
      array.push( <AgGridColumn
        headerName={headerName}
        valueGetter={params => findForecasthealth(params, field)}
        width={150}
        cellStyle= {params => findCellColor(params, field)}
      />)
    }
    return array
  }

  const RecalculateProjections = (props) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        style={{ textTransform: "capitalize" }}
        onClick={() => openASINRecalculateModal(props.data.asin)}
      >
        Recalculate
      </Button>
    );
  };
  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          faviconRenderer: FavIconRenderer,
          recalculateProjections: RecalculateProjections
        }}
      >
        <AgGridColumn headerName={''} field={'asin'} width={100} cellRenderer={({value}) => renderFavicon(value, vendorCode)} pinned='left'/>
        <AgGridColumn headerName={'ASIN'} field={'asin'} width={150} pinned='left'/>
        {tableData.length > 0 && renderForecastProjection()}
      </AgGridReact>
    </div>
  );
})

const removeDecimals = (params) => {
  if(params?.value) {
    return parseFloat(params?.value).toFixed(0);
  } 
}