import React, {useEffect, useRef} from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js";
import Wordcloud from "highcharts/modules/wordcloud";
import accessibility from "highcharts/modules/accessibility.js";

// context
import { useLayoutState } from "../../../../context/LayoutContext";

//helpers
import { numberWithCommas } from "../../../../healpers/utilityFunctions";

highchartsMore(Highcharts);
Wordcloud(Highcharts)
accessibility(Highcharts);

Highcharts.seriesTypes.wordcloud.prototype.deriveFontSize = function (relativeWeight) {
  var maxFontSize = 11;
  return Math.floor(maxFontSize * relativeWeight) + 7; 
};

export default function WordChart(props) {

  var COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];

  // global
  var layoutState = useLayoutState();

  //local
  const chartRef = useRef();

  // To make map responsive
  useEffect(() => {
      if(chartRef.current) {
        setTimeout(() => {
            Highcharts.charts[chartRef.current.chart.index].reflow()
        }, 200);
      }
  }, [layoutState.isSidebarOpened])

  const WordChartOptions = {
    series: [{
        type: 'wordcloud',
        data: props.wordCloudData,
        name: 'Occurrences',
    }],
    minFontSize:20,
    tooltip: {
      formatter: function () {
        return `<b><i>${this.point.options.name}</i></b></br>Rate of Interest: ${numberWithCommas(parseFloat((this.point.options.weight)).toFixed(2))}%`
      }
    },
    title: {
        text: null
    },
    credits: {
      enabled: false
    },
};

  if(props.wordCloudData && props.wordCloudData.length !== 0)
  return (
    <HighchartsReact highcharts={Highcharts} options={WordChartOptions} ref={chartRef}/>
  ) 
  else return (
    <h3 style={{ textAlign: "center",margin:'25% 0'}}>
      ...No Data To Show...
    </h3>
  )
};
