import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControlLabel,
  TextField,
  Divider,
  Checkbox,
  Input,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  ListItemText,
  Chip,
} from "@material-ui/core";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

//context
import { tokenConfig } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();

  // local
  const [email, setemail] = useState(null);
  const [password, setpassword] = useState(null);
  const [firstName, setfirstName] = useState(null);
  const [lastName, setlastName] = useState(null);
  const [orgName, setorgName] = useState(null);
  const [orgPos, setorgPos] = useState(null);
  const [mobileNumber, setmobileNumber] = useState(null);
  const [customerConfigs, setCustomerConfigs] = useState([]);
  const [filteredCustomerConfig, setFilteredCustomerConfigs] = useState([]);
  const [selectedVendorCodes, setSelectedVendorCodes] = useState([]);
  
  const handleChange = (event) => {
    const vendorCodes = [...selectedVendorCodes, event.target.value];
    setSelectedVendorCodes([...new Set(vendorCodes)]);
    filterCustomerConfig();
  };

  const handleDelete = (vendorCode) => {
    setSelectedVendorCodes(selectedVendorCodes.filter(code => code !== vendorCode));
    filterCustomerConfig();
  }

  const filterCustomerConfig = () => {
    const filteredCustomerConfig = customerConfigs.filter(config => !selectedVendorCodes.includes(config.vendorCode));
    setFilteredCustomerConfigs(filteredCustomerConfig);
  }

  useEffect(() => {
    filterCustomerConfig();
  }, [selectedVendorCodes])

  async function listCustomerConfigs() {
    try {
      const response = await axios.get(BASE_URL + api.listCustomerConfigs, tokenConfig());
      setCustomerConfigs(response.data);
      listUserVendorConfigs();
    } catch (err) {
      console.log(err);
    }
  }

  async function listUserVendorConfigs() {
    try {
      const response = await axios.get(BASE_URL + api.listUserVendorConfigs, tokenConfig());
      setSelectedVendorCodes(response.data.map(vendor => vendor.vendorCode));
      filterCustomerConfig();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    listCustomerConfigs();
  }, []);

  const [salesPermi, setsalesPermi] = useState({
    categories: {
      shippedCOGS: false,
      goalsAndPeformance: false,
      geographicInsights: false,
      ratingsAndReviews: false,
      allRatingsAndReview: false,
      keyMetricsVC: false,
    },
    allowed: false,
  });
  const [scAndCbPermi, setscAndCbPermi] = useState({
    categories: {
      sellerCentral: false,
      vendorCentral: false,
    },
    allowed: false,
  });
  const [biPermi, setbiPermi] = useState({
    categories: {
      jsSegment: false,
      competitionBenchmarking: false,
      productsAndBrands: false,
      segments: false,
      competitionDetailedSales: false,
      shareOfVoice: false,
      bcg: {
        bcgOverview: false,
        bcgMatrix: false,
      },
      forecast: {
        projections: false,
        healthCheck: false,
        segmentProjections: false,
        segmentForecastHealth: false
      }
    },
    allowed: false,
  });
  const [advertisingPermi, setadvertisingPermi] = useState({
    categories: {
      reachTrend: false,
      dayPartingDay: false,
      dayPartingHour: false,
      dayPartingAsinsDay: false,
      dayPartingAsins: false,
      priceWar: false,
      productsNotServing: false,
      CampaignsOutOfBudget: false,
    },
    allowed: false,
  });
  const [amsPermi, setAMSPermi] = useState({
    categories: {
      createCampaign: false,
      campaigns: false,
      vendorSettings: false,
      salesAnalysis: false,
      topPerformingKeywords: false,
      reachTrend: false,
      amsProfiles: false,
      amsDayParting: false,
      budgetChanges: false
    },
    allowed: false,
  });
  const [opsPermi, setopsPermi] = useState({
    categories: {
      suppressedAsins: false,
      eventNotifications: false,
      sinventoryManagement: false,
      fbaInventoryForecast: false,
      threePReport: false,
      mapViolators: false,
      strandedInventory: false,
      accountHealth: false,
      fbaReimbursements: false,
    },
    allowed: false,
  });
  const [creativePermi, setcreativePermi] = useState({
    categories: {
      detailedPage: false,
      aPlusPages: false,
      seo: false,
    },
    allowed: false,
  });
  const [productDetails, setproductDetails] = useState({
    categories: {
      productDetails: false,
      productHiResImages: false,
      asingSkuMapping: false,
      uploadHistoricData: false,
      cohesity: false,
      brandFinder: false,
      blockDomains: false,
      contactFinder: false,
      listBrands: false,
      listContacts: false,
      listEmptyDomains: false,
      searchContacts: false,
      updateItem: false,
      brandFinderGlobal: false,
      catalogItems: false,
      catalogActivity: false,
      cohesityLogs: false,
    },
    allowed: false,
  });
  const [usersPermi, setusersPermi] = useState({
    categories: {
      usersList: false,
      usersAdd: false,
      usersEdit: false,
    },
    allowed: false,
  });
  const [invoiceReconPermi, setinvoiceReconPermi] = useState({
    categories: {
      sellerCentralInvoices: false,
      vendorCentralInvoices: false,
    },
    allowed: false,
  });
  const [uncategorizedReports, setuncategorizedReports] = useState({
    categories: {
      brandSegmentsByDay: false,
      keywordsByAsin: false,
      salesAndRank: false,
      amsScreenOne: false,
      amsScreenTwo: false
    },
    allowed: false,
  });
  const [onboardingPermi, setOnboardingPermi] = useState({
    categories: {
      cronsManagement: false,
      onboarding: false,
      jsSegment: false,
      amsOnboarding: false,
      dapiStatistics: false,
    },
    allowed: false,
  });
  const [amsReportsPermi, setAmsReportsPermi] = useState({
    categories: {
      campaignPerformance: false,
      asinPerformance: false,
      keywordPerformance: false,
      searchTermPerformance: false,
      targetPerformance: false,
      budgetChanges: false,
      placementAnalysis: false,
      reachTrend: false,
    },
    allowed: false,
  });
  const [cronsPermi, setcronsPermi] = useState({
    categories: {
      cronsEdit: false,
    },
    allowed: false,
  });
  const [amazonAdvertisingPermi, setAmazonAdvertisingPermi] = useState({
    categories: {
      settingsAndOptimisation: {
       campaignsOptimisation: false,
       createCampaign: false,
       salesAnalysis: false,
       topPerformingKeywords: false,
       campaignsSettings: false,
       amsProfiles: false,
       dayPartingRules: false,
       budgetChanges: false
      },
      reports: {
        campaignPerformance: false,
        asinPerformance: false,
        keywordPerformance: false,
        searchTermPerformance: false,
        targetPerformance: false,
        budgetChanges: false,
        placementAnalysis: false,
        reachTrend: false
      },
    },
    allowed: false,
  });

  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  const validateData = () => {
    var vendorsFlag = false;

    if (!validateEmail(email)) {
      seterr("Email address is invalid!!");
      setTimeout(() => {
        seterr(null);
      }, 2000);
    } else if (
      !salesPermi &&
      !scAndCbPermi &&
      !biPermi &&
      !advertisingPermi &&
      !opsPermi &&
      !creativePermi &&
      !productDetails &&
      !usersPermi &&
      !cronsPermi &&
      !onboardingPermi &&
      !amsReportsPermi &&
      !amazonAdvertisingPermi
    ) {
      seterr("Grant atleast one permission");
      setTimeout(() => {
        seterr(null);
      }, 2000);
    } else if (vendorsFlag) {
      seterr("Vendor Details missing");
      setTimeout(() => {
        seterr(null);
      }, 2000);
    } else {
      createUser();
    }
  };

  const createUser = () => {
    var postBody = {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      orgName: orgName,
      orgPosition: orgPos,
      mobNumber: mobileNumber,
      permissions_module_level: {
        sales: salesPermi,
        businessIntelligence: biPermi,
        creative: creativePermi,
        advertising: advertisingPermi,
        operations: opsPermi,
        invoiceReconciliations: invoiceReconPermi,
        shortageclaimsAndChargebacks: scAndCbPermi,
        uncategorizedReports: uncategorizedReports,
        catalog: productDetails,
        users: usersPermi,
        cronsManagement: cronsPermi,
        onboarding: onboardingPermi,
        amsReports: amsReportsPermi,
        amazonAdvertising: amazonAdvertisingPermi,
      },
      userVendorConfigs: selectedVendorCodes
    };
    axios({
      method: "post",
      url: BASE_URL + api.registerUser,
      data: postBody,
      headers: tokenConfig(),
    })
      .then((res) => {
        setnotification(res.data.msg);
        setTimeout(() => {
          setnotification(null);
        }, 2000);
      })
      .catch((error) => {
        console.log(`Add User Error`, error);
        seterr(error?.response?.data?.msg)
      });
  };

  const updatePermissionsUser = (
    state,
    moduleName,
    isModuleLevelPermission,
    permissionsName,
    nested = false,
    subModule = null
  ) => {
    if (isModuleLevelPermission) {
      moduleName((prevState) => ({
        ...prevState,
        allowed: !prevState.allowed,
      }));
      return;
    }

    if (nested) {
      moduleName((prevState) => ({
        ...prevState,
        categories: {
          ...prevState.categories,
          [subModule]: {
            ...prevState.categories[subModule],
            [permissionsName]: !prevState.categories[subModule][permissionsName]
          },
        },
      }));
    } else {
      moduleName((prevState) => ({
        ...prevState,
        categories: {
          ...prevState.categories,
          [permissionsName]: !prevState.categories[permissionsName],
        },
      }));
    }
  };

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Add User"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Users
              </Link>
              <Typography className={classes.link} color="primary">
                Add User
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
            >
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography color="textPrimary" variant="h3">
                    Account Information
                  </Typography>
                  <br />
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={email}
                    onChange={(e) => setemail(e.target.value)}
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={password}
                    onChange={(e) => setpassword(e.target.value)}
                    label="Password"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography color="textPrimary" variant="h3">
                    Personal Information
                  </Typography>
                  <br />
                  <Divider />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={firstName}
                    onChange={(e) => setfirstName(e.target.value)}
                    label="First Name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={lastName}
                    onChange={(e) => setlastName(e.target.value)}
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={orgName}
                    onChange={(e) => setorgName(e.target.value)}
                    label="Organisation Name"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={orgPos}
                    onChange={(e) => setorgPos(e.target.value)}
                    label="Organisation Position"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    value={mobileNumber}
                    onChange={(e) => setmobileNumber(e.target.value)}
                    label="Mobile Number"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography color="textPrimary" variant="h3">
                        Modules
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography color="textPrimary" variant="h3">
                        Permissions
                      </Typography>
                    </Grid>
                  </Grid>
                  <br />
                  <Divider />
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salesPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              salesPermi,
                              setsalesPermi,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Sales"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salesPermi.categories.shippedCOGS}
                          disabled={!salesPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              salesPermi,
                              setsalesPermi,
                              false,
                              "shippedCOGS"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Shipped COGs -VC"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salesPermi.categories.allRatingsAndReview}
                          disabled={!salesPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              salesPermi,
                              setsalesPermi,
                              false,
                              "allRatingsAndReview"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="All Ratings & Reviews"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salesPermi.categories.geographicInsights}
                          disabled={!salesPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              salesPermi,
                              setsalesPermi,
                              false,
                              "geographicInsights"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Geographic Insights"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salesPermi.categories.goalsAndPeformance}
                          disabled={!salesPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              salesPermi,
                              setsalesPermi,
                              false,
                              "goalsAndPeformance"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Goals & Peformance"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salesPermi.categories.ratingsAndReviews}
                          disabled={!salesPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              salesPermi,
                              setsalesPermi,
                              false,
                              "ratingsAndReviews"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Ratings & Reviews"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={salesPermi.categories.keyMetricsVC}
                          disabled={!salesPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              salesPermi,
                              setsalesPermi,
                              false,
                              "keyMetricsVC"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Key Metrics VC"
                    />
                    <Divider />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={biPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              biPermi,
                              setbiPermi,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Business intelligance"
                    />
                  </Grid>
                  <Grid item xs={6}>
                  <FormControlLabel
                      control={
                        <Checkbox
                          checked={biPermi.categories.jsSegment}
                          disabled={!biPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              biPermi,
                              setbiPermi,
                              false,
                              "jsSegment"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="JS Segment"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={biPermi.categories.competitionBenchmarking}
                          disabled={!biPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              biPermi,
                              setbiPermi,
                              false,
                              "competitionBenchmarking"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Competition Benchmarking"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={biPermi.categories.productsAndBrands}
                          disabled={!biPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              biPermi,
                              setbiPermi,
                              false,
                              "productsAndBrands"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="JS Products & Brands"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={biPermi.categories.competitionDetailedSales}
                          disabled={!biPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              biPermi,
                              setbiPermi,
                              false,
                              "competitionDetailedSales"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Competition Detailed Sales"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={biPermi.categories.shareOfVoice}
                          disabled={!biPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              biPermi,
                              setbiPermi,
                              false,
                              "shareOfVoice"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Share of Voice"
                    />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.segments}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "segments"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Segments"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.bcg.bcgOverview}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "bcgOverview",
                          true,
                          "bcg"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="BCG Overview"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.bcg.bcgMatrix}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "bcgMatrix",
                          true,
                          "bcg"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="BCG Matrix"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.bcg.projections}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "projections",
                          true,
                          "forecast"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Projections"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.bcg.healthCheck}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "healthCheck",
                          true,
                          "forecast"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Health Check"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.bcg.segmentProjections}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "segmentProjections",
                          true,
                          "forecast"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Segment Projections"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={biPermi.categories.bcg.segmentForecastHealth}
                      disabled={!biPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          biPermi,
                          setbiPermi,
                          false,
                          "segmentForecastHealth",
                          true,
                          "forecast"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Segment Forecast Health"
                />
                    <Divider />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={creativePermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              creativePermi,
                              setcreativePermi,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Creative"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={creativePermi.categories.detailedPage}
                          disabled={!creativePermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              creativePermi,
                              setcreativePermi,
                              false,
                              "detailedPage"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Detailed Pages"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={creativePermi.categories.aPlusPages}
                          disabled={!creativePermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              creativePermi,
                              setcreativePermi,
                              false,
                              "aPlusPages"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="A+ Pages"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={creativePermi.categories.seo}
                          disabled={!creativePermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              creativePermi,
                              setcreativePermi,
                              false,
                              "seo"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="SEO"
                    />

                    <Divider />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={advertisingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              advertisingPermi,
                              setadvertisingPermi,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Advertising"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={advertisingPermi.categories.dayPartingDay}
                          disabled={!advertisingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              advertisingPermi,
                              setadvertisingPermi,
                              false,
                              "dayPartingDay"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Day Parting — Day"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={advertisingPermi.categories.dayPartingHour}
                          disabled={!advertisingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              advertisingPermi,
                              setadvertisingPermi,
                              false,
                              "dayPartingHour"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Day Parting — Hour"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            advertisingPermi.categories.dayPartingAsinsDay
                          }
                          disabled={!advertisingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              advertisingPermi,
                              setadvertisingPermi,
                              false,
                              "dayPartingAsinsDay"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Day Parting — ASINs Day"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={advertisingPermi.categories.dayPartingAsins}
                          disabled={!advertisingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              advertisingPermi,
                              setadvertisingPermi,
                              false,
                              "dayPartingAsins"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Day Parting — ASINs Hour"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={advertisingPermi.categories.priceWar}
                          disabled={!advertisingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              advertisingPermi,
                              setadvertisingPermi,
                              false,
                              "priceWar"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Price War"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            advertisingPermi.categories.productsNotServing
                          }
                          disabled={!advertisingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              advertisingPermi,
                              setadvertisingPermi,
                              false,
                              "productsNotServing"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Products Not Serving"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            advertisingPermi.categories.CampaignsOutOfBudget
                          }
                          disabled={!advertisingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              advertisingPermi,
                              setadvertisingPermi,
                              false,
                              "CampaignsOutOfBudget"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Campaigns out of Budget"
                    />

                    <Divider />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsPermi,
                              setAMSPermi,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="AMS"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsPermi.categories.createCampaign}
                          disabled={!amsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsPermi,
                              setAMSPermi,
                              false,
                              "createCampaign"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Create Campaign"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsPermi.categories.campaigns}
                          disabled={!amsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsPermi,
                              setAMSPermi,
                              false,
                              "campaigns"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="AMS Campaigns"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsPermi.categories.vendorSettings}
                          disabled={!amsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsPermi,
                              setAMSPermi,
                              false,
                              "vendorSettings"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Vendor Settings"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsPermi.categories.salesAnalysis}
                          disabled={!amsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsPermi,
                              setAMSPermi,
                              false,
                              "salesAnalysis"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Sales Analysis"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsPermi.categories.topPerformingKeywords}
                          disabled={!amsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsPermi,
                              setAMSPermi,
                              false,
                              "topPerformingKeywords"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Top Performing Keywords"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsPermi.categories.reachTrend}
                          disabled={!amsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsPermi,
                              setAMSPermi,
                              false,
                              "reachTrend"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Reach Trend"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsPermi.categories.amsDayParting}
                          disabled={!amsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsPermi,
                              setAMSPermi,
                              false,
                              "amsDayParting"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="AMS Day Parting"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsPermi.categories.amsProfiles}
                          disabled={!amsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsPermi,
                              setAMSPermi,
                              false,
                              "amsProfiles"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="AMS Profiles"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsPermi.categories.budgetChanges}
                          disabled={!amsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsPermi,
                              setAMSPermi,
                              false,
                              "budgetChanges"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Budget Changes"
                    />
                    <Divider />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              opsPermi,
                              setopsPermi,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Operations"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opsPermi.categories.suppressedAsins}
                          disabled={!opsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              opsPermi,
                              setopsPermi,
                              true,
                              "suppressedAsins"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Search Suppressed ASINs"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opsPermi.categories.eventNotifications}
                          disabled={!opsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              opsPermi,
                              setopsPermi,
                              true,
                              "eventNotifications"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Event Notifications"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opsPermi.categories.sinventoryManagement}
                          disabled={!opsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              opsPermi,
                              setopsPermi,
                              true,
                              "sinventoryManagement"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Inventory Management"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opsPermi.categories.fbaInventoryForecast}
                          disabled={!opsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              opsPermi,
                              setopsPermi,
                              true,
                              "fbaInventoryForecast"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Fulfilled by Amazon (FBA) Inventory Forecast"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opsPermi.categories.threePReport}
                          disabled={!opsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              opsPermi,
                              setopsPermi,
                              true,
                              "threePReport"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="3P Report"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opsPermi.categories.mapViolators}
                          disabled={!opsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              opsPermi,
                              setopsPermi,
                              true,
                              "mapViolators"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Map Violators"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opsPermi.categories.strandedInventory}
                          disabled={!opsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              opsPermi,
                              setopsPermi,
                              true,
                              "strandedInventory"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Stranded Inventory"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opsPermi.categories.accountHealth}
                          disabled={!opsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              opsPermi,
                              setopsPermi,
                              true,
                              "accountHealth"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Account Health"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={opsPermi.categories.fbaReimbursements}
                          disabled={!opsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              opsPermi,
                              setopsPermi,
                              true,
                              "fbaReimbursements"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="FBA Reimbursements"
                    />

                    <Divider />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={invoiceReconPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              invoiceReconPermi,
                              setinvoiceReconPermi,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Invoices Reconciliation"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            invoiceReconPermi.categories.sellerCentralInvoices
                          }
                          disabled={!invoiceReconPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              invoiceReconPermi,
                              setinvoiceReconPermi,
                              false,
                              "sellerCentralInvoices"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Seller Central Invoices"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            invoiceReconPermi.categories.vendorCentralInvoices
                          }
                          disabled={!invoiceReconPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              invoiceReconPermi,
                              setinvoiceReconPermi,
                              false,
                              "vendorCentralInvoices"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Vendor Central Invoices"
                    />

                    <Divider />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scAndCbPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              scAndCbPermi,
                              setscAndCbPermi,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Shortageclaims & Chargebacks"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scAndCbPermi.categories.sellerCentral}
                          disabled={!scAndCbPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              scAndCbPermi,
                              setscAndCbPermi,
                              false,
                              "sellerCentral"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Seller Central"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={scAndCbPermi.categories.vendorCentral}
                          disabled={!scAndCbPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              scAndCbPermi,
                              setscAndCbPermi,
                              false,
                              "sellerCentral"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Vendor Central"
                    />

                    <Divider />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={uncategorizedReports.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              uncategorizedReports,
                              setuncategorizedReports,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Uncategorised Reports"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            uncategorizedReports.categories.brandSegmentsByDay
                          }
                          disabled={!uncategorizedReports.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              uncategorizedReports,
                              setuncategorizedReports,
                              false,
                              "brandSegmentsByDay"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Brand Segments By Day"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            uncategorizedReports.categories.keywordsByAsin
                          }
                          disabled={!uncategorizedReports.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              uncategorizedReports,
                              setuncategorizedReports,
                              false,
                              "keywordsByAsin"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Keywords By ASIN"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={uncategorizedReports.categories.salesAndRank}
                          disabled={!uncategorizedReports.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              uncategorizedReports,
                              setuncategorizedReports,
                              false,
                              "salesAndRank"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Sales and Rank"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={uncategorizedReports.categories.amsScreenOne}
                          disabled={!uncategorizedReports.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              uncategorizedReports,
                              setuncategorizedReports,
                              false,
                              "amsScreenOne"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="AMS Screen 1"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={uncategorizedReports.categories.amsScreenTwo}
                          disabled={!uncategorizedReports.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              uncategorizedReports,
                              setuncategorizedReports,
                              false,
                              "amsScreenTwo"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="AMS Screen 2"
                    />

                    <Divider />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Catalog"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.productHiResImages}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "productHiResImages"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Product Hi-Res Images"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.asingSkuMapping}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "asingSkuMapping"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="ASIN SKU Mapping"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.uploadHistoricData}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "uploadHistoricData"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Upload Historic Data"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.cohesity}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "cohesity"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Cohesity"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.brandFinder}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "brandFinder"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Brand Finder"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.blockDomains}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "blockDomains"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Block Domains"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.contactFinder}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "contactFinder"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Contact Finder"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.listBrands}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "listBrands"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="List Brands"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.listContacts}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "listContacts"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="List Contacts"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.listEmptyDomains}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "listEmptyDomains"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="List Empty Domains"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.updateItem}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "updateItem"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Update Item"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.catalogItems}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "catalogItems"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Catalog Items"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.catalogActivity}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "catalogActivity"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Catalog Activity"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.cohesityLogs}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "cohesityLogs"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Cohesity Logs"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.brandFinderGlobal}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "brandFinderGlobal"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Brand Finder Global"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={productDetails.categories.searchContacts}
                          disabled={!productDetails.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              productDetails,
                              setproductDetails,
                              false,
                              "searchContacts"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Search Contacts"
                    />

                    <Divider />
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={usersPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              usersPermi,
                              setusersPermi,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Users"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={usersPermi.categories.usersAdd}
                          disabled={!usersPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              usersPermi,
                              setusersPermi,
                              false,
                              "usersAdd"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Users Add"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={usersPermi.categories.usersEdit}
                          disabled={!usersPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              usersPermi,
                              setusersPermi,
                              false,
                              "usersEdit"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Users Edit"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={usersPermi.categories.usersList}
                          disabled={!usersPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              usersPermi,
                              setusersPermi,
                              false,
                              "usersList"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Users List"
                    />

                    <Divider />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsReportsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsReportsPermi,
                              setAmsReportsPermi,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="AMS Reports"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsReportsPermi.categories.campaignPerformance}
                          disabled={!amsReportsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsReportsPermi,
                              setAmsReportsPermi,
                              false,
                              "campaignPerformance"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Campaign Performance"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsReportsPermi.categories.asinPerformance}
                          disabled={!amsReportsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsReportsPermi,
                              setAmsReportsPermi,
                              false,
                              "asinPerformance"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="ASIN Performance"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsReportsPermi.categories.keywordPerformance}
                          disabled={!amsReportsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsReportsPermi,
                              setAmsReportsPermi,
                              false,
                              "keywordPerformance"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Keyword Performance"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsReportsPermi.categories.searchTermPerformance}
                          disabled={!amsReportsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsReportsPermi,
                              setAmsReportsPermi,
                              false,
                              "searchTermPerformance"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Search Term Performance"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsReportsPermi.categories.targetPerformance}
                          disabled={!amsReportsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsReportsPermi,
                              setAmsReportsPermi,
                              false,
                              "targetPerformance"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Targeting Performance"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsReportsPermi.categories.budgetChanges}
                          disabled={!amsReportsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsReportsPermi,
                              setAmsReportsPermi,
                              false,
                              "budgetChanges"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Budget Changes"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsReportsPermi.categories.placementAnalysis}
                          disabled={!amsReportsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsReportsPermi,
                              setAmsReportsPermi,
                              false,
                              "placementAnalysis"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Placement Analysis"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={amsReportsPermi.categories.reachTrend}
                          disabled={!amsReportsPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              amsReportsPermi,
                              setAmsReportsPermi,
                              false,
                              "reachTrend"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Reach Trend"
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={onboardingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              onboardingPermi,
                              setOnboardingPermi,
                              true,
                              null
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Onboarding"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={onboardingPermi.categories.cronsManagement}
                          disabled={!onboardingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              onboardingPermi,
                              setOnboardingPermi,
                              false,
                              "cronsManagement"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Crons Management"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            onboardingPermi.categories.uploadHistoricData
                          }
                          disabled={!onboardingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              onboardingPermi,
                              setOnboardingPermi,
                              false,
                              "uploadHistoricData"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="Upload Historic Data"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={onboardingPermi.categories.jsSegment}
                          disabled={!onboardingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              onboardingPermi,
                              setOnboardingPermi,
                              false,
                              "jsSegment"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="JS Segment"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={onboardingPermi.categories.amsOnboarding}
                          disabled={!onboardingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              onboardingPermi,
                              setOnboardingPermi,
                              false,
                              "amsOnboarding"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="AMS Onboarding"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={onboardingPermi.categories.dapiStatistics}
                          disabled={!onboardingPermi.allowed}
                          onChange={(e) =>
                            updatePermissionsUser(
                              onboardingPermi,
                              setOnboardingPermi,
                              false,
                              "dapiStatistics"
                            )
                          }
                          color="primary"
                        />
                      }
                      label="D API Statistics"
                    />
                  </Grid>
                </Grid>
                
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          true,
                          null
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Amazon Advertising"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.settingsAndOptimisation.campaignsOptimisation}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "campaignsOptimisation",
                          true,
                          "settingsAndOptimisation"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Campaigns Optimisation"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.settingsAndOptimisation.createCampaign}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "createCampaign",
                          true,
                          "settingsAndOptimisation"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Create Campaign"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.settingsAndOptimisation.campaignsSettings}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "campaignsSettings",
                          true,
                          "settingsAndOptimisation"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Campaigns Settings"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.settingsAndOptimisation.amsProfiles}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "amsProfiles",
                          true,
                          "settingsAndOptimisation"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="AMS Profiles"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.settingsAndOptimisation.dayPartingRules}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "dayPartingRules",
                          true,
                          "settingsAndOptimisation"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Day Parting Rules"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.reports.campaignPerformance}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "campaignPerformance",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Campaign Performance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.reports.asinPerformance}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "asinPerformance",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="ASIN Performance"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.reports.keywordPerformance}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "keywordPerformance",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Keyword Performance"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.reports.searchTermPerformance}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "searchTermPerformance",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Search Term Performance"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.reports.targetPerformance}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "targetPerformance",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Targeting Performance"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.reports.budgetChanges}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "budgetChanges",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Budget Changes"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.reports.placementAnalysis}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "placementAnalysis",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Placement Analysis"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={amazonAdvertisingPermi.categories.reports.reachTrend}
                      disabled={!amazonAdvertisingPermi.allowed}
                      onChange={(e) =>
                        updatePermissionsUser(
                          amazonAdvertisingPermi,
                          setAmazonAdvertisingPermi,
                          false,
                          "reachTrend",
                          true,
                          "reports"
                        )
                      }
                      color="primary"
                    />
                  }
                  label="Reach Trend"
                />
            </Grid>
          </Grid>


                <Grid item xs={12}>
                  <Typography color="textPrimary" variant="h3">
                    Vendors
                  </Typography>
                  <br />
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                  <InputLabel id="demo-mutiple-chip-label">Vendors</InputLabel>
                  <Select
                    labelId="demo-mutiple-chip-label"
                    id="demo-mutiple-chip"
                    value={selectedVendorCodes}
                    onChange={handleChange}
                    placeholder="Add Vendor"
                    input={<Input id="select-multiple-chip" />}
                  >
                    {filteredCustomerConfig.map((vendor) => (
                      <MenuItem
                        key={vendor.vendorCode}
                        value={vendor.vendorCode}
                      >
                        {vendor.customerName + " - " + vendor.vendorCode}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.chips}>
                  {selectedVendorCodes.map((value) => (
                    <Chip
                      style={{ margin: "5px" }}
                      key={value}
                      label={value}
                      className={classes.chip}
                      onDelete={() => handleDelete(value)}
                    />
                  ))}
                </div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ marginLeft: "35%", marginRight: "35%" }}
                >
                  <Button
                    color="primary"
                    disabled={!email || !password}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={validateData}
                  >
                    Create New Account
                  </Button>
                </Grid>
              </Grid>
            </Widget>
          </Grid>
        </Grid>
        {notification ? (
          <Alert
            severity="success"
            style={{ position: "absolute", bottom: "-35%", left: "45%" }}
          >
            {notification}
          </Alert>
        ) : (
          <></>
        )}
        {err ? (
          <Alert
            severity="error"
            style={{ position: "absolute", bottom: "-35%", left: "45%" }}
          >
            {err}
          </Alert>
        ) : (
          <></>
        )}
      </Container>
    </>
  );
}
