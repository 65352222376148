import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import LineChart from "./components/LineChart/LineChart";
import Table from "./components/Table/Table";

// styles
import useStyles from "./styles";

//helpers
import colors from "../../healpers/colors";
import { encodeForProxy } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import pages from "../../healpers/pageCodes";
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";

//context
import { useLayoutState } from "../../context/LayoutContext";
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var layoutState = useLayoutState();
  var vendorState = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [linechartData, setlinechartData] = useState([]);
  const [asinList, setasinList] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [dateRange, setdateRange] = useState(null);
  const [selectedDateRange, setselectedDateRange] = useState(0);

  const computeDataForLineChart = (data) => {
    var keys = ["Week - 2", "Week - 1", "Week 0", "Week 1", "Week 2", "Week 3"];
    var lineChart = [];
    for (let i = 0; i < keys.length; i++) {
      var key = keys[i];
      var asinCollection = {};
      data.forEach((item) => {
        asinCollection[item["ASIN"]] = item[key];
      });
      lineChart.push({
        key: key,
        ...asinCollection,
      });
    }
    setlinechartData(lineChart);
    if (lineChart.length !== 0) {
      var asins = { ...lineChart[0] };
      delete asins.key;
      setasinList(Object.keys(asins));
    }
  };

  useEffect(() => {
    if (layoutState.dates) {
      const weekDateRange = cloneAndSortDescending(layoutState.dates["Copywriting/SEO"].weekly);
      setdateRange(weekDateRange);
      if (weekDateRange.length !== 0)
        setselectedDateRange(0);
    }
    return () => {
      setdateRange(null);
    };
  }, [layoutState.dates]);

  useEffect(() => {
    async function loadData() {
      try {
        setfullPageLoader(true);
        var encodedString = encodeForProxy(
          pages.copywritingseo,
          vendorState.selected,
          moment(dateRange[selectedDateRange].startdate).format("MM-DD-YYYY"),
          moment(dateRange[selectedDateRange].enddate).format("MM-DD-YYYY")
        );

        axios
          .get(BASE_URL + api.getCache + encodedString, tokenConfig() )
          .then((result) => {
            if (result.data.data) {
              computeDataForLineChart(result.data.data.lineChartData);
              setmainTableData(result.data.data.mainTableData);
              setfullPageLoader(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setfullPageLoader(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

    if (dateRange && dateRange.length != 0) {
      loadData();
    }

    return () => {
      setmainTableData([]);
      setlinechartData([]);
      setasinList([]);
    };
  }, [dateRange, selectedDateRange]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Copywriting / SEO"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Creative
              </Link>
              <Typography className={classes.link} color="primary">
                Copywriting / SEO
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Range
                </InputLabel>
                <Select
                  value={selectedDateRange}
                  onChange={(e) => {
                    setselectedDateRange(e.target.value);
                  }}
                  label="Select Range"
                  disabled={dateRange && dateRange.length === 0 ? true : false}
                >
                  {dateRange ? (
                    dateRange.map((range, index) => {
                      return (
                        <MenuItem value={index}>
                          {range.startdate + " - " + range.enddate}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
            >
              <LineChart
                colors={colors}
                linechartData={linechartData}
                asinList={asinList}
              />
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <div />
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export CSV
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
