import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    display: "flex",
    flexDirection: "row",
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  select: {
    minWidth: 150,
    maxWidth: 180,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: theme.palette.primary.main,
    backgroundColor:'rgba(255,255,255,0.5)',
  },
}));
