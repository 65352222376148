import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Container,
  Button,
  CircularProgress,
  Backdrop,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchASINPIR, fetchASINPIRRanges } from "../../http/api";

export default function Dashboard(props) {
  var classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var vendorState = useVendorState();

  const [mainTableData, setmainTableData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [selectedDate, setSelectedDate] = useState({});
  const [fullPageLoader, setfullPageLoader] = useState(false);

  useEffect(() => {
    fetchASINPIRRanges(vendorState?.selected)
      .then((dates) => {

        setDateRange(dates);
        // setSelectedDate(dates[dates.length-1])
        setSelectedDate(dates[0])
      }).catch((err) => {
        console.error('Failed to fetch the ranges')
      })

      return () => {
        setDateRange([]);
        setSelectedDate('');
      }
  }, [vendorState?.selected])

  useEffect(() => {

    async function fetchData() {
      try {

        setfullPageLoader(true);

        const granularity = 'week';
        const { filteredData } = await fetchASINPIR(vendorState.selected, granularity, selectedDate)

        setmainTableData(filteredData || []);
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if(vendorState?.selected && selectedDate) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
    };

  }, [vendorState?.selected, selectedDate]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title={`FBA Inventory Replenishment`}
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Operations
              </Link>
              <Typography className={classes.link} color="primary">
                {`FBA Inventory Replenishment`}
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.campaignControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Date
                </InputLabel>
                <Select
                  value={selectedDate}
                  onChange={(e) => {
                    setSelectedDate(e.target.value);
                  }}
                  label="Select Range"
                  disabled={dateRange && dateRange.length === 0 ? true : false}
                >
                  {dateRange && dateRange.length ? (
                    dateRange.map((range, index) => {
                      return (
                        <MenuItem value={range}>
                          {range}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    {`FBA Inventory Replenishment`}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
