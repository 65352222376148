import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import axios from "axios";
import * as XLSX from 'xlsx';

//icons
import RefreshIcon from '@material-ui/icons/Refresh';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import ConfirmDialog from "./components/ComfirmDialog/ConfirmDialog";
import Table from "./components/Table/Table";
import SampleFile from "../../healpers/assets/Sample.xlsx";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
//context
import { useUserState } from "../../context/UserContext";

export default function Page() {
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var userState = useUserState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [file, setfile] = useState({name:"No File uploaded"});
  const [confirmDialog, setconfirmDialog] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [estimatedTime, setestimatedTime] = useState(null);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);

  async function uploadCSV (e){
    const file = e.target.files[0];
    const reader = new FileReader();

      reader.onload = evt => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });

        const errPromise = new Promise((resolve, reject) => {
          data.forEach((entry,index) => {
            if(index == 0 && entry[0]!== "ASIN"){
              reject(false)
            }
            if(entry.length !== 1) {
              reject(index+1);
            }
            if(data.length == index+1){
              resolve("No error found")
            }
          });
        });

        errPromise
          .then(()=>{
            setfile(file);
            setconfirmDialog(true);
            convertSeconds(((data.length - 1)*5)*2); //rough time to fetch API is 5 sec.
          })
          .catch((rowNumber)=>{
            if(!rowNumber) {              
              seterr("Invalid column name found");
            } else {
              seterr("Uploaded file is not in proper format. Issue found in row no. "+(rowNumber));
            }            
            
            document.getElementById('upload-csv').value = '';
            setTimeout(() => {
              seterr(null)
            }, 2000);
            setfile({name:"No File uploaded"});
            setconfirmDialog(false);
            setestimatedTime(null);
          })
      };
    reader.readAsBinaryString(file);
  }

  function convertSeconds(seconds) {
    var convert = function(x) { return (x < 10) ? "0"+x : x; }
    setestimatedTime(convert(parseInt(seconds / (60*60))) + ":" +
           convert(parseInt(seconds / 60 % 60)) + ":" +
           convert(seconds % 60))
  }

  const fetchData = () => {
    axios.get( BASE_URL + api.listProductDetails + userState.userData.email ) // need to tokenize later
      .then((res)=>{
        setmainTableData(res.data)
      })
      .catch((err)=>{
        console.log(err)
      })
  }

  useEffect(() => {
    if(userState.userData) {
      fetchData();
    }
  }, [userState.userData])

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Upload File"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Product Details
              </Link>
              <Typography className={classes.link} color="primary">
                Upload File
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <a href={SampleFile} download="Sample File.xlsx" style={{textDecoration: 'none'}}>
                <Button 
                  color="primary" 
                  variant="outlined" 
                  component="span"
                  size="small"
                  startIcon={<GetAppIcon />}
                  >
                  Download Sample File
                </Button>
              </a>
              <FormControl variant="outlined" className={classes.formControl} style={{marginRight:'-5%'}}>
                <label htmlFor="upload-csv">
                  <input style={{ display: 'none' }} 
                    id="upload-csv"  
                    name="upload-csv"
                    type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                    onChange={(e) => uploadCSV(e)}
                  /> 
                  <Button 
                    color="primary" 
                    variant="contained" 
                    component="span"
                    size="medium"
                    startIcon={<PublishIcon />}
                    >
                    Upload here
                  </Button>
                </label>
                <Typography className={classes.link} color="primary" style={{fontSize:12, margin:'5% 0% 0% 1%'}}>
                  {file.name}
                </Typography>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
              header={
                <div className={classes.mainChartHeader}>
                  <div/>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<RefreshIcon />}
                    onClick={() => fetchData()}
                  >
                    Refresh
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog open={confirmDialog} toggleDialog={()=>setconfirmDialog(!confirmDialog)} file={file} setFile={setfile} estimatedTime={estimatedTime} setnotification={setnotification} seterr={seterr} fetchData={fetchData}/>
      {
        notification ? 
          <Alert severity="success" style={{position:'absolute', bottom: 30, left: '48%'}}>{notification}</Alert>
        :
        <></>
      }      
      {
        err ? 
          <Alert severity="error" style={{position:'absolute', bottom: 30, left: '48%'}}>{err}</Alert>
        :
        <></>
      }    
    </>
  );
}
