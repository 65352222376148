import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import useStyles from "./styles";

export default function Info({ title, subtitle, redirect, redirectTitle}) {
  const classes = useStyles();
  return (
    <Container maxWidth={false}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            bodyClass={classes.tableOverflow}
            disableWidgetMenu
          >
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5">{title}</Typography>
              <Typography variant="subtitle1">
                {subtitle}
              </Typography>
              {
                redirect &&
                <Link to={redirect}>{redirectTitle}</Link>
              }
            </Box>
          </Widget>
        </Grid>
      </Grid>
    </Container>
  );
}
