import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";

import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { useVendorState } from "../../../../context/VendorContext";

export default forwardRef(function Tables(props, ref) {
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  var vendorState = useVendorState();

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: "#F6F7FF" };
    }
  };

  const rowStyle = { background: "white" };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      const options = {
        fileName: `${vendorState.selected}_STRANDED_INVENTORY.csv`,
      };
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ ...options, onlySelected: true });
      else gridApi.exportDataAsCsv(options);
    },
  }));

  return (
    <>
      <div
        className="ag-theme-material"
        style={{ height: 620, width: "100%" }}
        id="#grid-theme-wrapper"
      >
        <AgGridReact
          ref={gridRef}
          rowData={props.tableData}
          suppressExcelExport={true}
          rowSelection="multiple"
          tooltipShowDelay={100}
          tooltipMouseTrack={true}
          rowHeight={60}
          defaultColDef={{
            filter: "agTextColumnFilter",
            resizable: true,
            sortable: true,
            headerComponentParams: {
              template:
                '<div class="ag-cell-label-container" role="presentation">' +
                '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                "  </div>" +
                "</div>",
            },
          }}
          onGridReady={onGridReady}
          pagination={true}
          paginationPageSize={10}
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          suppressDragLeaveHidesColumns={true}
        >

          {props.headerNames.length > 0 && (
            <>
              <AgGridColumn headerName={''} field={'favicon'} width={100} cellRenderer={renderImage}/>
              {props.headerNames.map(({ headerName, field }, index) => (
                <AgGridColumn
                  key={field}
                  headerName={headerName}
                  field={field}
                  // width={120}
                  checkboxSelection={index == 0}
                  headerCheckboxSelection={index == 0}
                  cellRenderer={index == 1 ? renderImage : null}
                />
              ))}
            </>
          )}
        </AgGridReact>
      </div>
    </>
  );
});

const renderImage = ({ value }) => `<img style="height: 40px; width: 40px" src="${value}"`;