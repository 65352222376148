import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  link: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  headerRight: {
    display: "flex",
    width: "30%",
    alignItems: "center",
    justifyContent: "center",
    gap: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 100,
    color: theme.palette.primary.main,
    backgroundColor: "rgba(255,255,255,0.5)",
  },
}));
