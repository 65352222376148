const validColumnNames = [
    { name: "Vendor Code", modifiedName: "vendorCode" },
    { name: "ASIN", modifiedName: "asin" },
    { name: "SKU", modifiedName: "sku" },
    { name: "Product Title", modifiedName: "productTitle" },
    { name: "Map Price", modifiedName: "mapPrice" },
    { name: "Product Description", modifiedName: "productDescription" },
    { name: "Bullet 1", modifiedName: "bullet1" },
    { name: "Bullet 2", modifiedName: "bullet2" },
    { name: "Bullet 3", modifiedName: "bullet3" },
    { name: "Bullet 4", modifiedName: "bullet4" },
    { name: "Bullet 5", modifiedName: "bullet5" }
]

export const validateASINSKUMappingData = (data) => {

    try {

        if (!Array.isArray(data) || data?.length <= 1 ||
            !Array.isArray(data[0]) || data[0]?.length > 11) {
            throw new Error('Invalid Number of Column Names')
        }

        if (data[0].every((ele) => !validColumnNames.map(elem => elem?.name).includes(ele))) {
            throw new Error(`Invalid Column Name Found! Check Sample Excel`)
        }

        const excelData = []
        data.forEach((row, i) => {
            if (i === 0) {
                return true;
            }
            let rowObj = {};
            let colNames = validColumnNames.map(ele => ele?.modifiedName);
            colNames.forEach((ele, index) => {
                if (row[index]) {
                    rowObj[ele] = row[index];
                } else {
                    console.error(`No Data Found for ${ele}; Row No: ${i} `)
                }
            })

            if(Object.keys(rowObj).length) {
                excelData.push(rowObj)
            }
        })

        return { isValid: true, data: excelData }

    } catch (err) {
        return { isValid: false, message: err.message || err }
    }

} 