/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Backdrop,
  CircularProgress,
  FormControl,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context

//helpers
import useStyles from "./styles";
import api from "../../healpers/apiRoutes";
import axios from "axios";
import { BASE_URL } from "../../healpers/api";
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig, useUserState } from "../../context/UserContext";
import { Calculate, Recycling } from "@mui/icons-material";
import ConfirmationPop from "../../components/Confirmation/Confirmation";
import Modal from "react-responsive-modal";

export default function SegmentProjections() {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();
  const userState = useUserState();

  const [forecastData, setForecastData] = useState([]);
  const [fullPageLoader, setFullPageLoader] = useState(false);
  const [recalculateModal, setRecalculateModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [selectedASINProjection, setSelectedASINProjection] = useState(0);
  const [asinRecalculateModal, setASINRecalculateModal] = useState(false);
  const [selectedASIN, setSelectedASIN] = useState("");
  const [excludeASINProjection, setExcludeASINProjection] = useState(false);

  useEffect(() => {
    if (vendorState.selected && !fullPageLoader) {
      getSegmentProjectionData();
    }
  }, [vendorState.selected]);

  const getSegmentProjectionData = async (fromDB) => {
    try {
      setFullPageLoader(true);
      const params = {
        vendorCode: vendorState?.selected,
        fromDB
      };
      const result = await axios.get(
        BASE_URL + api.getSegmentForecast,
        tokenConfig(params)
      );
      const projections = parseProjections(result.data.data);
      setForecastData(projections);
      setFullPageLoader(false);
    } catch (error) {
      setFullPageLoader(false);
      console.log("error", error);
    }
  };

  const parseProjections = (projections) => {
    const projectionObj = {};
    projections.forEach((projection) => {
      if(!projectionObj[projection.asin]) {
        projectionObj[projection.asin] = {
          asin: projection.asin,
        };
      }
      projectionObj[projection.asin][projection.forecastMonth] = projection.forecastProjection;
    });
    return Object.values(projectionObj);
  }

  const calculateSegmentProjections = async (reset) => {
    try {
      setFullPageLoader(true);
      const body = {
        vendorCode: vendorState?.selected,
        reset
      };
      const result = await axios.post(
        BASE_URL + api.calculateSegmentProjections,
        body,
        tokenConfig()
      );
      const projections = parseProjections(result.data.data);
      setForecastData(projections);
      setFullPageLoader(false);
    } catch (error) {
      setFullPageLoader(false);
      console.log("error", error);
    }
  }

  const fetchASINSegmentForecast = async (asin) => {
    try {
      setFullPageLoader(true);
      const params = {
        asin: asin
      };
      const result = await axios.get(
        BASE_URL + api.getASINSegmentForecast,
        tokenConfig(params)
      );
      const segmentConfig = result.data.data;
      setSelectedASINProjection(segmentConfig?.forecastProjection);
      setFullPageLoader(false);
    } catch (error) {
      setFullPageLoader(false);
      console.log("error", error);
    }
  }

  const changeASINProjection = async () => {
    try {
      setFullPageLoader(true);
      const body = {
        asin: selectedASIN,
        projection: parseInt(selectedASINProjection),
        excludeProjection: excludeASINProjection,
        vendorCode: vendorState?.selected,
      };
      await axios.post(
        BASE_URL + api.changeASINProjection,
        body,
        tokenConfig()
      );
      await getSegmentProjectionData(true);
      setASINRecalculateModal(false);
      setFullPageLoader(false);
    } catch (error) {
      setFullPageLoader(false);
      console.log("error", error);
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Segment Projections"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                <EqualizerIcon className={classes.icon} />
                Business Intelligence
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Segment Projections
              </Typography>
            </Breadcrumbs>
          }
        />
        {!fullPageLoader && <Grid container spacing={4} className="js_product_grid">
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                    className={classes.mainChartHeaderButton}
                  >
                    Export
                  </Button>
                  {
                    userState?.userData?.email?.includes("salesduo.com") ?
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Calculate />}
                        onClick={() => setRecalculateModal(true)}
                        className={classes.mainChartHeaderButton}
                      >
                        Recalculate
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<Recycling />}
                        onClick={() => setResetModal(true)}
                        className={classes.mainChartHeaderButton}
                      >
                        Reset
                      </Button>
                    </div>
                    :null
                  }
                </div>
                
              }
            >
              <Table
                ref={exportFunctionRef}
                tableData={forecastData}
                vendorCode={vendorState?.selected}
                openASINRecalculateModal={(asin)=>{
                  setSelectedASIN(asin);
                  setASINRecalculateModal(true);
                  fetchASINSegmentForecast(asin);
                }}
              />
            </Widget>
          </Grid>
        </Grid>}
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ConfirmationPop
        open={recalculateModal}
        onCancel={() => setRecalculateModal(false)}
        title="Recalculate Projections"
        subtitle={`Are you sure to recalcuate projections?`}
        onConfirm={() => calculateSegmentProjections(false)}
        activity={fullPageLoader}
        />
        <ConfirmationPop
        open={resetModal}
        onCancel={() => setResetModal(false)}
        title="Reset Projections"
        subtitle={`Are you sure to reset projections?`}
        onConfirm={() => calculateSegmentProjections(true)}
        activity={fullPageLoader}
        />
        <Modal open={asinRecalculateModal} onClose={() => setASINRecalculateModal(false)} styles={{ modal: { width: 600, borderRadius: 10 } }} center>
          <Grid container style={{ paddingBottom: 30 }} alignItems="center" flexDirection="row">
            <Typography style={{ fontSize: 12, paddingRight: 10, fontWeight: 600 }}>ASIN :</Typography>
            <Typography style={{ fontSize: 12 }}>{selectedASIN}</Typography>
          </Grid>
          <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                type="number"
                value={selectedASINProjection}
                id="outlined-adornment-amount"
                name="asinProjection"
                onChange={(number) => {
                  setSelectedASINProjection(number.target.value);
                }}
                label="Projection"
                variant="outlined"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} spacing={4} style={{ paddingBottom: 30 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={excludeASINProjection}
                  onChange={() => setExcludeASINProjection(!excludeASINProjection)}
                  color="primary"
                />
              }
              label="Exclude Projection"
            />
          </Grid>
          <Grid container fullWidth direction="row" justifyContent="center" alignItems="center">
            <Button variant="contained" color="primary" size="small" onClick={() => changeASINProjection()}>
              Submit
            </Button>
          </Grid>
        </Modal>
      </Container>
    </>
  );
}
