import React, { useState, useRef, forwardRef, useImperativeHandle, useMemo } from "react";
// import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { MaterialReactTable } from 'material-react-table';
import { calcACOS, checkIfImageExists, numberWithCommas } from "../../../healpers/utilityFunctions";
import { Box, Button } from "@material-ui/core";

// import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-material.css';
// import '../styles.js'

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));


  const columns = [
      {
        header: 'Products',
        accessorKey: 'asin',
        Cell: ({ row }) => (
          <div style={{ display: "flex" }}>
            <div style={{ fontSize: 10, marginRight: 10 }}><img width={50} src={row.original.favicon || checkIfImageExists(`https://sd-catalog-images.s3.amazonaws.com/${props.vendorCode}_${row.original.asin}.jpg`) || checkIfImageExists(`https://sd-catalog-images.s3.amazonaws.com/${row.original.asin}.jpg`)} alt={row.original.title}/></div>
            <div style={{ display: "flex", alignItems: "flex-start", flexDirection: "column", justifyContent: "center" }}>
              <div style={{ fontSize: 14, }}>{row.original.title}</div>
              <div style={{ fontSize: 10 }}>SKU: {row.original.sku}</div>
              <div style={{ fontSize: 10 }}>ASIN: {row.original.asin}</div>
            </div>
          </div>
        ),
        size: 350
      },
      {
        header: 'Campaign Name',
        accessorKey: 'campaignName',
        size: 300
      },
      {
        header: 'ACOS',
        accessorKey: 'acos',
        aggregationFn: 'mean',
        AggregatedCell: ({cell}) => (
          <div>{calcACOS(cell.getValue())}</div>
        ),
        Cell: ({ cell }) => <span>{calcACOS(cell.getValue())}</span>,
        size: 150
      },
      {
        header: 'ROAS',
        accessorKey: 'roas',
        aggregationFn: 'mean',
        AggregatedCell: ({cell}) => (
          <div>{cell.getValue() ? cell.getValue().toFixed(2) : 0}</div>
        ),
        Cell: ({ cell }) => <span>{cell.getValue() ? cell.getValue().toFixed(2) : 0}</span>,
        size: 150
      },
      {
        header: 'Sales',
        accessorKey: 'sales',
        aggregationFn: 'sum',
        AggregatedCell: ({cell}) => (
          <div>${numberWithCommas(cell.getValue(), 2)}</div>
        ),
        Cell: ({ cell }) => <span>${numberWithCommas(cell.getValue(), 2)}</span>,
        size: 150
      },
      {
        header: 'Impressions',
        accessorKey: 'impressions',
        aggregationFn: 'sum',
        AggregatedCell: ({cell}) => (
          <div>{numberWithCommas(cell.getValue())}</div>
        ),
        Cell: ({ cell }) => <span>{numberWithCommas(cell.getValue())}</span>,
        size: 200
      },
      {
        header: 'Spend',
        accessorKey: 'cost',
        aggregationFn: 'sum',
        AggregatedCell: ({cell}) => (
          <div>${numberWithCommas(cell.getValue(), 2)}</div>
        ),
        Cell: ({ cell }) => <span>${numberWithCommas(cell.getValue(), 2)}</span>,
        size: 150
      },
      {
        header: 'Clicks',
        accessorKey: 'clicks',
        aggregationFn: 'sum',
        AggregatedCell: ({cell}) => (
          <div>{numberWithCommas(cell.getValue())}</div>
        ),
        Cell: ({ cell }) => <span>{numberWithCommas(cell.getValue())}</span>,
        size: 150
      },
      {
        header: 'CTR',
        accessorKey: 'ctr',
        aggregationFn: 'mean',
        AggregatedCell: ({cell}) => (
          <div>{cell.getValue() ? cell.getValue().toFixed(2) : cell.getValue()}</div>
        ),
        size: 150
      },
      {
        header: 'CPC',
        accessorKey: 'cpc',
        aggregationFn: 'mean',
        AggregatedCell: ({cell}) => (
          <div>{cell.getValue() ? cell.getValue().toFixed(2) : cell.getValue()}</div>
        ),
        size: 150
      },
      
    ]
  return (
    <div className="ag-theme-material asin_performance_table" style={{ height: props.height, width: '100%' }} id="#grid-theme-wrapper">
      {
        props.tableData &&
        <MaterialReactTable
          columns={columns}
          data={props.tableData}
          enableColumnResizing
          enableGrouping
          // enableStickyHeader
          // enableStickyFooter
          initialState={{
            density: 'compact',
            // expanded: true, //expand all groups by default
            grouping: ['asin'], //an array of columns to group by by default (can be multiple)
            pagination: { pageIndex: 0, pageSize: 20 },
            // sorting: [{ id: 'asin', desc: false }], //sort by state by default
          }}
          muiToolbarAlertBannerChipProps={{ color: 'primary' }}
          muiTableContainerProps={{ sx: { maxHeight: 700 } }}
          renderTopToolbar={({table}) => (
            <Box
              sx={{ display: 'flex', gap: '1rem', p: '1rem', flexWrap: 'wrap' }}
            >
              <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={() =>{
                  const rows = table.getPrePaginationRowModel().rows.map(row => row.original);
                  props.exportTable(rows);
                }
                }
                variant="contained"
                color="primary"
              >
                Export
              </Button>
            </Box>
          )}
        />
      }
    </div>
  );
})
