/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  Card,
  CardContent,
  CardActions,
  Button,
  Box,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Collapse,
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import _ from "lodash";
import "date-fns";
import axios from "axios";
import moment from "moment";

//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";

import useStyles from "./styles";

import { makeStyles } from "@material-ui/styles";

const useMetricStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  cardHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",
  },
  cardFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 14,
  },
  title: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  label: {
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
  value: {
    marginRight: theme.spacing(2),
  },
}));

const MetricCard = ({ header, obj }) => {
  const classes = useMetricStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function flattenObj(rootKey, obj) {
    if (typeof obj != "object") {
      return obj;
    }
    let flatObj = {};
    for (const [key, value] of Object.entries(obj)) {
      let prefix = rootKey + key.replace(/^\w/, (x) => x.toUpperCase());
      if (typeof value != "object") {
        flatObj[prefix] = value;
        continue;
      }
      flatObj = {
        ...flatObj,
        ...flattenObj(prefix, value),
      };
    }
    return flatObj;
  }

  const data = flattenObj("", obj);

  const convertKey = (key) =>
    key
      .replaceAll(/([A-Z])/g, (x) => ` ${x}`)
      .replace(/(^\w)/, (x) => x.toUpperCase())
      .replace(/(\d+)d/, (x) => ` ${x.slice(0, -1)} days`);

  const title = convertKey(header);

  return (
    <Card className={classes.root}>
      <CardContent>
        <Box
          marginBottom={2}
          className={classes.cardHeader}
          onClick={handleExpandClick}
        >
          <Typography className={classes.title} variant="h6">
            {title}
            <Typography
              style={{
                fontSize: 14,
              }}
              variant="body1"
            >
              Target: {data.targetCondition == "LESS_THAN" ? "under" : "over"}{" "}
              {data.TargetValue} %
            </Typography>
          </Typography>
          {data.Status == "GOOD" ||
          data.AhrStatus == "GREAT" ||
          data.WarningsCount == 0 ? (
            <SuccessIcon style={{ color: "green" }} />
          ) : (
            <ErrorIcon style={{ color: "red" }} />
          )}
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <TableContainer>
            <Table>
              <TableBody>
                {Object.entries(data).map(([key, value]) => (
                  <TableRow key={key} component="th" scope="row">
                    <TableCell align="left">{convertKey(key)}</TableCell>
                    <TableCell align="left">{value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Divider />
          <Box marginTop={2} className={classes.cardFooter}>
            <Typography
              style={{
                fontSize: 14,
              }}
              variant="subtitle1"
            >
              Time Window:{" "}
              {moment(data.ReportingDateTo, "YYYY-MM-DD").diff(
                moment(data.ReportingDateFrom, "YYYY-MM-DD"),
                "days"
              )}{" "}
              days
            </Typography>
            |
            <Typography
              style={{
                fontSize: 14,
              }}
              variant="subtitle1"
            >
              Report Date: {data.ReportingDateFrom} - {data.ReportingDateTo}
            </Typography>
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default function AccountHealth(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [data, setData] = useState({});
  const [fullPageLoader, setfullPageLoader] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setfullPageLoader(true);
        const response = await axios.get(
          BASE_URL + api.sellerPerformance,
          tokenConfig({
            vendorCode: vendorState.selected,
          })
        );

        if (response?.data?.data) {
          const data = response.data.data;
          setData(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if (vendorState.selected) {
      fetchData();
    }

    return () => {
      setData({});
    };
  }, [vendorState.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Account Health"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                SP Reports
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Seller Performance
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <div className={classes.headerRight}>
                <Typography variant="body1" color="textSecondary">
                  Account Status:
                  <Typography
                    style={{
                      fontSize: 12,
                    }}
                    variant="body1"
                  >
                    Updated On: {moment(data.updatedAt).format("YYYY-MM-DD")}
                  </Typography>
                </Typography>
                {data.accountStatus != "NORMAL" ? (
                  <ErrorIcon style={{ color: "red" }} />
                ) : (
                  <SuccessIcon style={{ color: "green" }} />
                )}
                <Typography variant="body2">{data.accountStatus}</Typography>
              </div>
            </>
          }
        />
        <Grid container spacing={4}>
          {Object.entries(data).map(([key, value]) => {
            if (typeof value != "object") return undefined;

            return (
              <Grid item xs={4}>
                <MetricCard header={key} obj={value} />
              </Grid>
            );
          })}
        </Grid>
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
