import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import Themes from "./themes";
import App from "./components/App";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { VendorProvider } from "./context/VendorContext";
import { PopupProvider } from "./context/PopupContext";
import "./index.css"

ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
      <VendorProvider>
        <PopupProvider>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </PopupProvider>
      </VendorProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById("root"),
);
