import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
  Backdrop,
  InputLabel,
  FormControlLabel,
  
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import SmallTable from "./components/SmallTable/Table";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useLayoutState } from "../../context/LayoutContext";
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { encodeForProxy } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import pages from "../../healpers/pageCodes";
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";

export default function SellerInvoiceReconciliation() {

  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var layoutState = useLayoutState();
  var vendorState = useVendorState();
  // local
  const [mainTableData, setmainTableData] = useState(null);
  const [smallTableData, setsmallTableData] = useState(null);
  const [fullPageLoader, setfullPageLoader] = useState(false);

  const [dateRange, setdateRange] = useState(null);

  const [selectedDateRange, setselectedDateRange] = useState(0);  
  
  
  useEffect(() => {

    if (layoutState.dates) {
      const weekDateRange = cloneAndSortDescending(layoutState.dates["Seller Invoice Reconciliation"].weekly);
      setdateRange(weekDateRange);
      if (weekDateRange.length !== 0)
        setselectedDateRange(0);
    }

    return () => {
      setdateRange(null);
    };
  }, [layoutState.dates]);
   
      
  useEffect(() => {
  
    async function loadData() {
    
      try {
        setfullPageLoader(true);

        var encodedString = encodeForProxy(
 		  pages.scinvoicereconciliation,
          vendorState.selected,
          moment(dateRange[selectedDateRange].startdate).format("MM-DD-YYYY"),
          moment(dateRange[selectedDateRange].enddate).format("MM-DD-YYYY")
        );

        axios
           .get(BASE_URL + api.getCache + encodedString, tokenConfig() )
                
           .then((result) => {
          
            if (result.data.data) {
              setsmallTableData(result.data.data.smallTable);
              setmainTableData(result.data.data.mainTable);
              setfullPageLoader(false);
            }       
          })
          .catch((err) => {
            console.log(err);
            setfullPageLoader(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

 //  if (dateRange && dateRange.length != 0) {
      loadData();
 //  }

    return () => {
       setsmallTableData([]);
       setmainTableData([]);
       
    };
  }, [dateRange, selectedDateRange]);

  return (
  <>
    <Container maxWidth={false}>
      <PageTitle
        title="Seller Central Invoices Reconciliation"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              Operations
            </Link>
            <Typography className={classes.link} color="primary">
              Seller Central Invoices Reconciliation
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Range
              </InputLabel>
              <Select
                value={selectedDateRange}
                onChange={(e) => {
                  setselectedDateRange(e.target.value);
                }}
                label="Select Range"
                disabled={dateRange && dateRange.length === 0 ? true : false}
              >
                {dateRange ? (
                  dateRange.map((range, index) => {
                    return (
                      <MenuItem value={index}>
                        {range.startdate + " - " + range.enddate}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Dates</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 24,
                    color: "black",
                  }}
                >
                  Payment Period
                </Typography>
              </div>
            }
          >
            <SmallTable tableData={smallTableData} />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                Seller Invoice Reconciliation
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<PrintIcon />}
                  onClick={() => exportFunctionRef.current.onBtnExport()}
                >
                  Export CSV
                </Button>
              </div>
            }
          >
            <Table ref={exportFunctionRef} tableData={mainTableData} />
          </Widget>
        </Grid>
      </Grid>
          </Container>
  </>
  );
}
