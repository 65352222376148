export const INITIAL_CRONS = [
  {
    id: "",
    reportType: "ordermetricshour",
    status: "inactive",
    vendorCode: "",
    config: { asins: "" },
    title: "Order Metrics Hour",
  },
  {
    id: "",
    reportType: "ordermetricsday",
    status: "inactive",
    vendorCode: "",
    config: { asins: "" },
    title: "Order Metrics Day",
  },
  {
    id: "",
    reportType: "ordermetricsasinday",
    status: "inactive",
    vendorCode: "",
    config: { asins: "" },
    title: "Order Metrics ASIN Day",
  },
  {
    id: "",
    reportType: "shareofvoice",
    status: "inactive",
    vendorCode: "",
    config: { searchTerms: "" },
    title: "Share Of Voice",
  },
  {
    id: "",
    reportType: "ordermetricsasinhour",
    status: "inactive",
    vendorCode: "",
    config: { asins: "" },
    title: "Order Metrics ASIN Hour",
  },
  {
    id: "",
    reportType: "3preport",
    status: "inactive",
    vendorCode: "",
    config: { asins: "" },
    title: "3P Report",
  },
  {
    id: "",
    reportType: "ordermetricsasinweek",
    status: "inactive",
    vendorCode: "",
    config: { asins: "" },
    title: "Order Metrics ASIN Week",
  },
  {
    id: "",
    reportType: "amazonproductreview",
    status: "inactive",
    vendorCode: "",
    config: { asins: "" },
    title: "Amazon Product Review",
  },
  {
    id: "",
    reportType: "jskeywordbyasin",
    status: "inactive",
    vendorCode: "",
    config: { asins: "" },
    title: "Js Keyword By ASIN",
  },
  {
    id: "",
    reportType: "jspriceandwar",
    status: "inactive",
    vendorCode: "",
    config: { segments: [{ id: "", name: "" }] },
    title: "Js Price and War",
  },
  {
    id: "",
    reportType: "jssegmentbyday",
    status: "inactive",
    vendorCode: "",
    config: { segments: [{ id: "", name: "" }] },
    title: "Js Segment By Day",
  },
  {
    id: "",
    reportType: "jssalesestimatedata",
    status: "inactive",
    vendorCode: "",
    config: { segments: [{ id: "", name: "" }] },
    title: "Js Sales Estimate Data",
  },
];
