import { makeStyles } from "@material-ui/styles";

export default makeStyles(() => ({
  reported: {
    margin: "8px 0 16px 0",
    color: "#0a58ca",
    textAlign: "center",
  },
  header: {
    color: "#0a58ca",
    backgroundColor: "#F7F7F7",
    borderBottom: "1px solid  rgba(0, 0, 0, 0.175)",
    textAlign: "center",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  footerText: {
    color: "#0a58ca",
    textAlign: "center",
  },
}));
