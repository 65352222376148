import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import moment from "moment";
import { toProperCase } from "../../../healpers/utilityFunctions.js";


import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../styles.js'

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);


  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };


  return (
    <div className="ag-theme-material" style={{ height: props.height }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressLoadingOverlay
        suppressExcelExport={true}
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        fullWidth
        suppressDragLeaveHidesColumns={true}
        suppressRowClickSelection={true}
      >
        <AgGridColumn headerName="Campaign" field="Campaign Name" width={450} />
        {/* <AgGridColumn headerName="Ad Group" field="adGroup" width={300} /> */}
        <AgGridColumn headerName="ACOS" field="ACOS" width={130} valueFormatter={calcACOS} />
        <AgGridColumn headerName="Sales" field="Sales(USD)" width={130} valueFormatter={commaSeperator} />
        <AgGridColumn headerName="Spend" field="Spend(USD)" width={130} valueFormatter={commaSeperator} />
        <AgGridColumn headerName="State" field="Campaign Status" width={130} valueFormatter={getCampaignStatus} />
        <AgGridColumn headerName="Targeting Type" field="Targeting" width={150} valueFormatter={properCase} />
      </AgGridReact>
    </div>
  );
})

const calcACOS = (params) => params.value ? `${(parseFloat(params.value) * 100).toFixed(2)}%` : "-";
const dateFormat = (params) => params.value && moment(params.value, "YYYYMMDD").format("MM-DD-YYYY");
const properCase = (params) => params.value && toProperCase(params.value);
const commaSeperator = (params) => params.value ? '$' + params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '$0';
const getCampaignStatus = (params) => params.value && toProperCase(params.value.split("_").pop());

