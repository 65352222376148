import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
// components

const useStyles = makeStyles(theme => ({
  tableOverflow: {
    overflow: 'auto'
  },  
  success: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  warning: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  secondary: {
    height:25,
    marginTop:-2,
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  }
}))

const states = {
  sent: "success",
  warning: "warning",
  secondary: "secondary",
};

export default forwardRef ( function Tables(props,ref) {
  
  const classes = useStyles();
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={props.tableData}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                },              
            }}            
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={10}
            rowStyle={rowStyle}
            suppressDragLeaveHidesColumns={true}
        >
            <AgGridColumn headerName="Invoice Date" field="invoice date" width={150} />
            <AgGridColumn headerName="Account Name" field="account_name" width={150} />
            <AgGridColumn headerName="Description" field="description" width={350} />
            <AgGridColumn headerName="Channel" field="channel" width={150} />
            <AgGridColumn headerName="Currency" field="currency" width={150} />
            <AgGridColumn headerName="Amount" field="amount" width={150} />
            <AgGridColumn headerName="Account Code" field="account_code" width={150} />
            <AgGridColumn headerName="Tax Code" field="tax_code" width={150} />
            <AgGridColumn headerName="Mapping" field="Mapping" width={200} /> 
            <AgGridColumn headerName="Start Date" field="startdate" width={150}/>
            <AgGridColumn headerName="End Date" field="enddate" width={150}/> 
                                  
        </AgGridReact>
    </div>
  );
})

function cellStyle(params) {
  var color;
  // if(params.column["colId"]=="Rep OOS" || params.column["colId"]=="Rep OOS - % of Total"){
  //   if(params.value.charAt(0) === "-")
  //     color = '#d8e9d2'
  //   else color = '#e6b7af'
  // } else {
  //   if(params.value.charAt(0) === "-")
  //     color = '#e6b7af'
  //   else if(params.value === "")
  //     color = null
  //   else color = '#d8e9d2'
  // }
  return { 
    // backgroundColor: color,
    borderBottom: '1px solid rgba(178,178,178,0.25)'
  };
}

const decimalComparator = (valueA, valueB) => parseFloat(valueA) - parseFloat(valueB);

const decimalComparatorWithDollar = (valueA, valueB) => parseFloat(valueA.includes("-") ? valueA.replace("$", "") : valueA.slice(1)) - parseFloat(valueB.includes("-") ? valueB.replace("$", "") : valueB.slice(1));

const integerComparatorWithComma = (valueA, valueB) => parseInt(valueA.replace(",","")) - parseInt(valueB.replace(",",""));
