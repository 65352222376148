import React, { useEffect, useState, version } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Tab,
  Tabs,
  Button,
} from "@material-ui/core";
import Widget from "../../components/Widget/Widget";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import ErrorIcon from "@material-ui/icons/Error";

// react-jsonschema-form
import Form from "@rjsf/material-ui";
import validator from "@rjsf/validator-ajv8";
import { useHistory } from "react-router-dom";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { useVendorState } from "../../context/VendorContext";

// component
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { tokenConfig } from "../../context/UserContext";
import * as jsondiffpatch from "jsondiffpatch";
import "jsondiffpatch/dist/formatters-styles/html.css";
import _ from "lodash";

const CustomErrorsMessages = (props) => {
  return (
    <Box
      style={{
        color: "#4A4A4A",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#fff",
        padding: "16px",
        marginBottom: "16px",
      }}
    >
      <Typography variant="h6">Errors</Typography>
      <List>
        {props.errors.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <ErrorIcon style={{ color: "red" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography style={{ color: "red" }} variant="body2">
                {item}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

// Because we operating in one markeplace and with one langauge tag for now
// TODO: Remove hardcoded language_tag value en_US from the frontend atleast
const DELETE_KEYS = ["marketplace_id", "language_tag"];

const SKIP_FIELDS = {
  // Very deeply nested fields which cause issue when simplifying and rendering
  purchasable_offer: true,
  fulfillment_availability: true,
  unit_count: true,
  cable: true
};

function parseItem(type, data) {
  switch (type) {
    case "string":
      return data.toString();
    case "number":
    case "integer":
      return isNaN(Number(data)) ? undefined : Number(data);
    case "boolean":
      if (!isNaN(Number(data))) {
        return Boolean(Number(data));
      } else {
        return "true" == data;
      }
    default:
      console.log(type);
      throw new Error("type not matched");
  }
}

// Simplifies the schema
function processItem(param) {
  const item = _.cloneDeep(param);
  const { type } = item;
  delete item.examples;

  if (["string", "number", "integer", "boolean", "null"].indexOf(type) >= 0) {
    if (item?.anyOf) {
      for (const value of item.anyOf) {
        if (value?.enum) {
          value.title = "Select from options";
        } else {
          value.title = "Enter custom value";
        }
      }
    }
    return item;
  }

  if (type == "object") {
    let { properties, required } = item;
    required = required.filter((key) => {
      if (DELETE_KEYS.indexOf(key) >= 0) {
        delete properties[key];
        return false;
      }
      return true;
    });
    if (Object.keys(properties).length == 0) return null;

    for (const key of Object.keys(properties)) {
      properties[key] = processItem(properties[key]);
    }
    if (Object.keys(properties).length == 1) {
      const key = Object.keys(properties)[0];
      return properties[key];
    }

    item.properties = properties;
    item.required = required;
    return item;
  }

  if (type == "array") {
    let { selectors, maxItems, maxUniqueItems = 0 } = item;

    if (maxItems && maxItems == 1) {
      return processItem(item.items);
    }

    if (selectors) {
      selectors = selectors.filter((key) => DELETE_KEYS.indexOf(key) < 0);
      const { selectors: selectorsCopy } = item;
      const selectorsHadDeleteKeys = selectorsCopy.reduce(
        (bool, el) => (bool ? bool : DELETE_KEYS.indexOf(el) >= 0),
        false
      );
      if (
        selectors.length == 0 ||
        (selectors.length == 1 && selectorsHadDeleteKeys && maxUniqueItems <= 1)
      ) {
        const { title, description } = item;
        item.items.title = title;
        item.items.description = description;
        return processItem(item.items);
      } else if (selectors.length >= 1 && maxUniqueItems >= 1) {
        item.maxItems = maxUniqueItems;
      }
    }
    item.items = processItem(item.items);
    return item;
  }

  throw new Error("Not a recognized type");
}

function markNotRequired(schema) {
  const item = _.cloneDeep(schema);
  const { type } = item;

  // Delete required section
  delete item.required;
  if (["string", "number", "integer", "boolean", "null"].indexOf(type) >= 0) {
    return item;
  } else if (type == "object") {
    const { properties } = item;
    for (const key of Object.keys(properties)) {
      properties[key] = markNotRequired(properties[key]);
    }
    item.properties = properties;
    return item;
  } else if (type == "array") {
    delete item.minItems;
    item.items = markNotRequired(item.items);
    return item;
  }
}

function createSchema(productSchema, attributes) {
  const schema = {
    type: "object",
    properties: {},
    required: ["required", "optional", "extra"],
  };

  schema.properties["required"] = productSchema.required.reduce(
    (obj, key) => {
      if (SKIP_FIELDS[key]) return obj;
      obj.properties[key] = processItem(productSchema["properties"][key]);
      return obj;
    },
    {
      type: "object",
      properties: {},
      required: productSchema.required,
      title: "Required",
      description:
        "All these fields are required and must not be empty for this listing.",
    }
  );

  schema.properties["optional"] = Object.keys(attributes).reduce(
    (obj, key) => {
      if (SKIP_FIELDS[key]) return obj;

      // Skip if already covered in required fiels
      if (schema.properties.required.properties[key]) return obj;
      obj.properties[key] = processItem(productSchema["properties"][key]);
      return obj;
    },
    {
      type: "object",
      properties: {},
      title: "Optional",
      description:
        "All these fields are optional but already exist in the current listing.",
    }
  );

  schema.properties["extra"] = Object.keys(productSchema.properties).reduce(
    (obj, key) => {
      if (SKIP_FIELDS[key]) return obj;
      if (
        schema.properties.required.properties[key] ||
        schema.properties.optional.properties[key]
      )
        return obj;
      let itemSchema = processItem(productSchema["properties"][key]);
      itemSchema = markNotRequired(itemSchema);
      obj.properties[key] = itemSchema;
      return obj;
    },
    {
      type: "object",
      properties: {},
      title: "Extra",
      description:
        "All these fields are optional but don't yet exist in the current listing.",
    }
  );

  return schema;
}
// Simplifies the payload
function processPayloadItem(item, matchSchema) {
  const PRIMITIVE_TYPES = ["string", "number", "boolean", "null"];

  // handle the primitive types
  if (
    ["string", "number", "integer", "boolean", "null"].indexOf(
      matchSchema.type
    ) >= 0
  ) {
    let value = null;
    while (true) {
      if (typeof item == "object") {
        let keys = Object.keys(item);
        keys = keys.filter((x) => DELETE_KEYS.indexOf(x) < 0);

        if (keys.length != 1) {
          console.error(
            "SOLVE THIS: keys length should not be greater than 0",
            keys,
            matchSchema
          );
        }
        // item = parseItem(matchSchema.type, item[keys[0]]);
        item = item[keys[0]];
      } else if (PRIMITIVE_TYPES.indexOf(typeof item) >= 0) {
        value = parseItem(matchSchema.type, item);
        break;
      } else {
        throw new Error("unknown type seen");
      }
    }
    if (matchSchema?.editable === false && value != "") {
      matchSchema["readOnly"] = true;
      delete matchSchema["anyOf"];
      delete matchSchema["oneOf"];
      delete matchSchema["enum"];
      delete matchSchema["enumNames"];
    }
    return [value, matchSchema];
  }

  if (matchSchema.type == "array") {
    const values = [];

    let items = _.cloneDeep(item);
    if (!Array.isArray(items)) {
      let keys = Object.keys(items);
      keys = keys.filter((x) => DELETE_KEYS.indexOf(x) < 0);
      if (keys.length != 1)
        throw new Error("Keys cannot be of length anything other than 1");
      items = [item[keys[0]]];
    }
    for (const value of items) {
      const [obj, schema] = processPayloadItem(value, matchSchema.items);
      matchSchema.items = schema;
      values.push(obj);
    }
    return [values, matchSchema];
  }
  if (matchSchema.type == "object") {
    const value = {};
    let items = _.cloneDeep(item);
    if (Array.isArray(items)) {
      if (items.length != 1 && matchSchema.title != "External Product ID") {
        console.error(
          "SOLVE THIS: keys length should not be greater than 0",
          items,
          matchSchema
        );
      }
      items = items[0];
    }
    for (const key of Object.keys(items)) {
      if (DELETE_KEYS.indexOf(key) >= 0) continue;
      const [obj, schema] = processPayloadItem(
        items[key],
        matchSchema.properties[key]
      );
      matchSchema.properties[key] = schema;
      value[key] = obj;
    }
    return [value, matchSchema];
  }

  throw new Error("Type did not match anything");
}

function flatten_payload(key, payload) {
  const obj = {};
  if (["string", "number", "boolean"].indexOf(typeof payload) >= 0) {
    obj[key] = payload;
  } else if (Array.isArray(payload)) {
    for (const i in payload) {
      for (const [itemKey, value] of Object.entries(
        flatten_payload("", payload[i])
      )) {
        const objKey = key + "[]" + i + itemKey;
        obj[objKey] = value;
      }
    }
  } else if (typeof payload == "object") {
    for (const property of Object.keys(payload)) {
      for (const [itemKey, value] of Object.entries(
        flatten_payload(property, payload[property])
      )) {
        const objKey = key + "$" + itemKey;
        obj[objKey] = value;
      }
    }
  } else {
    console.log(key, payload);
    throw new Error("not a recognized type");
  }
  return obj;
}

function customSchemaChanges(schema) {
  // Bullet_points is an array with a max 5 items
  if (schema.properties.bullet_point) {
    schema.properties.bullet_point = {
      ...schema.properties.bullet_point,
      selectors: ["marketplace_id", "language_tag", "value"],
      maxUniqueItems: 5,
      maxItems: 5,
    };
  }

  // merchant_suggested_asin is a required field
  schema.required = [...schema.required, "merchant_suggested_asin"];
  return schema;
}

export default function CatalogVersion(props) {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [versionData, setVersionData] = useState(null);
  var vendorState = useVendorState();

  const history = useHistory();

  const productOptionsSchema = {
    title: "Enter details for the product",
    type: "object",
    required: ["vendorCode", "sku", "asin", "marketplaceId"],
    properties: {
      vendorCode: {
        type: "string",
        title: "Vendor Code",
      },
      sku: {
        type: "string",
        title: "SKU",
      },
      asin: {
        type: "string",
        title: "ASIN",
        minLength: 10,
      },
      marketplaceId: {
        type: "string",
        title: "Marketplace ID",
      },
    },
  };

  const [productOptions, setProductOptions] = useState({});

  function customPayloadChanges(payload, asin, marketplaceId) {
    // Auto populate merchant_suggested_asin
    if (payload.attributes.merchant_suggested_asin) {
      const merchant_suggested_asin =
        payload.attributes.merchant_suggested_asin;
      const messages = [];
      if (merchant_suggested_asin.length > 1) {
        messages.push("More than one asin in merchant_suggested_asin");
      }
      if (merchant_suggested_asin[0].value != asin) {
        messages.push("Requested asin and payload asin not matching");
      }
      setErrorMessage(messages);
    } else {
      payload.attributes.merchant_suggested_asin = [
        {
          value: asin,
          marketplace_id: marketplaceId,
        },
      ];
    }

    return payload;
  }

  async function loadProductData(productOptions) {
    try {
      setfullPageLoader(true);
      const response = await axios.get(
        // TODO: Will be updated
        BASE_URL + api.spListingVersions + productOptions.vendorCode,
        tokenConfig(productOptions)
      );

      const productSchema = response.data.data.productSchema;
      const originalSchema = customSchemaChanges(productSchema.schema);

      for (const i in response.data.data.versions) {
        response.data.data.versions[i].payload = customPayloadChanges(
          response.data.data.versions[i].payload,
          productOptions.asin,
          productOptions.marketplace_id
        );
      }

      const allAttributes = response.data.data.versions.reduce((prev, curr) => {
        return {
          ...prev,
          ...curr.payload.attributes,
        };
      }, {});

      // Convert data and store the data in array
      const schema = createSchema(originalSchema, allAttributes);
      const versions = [];
      for (const version of response.data.data.versions) {
        const {
          payload: { attributes },
        } = version;

        const formData = Object.keys(attributes).reduce(
          (obj, key) => {
            if (SKIP_FIELDS[key]) return obj;

            const isRequired = originalSchema.required.indexOf(key) >= 0;

            const rootKey = isRequired ? "required" : "optional";
            const itemSchema = schema.properties[rootKey].properties[key];
            const [convertedItem, updatedSchema] = processPayloadItem(
              attributes[key],
              itemSchema
            );
            obj[rootKey][key] = convertedItem;
            // schema.properties[rootKey].properties[key] = updatedSchema;

            return obj;
          },
          {
            required: {},
            optional: {},
          }
        );
        const { asin, sku, marketplaceId } = productOptions;
        const obj = {
          asin,
          sku,
          marketplaceId,
          ...formData,
        };

        version.flat_data = flatten_payload("root", obj);
        versions.push(version);
      }

      for (let i in versions) {
        if (i == 0) {
          continue;
        }
        console.log(versions[i].flat_data, versions[i - 1].flat_data);
        const delta = jsondiffpatch.diff(
          versions[i].flat_data,
          versions[i - 1].flat_data
        );
        versions[i].delta = delta;
        versions[i].delta_html = jsondiffpatch.formatters.html.format(
          delta,
          versions[i].flat_data
        );
      }
      jsondiffpatch.formatters.html.hideUnchanged();
      setVersionData(versions);
    } catch (err) {
      const { response } = err;
      if (response?.data && response?.data?.message) {
        const { message, errors } = response.data;
        const messages = errors ? [message, ...errors] : [message];
        setErrorMessage(messages);
      } else {
        setErrorMessage(["Error occurred while loading data"]);
        console.log(err);
      }
    } finally {
      setfullPageLoader(false);
    }
  }

  useEffect(() => {
    if (props?.location?.state && vendorState?.selected) {
      const { sku, asin, marketplaceId } = props.location.state;
      if (sku && asin && marketplaceId) {
        setProductOptions({
          vendorCode: vendorState.selected,
          asin,
          sku,
          marketplaceId,
        });
      }
    }

    return () => {
      setProductOptions({});
    };
  }, [props.location.state, vendorState.selected]);

  // Load the data from the API
  useEffect(() => {
    const { asin, sku, marketplaceId, vendorCode } = productOptions;
    if (asin && sku && marketplaceId && vendorCode) {
      setErrorMessage([]);
      loadProductData(productOptions);
    } else {
      setErrorMessage([
        "Either one or more of asin, sku or marketplaceId is missing",
      ]);
    }

    // return () => {
    //   setGetData(null);
    // };
  }, [productOptions]);

  const onProductOptionsSubmit = ({ formData }) => {
    setErrorMessage([]);
    const { asin, sku, marketplaceId, vendorCode } = formData;
    history.push("/app/catalog/versions", {
      sku,
      asin,
      marketplaceId,
      vendorCode,
    });
  };

  const [tabValue, setTabValue] = useState(0);
  const [showJSON, setShowJSON] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const showMetaKeys = [
    "root$asin",
    "root$sku",
    "root$marketplaceId",
    "root$productType",
    "root$required$brand",
    "root$required$item_name",
    "root$required$item_type_keyword",
    "root$required$merchant_suggested_asin",
    "root$required$included_components",
    "root$required$item_type_name",
    "root$required$manufacturer",
  ];

  async function rollBack(version) {
    const { payload } = version;
    setfullPageLoader(true);
    try {
      const response = await axios.post(
        BASE_URL + api.spListing + productOptions.vendorCode,
        payload,
        tokenConfig(productOptions)
      );

      setnotification(response.data.message);
      setTimeout(() => {
        setnotification(null);
      }, 2000);
    } catch (err) {
      const { response } = err;
      if (response.data && response.data.message) {
        const { message, errors } = response.data;
        const messages = errors ? [message, ...errors] : [message];
        setErrorMessage(messages);
      }
    } finally {
      setfullPageLoader(false);
      window.location.reload(false);
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Catalog Versions"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="primary"
                className={classes.link}
                onClick={() => history.push("/app/catalog/catalogItems")}
              >
                Catalog
              </Link>
              <Typography className={classes.link} color="inherit">
                Catalog Versions
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Form
              schema={productOptionsSchema}
              validator={validator}
              onSubmit={onProductOptionsSubmit}
              formData={productOptions}
              noHtml5Validate
            />
          </Grid>

          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowJSON(!showJSON)}
            >
              Show JSON
            </Button>
            {showJSON && (
              <>
                <AppBar position="static">
                  <Tabs value={tabValue} onChange={handleChange}>
                    <Tab label="Simplified Data" />
                  </Tabs>
                </AppBar>
                {tabValue == 0 && (
                  <pre value={tabValue} index={0}>
                    {JSON.stringify(versionData[0].flat_data, null, 2)}
                  </pre>
                )}
              </>
            )}
          </Grid>

          <Grid item xs={12}>
            {errorMessage.length > 0 && (
              <CustomErrorsMessages errors={errorMessage} />
            )}
            <Widget
              upperTitle
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    Versions
                  </Typography>
                </div>
              }
            >
              <Grid container>
                {versionData &&
                  versionData[0] &&
                  Object.keys(versionData[0].flat_data).map((key) => {
                    if (showMetaKeys.indexOf(key) < 0) {
                      return null;
                    }
                    return (
                      <Grid
                        key={key}
                        item
                        sm={6}
                        xs={12}
                        className={classes.borderBottom}
                      >
                        <Box
                          component="span"
                          className={classes.versionHeaderCellLabel}
                        >
                          {key
                            .replaceAll("root$", "")
                            .replaceAll("$", " ")
                            .replaceAll(/^(\w)/g, (x) => x.toUpperCase())}
                          {":  "}
                        </Box>
                        <span
                          style={{
                            overflowWrap: "anywhere",
                            fontSize: "1rem",
                          }}
                        >
                          {versionData[0].flat_data[key]}
                        </span>
                      </Grid>
                    );
                  })}
              </Grid>
              <br /> <br />
              {versionData &&
                versionData?.length > 0 &&
                versionData.map((version) => (
                  <Grid container spacing={4} key={version.version}>
                    <Grid item sm={6} className={classes.borderBottom}>
                      <Box
                        component="div"
                        className={classes.versionHeaderCellLabel}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <b>Actual</b>{" "}
                        <div>
                          {
                            // (actualVersion.version > 1) &&
                            <Button
                              style={{ marginLeft: 10 }}
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={() => rollBack(version)}
                            >
                              Roll Back
                            </Button>
                          }
                        </div>
                      </Box>
                      <Typography>Version: {version.version}</Typography>
                      <Typography variant="caption">
                        Submission ID: {version.submissionId}
                      </Typography>
                      {Object.keys(version.flat_data).map((key) => {
                        return (
                          <Grid
                            key={key}
                            item
                            sm={6}
                            xs={12}
                            className={classes.borderBottom}
                          >
                            <Box
                              component="span"
                              className={classes.versionHeaderCellLabel}
                            >
                              {key
                                .replaceAll("root$", "")
                                .replaceAll("$", " ")
                                .replaceAll(/^(\w)/g, (x) => x.toUpperCase())}
                              {":  "}
                            </Box>
                            <span
                              style={{
                                overflowWrap: "anywhere",
                                fontSize: "1rem",
                              }}
                            >
                              {versionData[0].flat_data[key]}
                            </span>
                          </Grid>
                        );
                      })}
                    </Grid>
                    <Grid item sm={6} className={classes.borderLeftBottom}>
                      <Box
                        component="div"
                        className={classes.versionHeaderCellLabel}
                      >
                        <b> Changed</b>
                      </Box>
                      <Box>
                        <pre
                          id="diffOutput"
                          dangerouslySetInnerHTML={{
                            __html: version.delta_html,
                          }}
                        ></pre>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
            </Widget>
          </Grid>
          {notification ? (
            <Alert
              severity="success"
              style={{ position: "relative", bottom: "3.7rem", left: "12rem" }}
            >
              {notification}
            </Alert>
          ) : (
            <></>
          )}
        </Grid>

        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
