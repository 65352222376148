import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  subHeaderlink: {
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none'
  },
  subHeaderlinkActive: {
    display: 'flex',
    alignItems: 'center',
    color: 'blue',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline'
    }
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  campaignControl: {
    margin: theme.spacing(1),
    minWidth: 290,
  },
  dateFormControl: {
    maxWidth: 170,
    marginRight: 8,
    marginTop: -8
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  mainChartHeaderRight: {
    width: "100%",
    display: "flex",
    alignItems: "right",
    justifyContent: "space-between",
  },
  mainChartBody: {
    overflowX: "auto"
  },
  reviewBoxHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignContent: "center"
  },
  reviewBoxBody: {
    padding: "2rem 4rem"
  },
  reviewBoxMeta: {
    textAlign: "end",
    fontWeight: 600
  },
  arrowIcon: {
    '&:hover': {
      background: "gray"
    }
  },
  arrowIconDisabled: {
    cursor: "not-allowed",
    pointerEvents: "none",
    color: "#c0c0c0",
    backgroundColor: "#ffffff"
  }
}));