import { GET_API } from "./axios"

import { filterThreePDataByDate, modifyThreePData } from "../healpers/threep.helpers";
import moment from "moment";
import { tokenConfig } from '../context/UserContext';
import { BASE_URL } from "../healpers/api";
import api from "../healpers/apiRoutes";

/**
 * Fetches SOV data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of SOV data
 */
export const fetchThreePData = async (vendorCode, reportDate) => {
    try {
    const url = BASE_URL + api.getthreep;
    
    console.log("getthreep url is "+url);
    
    const config = {
        ...tokenConfig(),
        params: {
            vendorCode: vendorCode,
            reportDate: reportDate
        }
    }
    const result = await GET_API(url, config.headers, config.params);
    const data = result?.data?.data || [];
    if(!data) {
            return {};
        } else {
        
        /*
            const filteredData = modifyThreePData(data);

			console.log("filteredData in http file: "+filteredData);
			
			return { filteredData };
			*/
			
			return modifyThreePData(data);
        }
    } catch(err) {
        throw new Error(err.message || err)
    }
}

// get dates for dropdown
 export const fetchDateRanges3P = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getthreepRanges;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const ranges = result?.data?.data || {};
        if (!ranges?.dates) {
            throw new Error('Failed to fetch date ranges for 3P')
        } else {
            const dates = (ranges?.dates || []).map(ele => { return { date: moment(ele.reportDate).format('MM/DD/YYYY') } })
            return { dates };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}
