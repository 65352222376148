import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Select,
  FormControl,
  MenuItem,
  Container,
  Button,
  CircularProgress,
  Backdrop,
  InputLabel,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import PageTitle from "../../components/PageTitle/PageTitle";
import LineChart from "./components/LineChart/LineChart";

//context
import { useVendorState } from "../../context/VendorContext";
import { useLayoutState } from "../../context/LayoutContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import colors from "../../healpers/colors";
import { encodeForProxyCustom, encodeForProxyCustomAll, ascendingSort, sortObjectByKeys, toProperCase } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import pages from "../../healpers/pageCodes";

import { useLocation } from 'react-router-dom'


export default function KeyMetricsVCGraph(props) {
  var classes = useStyles();

  //global
  var vendorState = useVendorState();
  
  const location = useLocation()
  
//  const { duration = 'weekly' } = location.state.duration || {}
  const duration = location.state.duration;
  const kpi = location.state.kpi;
  console.log("kpi ", location.state.kpi);
  console.log("location ", location);
  console.log("duration ", duration);
  
  // local
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [linechartData, setlinechartData] = useState([]);
  const [range, setRange] = useState("Week");
  const [filter, setFilter] = useState([ "ALL" ]);
  const [selectedFilter, setSelectedFilter] = useState( "ALL");

	
  const computeDataForLineChart = (data) => {
    
    console.log("In computeDataForLineChart, data for chart is ", data.data.chart);
    console.log("before for loop duration ",duration);
    console.log("before for loop kpi ",kpi);
    
    var tempChart = data.data.chart;
    var dateCollection = {};
    var lineChart = [];
    var dateCollection = {};    
    for (let i = 0; i < tempChart.length; i++) {

      console.log(" inside ifor loop KPI is: ", kpi);
      console.log(" inside for loop dateCollection KPI is: ", tempChart[i]["KPI"]);
      
      if(kpi.toString()==tempChart[i]["KPI"].toString() && duration == "weekly") {
        console.log("inside if loop Weekly link KPI is: ", kpi);
        dateCollection.Interval = tempChart[i]["Week"];
        dateCollection.labelname = "Week "+tempChart[i]["Week"];
        dateCollection.kpi = tempChart[i]["KPI"];
        dateCollection.CurrentYear = tempChart[i]["Year"];
        dateCollection.PreviousYear = tempChart[i]["Previous Year"];
        dateCollection.CurrentYearSum = tempChart[i]["SUM of Current Year Value"];
        dateCollection.PreviousYearSum = tempChart[i]["SUM of Previous Year Value"];
        dateCollection.year = tempChart[i]["Year"];
        
        lineChart.push({
        key: `${tempChart[i]["Year"]} - ${tempChart[i]["Week"]}`,
        ...dateCollection,
      });
      setRange("Week");
	 
	 } else if (kpi.toString()==tempChart[i]["KPI"].toString() && duration == "monthly") {
        console.log("inside if loop Monthly link KPI is: ", kpi);
        dateCollection.Interval = tempChart[i]["Month"];
        dateCollection.labelname = "Month "+tempChart[i]["Month"];        
        dateCollection.kpi = tempChart[i]["KPI"];
        dateCollection.CurrentYear = tempChart[i]["Year"];
        dateCollection.PreviousYear = tempChart[i]["Previous Year"];
        dateCollection.CurrentYearSum = tempChart[i]["SUM of Current Year Value"];
        dateCollection.PreviousYearSum = tempChart[i]["SUM of Previous Year Value"];
        dateCollection.year = tempChart[i]["Year"];
        
        lineChart.push({
        key: `${tempChart[i]["Year"]} - ${tempChart[i]["Month"]}`,
        ...dateCollection,
      });
      setRange("Month");
	 
	  } else if (kpi.toString()==tempChart[i]["KPI"].toString() && duration == "quarterly") {
        console.log("inside if loop Quarterly link KPI is: ", kpi);
        dateCollection.Interval = tempChart[i]["Quarter"];
        dateCollection.labelname = "Quarter "+tempChart[i]["Quarter"];         
        dateCollection.kpi = tempChart[i]["KPI"];
        dateCollection.CurrentYear = tempChart[i]["Year"];
        dateCollection.PreviousYear = tempChart[i]["Previous Year"];
        dateCollection.CurrentYearSum = tempChart[i]["SUM of Current Year Value"];
        dateCollection.PreviousYearSum = tempChart[i]["SUM of Previous Year Value"];
        dateCollection.year = tempChart[i]["Year"];
        
        lineChart.push({
        key: `${tempChart[i]["Year"]} - ${tempChart[i]["Quarter"]}`,
        ...dateCollection,
      });
      setRange("Quarter");
	  } else if (kpi.toString()==tempChart[i]["KPI"].toString() && duration == "yearly") {
        console.log("inside if loop annual link KPI is: ", kpi);
        dateCollection.Interval = tempChart[i]["Annual"];
		dateCollection.labelname = "Year "+tempChart[i]["Annual"];  
        dateCollection.kpi = tempChart[i]["KPI"];
        dateCollection.CurrentYear = tempChart[i]["Year"];
        dateCollection.PreviousYear = tempChart[i]["Previous Year"];
        dateCollection.CurrentYearSum = tempChart[i]["SUM of Current Year Value"];
        dateCollection.PreviousYearSum = tempChart[i]["SUM of Previous Year Value"];
        dateCollection.year = tempChart[i]["Year"];
        
        lineChart.push({
        key: `${tempChart[i]["Year"]} - ${tempChart[i]["Annual"]}`,
        ...dateCollection,
      });
      setRange("Year");
	 } else { console.log(" else KPI is: ", tempChart[i]["KPI"]); }
  
  }  // for closes

    let lineChartSortObj = {};

    lineChart.forEach(data => {
      if (!lineChartSortObj[data.year]) {
        lineChartSortObj[data.year] = [];
      }
      lineChartSortObj[data.year].push(data);
    })

    lineChartSortObj = sortObjectByKeys(lineChartSortObj);
    let sortedData = [];
    Object.values(lineChartSortObj).forEach(value => {
      value.sort(function(a, b) {
        if(isNaN(parseInt(a.Interval))){
          a.Interval = a.Interval.match(/\d/g).join("");
          b.Interval = b.Interval.match(/\d/g).join("");
        }
        return a.Interval - b.Interval 
      })
      sortedData = [...sortedData, ...value];
    })
    console.log('sortedData', sortedData);
    setlinechartData(sortedData);
          
  };
  
  
  useEffect(() => {
    async function loadData() {
      try {
        setfullPageLoader(true);
        
        console.log("Inside loadData duration is: ", duration);
        var durationStr = "";

        // for yearly we have to switch it to annual as the duration, others are fine
        if(duration=="yearly")
        	durationStr = "annual";
        else 
        	durationStr = duration; 
                
        console.log("Inside loadData durationStr is: ", durationStr);
                        
        let encodedString = encodeForProxyCustom(
          pages.keymetricsvcchart, 
          vendorState.selected,
          durationStr.toUpperCase(),
          selectedFilter
        );
        if (selectedFilter === "ALL") {
          encodedString = encodeForProxyCustomAll(
            pages.keymetricsvcchart, 
            vendorState.selected,
            durationStr.toUpperCase(),
          );
        }

        axios
          .get(BASE_URL + api.getCache + encodedString, tokenConfig() )
          .then((result) => {
            if (result.data) {
            
            console.log('chart from api',result.data)
            if(result.data.data && selectedFilter === "ALL") {
              let years = filter;
              
              result.data.data.chart.forEach(data => {
                if(!years.includes(data.Year)) {
                  years.push(data.Year);
                }
              })
              ascendingSort(years)
              setFilter(years);
            }  
            
              computeDataForLineChart(result.data);
              setfullPageLoader(false);
            }
          })
          .catch((err) => {
            console.log(err);
            setfullPageLoader(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

    if (duration && duration.length != 0) {
       loadData();
    }

    return () => {
      setlinechartData([]);
    };
 }, [selectedFilter])
 

const goBack = () => {
    props.history.goBack()
  }

  const generateLabel = () => {
    if(selectedFilter === "ALL") {
      if(filter.length > 2) {
        return `${range} - ${filter[1]}-${filter[filter.length -1]}`;
      } else {
        return `${filter[1]}`;
      }
    } else {
      return `${range} - ${selectedFilter}`;
    }
  }
  
  const getSubHeaderLinkClass = () => {
    if (location?.state?.kpi) {
      return classes.subHeaderlinkActive;
    } else {
      return classes.subHeaderlink;
    }
  }

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Key Metrics VC Graph"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              Sales
            </Link>
            <Link color="inherit" className={classes.link}>
              Goals & Performance
            </Link>
            <Link color="inherit" className={classes.link}>Vendor Central</Link>
            <Typography className={getSubHeaderLinkClass()} onClick={goBack} color="primary">Key Metrics VC</Typography>
            <Typography className={classes.link} >{location?.state?.kpi}</Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <>
            <FormControl style={{ marginRight: "8%", width: 150 }}>
            <InputLabel id="demo-simple-select-outlined-label">
                  Select Range
                </InputLabel>
                <Select
                  value={selectedFilter}
                  onChange={(e) => {
                    setSelectedFilter(e.target.value);
                  }}
                  defaultValue={selectedFilter}
                  label="Select Filter"
                  disabled={filter && filter.length === 0 ? true : false}
                >
                  {filter ? (
                    filter.map((range) => {
                      return (
                        <MenuItem value={range}>
                          {range}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
            </FormControl>
          </>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                    textAlign: "left",
                    width: "100%"
                  }}
                >
                  Key Metrics VC Graph
                </Typography>
                <LineChart colors={colors} linechartData={linechartData} label={range} filter={toProperCase(selectedFilter)}/>
              </div>
            }
          >
          </Widget>
        </Grid>
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        onClick={() => setfullPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
