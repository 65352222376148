export default {
    sales:"SALES",
    shippedcogs:"SHIPPEDCOGS",
    sellercentral:"SELLERCENTRAL",
    chargebacks:"CHARGEBACKS",
    shortageclaims:"SHORTAGECLAIMS",
    geoinsights:"GEOINSIGHTS",
    detailedpages:"DETAILEDPAGES",
    apluspages:"APLUSPAGES",
    copywritingseo:"COPYWRITINGSEO",
    inventorymanagement:"INVENTORYMANAGEMENT",
    productsnotserving:"PRODUCTSNOTSERVING",
    campaignsoutofbudget:"CAMPAIGNSOUOTOFBUDGET",
    searchqueryperformance:"SEARCHQUERYPERFORMANCE",
    fusionads:"FUSIONADS",
    reachtrend:"REACHTREND",
    invoicereconciliation:"INVOICERECONCILIATION",
    campaignperformance:"CAMPAIGNPERFORMANCE",
    scinvoicereconciliation:"SCINVOICERECONCILIATION",
    orderMetrics: "ORDERMETRICS",
    keymetricsvc: "KEYMETRICSVC",
    keymetricsvcchart:"KEYMETRICSVCCHART"
}