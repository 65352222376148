import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Select,
  FormControl,
  MenuItem,
  Container,
  InputLabel,
  Link,
  CircularProgress,
  Backdrop,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import GeoMap from "./components/geoMap/GeoMap";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useLayoutState } from "../../context/LayoutContext";
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { getMonthDatesBetween, getQuarterDatesBetween, getWeekDatesBetween, getYearDatesBetween } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";
import { SP_REPORT_TYPES } from "../../constant/spReports";

export default function GeographicInsights() {
  var classes = useStyles();

  //global
  var vendorState = useVendorState();

  // local
  const [mapData, setmapData] = useState(null);
  const [maxRevenue, setmaxRevenue] = useState(0);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [range, setRange] = useState(null);

  const [radioValue, setradioValue] = useState("weekly");

  const [dateRange, setdateRange] = useState(null);

  const [weekRange, setweekRange] = useState([]);
  const [monthRange, setmonthRange] = useState([]);
  const [quarterRange, setquarterRange] = useState([]);
  const [annualRange, setannualRange] = useState([]);
  const [selectedDateRange, setselectedDateRange] = useState(0);

  const handleRadioChange = (event) => {
    setradioValue(event.target.value);
    if (event.target.value === "weekly") {
      setdateRange(weekRange);
      setselectedDateRange(0);
    } else if (event.target.value === "monthly") {
      setdateRange(monthRange);
      setselectedDateRange(0);
    } else if (event.target.value === "quarterly") {
      setdateRange(quarterRange);
      setselectedDateRange(0);
    } else {
      setdateRange(annualRange);
      setselectedDateRange(0);
    }
    // setselectedDateRange(0);
  };

  useEffect(() => {
    if(vendorState.selected){
      fetchDateRanges();
    }
  }, [vendorState.selected]);

  useEffect(() => {
    if (range) {
      const weekDateRange = cloneAndSortDescending([...getWeekDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const monthDateRange = cloneAndSortDescending([...getMonthDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const quarterDateRange = cloneAndSortDescending([...getQuarterDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      const annualDateRange = cloneAndSortDescending([...getYearDatesBetween(new Date(range.startDate), new Date(range.endDate))]);
      setdateRange(weekDateRange);
      if (weekDateRange.length !== 0)
        setselectedDateRange(0);

      setweekRange(weekDateRange);
      setmonthRange(monthDateRange);
      setquarterRange(quarterDateRange);
      setannualRange(annualDateRange);
    }
    return () => {
      setdateRange(null);
      setweekRange([]);
      setmonthRange([]);
      setquarterRange([]);
      setannualRange([]);
    };
  }, [range]);

  const fetchDateRanges = async () => {
    try{
      const params = {
        vendorCode: vendorState.selected,
        reportType: SP_REPORT_TYPES.SALES_GEO_REPORT
      }
      const result = await axios.get(BASE_URL + api.spDateRange, tokenConfig(params) )
      if (result.data.data) {
        setRange(result.data.data);
        setfullPageLoader(false);
      }          
    }catch(error){
      console.log(error)
      setfullPageLoader(false);
    }
  }

  useEffect(() => {
    async function loadData(range) {
      setfullPageLoader(true);

      const params = {
        vendorCode: vendorState.selected,
        startDate: moment(dateRange[selectedDateRange].startdate).format("MM-DD-YYYY"),
        endDate: moment(dateRange[selectedDateRange].enddate).format("MM-DD-YYYY")
      }
      axios
        .get(BASE_URL + api.spGeographicInsights, tokenConfig(params) )
        .then((result) => {
          if (result.data.data) {
            setmapData(result.data.data.geoData);
            setmaxRevenue(result.data.data.maxRev);
            setfullPageLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setfullPageLoader(false);
        });
    }

    if (dateRange && dateRange.length != 0) {
      loadData();
    }

    return () => {
      setmapData(null);
    };
  }, [radioValue, dateRange, selectedDateRange]);

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Sales"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              Sales
            </Link>
            <Typography className={classes.link} color="primary">
              Geographic Insights
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <>
            <FormControl style={{ marginRight: "8%" }}>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="weekly"
                  control={<Radio color="primary" />}
                  label="Weekly"
                />
                <FormControlLabel
                  value="monthly"
                  control={<Radio color="primary" />}
                  label="Monthly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl style={{ marginRight: "10%" }}>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="quarterly"
                  control={<Radio color="primary" />}
                  label="Quarterly"
                />
                <FormControlLabel
                  value="yearly"
                  control={<Radio color="primary" />}
                  label="Yearly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Range
              </InputLabel>
              <Select
                value={selectedDateRange}
                onChange={(e) => {
                  setselectedDateRange(e.target.value);
                }}
                label="Select Range"
                disabled={dateRange && dateRange.length === 0 ? true : false}
              >
                {dateRange && dateRange.length != 0 ? (
                  dateRange.map((range, index) => {
                    return (
                      <MenuItem value={index}>
                        {range.startdate + " - " + range.enddate}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Dates</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            disableWidgetMenu
          >
            <GeoMap mapData={mapData ? mapData : []} maxRevenue={maxRevenue} />
          </Widget>
        </Grid>
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
