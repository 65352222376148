import React, { useState, useRef , useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
  Backdrop,
  InputLabel,
  FormControlLabel,
  
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import SmallTable from "./components/SmallTable/Table";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useLayoutState } from "../../context/LayoutContext";
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { encodeForProxy } from "../../healpers/utilityFunctions";
import { encodeForCacheWithoutDates } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import pages from "../../healpers/pageCodes";

function sortByMonth(arr) {
  var months = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
  arr.sort(function(a, b){
      return months.indexOf(a.Payments)
           - months.indexOf(b.Payments);
  });
  return arr;
}

export default function InvoiceReconciliation() {

  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var layoutState = useLayoutState();
  var vendorState = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState(null);
  const [smallTableData, setsmallTableData] = useState(null);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  
      
  useEffect(() => {
  
    async function loadData() {
      try {
        setfullPageLoader(true);

        var encodedString = encodeForCacheWithoutDates(
 		  pages.invoicereconciliation,
          vendorState.selected
        );

        axios
          .get(BASE_URL + api.getCache + encodedString, tokenConfig() )
          .then((result) => {
            if (result.data.data) {
              setmainTableData(result.data.data.mainTable);
              setsmallTableData( sortByMonth(result.data.data.smallTable) );       
              setfullPageLoader(false);
            } 
          })
          .catch((err) => {
            console.log(err);
            setfullPageLoader(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

    loadData();

    return () => {
     //  setsmallTableData([]);
       setmainTableData([]);
       
    };
  }, [vendorState.selected]);


  return (
  <>
    <Container maxWidth={false}>
      <PageTitle
        title="Vendor Central Invoices Reconciliation"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              Operations
            </Link>
            <Typography className={classes.link} color="primary">
              Vendor Central Invoices Reconciliation
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <>
            <FormControl variant="outlined" className={classes.formControl}>
            </FormControl>
          </>
        }
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            bodyClass={classes.mainChartBody}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 24,
                    color: "black",
                  }}
                >
                  Invoices Summary
                </Typography>
              </div>
            }
          >
            <SmallTable tableData={smallTableData} />
          </Widget>
        </Grid>
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                Vendor Invoice Reconciliation
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<PrintIcon />}
                  onClick={() => exportFunctionRef.current.onBtnExport()}
                >
                  Export CSV
                </Button>
              </div>
            }
          >
            <Table ref={exportFunctionRef} tableData={mainTableData} />
          </Widget>
        </Grid>
      </Grid>
          </Container>
  </>
  );
}
