import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
    link: {
      display: 'flex',
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
    },
    dateFormControl : {
      minWidth:165,
      marginRight:8,
      marginTop:-8
    },
    mainChartHeader: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      [theme.breakpoints.only("xs")]: {
        flexWrap: "wrap",
      },
    },
  }));