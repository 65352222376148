import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./styles.css";
import { Button } from "@material-ui/core";
import ConfirmDialog from "../ComfirmDialog/ConfirmDialog";
import { toProperCase } from "../../../../healpers/utilityFunctions";

export default forwardRef(function Tables(
  { setnotification, seterr, setfullpageloader, tableData, fetchData },
  ref
) {
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [deleteVendor, setDeleteVendor] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else gridApi.exportDataAsCsv();
    },
  }));

  const rowStyle = { background: "white" };

  const DeleteButtonRenderer = (props) => {
    return (
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        style={{ textTransform: "capitalize" }}
        onClick={() => {
          setDeleteVendor(props.data);
          setConfirmDialog(true);
        }}
      >
        Delete
      </Button>
    );
  };

  return (
    <div
      className="ag-theme-material"
      style={{ height: 500, width: "100%" }}
      id="#grid-theme-wrapper"
    >
      <AgGridReact
        ref={gridRef}
        rowData={tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={20}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
        frameworkComponents={{
          deleteButtonRenderer: DeleteButtonRenderer,
        }}
      >
        <AgGridColumn headerName="Customer Name" field="customerName" />
        <AgGridColumn width={120} headerName="Country" field="countryCode" />
        <AgGridColumn headerName="Marketplace Id" field="marketplaceId" />
        <AgGridColumn headerName="Vendor Code" field="vendorCode" />
        <AgGridColumn width={120} headerName="Status" field="status" valueFormatter={(props) => toProperCase(props.value)}/>
        <AgGridColumn headerName="AMS Profile ID" field="amsProfileId" />
        <AgGridColumn headerName="Entity ID" field="entityId" />
        <AgGridColumn width={120} headerName="Type" field="accountType" valueFormatter={(props) => toProperCase(props.value)}/>
        <AgGridColumn
          headerName="Delete"
          cellRenderer={"deleteButtonRenderer"}
        />
      </AgGridReact>
      <ConfirmDialog
        setfullpageloader={setfullpageloader}
        setnotification={setnotification}
        seterr={seterr}
        open={confirmDialog}
        closeDialog={() => {
          setDeleteVendor(null);
          setConfirmDialog(false);
        }}
        fetchData={fetchData}
        deleteVendor={deleteVendor}
      />
    </div>
  );
});
