import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  header:{
    width:"100%",
    backgroundColor:"white",
    padding:"20px",
    marginBottom:"30px"
  },
  link: {
    display: 'flex',
  },
}));
