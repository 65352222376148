import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Radio, RadioGroup, FormLabel, Select, FormControl, MenuItem, FormControlLabel, Typography, InputLabel, Checkbox, ListItemText } from "@material-ui/core";

//components
import Dot from "../../../../components/Sidebar/components/Dot";

// styles
const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  dateFormControl: {
    minWidth: 165,
    marginRight: 8,
    marginTop: -8
  },
  mainChartHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.only("xs")]: {
      flexWrap: "wrap",
    },
  },
  pieChartLegendWrapper: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    height: "100%",
    marginLeft: 15,
    marginTop: 20
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1),
    marginRight: 20,
  },

}));

export default function LeftColumn({ brandList, segmentNames, colors, parentCallback, selectedBrandList, setSelectedBrandList }) {

  //global
  const classes = useStyles();

  //local
  const [ddVal, setddVal] = useState(0);
  console.log('ddVal ' + ddVal);

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">Brand Segment</InputLabel>
        <Select id="segmentname"
          defaultValue={0}
          value={ddVal}
          label="Segment Name"

          //     onChange={(e) => setddVal(e.target.value)}
          onChange={(e) => {
            setddVal(e.target.value);
            parentCallback(e.target.value);
          }}
          autoWidth
        >
          {segmentNames && segmentNames.length ? (
            segmentNames.map(({ code, name }, index) => {
              return (
                <MenuItem key={index} value={code}>
                  {name}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value={0}>
              <i>No Segments</i>
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl} >
        <InputLabel id="demo-simple-select-outlined-label">
          Brands
        </InputLabel>
        <Select
          value={selectedBrandList}
          onChange={(e) => setSelectedBrandList(e.target.value)}
          label="Brands"
          multiple
          renderValue={(selected) => `${selected.length} selected`}
        >
          {
            brandList.map((brand) =>
              <MenuItem value={brand}>
                <Checkbox
                  checked={
                    selectedBrandList.findIndex(item => item === brand) >= 0
                  }
                />
                <ListItemText primary={brand} />
              </MenuItem>
            )
          }
        </Select>
      </FormControl>

      <div className={classes.pieChartLegendWrapper}>
        {
          selectedBrandList.map((item, index) => {
            return (
              <div key={colors[index]} className={classes.legendItemContainer}>
                <Dot color={colors[index]} legend />
                <Typography style={{ whiteSpace: "nowrap", fontSize: 13 }} >
                  &nbsp;{item}&nbsp;
                </Typography>
              </div>
            )
          }
          )
        }
      </div>
    </div>
  )
}
