import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import axios from "axios";
import moment from "moment";

//helpers
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";

//context
import { useUserState } from "../../../../context/UserContext";

export default function DraggableDialog({open, toggleDialog, file, setFile, estimatedTime, setnotification, seterr, fetchData}) {

  //Global
  var userState = useUserState();

  const confirmSubmission = async (finalFile) => {
    
    const formData = new FormData();
    var newFile =  new File([finalFile], file.name);
    formData.append("file", newFile);
    formData.append("email", userState.userData.email)

    axios({
      method:'post',
      url: BASE_URL + api.uploadContactFinder2,
      headers: { 
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "multipart/form-data"
      },
      data: formData,
    })
    .then((result) => {
      toggleDialog()
      setFile({name:"No File uploaded"});
      document.getElementById('upload-csv').value = '';
      setnotification(result.data.msg)
      setTimeout(() => {
        setnotification(null);
      }, 2000);
      fetchData()
    }).catch((err) => {
      console.log(err);
      toggleDialog();
      seterr(err)
      setTimeout(() => {
        seterr(null);
      }, 2000);
      setFile({name:"No File uploaded"})
    });
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={toggleDialog}
        fullWidth={true}
      >
        <Paper>
        <DialogTitle>
          Confirm upload
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            File Name: {file.name}
          </DialogContentText>
          <DialogContentText>
            Estimated Time to generate report (HH:MM:SS) : {estimatedTime}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            autoFocus 
            variant="outlined"
            onClick={()=>{
              toggleDialog()
              setFile({name:"No File uploaded"})
              document.getElementById('upload-csv').value = '';
            }} color="primary">
            Cancel
          </Button>
          <Button 
            variant="contained"
            onClick={()=>{              
              confirmSubmission(file)
            }} color="primary">
            Confirm
          </Button>
        </DialogActions>
        </Paper>
      </Dialog>
    </div>
  );
}