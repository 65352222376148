/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchMerchantListingsFYPReport } from "../../http/api";

import useStyles from "./styles";

export default function Charts(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {

        setfullPageLoader(true);
        const { filteredData } = await fetchMerchantListingsFYPReport(vendorState?.selected)
        setmainTableData(filteredData);

      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    fetchData();
    return () => {
      setmainTableData([]);
    };

  }, [vendorState?.selected]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Search Suppressed ASINs"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
				Operations
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Search Suppressed ASINs
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
