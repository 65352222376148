import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Select,
  FormControl,
  MenuItem,
  Container,
  Button,
  CircularProgress,
  Backdrop,
  InputLabel,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import SmallTable from "./components/SmallTable/Table";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";
import { useLayoutState } from "../../context/LayoutContext";
import { tokenConfig } from "../../context/UserContext";

//helpers
import { encodeForProxy } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import pages from "../../healpers/pageCodes";
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";

export default function KeyMetricsVC() {
  var classes = useStyles();

  //global
  var vendorState = useVendorState();
  var layoutState = useLayoutState();
  
  console.log(vendorState)
  
  // local
  const [mainTableData, setmainTableData] = useState(null);
  const [smallTableData, setsmallTableData] = useState(null);
  const [fullPageLoader, setfullPageLoader] = useState(false);

  const [radioValue, setradioValue] = useState("weekly");

  const [dateRange, setdateRange] = useState(null);

  const [weekRange, setweekRange] = useState(null);
  const [monthRange, setmonthRange] = useState(null);
  const [quarterRange, setquarterRange] = useState(null);
  const [annualRange, setannualRange] = useState(null);
  const [selectedDateRange, setselectedDateRange] = useState(0);  

  const handleRadioChange = (event) => {
    setradioValue(event.target.value);
    if (event.target.value === "weekly") {
      setdateRange(weekRange);
      setselectedDateRange(weekRange.length-1);
    } else if (event.target.value === "monthly") {
      setdateRange(monthRange);
      setselectedDateRange(monthRange.length-1);
    } else if (event.target.value === "quarterly") {
      setdateRange(quarterRange);
      setselectedDateRange(quarterRange.length-1);
    } else {
      setdateRange(annualRange);
      setselectedDateRange(annualRange.length-1);
    }
    // setselectedDateRange(0);
  };

  useEffect(() => {
    if (layoutState.dates) {
      const weekDateRange = cloneAndSortDescending(layoutState.dates["KeyMetrics VC"].weekly);
      const monthDateRange = cloneAndSortDescending(layoutState.dates["KeyMetrics VC"].monthly);
      const quarterDateRange = cloneAndSortDescending(layoutState.dates["KeyMetrics VC"].quarterly);
      const annualDateRange = cloneAndSortDescending(layoutState.dates["KeyMetrics VC"].annually);
      setdateRange(weekDateRange);
      if (weekDateRange.length !== 0)
        setselectedDateRange(0);

      setweekRange(weekDateRange);
      setmonthRange(monthDateRange);
      setquarterRange(quarterDateRange);
      setannualRange(annualDateRange);
    }
    return () => {
      setdateRange(null);
      setweekRange([]);
      setmonthRange([]);
      setquarterRange([]);
      setannualRange([]);
    };
  }, [layoutState.dates]);

  useEffect(() => {
    async function loadData(range) {
      try {
        setfullPageLoader(true);

        var encodedString = encodeForProxy(
          pages.keymetricsvc,
          vendorState.selected,
          moment(dateRange[selectedDateRange].startdate).format("MM-DD-YYYY"),
          moment(dateRange[selectedDateRange].enddate).format("MM-DD-YYYY")
        );

        axios
          .get(BASE_URL + api.getCache + encodedString, tokenConfig() )
          .then((result) => {
            if (result.data.data) {
              setsmallTableData(result.data.data.smallTable);
              setmainTableData(result.data.data.smallTable);
              setfullPageLoader(false);
            }          
          })
          .catch((err) => {
            console.log(err);
            setfullPageLoader(false);
          });
      } catch (error) {
        console.log(error);
        setfullPageLoader(false);
      }
    }

    if (dateRange && dateRange.length != 0) {
      loadData();
    }

    return () => {
      setsmallTableData(null);
      setmainTableData(null);
    };
  }, [radioValue, dateRange, selectedDateRange]);

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Key Metrics VC"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              Sales
            </Link>
            <Link color="inherit" className={classes.link}>
              Goals & Performance
            </Link>
            <Link color="inherit" className={classes.link}>
              Vendor Central
            </Link>
            <Typography className={classes.link} color="primary">
              Key Metrics
            </Typography>
          </Breadcrumbs>
        }
        rightAlignComponent={
          <>
            <FormControl style={{ marginRight: "8%" }}>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="weekly"
                  control={<Radio color="primary" />}
                  label="Weekly"
                />
                <FormControlLabel
                  value="monthly"
                  control={<Radio color="primary" />}
                  label="Monthly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl style={{ marginRight: "10%" }}>
              <RadioGroup value={radioValue} onChange={handleRadioChange}>
                <FormControlLabel
                  value="quarterly"
                  control={<Radio color="primary" />}
                  label="Quarterly"
                />
                <FormControlLabel
                  value="yearly"
                  control={<Radio color="primary" />}
                  label="Yearly"
                />
              </RadioGroup>
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Select Range
              </InputLabel>
              <Select
                value={selectedDateRange}
                onChange={(e) => {
                  setselectedDateRange(e.target.value);
                }}
                label="Select Range"
                disabled={dateRange && dateRange.length === 0 ? true : false}
              >
                {dateRange ? (
                  dateRange.map((range, index) => {
                    return (
                      <MenuItem value={index}>
                        {range.startdate + " - " + range.enddate}
                      </MenuItem>
                    );
                  })
                ) : (
                  <MenuItem value={0}>
                    <i>No Dates</i>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </>
        }
      />
      <Grid container spacing={4}>
        <Grid item sm={8} xs={12}>
        </Grid>
        <Grid item sm={4} xs={12}>
        </Grid>
        <Grid item xs={12}>
          <Widget
            upperTitle
            noBodyPadding
            bodyClass={classes.tableOverflow}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant="h2"
                  gutterBottom
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    color: "black",
                  }}
                >
                  Key Metrics VC
                </Typography>
              </div>
            }
          >
            <Table tableData={smallTableData} radioval={radioValue} selecteddate={selectedDateRange} />
          </Widget>
        </Grid>
      </Grid>
      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        onClick={() => setfullPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
