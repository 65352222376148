import { Link } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@material-ui/core";
import useStyles from "./styles";

export default function Table({ tableData, radioval }) {
  const classes = useStyles();
  const footerLabel = (label) => (parseFloat(label) < 0 ? "red" : "green");
  if (tableData === null) {
    return null;
  } else {
    return (
      <>
        <Grid container spacing={3} style={{ padding: "0 3%" }}>
          {tableData.map((cardItem) => (
            <Grid item xs={3} key={cardItem}>
              <Link
                style={{ textDecoration: "none" }}
                to={{
                  pathname: "/app/sales/keymetricsvcgraph/",
                  state: { duration: radioval, kpi: cardItem.KPIs },
                }}
              >
                <Card>
                  <CardHeader
                    className={classes.header}
                    component={Typography}
                    title={cardItem.KPIs}
                  />

                  <CardContent>
                    <Typography variant="h3" className={classes.reported}>
                      {cardItem.Reported}
                    </Typography>

                    <Grid container className={classes.footer}>
                      <Grid cardItem sx={6}>
                        <Typography className={classes.footerText}>
                          Prior Period
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "center",
                            color: footerLabel(cardItem["Prior Period"]),
                          }}
                        >
                          {cardItem["Prior Period"]}
                        </Typography>
                      </Grid>
                      <Grid cardItem sx={6}>
                        <Typography className={classes.footerText}>
                          Last Year
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "center",
                            color: footerLabel(cardItem["Last Year"]),
                          }}
                        >
                          {cardItem["Last Year"]}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </>
    );
  }
}
