import React, {useEffect, useRef} from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";

import { useTheme } from "@material-ui/styles";

// context
import { useLayoutState } from "../../../../context/LayoutContext";
import { useVendorState } from "../../../../context/VendorContext";

//helpers
import { convertNumberToShorthand } from "../../../../healpers/utilityFunctions";

highchartsMore(Highcharts);
solidGauge(Highcharts);

export default function App({currentSalesGoal, salesGoal}) {

  // global
  var layoutState = useLayoutState();
  var vendorstate = useVendorState();
  const theme = useTheme();

  //local
  const chartRef = useRef();
  const [labelsize, setlabelsize] = React.useState(30)

  // To make map responsive
  useEffect(() => {
    if(chartRef.current) {
      setTimeout(() => {
          Highcharts.charts[chartRef.current.chart.index].reflow()
      }, 200);
    }
    if(window.innerWidth<1300)
      setlabelsize(16)
    else
      setlabelsize(30)
  }, [layoutState.isSidebarOpened])

  // window.onresize = (event) => {
  //   if(event.target.screen.availWidth<1100)
  //     setlabelsize(18)
  //   else
  //     setlabelsize(30)
  // }

  const GaugeChart = {
    chart: {
      type: "solidgauge",
    },
  
    title: null,
  
    tooltip: {
      enabled: false
    },
  
    exporting: {
      enabled: false
    },
  
    pane: {
      startAngle: -90,
      endAngle: 90,
      background: [
        {          
          outerRadius: "112%",
          innerRadius: "88%",
          backgroundColor: Highcharts.Color(theme.palette.primary.main)
            .setOpacity(0.3)
            .get(),
          borderWidth: 0,
          shape: 'arc'
        },
      ],
    },
  
    credits: {
        enabled: false
    },

    yAxis: {
      min: 0,
      max: parseFloat(salesGoal),
      lineWidth: 0,
      minorTickLength: 0,
      tickPositions: [0, parseFloat(salesGoal)],
      labels: {        
        distance: 0,
        y: 30,
        formatter: function() {
          switch (this.value) {
            default:
              return '$' + convertNumberToShorthand(this.value);
          }
        },
        style: {
          'font-size': labelsize==16 ? 14 : 18,
        }
      }
    },
  
    plotOptions: {
      solidgauge: {
        dataLabels: {
            y: 0,
            borderWidth: 0,
            useHTML: true
        }
      }
    },
  
    series: [
      {
        name: "Move",
        type: "solidgauge",
        data: [
          {
            color: theme.palette.primary.main,
            radius: "112%",
            innerRadius: "88%",
            y: parseFloat(currentSalesGoal),
          }
        ],
        dataLabels: {
          crop:true,
          format:
              '<div style="color:#4B50DC;">' +
              '<span style="font-size:'+ labelsize +'px">$'+convertNumberToShorthand(currentSalesGoal)+'</span><br/>' +
              '</div>'
        },
      },
    ],
  };

  if(currentSalesGoal)
  return (
    <div style={{
      width:'90%',
      margin: 'auto',
    }}>
      <HighchartsReact highcharts={Highcharts} options={GaugeChart} ref={chartRef}/>
    </div>
  );
  else return (
    <h3 style={{ textAlign: "center",margin:'55% 0'}}>
      ...No Data To Show...
    </h3>
  )
};
