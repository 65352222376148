/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
// import LineBasicChart from "./components/line-basic/lineBasic";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchPriceWarData, fetchPriceWarRanges } from "../../http/api";

import useStyles from "./styles";

export default function Charts(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [dateRange, setDateRange] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [segmentsList, setSegmentsList] = useState([]);
  const [mainTableData, setmainTableData] = useState([]);
  const [selectedInterval, setSelectedInterval] = useState('');
  const [selectedSegment, setSelectedSegment] = useState('');
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    fetchPriceWarRanges(vendorState?.selected)
      .then(({ intervals, segments }) => {

        setDateRange(intervals);
        setSegmentsList(segments);
        setSelectedInterval(intervals?.[intervals?.length - 1])
   //     setSelectedSegment(segmentIds[0])
          setSelectedSegment(segments?.[0]?.id)
      }).catch((err) => {
        console.error('Failed to fetch the ranges')
      })

    return () => {
      setDateRange([]);
      setSegmentsList([]);
      setSelectedInterval('')
      setSelectedSegment('')
    }
  }, [vendorState?.selected])

  useEffect(() => {
    async function fetchData() {
      try {

        setfullPageLoader(true);
        const { filteredData, priceWarGraphData } = await fetchPriceWarData(selectedInterval, selectedSegment, vendorState?.selected)
        setmainTableData(filteredData);
        setGraphData(priceWarGraphData);
        setHeaders(Object.keys(filteredData?.[0] || {}))

      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if (selectedInterval && selectedSegment) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
      setGraphData([]);
      setHeaders([])
    };

  }, [selectedInterval, selectedSegment]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Price War"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                <EqualizerIcon className={classes.icon} />
                Business Intelligence
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Price War
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.campaignControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Interval
                </InputLabel>
                <Select
                  value={selectedInterval || ''}
                  onChange={(e) => {
                    setSelectedInterval(e.target.value);
                  }}
                  label="Select Interval"
                  disabled={dateRange && dateRange.length === 0 ? true : false}
                >
                  {dateRange && dateRange.length ? (
                    dateRange.map((range, index) => {
                      return (
                        <MenuItem key={index} value={range}>
                          {range}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.campaignControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Segment ID
                </InputLabel>
                <Select
                  value={selectedSegment || ''}
                  onChange={(e) => {
                    setSelectedSegment(e.target.value);
                  }}
                  label="Select Segment ID"
                  disabled={segmentsList && segmentsList.length === 0 ? true : false}
                >
                  {segmentsList && segmentsList.length ? (
                    segmentsList.map((segment, index) => {
                      return (
                        <MenuItem key={index} value={segment?.id}>
                          {segment?.name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Segments</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          {/* <Grid item xs={12}>
            <Widget >
              <LineBasicChart interval={selectedInterval} data={graphData} />
            </Widget>
          </Grid> */}
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} headers={headers} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
