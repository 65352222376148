import React, { useEffect, useCallback, useState } from "react";
import {
  Container,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Backdrop,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AppBar,
  Tab,
  Tabs,
  Button,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ErrorIcon from "@material-ui/icons/Error";

// react-jsonschema-form
import Form from "@rjsf/material-ui";
import validator from "@rjsf/validator-ajv8";
import { customizeValidator } from "@rjsf/validator-ajv8";
import { Validator } from "jsonschema";
import debounce from "lodash.debounce";
import uniq from "lodash.uniq";
import { useHistory } from "react-router-dom";

// styles
import useStyles from "./styles";

//helpers
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import { useVendorState } from "../../context/VendorContext";

// component
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { tokenConfig } from "../../context/UserContext";
import _ from "lodash";

const AccordionFieldSet = (props) => {
  const classes = useStyles();
  const { title, properties } = props;
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h4">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {properties.map((element) => element.content)}
      </AccordionDetails>
    </Accordion>
  );
};

const CustomErrorsMessages = (props) => {
  return (
    <Box
      style={{
        color: "#4A4A4A",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        backgroundColor: "#fff",
        padding: "16px",
        marginBottom: "16px",
      }}
    >
      <Typography variant="h6">Custom Errors</Typography>
      <List>
        {props.errors.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <ErrorIcon style={{ color: "red" }} />
            </ListItemIcon>
            <ListItemText>
              <Typography style={{ color: "red" }} variant="body2">
                {item}
              </Typography>
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default function UpdateItem(props) {
  const classes = useStyles();
  const [formData, setFormData] = useState(null);
  const [formSchema, setFormSchema] = useState(null);
  const [formValidator, setFormValidator] = useState(null);
  const [getData, setGetData] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [notification, setnotification] = useState(null);
  const [uiSchema, setUiSchema] = useState({
    "ui:order": ["required", "optional", "extra"],
    required: {
      "ui:ObjectFieldTemplate": AccordionFieldSet,
    },
    optional: {
      "ui:ObjectFieldTemplate": AccordionFieldSet,
    },
    extra: {
      "ui:ObjectFieldTemplate": AccordionFieldSet,
    },
  });
  var vendorState = useVendorState();

  const history = useHistory();

  const productOptionsSchema = {
    title: "Enter details for the product",
    type: "object",
    required: ["vendorCode", "sku", "asin", "marketplaceId"],
    properties: {
      vendorCode: {
        type: "string",
        title: "Vendor Code",
      },
      sku: {
        type: "string",
        title: "SKU",
      },
      asin: {
        type: "string",
        title: "ASIN",
        minLength: 10,
      },
      marketplaceId: {
        type: "string",
        title: "Marketplace ID",
      },
    },
  };

  const [productOptions, setProductOptions] = useState({});
  // Because we operating in one markeplace and with one langauge tag for now
  // TODO: Remove hardcoded language_tag value en_US from the frontend atleast
  const DELETE_KEYS = ["marketplace_id", "language_tag"];

  // These are edge case fields which the current function is not able to handle
  // TODO: Make skipped_fields as dynamic params which can be selected by multiple dropdown
  const SKIP_FIELDS = {
    // Very deeply nested fields which cause issue when simplifying and rendering
    purchasable_offer: true,
    cable: true
  };

  // TODO: Move these functions to utils and make them pure
  // Simplifies the schema
  function processItem(param) {
    const item = _.cloneDeep(param);
    const { type } = item;
    delete item.examples;

    if (["string", "number", "integer", "boolean", "null"].indexOf(type) >= 0) {
      if (item?.anyOf) {
        for (const value of item.anyOf) {
          if (value?.enum) {
            value.title = "Select from options";
          } else {
            value.title = "Enter custom value";
          }
        }
      }
      return item;
    }

    if (type == "object") {
      let { properties, required } = item;
      required = required.filter((key) => {
        if (DELETE_KEYS.indexOf(key) >= 0) {
          delete properties[key];
          return false;
        }
        return true;
      });
      if (Object.keys(properties).length == 0) return null;

      for (const key of Object.keys(properties)) {
        properties[key] = processItem(properties[key]);
      }
      if (Object.keys(properties).length == 1) {
        const key = Object.keys(properties)[0];
        return properties[key];
      }

      item.properties = properties;
      item.required = required;
      return item;
    }

    if (type == "array") {
      let { selectors, maxItems, maxUniqueItems = 0 } = item;

      if (maxItems && maxItems == 1) {
        return processItem(item.items);
      }

      if (selectors) {
        selectors = selectors.filter((key) => DELETE_KEYS.indexOf(key) < 0);

        if (
          selectors.length == 0 ||
          (selectors.length == 1 && maxUniqueItems <= 1)
        ) {
          const { title, description } = item;
          item.items.title = title;
          item.items.description = description;
          return processItem(item.items);
        } else if (selectors.length >= 1 && maxUniqueItems >= 1) {
          item.maxItems = maxUniqueItems;
        }
      }
      item.items = processItem(item.items);
      return item;
    }

    throw new Error("Not a recognized type");
  }

  function parseItem(type, data) {
    switch (type) {
      case "string":
        return data.toString();
      case "number":
      case "integer":
        return isNaN(Number(data)) ? undefined : Number(data);
      case "boolean":
        if (!isNaN(Number(data))) {
          return Boolean(Number(data));
        } else {
          return "true" == data;
        }
      default:
        console.log(type);
        throw new Error("type not matched");
    }
  }

  // Simplifies the payload
  function processPayloadItem(item, matchSchema) {
    const PRIMITIVE_TYPES = ["string", "number", "boolean", "null"];

    // handle the primitive types
    if (
      ["string", "number", "integer", "boolean", "null"].indexOf(
        matchSchema.type
      ) >= 0
    ) {
      let value = null;
      while (true) {
        if (typeof item == "object") {
          let keys = Object.keys(item);
          keys = keys.filter((x) => DELETE_KEYS.indexOf(x) < 0);

          if (keys.length != 1) {
            console.error(
              "SOLVE THIS: keys length should not be greater than 0",
              keys,
              matchSchema
            );
          }
          // item = parseItem(matchSchema.type, item[keys[0]]);
          item = item[keys[0]];
        } else if (PRIMITIVE_TYPES.indexOf(typeof item) >= 0) {
          value = parseItem(matchSchema.type, item);
          break;
        } else {
          throw new Error("unknown type seen");
        }
      }
      // TODO: These are not editable fields. Temporarily turned on for Ops team to edit
      // if (matchSchema?.editable === false && value != "") {
      // matchSchema["readOnly"] = true;
      // matchSchema["disabled"] = false;
      // delete matchSchema["anyOf"];
      // delete matchSchema["oneOf"];
      // delete matchSchema["enum"];
      // delete matchSchema["enumNames"];
      // }
      return [value, matchSchema];
    }

    if (matchSchema.type == "array") {
      const values = [];

      let items = _.cloneDeep(item);
      if (!Array.isArray(items)) {
        let keys = Object.keys(items);
        keys = keys.filter((x) => DELETE_KEYS.indexOf(x) < 0);
        if (keys.length != 1)
          throw new Error("Keys cannot be of length anything other than 1");
        items = [item[keys[0]]];
      }
      for (const value of items) {
        const [obj, schema] = processPayloadItem(value, matchSchema.items);
        matchSchema.items = schema;
        values.push(obj);
      }
      return [values, matchSchema];
    }
    if (matchSchema.type == "object") {
      const value = {};
      let items = _.cloneDeep(item);
      if (Array.isArray(items)) {
        if (items.length != 1 && matchSchema.title != "External Product ID") {
          console.error(
            "SOLVE THIS: keys length should not be greater than 0",
            items,
            matchSchema
          );
        }
        items = items[0];
      }
      for (const key of Object.keys(items)) {
        if (DELETE_KEYS.indexOf(key) >= 0) continue;
        const [obj, schema] = processPayloadItem(
          items[key],
          matchSchema.properties[key]
        );
        matchSchema.properties[key] = schema;
        value[key] = obj;
      }
      return [value, matchSchema];
    }

    throw new Error("Type did not match anything");
  }

  // Converts the simplified data back to payload
  function processData(data, schema) {
    const STATIC_VALUES = {
      marketplace_id: productOptions.marketplaceId,
      language_tag: "en_US",
    };
    if (typeof data == "undefined") {
      return undefined;
    }
    if (["string", "number", "boolean"].indexOf(typeof data) >= 0) {
      if (schema.type == "array") {
        return [processData(data, schema.items)];
      } else if (schema.type == "object") {
        const value = {};
        for (const key of Object.keys(schema.properties)) {
          if (STATIC_VALUES[key]) {
            value[key] = STATIC_VALUES[key];
          } else if (typeof data != "undefined") {
            if (
              ["string", "boolean", "number", "integer"].indexOf(
                schema.properties[key].type
              ) >= 0
            ) {
              value[key] = data;
            } else {
              value[key] = processData(data, schema.properties[key]);
            }
          }
        }
        return value;
      }
      return data;
    } else if (Array.isArray(data)) {
      const value = [];
      if (schema.type == "array") {
        for (const item of data) {
          value.push(processData(item, schema.items));
        }
      } else {
        throw new Error("Schema type is not array");
      }
      return value;
    } else if (typeof data == "object") {
      if (schema.type == "array") {
        return [processData(data, schema.items)];
      } else if (schema.type == "object") {
        const value = {};

        const isSimilarSchema = Object.keys(data).reduce((bool, key) => {
          return bool ? bool : Object.keys(schema.properties).indexOf(key) >= 0;
        }, false);
        if (!isSimilarSchema && Object.keys(data).length > 0) {
          console.log(
            "Schema not same. case not handled",
            data,
            schema.properties
          );
          throw new Error("Schema not same. case not handled");
        }
        for (const key of Object.keys(schema.properties)) {
          if (STATIC_VALUES[key]) {
            value[key] = STATIC_VALUES[key];
          } else if (typeof data[key] != "undefined") {
            if (
              ["string", "boolean", "number", "integer"].indexOf(
                schema.properties[key].type
              ) >= 0
            ) {
              value[key] = data[key];
            } else {
              value[key] = processData(data[key], schema.properties[key]);
            }
          }
        }
        return value;
      }
    } else throw new Error("Unseen type.Case not handled");
  }

  function markNotRequired(schema) {
    const item = _.cloneDeep(schema);
    const { type } = item;

    // Delete required section
    delete item.required;
    if (["string", "number", "integer", "boolean", "null"].indexOf(type) >= 0) {
      return item;
    } else if (type == "object") {
      const { properties } = item;
      for (const key of Object.keys(properties)) {
        properties[key] = markNotRequired(properties[key]);
      }
      item.properties = properties;
      return item;
    } else if (type == "array") {
      delete item.minItems;
      item.items = markNotRequired(item.items);
      return item;
    }
  }

  function validatePayload(data, schema, metaSchema) {
    const payload = _.cloneDeep(data);

    const v = new Validator();
    v.addSchema(metaSchema, metaSchema["$id"]);
    const obj = v.validate(payload, schema);

    return obj;
  }

  function customSchemaChanges(schema) {
    // Bullet_points is an array with a max 5 items
    if (schema.properties.bullet_point) {
      schema.properties.bullet_point = {
        ...schema.properties.bullet_point,
        selectors: ["marketplace_id", "language_tag", "value"],
        maxUniqueItems: 5,
        maxItems: 5,
      };
    }

    // merchant_suggested_asin is a required field
    schema.required = [...schema.required, "merchant_suggested_asin"];
    return schema;
  }

  function customPayloadChanges(payload) {
    // Auto populate merchant_suggested_asin
    if (payload.attributes.merchant_suggested_asin) {
      const merchant_suggested_asin =
        payload.attributes.merchant_suggested_asin;
      const messages = [];
      if (merchant_suggested_asin.length > 1) {
        messages.push("More than one asin in merchant_suggested_asin");
      }
      if (merchant_suggested_asin[0].value != productOptions.asin) {
        messages.push("Requested asin and payload asin not matching");
      }
      setErrorMessage(messages);
    } else {
      payload.attributes.merchant_suggested_asin = [
        {
          value: productOptions.asin,
          marketplace_id: productOptions.marketplaceId,
        },
      ];
    }

    return payload;
  }

  async function loadProductData(productOptions) {
    try {
      setfullPageLoader(true);
      const response = await axios.get(
        BASE_URL + api.spListing + productOptions.vendorCode,
        tokenConfig(productOptions)
      );

      const productSchema = response.data.data.productSchema;
      const schema = customSchemaChanges(productSchema.schema);
      const metaSchema = productSchema.metaSchema;

      // TODO: Auto populate value in merchant_suggested_asin if it's empty and verify if it's the same ASIN
      const payload = customPayloadChanges(response.data.data.payload);

      setGetData({
        payload,
        productSchema: {
          schema,
          metaSchema,
        },
      });
      setfullPageLoader(false);
    } catch (err) {
      const { response } = err;
      if (response.data && response.data.message) {
        const { message, errors } = response.data;
        const messages = errors ? [message, ...errors] : [message];
        setErrorMessage(messages);
      } else {
        setErrorMessage(["Error occurred while loading data"]);
      }
      setfullPageLoader(false);
    }
  }

  function createSchema(productSchema, attributes) {
    const schema = {
      type: "object",
      properties: {},
      required: ["required", "optional", "extra"],
    };

    schema.properties["required"] = productSchema.required.reduce(
      (obj, key) => {
        if (SKIP_FIELDS[key]) return obj;
        obj.properties[key] = processItem(productSchema["properties"][key]);
        return obj;
      },
      {
        type: "object",
        properties: {},
        required: productSchema.required,
        title: "Required",
        description:
          "All these fields are required and must not be empty for this listing.",
      }
    );

    schema.properties["optional"] = Object.keys(attributes).reduce(
      (obj, key) => {
        if (SKIP_FIELDS[key]) return obj;

        // Skip if already covered in required fiels
        if (schema.properties.required.properties[key]) return obj;
        obj.properties[key] = processItem(productSchema["properties"][key]);
        return obj;
      },
      {
        type: "object",
        properties: {},
        title: "Optional",
        description:
          "All these fields are optional but already exist in the current listing.",
      }
    );

    schema.properties["extra"] = Object.keys(productSchema.properties).reduce(
      (obj, key) => {
        if (SKIP_FIELDS[key]) return obj;
        if (
          schema.properties.required.properties[key] ||
          schema.properties.optional.properties[key]
        )
          return obj;
        let itemSchema = processItem(productSchema["properties"][key]);
        itemSchema = markNotRequired(itemSchema);
        obj.properties[key] = itemSchema;
        return obj;
      },
      {
        type: "object",
        properties: {},
        title: "Extra",
        description:
          "All these fields are optional but don't yet exist in the current listing.",
      }
    );

    return schema;
  }

  function makePayload(formData) {
    const productSchema = getData.productSchema.schema;
    const {
      required = {},
      optional = {},
      extra = {},
    } = removeEmptyObjectsAndArrays(_.cloneDeep(formData));

    const payload = [required, optional, extra].reduce((obj, fields) => {
      // Convert all the fields back to their original values
      const data = Object.keys(fields).reduce((data, key) => {
        data[key] = processData(fields[key], productSchema.properties[key]);
        return data;
      }, {});
      return { ...obj, ...data };
    }, {});

    for (const key in SKIP_FIELDS) {
      if (getData.payload.attributes[key]) {
        payload[key] = getData.payload.attributes[key];
      }
    }

    return payload;
  }

  const onSubmit = ({ formData }) => {
    const productSchema = getData.productSchema.schema;
    const metaSchema = getData.productSchema.metaSchema;
    const attributes = makePayload(formData);
    const validationObj = validatePayload(
      attributes,
      productSchema,
      metaSchema
    );

    const { productType, requirements } = getData.payload;

    const payload = {
      productType,
      requirements,
      attributes,
    };

    // Show validation errors on UI

    if (validationObj.errors.length != 0) {
      console.log("validation obj", validationObj, attributes);
      const messages = [];
      for (const { message, property } of validationObj.errors) {
        messages.push(message + property);
      }
      messages.sort();
      setErrorMessage(messages);
      return null;
    } else {
      setErrorMessage([]);
    }

    // start loader and send API request
    (async () => {
      setfullPageLoader(true);
      try {
        const response = await axios.post(
          BASE_URL + api.spListing + productOptions.vendorCode,
          payload,
          tokenConfig(productOptions)
        );

        setnotification(response.data.message);
        setTimeout(() => {
          setnotification(null);
        }, 2000);
      } catch (err) {
        const { response } = err;
        if (response.data && response.data.message) {
          const { message, errors } = response.data;
          const messages = errors ? [message, ...errors] : [message];
          setErrorMessage(messages);
        }
      } finally {
        setfullPageLoader(false);
      }
    })();
  };

  function removeEmptyObjectsAndArrays(obj) {
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value == "object") {
        obj[key] = removeEmptyObjectsAndArrays(value);
        if (JSON.stringify(value) == "[]" || JSON.stringify(value) == "{}") {
          delete obj[key];
        }
      }
    }
    return obj;
  }
  const onChange = ({ formData }) => {
    setFormData(removeEmptyObjectsAndArrays(_.cloneDeep(formData)));
  };

  // 300ms second delay
  const debouncedChangeHandler = useCallback(debounce(onChange, 300), [
    formData,
    formSchema,
    getData,
  ]);

  useEffect(() => {
    if (!getData) {
      return;
    }
    const productSchema = getData.productSchema.schema;
    const attributes = getData.payload.attributes;

    const schema = createSchema(productSchema, attributes);

    const metaSchemaId = getData.productSchema.metaSchema["$id"];
    const validator = customizeValidator({
      [metaSchemaId]: getData.productSchema.metaSchema,
    });

    setFormValidator(validator);

    // Prefill the form and Disable not editable but non-empty fields
    const formData = Object.keys(attributes).reduce(
      (obj, key) => {
        if (SKIP_FIELDS[key]) return obj;

        const isRequired = productSchema.required.indexOf(key) >= 0;

        const rootKey = isRequired ? "required" : "optional";
        const itemSchema = schema.properties[rootKey].properties[key];

        const [convertedItem, updatedSchema] = processPayloadItem(
          attributes[key],
          itemSchema
        );
        obj[rootKey][key] = convertedItem;
        schema.properties[rootKey].properties[key] = updatedSchema;

        return obj;
      },
      {
        required: {},
        optional: {},
      }
    );

    for (const [key, value] of Object.entries(
      schema.properties.required.properties
    )) {
      if (value.type == "boolean") {
        formData.required[key] =
          formData.required[key]?.toString()?.toLowerCase() == "true"
            ? true
            : false;
      }
    }

    const updatedUiSchema = uiSchema;
    for (const [key, value] of Object.entries(schema.properties)) {
      for (const [field, schema] of Object.entries(value.properties)) {
        if (schema.editable === false && formData?.[key]?.[field]) {
          updatedUiSchema[key] = {
            ...updatedUiSchema[key],
            [field]: {
              "ui:options": {
                style: {
                  opacity: "0.6",
                },
              },
            },
          };
        }
      }
    }

    setFormSchema(schema);
    setUiSchema(updatedUiSchema);
    setFormData(formData);
  }, getData);

  useEffect(() => {
    if (props?.location?.state && vendorState?.selected) {
      const { sku, asin, marketplaceId } = props.location.state;
      if (sku && asin && marketplaceId) {
        setProductOptions({
          vendorCode: vendorState.selected,
          asin,
          sku,
          marketplaceId,
        });
      }
    }

    return () => {
      setProductOptions({});
    };
  }, [props.location.state, vendorState.selected]);

  // Load the data from the API
  useEffect(() => {
    const { asin, sku, marketplaceId, vendorCode } = productOptions;
    if (asin && sku && marketplaceId && vendorCode) {
      setErrorMessage([]);
      console.log("Loading data", productOptions);
      loadProductData(productOptions);
    } else {
      setErrorMessage([
        "Either one or more of asin, sku or marketplaceId is missing",
      ]);
    }

    return () => {
      setGetData(null);
    };
  }, [productOptions]);

  const onProductOptionsSubmit = ({ formData }) => {
    setFormData(null);
    setFormSchema(null);
    setErrorMessage([]);
    const { asin, sku, marketplaceId, vendorCode } = formData;
    history.push("/app/catalog/updateItem", {
      sku,
      asin,
      marketplaceId,
      vendorCode,
    });
  };

  const [tabValue, setTabValue] = useState(0);
  const [showJSON, setShowJSON] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const log = (type) => console.log.bind(console, type);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Update item"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="primary"
                className={classes.link}
                onClick={() => history.push("/app/catalog/catalogItems")}
              >
                Catalog
              </Link>
              <Typography className={classes.link} color="inherit">
                Update Item
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Form
              schema={productOptionsSchema}
              validator={validator}
              onSubmit={onProductOptionsSubmit}
              formData={productOptions}
              noHtml5Validate
            />
          </Grid>

          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowJSON(!showJSON)}
            >
              Show JSON
            </Button>
            {showJSON && (
              <>
                <AppBar position="static">
                  <Tabs value={tabValue} onChange={handleChange}>
                    <Tab label="Simplified Schema" />
                    <Tab label="Simplified Payload" />
                    <Tab label="Original Schema" />
                    <Tab label="Original Payload" />
                    <Tab label="Changed Payload" />
                  </Tabs>
                </AppBar>
                {tabValue == 0 && (
                  <pre value={tabValue} index={0}>
                    {JSON.stringify(formSchema, null, 2)}
                  </pre>
                )}
                {tabValue == 1 && (
                  <pre value={tabValue} index={1}>
                    {JSON.stringify(formData, null, 2)}
                  </pre>
                )}
                {tabValue == 2 && (
                  <pre value={tabValue} index={2}>
                    {JSON.stringify(getData?.productSchema?.schema, null, 2)}
                  </pre>
                )}
                {tabValue == 3 && (
                  <pre value={tabValue} index={3}>
                    {JSON.stringify(getData?.payload, null, 2)}
                  </pre>
                )}

                {tabValue == 4 && (
                  <pre value={tabValue} index={4}>
                    {JSON.stringify(makePayload(formData), null, 2)}
                  </pre>
                )}
              </>
            )}
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setProductOptions({ ...productOptions })}
            >
              Discard Changes & Reload Form
            </Button>
          </Grid>

          <Grid item xs={12} style={{ position: "relative" }}>
            {errorMessage.length > 0 && (
              <CustomErrorsMessages errors={errorMessage} />
            )}
            {formSchema && (
              <>
                <Form
                  schema={formSchema}
                  validator={formValidator}
                  onSubmit={onSubmit}
                  uiSchema={uiSchema}
                  onError={log("errors")}
                  formData={formData}
                  onChange={debouncedChangeHandler}
                  noHtml5Validate
                  noValidate
                />
                <Button
                  color="default"
                  variant="contained"
                  style={{
                    position: "absolute",
                    bottom: "16px",
                    left: "125px",
                  }}
                  onClick={() => history.push("/app/catalog/catalogItems")}
                >
                  Cancel
                </Button>
              </>
            )}
          </Grid>
          {notification ? (
            <Alert
              severity="success"
              style={{ position: "relative", bottom: "3.7rem", left: "12rem" }}
            >
              {notification}
            </Alert>
          ) : (
            <></>
          )}
        </Grid>
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
