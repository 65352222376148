import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
// components

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const getClass = (params, headers = []) => {
      const index = (headers || []).indexOf(params.colDef.field)
      if(index <= 1) {
        return;
      }
   //   if(params?.data[headers[index-1]] > params?.data[headers[index]]) {
		if( Number.isInteger(params?.data[headers[index-1]]) && Number.isInteger(params?.data[headers[index]]) && params?.data[headers[index-1]] > params?.data[headers[index]]) {
        return { 
          backgroundColor: '#d8e9d2',
          borderBottom: '1px solid rgba(178,178,178,0.25)'
        };
      }
  }

  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={20}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
      >
        <AgGridColumn headerName={''} field={'favicon'} width={100} cellRenderer={renderImage}/>
        {
          (props.headers || []).map((ele, i) => {
            return (
              <AgGridColumn cellStyle={(params) => getClass(params, props.headers)} key={i} headerName={ele.toUpperCase()} field={ele} width={160}/>
            )
          })
        }


      </AgGridReact>
    </div>
  );
})

const renderImage = ({ value }) => `<img style="height: 40px; width: 40px" src="${value}"`;