import {
  Grid,
  Box,
  Checkbox,
  CardContent,
  InputLabel,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles";

export default function SelectProducts({
  options,
  selectedProducts,
  selectedAllProducts,
  onSelectAllProducts,
  onChangeProducts,
  fullPageLoader,
}) {
  const classes = useStyles();

  return (
    <Grid container>
      {fullPageLoader ? (
        <Backdrop className={classes.backdrop} open={fullPageLoader}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Box className={classes.boxWrapper}>
            <Checkbox
              id="select-all"
              checked={selectedAllProducts}
              onChange={onSelectAllProducts}
              classes={{ root: classes.root }}
            />
            <InputLabel htmlFor="select-all" className={classes.selectAllLabel}>
              {selectedAllProducts ? "Clear All" : "Select All"}
            </InputLabel>
          </Box>
          <Box className={classes.productsList}>
            {options.map((product, indx) => (
              <Box className={classes.boxWrapper} key={indx}>
                <Checkbox
                  id={product.productName}
                  key={product.productName}
                  name={product.productName}
                  checked={selectedProducts.some(
                    (selectedProduct) =>
                      selectedProduct.productName === product.productName
                  )}
                  onChange={(e) => onChangeProducts(e, product)}
                  classes={{ root: classes.root }}
                />
                <CardContent>
                  <InputLabel
                    className={classes.cardFont}
                    htmlFor={product.productName}
                  >
                    {product.productName}
                    {product?.sku ? `- ${product?.sku}` : ""}
                  </InputLabel>
                </CardContent>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Grid>
  );
}
