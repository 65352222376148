import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../healpers/api";
import api from "../../../../healpers/apiRoutes";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5, 10, 5),
    borderRadius: 5,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 230,
  },
}));

export default function TransitionsModal({
  open,
  handleOpen,
  handleClose,
  seterr,
  setnotification,
  fetchData,
}) {
  const [domain, setDomain] = useState("");
  const [domainType, setDomainType] = React.useState("included");

  const handleDomainType = (event) => {
    setDomainType(event.target.value);
  };

  const blockDomain = () => {
    axios
      .post(BASE_URL + api.blockDomain, { domain, type: domainType })
      .then((response) => setnotification(response.data.msg))
      .catch((error) => seterr(error.response.data.msg))
      .finally(() => {
        setDomain("");
        setTimeout(() => {
          seterr(null);
          setnotification(null);
        }, 2000);
        fetchData();
      });
  };
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Typography color="primary" variant="h3">
              Block Domain
            </Typography>
            <form
              className={classes.root}
              noValidate
              autoComplete="off"
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              <Box
                display={"flex"}
                flexDirection="column"
                justifyContent={"center"}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  label="Enter domain to block"
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  style={{marginBottom: 20}}
                />
                <FormControl variant="outlined" className={classes.formControl} style={{marginBottom: 20}}>
                  <InputLabel id="domain-type">
                    Domain Type
                  </InputLabel>
                  <Select
                    labelId="domain-type"
                    id="demo-simple-select-outlined"
                    value={domainType}
                    onChange={handleDomainType}
                    label="Age"
                  >
                    <MenuItem value={"included"}>Included</MenuItem>
                    <MenuItem value={"endswith"}>Endswith</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  color="primary"
                  variant="contained"
                  component="span"
                  size="medium"
                  onClick={blockDomain}
                  disabled={!domain}
                >
                  Block
                </Button>
              </Box>
            </form>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
