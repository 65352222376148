/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  CircularProgress,
  Backdrop
} from "@material-ui/core";
import moment from "moment";
import "date-fns";
//icons
import EqualizerIcon from "@material-ui/icons/Equalizer";
import PrintIcon from "@material-ui/icons/Print";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import Table from "./components/Table/Table";
//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchShareOfVoiceData, fetchSOVRanges } from "../../http/api";

import useStyles from "./styles";

export default function Charts(props) {
  const exportFunctionRef = useRef();
  //global
  const classes = useStyles();
  var vendorState = useVendorState();

  // local
  const [dateRanges, setDateRanges] = useState([]);
  const [searchTerms, setSearchTerms] = useState([]);
  const [mainTableData, setmainTableData] = useState([]);
  const [selectedDateRange, setselectedDateRange] = useState({});
  const [selectedSearchTerm, setselectedSearchTerm] = useState('');
  const [fullPageLoader, setfullPageLoader] = useState(false);
  
  useEffect(() => {
    setfullPageLoader(true);
    fetchSOVRanges(vendorState?.selected)
      .then(({ dates, sTerms }) => {
        setDateRanges(dates);
        setSearchTerms(sTerms);
        const range = `${dates[dates.length-1].startDate} - ${dates[dates.length-1].endDate}`;
        setselectedDateRange(range)
        setselectedSearchTerm(sTerms[0])
        setfullPageLoader(false);       
      }).catch((err) => {
        console.error('Failed to fetch the ranges')
        setfullPageLoader(false);
      })

      return () => {
        setDateRanges([]);
        setSearchTerms([]);
        setselectedDateRange({})
        setselectedSearchTerm('')  
      }
  }, [ vendorState?.selected ])

  useEffect(() => {
    async function fetchData() {
      try {

        setfullPageLoader(true);
        const [startDate, endDate] = selectedDateRange.split(' - ');
        const { filteredData } = await fetchShareOfVoiceData(vendorState.selected, startDate, endDate, selectedSearchTerm)

        setmainTableData(filteredData);
        
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if(selectedDateRange && selectedSearchTerm) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
    };

  }, [selectedDateRange, selectedSearchTerm]);

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Share of Voice"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                <EqualizerIcon className={classes.icon} />
                Business Intelligence
              </Link>
              <Typography className={classes.link}>
                <EqualizerIcon color="primary" className={classes.icon} />
                Share of Voice
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.campaignControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Date
                </InputLabel>
                <Select
                  value={selectedDateRange}
                  onChange={(e) => {
                    setselectedDateRange(e.target.value);
                  }}
                  label="Select Range"
                  disabled={dateRanges && dateRanges.length === 0 ? true : false}
                >
                  {dateRanges && dateRanges.length ? (
                    dateRanges.map((range, index) => {
                      return (
                        <MenuItem value={range.startDate + " - " + range.endDate}>
                          {range.startDate + " - " + range.endDate}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Search Term
                </InputLabel>
                <Select
                  value={selectedSearchTerm}
                  onChange={(e) => {
                    setselectedSearchTerm(e.target.value);
                  }}
                  label="Select Search Term"
                  disabled={searchTerms && searchTerms.length === 0 ? true : false}
                >
                  {searchTerms && searchTerms.length ? (
                    searchTerms.map((name) => {
                      return (
                        <MenuItem value={name}>
                          {name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No SearchTerms</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
