export default function ArrayFormatting(props) {
  const { value } = props;
  // let allNew = Array.isArray(value[0]);
  let removedItems = [];
  let newItems = [];
  let changedItems = [];
  // if (!allNew) {
    let a = Object.entries(value).filter((e) => !e[0].startsWith("_"));
    let _a = Object.entries(value)
      .filter((e) => e[0].startsWith("_") && !e[0].startsWith("_t"))
      .map((item) => [item[0].substring(1), item[1][0]]);
    let _a_keys = JSON.parse(JSON.stringify(_a)).map((item) => item[0]);
    let a_keys = JSON.parse(JSON.stringify(a)).map((item) => item[0]);

    for (const _aa of _a) {
      if (!a_keys.includes(_aa[0])) newItems.push(_aa[1]);
    }
    for (const aa of a) {
      if (!_a_keys.includes(aa[0])) removedItems.push(aa[1]);
      for (const _aa of _a) {
        if (_aa[0] === aa[0]) {
          changedItems.push(_aa[1]);
        }
      }
    }
  // }
  // console.log("newItems", newItems);
  // console.log("changedItems", changedItems);
  // console.log("removedItems", removedItems);
  return (
    <>
    <span>
      <span >
        {removedItems.length ?
          removedItems.map((item, indx) => (
            <>
              <br/>
              <tr style={{ backgroundColor: "lightpink" }}>
                <td style={{ width: 200, paddingRight: 10}}>
                  {item.toString().split("-")[0]}
                </td>
                <td style={{ width: 100, paddingRight: 10}}>
                  {item.toString().split("-")[1]}
                </td>
                <td>
                  {item.toString().split("-")[2]}
                </td>
              </tr>
            </>
          )): null}
      </span>
      {changedItems.length ? (
        <>
          <span>
            {changedItems.length &&
              changedItems.map((item, indx) => (
                <>
                  <br/>
                  <tr style={{ backgroundColor: "lightyellow" }}>
                    <td style={{ width: 200, paddingRight: 10}}>
                      {item.toString().split("-")[0]}
                    </td>
                    <td style={{ width: 100, paddingRight: 10}}>
                      {item.toString().split("-")[1]}
                    </td>
                    <td>
                      {item.toString().split("-")[2]}
                    </td>
                  </tr>
                </>
              ))}
          </span>
        </>
      ): null}
      {newItems.length ? (
        <>
          <span>
            {newItems.length &&
              newItems.map((item, indx) => (
                <>
                <br/>
                  <tr style={{ backgroundColor: "lightgreen" }}>
                    <td style={{ width: 200, paddingRight: 10}}>
                      {item.toString().split("-")[0]}
                    </td>
                    <td style={{ width: 100, paddingRight: 10}}>
                      {item.toString().split("-")[1]}
                    </td>
                    <td>
                      {item.toString().split("-")[2]}
                    </td>
                  </tr>
                </>
              ))}
          </span>
        </>
      ): null}
    </span>
  </>
  )
}
