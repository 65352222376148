import React, { useState} from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,

  Box,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";

// styles
import useStyles from "./styles";

// context
import {
  useUserDispatch,
  useUserState,
} from "../../context/UserContext";
import { usePopupState, usePopupDispatch } from "../../context/PopupContext";
import axios from "axios";
import { BASE_URL } from "../../healpers/api";
import {
  ADD_ERROR,
  ADD_NOTIFICATION,
  CLEAR_ERRORS,
  CLEAR_NOTIFICATION,
} from "../../context/types";
import { toProperCase } from "../../healpers/utilityFunctions";


function Register(props) {
  var classes = useStyles();

  // global
  var userState = useUserState();
  var userDispatch = useUserDispatch();
  var popupDispatch = usePopupDispatch();
  var { err, notification } = usePopupState();

  // local
  var [isLoading, setIsLoading] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [vendorCode, setVendorCode] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [domainName, setDomainName] = useState("");

  async function registerUser() {
    setIsLoading(true);
    const url = BASE_URL + "/api/user/signup";
    try{
      if(password.length < 8){
        throw new Error("Password must be at least 8 characters long");
      }
      const body = {
        email,
        password,
        // vendorCode,
        customerName,
        // countryCode,
      }
      if(domainName.length > 0) {
        body.domainName = domainName
      }
      const res = await axios.post(url, body);
      popupDispatch({ type: ADD_NOTIFICATION, payload: res.data.msg });
      setTimeout(() => {
        popupDispatch({ type: CLEAR_NOTIFICATION });
      }, 2000);
      setIsLoading(false);
      props.history.push("/login");
    }catch(error){
      popupDispatch({ type: ADD_ERROR, payload: error?.response?.data?.msg || error.message });
      setTimeout(() => {
        popupDispatch({ type: CLEAR_ERRORS });
      }, 2000);
      setIsLoading(false);
    }
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <Typography className={classes.logotypeText}>
          Dashboard Register
        </Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Typography variant="h1" className={classes.greeting}>
              Create Account
            </Typography>
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              margin="normal"
              placeholder="Email*"
              type="email"
              fullWidth
            />
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              placeholder="Password*"
              type="password"
              fullWidth
            />
            {/* <TextField
              id="vendorCode"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={vendorCode}
              onChange={(e) => setVendorCode(e.target.value.toUpperCase())}
              margin="normal"
              placeholder="Vendor Code*"
              type="text"
              inputProps={{ maxLength: 6 }}
              fullWidth
            /> */}
            <TextField
              id="customerName"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={toProperCase(customerName)}
              onChange={(e) => setCustomerName(e.target.value)}
              margin="normal"
              placeholder="Brand Name*"
              type="text"
              fullWidth
            />
            {/* <TextField
              id="countryCode"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={countryCode}
              onChange={(e) => setCountryCode(e.target.value.toUpperCase())}
              margin="normal"
              placeholder="Country Code*"
              type="text"
              inputProps={{ maxLength: 2 }}
              fullWidth
            /> */}
            <TextField
              id="domainName"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={domainName}
              onChange={(e) => setDomainName(e.target.value)}
              margin="normal"
              placeholder="Domain Name"
              type="text"
              fullWidth
            />

            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={
                    !email ||
                    !password ||
                    // !vendorCode ||
                    !customerName
                    // !countryCode
                    // !domainName
                  }
                  onClick={registerUser}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Register
                </Button>
              )}
            </div>
            <Box marginTop={2}>
              Already registered?
              <Button
                color="primary"
                size="large"
                className={classes.forgetButton}
                onClick={() => props.history.push("/login")}
              >
                Login
              </Button>
            </Box>
          </React.Fragment>
        </div>
      </div>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "absolute", bottom: 30, left: "48%" }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </Grid>
  );
}

export default withRouter(Register);
