import React, { useState , useRef, useEffect } from "react";
import {
  Container,
  Button,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";


// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
//import DashedLineChart from "./components/LineChart/LineChart";
//import BarChart from "./components/BarChart/BarChart";
import Table from "./components/Table/Table";

//context
import { useLayoutState } from "../../context/LayoutContext";
import { useVendorState } from "../../context/VendorContext";
import { tokenConfig } from "../../context/UserContext";
//helpers

import { getWeekDatesBetween } from "../../healpers/utilityFunctions";
import { BASE_URL } from "../../healpers/api";
import api from "../../healpers/apiRoutes";
import pages from "../../healpers/pageCodes";
import { cloneAndSortDescending } from "../../healpers/dateRange.helpers";

export default function CampaignPerformancePage() {
  
  const classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var layoutState = useLayoutState();
  var vendorState = useVendorState();
  // local
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [dateRange, setdateRange] = useState(null);
  const [selectedDateRange, setselectedDateRange] = useState(0);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    async function fetchDates() {
      const {
        data: { data },
      } = await axios.get(
        BASE_URL + api.getAMSCampaignReportsDateRange + vendorState.selected,
        tokenConfig()
      );
      const weekDateRange = cloneAndSortDescending([
        ...getWeekDatesBetween(new Date(data.startDate), new Date(data.endDate)),
      ]);
      setdateRange(weekDateRange);
    }

    if(vendorState.selected) {
      fetchDates();
    }
    return () => {
      setdateRange(null);
    };
  }, [vendorState.selected]);
  

  useEffect(() => {
    async function loadData() {
      try {
        setfullPageLoader(true);
                
        axios
          .get(
            BASE_URL + api.getAMSCampaignReports,
            tokenConfig({
              startDate: moment(dateRange[selectedDateRange].startdate).format(
                "YYYY-MM-DD"
              ),
              endDate: moment(dateRange[selectedDateRange].enddate).format(
                "YYYY-MM-DD"
              ),
              vendorCode: vendorState.selected,
            })
          )
          .then((result) => {
            if (result.data) {
              setTableData(result.data);
              setfullPageLoader(false);
            }
            // else {
            //   fetchDataFromAirtable();
            // }
          })
          .catch((err) => {
            console.log(err);
            // fetchDataFromAirtable();
            setfullPageLoader(false);
          });
      } catch (error) {
        console.log(error);
      }
    }

    if (dateRange && dateRange.length != 0) {
      loadData();
    }

    return () => {
      setTableData([]);
    };
  }, [dateRange, selectedDateRange]);
  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Products Not Serving"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                color="inherit"
                // href="/#/app/dashboard"
                className={classes.link}
              >
                Advertising
              </Link>
              <Typography className={classes.link}>
                Products Not Serving
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.formControl} style={{ marginRight: "1%" }}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select Range
                </InputLabel>
                <Select
                  value={selectedDateRange}
                  onChange={(e) => {
                    setselectedDateRange(e.target.value);

                  }}
                  label="Select Range"
                  disabled={dateRange && dateRange.length === 0 ? true : false}
                >
                  {dateRange ? (
                    dateRange.map((range, index) => {
                      return (
                        <MenuItem value={index}>
                          {range.startdate + " - " + range.enddate}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No Dates</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              disableWidgetMenu
            >
              <Table tableData={tableData} />
            </Widget>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
