import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine
} from "recharts";
import { noCurve } from "../../../../healpers/noCurve";
import {
  numberWithCommas,
} from "../../../../healpers/utilityFunctions";

// components
import Dot from "../../../../components/Sidebar/components/Dot";

// context
import { useLayoutState } from "../../../../context/LayoutContext";

const CustomTooltip = (props) => {
  if (props.active) {
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffff",
          padding: "5px",
          border: "1px solid #cccc",
        }}
      >
        <label style={{ fontWeight: "bold" }}>
          <u>
            <i>{props.label}</i>
          </u>
        </label>
        <br />
        {props.payload.map((asin) => {
          return (
            <>
              <div style={{ display: "flex", marginTop: 10 }}>
                <div style={{ marginTop: 5 }}>
                  <Dot color={asin.color} legend />
                </div>
                <div style={{ marginLeft: 10 }}>
                  {`${asin.dataKey}: ${numberWithCommas(
                    asin.value
                  )}`}
                </div>
              </div>
            </>
          );
        })}
      </div>
    );
  }

  return null;
};

export default function App({ colors, linechartData, asinList }) {

  // global
  var layoutState = useLayoutState();

  if (asinList.length !== 0)
    return (
      <LineChart width={1000} height={500} data={linechartData} style={{ textAlign: "center", margin: `0 ${layoutState.isSidebarOpened ? 0 : 10 }%` }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="key" padding={{ left: 30, right: 30 }} />
        <YAxis />
        <ReferenceLine x="Week 0" stroke="red" label="Go live" />
        <Tooltip content={<CustomTooltip />} />
        {asinList.length !== 0 ? (
          asinList.map((asin, index) => {
            return (
              <Line
                type="monotone"
                type={noCurve}
                dataKey={asin}
                stroke={colors[index]}
                // dot={<CustomizedDot count={index + 1} />}
              />
            );
          })
        ) : (
          <></>
        )}
      </LineChart>
    );
  else
    return (
      <h3 style={{ textAlign: "center", margin: "16% 0" }}>
        ...No Data To Show...
      </h3>
    );
}
