import moment from 'moment';

export const modifyPriceAndWarData = (asins, data = [], productDetails=[]) => {
    let obj = {};
    const tableData = []
    if(!data?.length || !productDetails?.length) {
        return tableData;
    }
    asins.forEach((asin) => {
        obj = {}
        obj.asin = asin;
		    // add product details
		    obj.sku = productDetails.find(elem => elem?.asin === asin)?.sku;
		    obj.title = productDetails.find(elem => elem?.asin === asin)?.productTitle;
		    obj.favicon = productDetails.find(elem => elem?.asin === asin)?.favicon;
		
        const asinsLevelData = data.filter(ele => ele?.asin === asin);
        if(!asinsLevelData.every(ele => +ele?.estimatedUnitsSold === 0)) {
            asinsLevelData.forEach((asinData) => {
                let date = moment(asinData?.date).format('MM/DD/YYYY');
                obj[date] = asinData?.estimatedUnitsSold;
            })
            tableData.push(obj)
        }
    });
    return tableData
}

export const fetchPriceWarGraphData = (asins, data) => {
    let obj = {};
    const graphData = []
    asins.forEach((asin) => {
        obj = {}
        obj.name = asin;
        obj.data = data.filter(ele => ele?.asin === asin).map(ele => ele?.estimatedUnitsSold) || [];
        if(!obj.data.every(ele => +ele === 0)) {
             graphData.push(obj)
        }
    });
    return graphData
}

export const modifyCompetitionSalesByASIN = (data) => {
    return data.map(ele => {
        return {
            ...ele,
            revenue: '$' + Number(parseFloat(ele?.revenue || 0)?.toFixed(2)).toLocaleString(),
            estimatedUnitsSold: Math.round(+ele?.estimatedUnitsSold || 0),
            _avg: undefined
        }
    })
}