import moment from 'moment';

export const modifyProductAndReviewData = (data, asin) => {
    let map = new Map();
    return data.map((ele) => {
        if (!map.get(ele?.asin?.original) || asin) {
            map.set(ele?.asin?.original, true);
            const aggData = data.filter(elem1 => elem1?.asin?.original === ele?.asin?.original)
            const avgRating = (aggData.map(ele => ele.rating).reduce((a,b) => a+b, 0))/aggData.length
            return {
                ...ele,
                parentASIN: ele?.asin?.original,
                childASIN: ele?.asin?.variant,
                date: moment(ele?.date).format('MM/DD/YYYY'),
                verifiedPurchase: ele?.verifiedPurchase ? "True": "False",
                avgRating: Number.isSafeInteger(avgRating)? avgRating : avgRating.toFixed(1),
                numberOfReviews: aggData.length
            }
        }
        return null;
    }).filter(ele => !!ele);
}

export const modifyProductAndReviewDataAll = (data, asin) => {
    let map = new Map();
    return data.map((ele) => {
        if (!map.get(ele?.asin?.original) || asin) {
            map.set(ele?.asin?.original, true);
            const aggData = data.filter(elem1 => elem1?.asin?.original === ele?.asin?.original)
            const avgRating =  (aggData.map(ele => ele.rating).reduce((a,b) => a+b, 0))/aggData.length
            return {
                ...ele,
                parentASIN: ele?.asin?.original,
                childASIN: ele?.asin?.variant,
                date: moment(ele?.date).format('MM/DD/YYYY'),
                avgRating: Number.isSafeInteger(avgRating)? avgRating : avgRating.toFixed(1),
                numberOfReviews: aggData.length
            }
        }
        return null;
    }).filter(ele => !!ele);
}
