// eslint-disable-next-line import/no-anonymous-default-export
export default {
    // getAllVendors:"/api/get/vendorList",
    getCache:"/api/get/",
    setCache:"/api/set/",
    loginUser:"/api/user/login",
    registerUser:"/api/user/register",
    listUsers:"/api/user/fetchAll",
    updateUser: "/api/user/updateUser",
    userVendorConfigs:"/api/user/vendorConfigs",
    forgotPassword:"/api/user/forgotPassword",
    resetPassword:"/api/user/resetPassword",
    uploadProductDetails:"/api/productDetails/upload",
    uploadProductDetailsV2:"/api/productDetails/upload/v2",
    uploadASINSKUMapping:"/api/productDetails/ASINSKUMapping/upload",
    uploadProductDetailsHiRes:"/api/productHiResImages/upload",
    listProductDetails:"/api/productDetails/list/",
    listProductDetailsHiRes:"/api/productHiResImages/list/",
    //download
    downloadProductDetails:"/api/productDetails/download/",
    downloadProductDetailsHiRes:"/api/productHiResImages/download/",
    getOrderMetrics: "/api/orderMetrics/fetch",
    getOrderMetricsASIN: "/api/orderMetrics/fetch/asin" ,
    getASINs: "/api/orderMetrics/fetch/asins",
    getShareOfVoice: "/api/ShareOfVoice/fetch" ,
    getShareOfVoiceRanges: "/api/ShareOfVoice/fetch/ranges",
    getBrandSegmentByDay: "/api/brandSegments/fetch",
    getBrandSegmentByDayRanges: "/api/brandSegments/fetch/ranges",
    getMerchantListingsReport: "/api/merchantListingsReport/fetch",
    getKeywordsByASIN: "/api/keywordsByASIN/fetch",
    getKeywordsByASINRanges: "/api/keywordsByASIN/fetch/ranges",
    getSalesEstimateData: "/api/salesEstimateData/fetch",
    getSalesEstimateDataRanges: "/api/salesEstimateData/fetch/ranges",
    getPriceWarData: "/api/salesEstimateData/price-war/fetch",
    getPriceWarDataRanges: "/api/salesEstimateData/price-war/fetch/ranges",
    getOrderMetricsASINPIR: "/api/orderMetrics/fetch/asin/pir",
    getOrderMetricsASINPIRRanges: "/api/orderMetrics/fetch/asin/pir/ranges",
    getCompetitionSalesByASIN: "/api/salesEstimateData/fetch/aggData",
    getProductReviews: "/api/amazonProductReviews/fetch",
    getProductReviewsRanges: "/api/amazonProductReviews/fetch/ranges",
    getEventNotifications: "/api/eventNotification/fetch",
    getEventNotificationTypes: "/api/eventNotification/fetch/notificationtypes", 
    getEventNotificationsTypeData: "/api/eventNotification/fetch/notifications",
    getCohesityNotifications: "/api/cohesityNotification/fetch",
    getCohesityNotificationTypes: "/api/cohesityNotification/fetch/notificationtypes", 
    getCohesityNotificationsTypeData: "/api/cohesityNotification/fetch/notifications",
    getthreep: "/api/threep/fetch" ,
    getthreepRanges: "/api/threep/fetch/ranges",
    getMapViolaterRanges: "/api/mapViolater/fetch/ranges",
    getMapViolaters: "/api/mapViolater/fetch",
    getProductReviewsAll: "/api/amazonProductReviews/fetch/all",
    getOrderMetricsStats: "/api/orderMetrics/fetch/stats",
    pullOrderMetricsData: "/api/orderMetrics/pull/data",
    getReachTrend: "/api/ams/reachTrend/fetch",
    saveVendorSettings: "/api/amsPost/saveVendorSettings",
    updateVendorSettings: "/api/amsPost/updateVendorSettings",
    getVendorSettings: "/api/ams/vendorSettings",
    getSubCategories: "/api/ams/subCategories",
    saveProductCatalogs: "/api/amsPost/saveProductCatalogs",
    getAMSCampaigns: "/api/ams/campaigns/fetch",
    keywordPerformance: "/api/ams/keyword-reports",
    asinPerformance: "/api/ams/asin-reports",
    fetchPortfolios: "/api/ams/fetchPortfolios",
    searchTermPerformance: "/api/ams/search-term-reports",
    productTargetPerformance: "/api/ams/product-target-reports",
    placementAnalysis: "/api/ams/placementAnalysis",
    getAMSCampaign: "/api/ams/campaign/fetch",
    getCampaign: "/api/ams/getCampaign",
    editAMSCampaigns: "/api/ams/campaigns/edit",
    runOptimization: "/api/amsPost/runOptimization",
    runOptimizationImmediately: "/api/amsPost/runOptimizationsImmediately",
    stopOptimization: "/api/amsPost/stopOptimization",
    startOptimizations: "/api/amsPost/startOptimizations",
    stopOptimizations: "/api/amsPost/stopOptimizations",
    createScheduledCampaign: "/api/amsPost/createScheduledCampaign",
    amsScreenOne: "/api/ams/screen1/fetch",
    amsScreenTwo: "/api/ams/screen2/fetch",
    amsCreateAutoCampaign: "/api/amsPost/createAutoCampaign",
    amsCreateManualCampaign: "/api/amsPost/createManualCampaign",
    getAMSSubCategories:"/api/ams/subCategories",
    getAMSProducts:"/api/ams/products",
    getAMSCampaignVersions: "/api/ams/campaign/versions",
    getApiConfig: "/api/cronsManagement/fetch" ,
    postApiConfig: "/api/cronsManagement/save" ,
    loginDetails: "/api/user/loginDetails",
    getBrandDetails: "/api/brand-finder/list/",
    uploadBrandDetails: "/api/brand-finder/upload",
    downloadBrandDetails: "/api/brand-finder/download/",
    listBlockedDomains: "/api/blockDomains/list",
    blockDomain: "/api/blockDomains",
    updateBlockedDomains: "/api/blockDomains/update",
    uploadDomainsSheet: "/api/blockDomains/upload",
    spSellerCentral: "/api/sp/sellerCentral",
    spGeographicInsights: "/api/sp/geographicInsights",
    spVendorSalesReports: "/api/sp/vendorCentral",
    spGeographicDateRange: "/api/sp/geographicDateRange",
    spDateRange: "/api/sp/dateRange",
    spSellerCentralDateRange: "/api/sp/sellerCentralDateRange",
    listContactFinder: "/api/contactFinder/list/",
    uploadContactFinder: "/api/contactFinder/upload",
    downloadContactFinder: "/api/contactFinder/download/",
    listBrands: "/api/brand-finder/brands",
    searchContacts: "/api/contactFinder/search",
    listInventoryReports: "/api/sp/inventoryReports",
    getAMSCampaignReports: "/api/ams/campaign-reports/",
    getAMSCampaignReportsDateRange: "/api/ams/campaign-reports/date-range/",
    listEmptyDomains: "/api/emptyDomains/list",
    updateDomainStatus: "/api/emptyDomains/status",
    updateDomainComment: "/api/emptyDomains/comment",
    listContactFinder2: "/api/contactFinder/v2/list/",
    uploadContactFinder2: "/api/contactFinder/v2/upload",
    downloadContactFinder2: "/api/contactFinder/v2/download/",
    searchContactsFromApolloApi: "/api/searchContacts",
    spListing: "/api/sp/product/listing/",
    spListingVersions: "/api/sp/product/listing/versions/",
    listAmsProfiles: "/api/amsProfiles/list",
    updateAmsProfiles: "/api/amsProfiles/update",
    brandFinderGlobalUpload: "/api/brandFinderGlobal/upload",
    brandFinderGlobalDownload: "/api/brandFinderGlobal/download/",
    brandFinderGlobalList: "/api/brandFinderGlobal/list/",
    catalogGetAPI: "/api/sp/catalog",
    exportActivities: "/api/sp/exportActivities",
    getAMSDayPartings: "/api/ams/getDayPartings",
    createDayParting: "/api/amsPost/createDayParting",
    removeDayParting: "/api/amsPost/removeDayParting",
    pullThreePReport: "/api/threep/pull/data",
    pullShareOfVoiceReport: "/api/shareOfVoice/pull/data",
    pullGeoReports: "/api/sp/geographicInsights/pull/data",
    fetchCampaignReports: "/api/ams/campaignReports/pull/data",
    fetchSalesTrafficReports: "/api/sp/sellerCentral/pull/data",
    pullVendorSalesReport: "/api/sp/vendorSalesReport/pull/data",
    catalogRefresh: "/api/sp/catalog/refresh",
    pullJSSegmentReports: "/api/js/pull/reports",
    checkJSSegment: "/api/js/checkSegment",
    createJSSegment: "/api/js/createSegment",
    importJSSegment: "/api/js/importSegment",
    editJSSegment: "/api/js/editSegment",
    fetchJSSegments: "/api/js/segments",
    getJSSegment: "/api/js/segment",
    deleteJSSegment: "/api/js/deleteSegment",
    getCompetitionBenchmarkData: "/api/js/competitionBenchmark",
    getProductsAndBrands: "/api/js/productsAndBrands",
    strandedInventory: "/api/sp/stranded-inventory",
    sellerPerformance: "/api/sp/seller-performance",
    fbaReimbursements: "/api/sp/fba-reimbursements",
    getAmsOnboardingProfiles: "/api/amsVendorOnboarding/amsProfiles",
    generateVendorCode: "/api/amsVendorOnboarding/generateVendorCode",
    listCustomers: "/api/amsVendorOnboarding/listCustomers",
    addToAms: "/api/amsVendorOnboarding/addToAms",
    deleteFromAms: "/api/amsVendorOnboarding/deleteVendor",
    listBudgetChanges: "/api/ams/budgetChanges",
    cohesityLogs: "/api/cohesity/cohesityLogs",
    fetchCatalogActivities: "/api/sp/fetchCatalogActivities",
    changeVariants: "/api/sp/product/changeVariants",
    uploadBaseline: "/api/sp/product/uploadBaseline",
    fetchIntegrations: "/api/user/listIntegrations",
    listCustomerConfigs: "/api/user/customerConfigs",
    listUserVendorConfigs: "/api/user/vendorConfigs",
    fetchForecastHealth: "/api/bcg/fetchForecastHealth",
    getBcgMatrixData: "/api/bcg/getBcgData",
    getBcgForecast: "/api/bcg/bcgForecast",
    getSegmentForecast: "/api/bcg/segmentForecastData",
    getSegmentForecastHealth: "/api/bcg/segmentForecastHealth",
    getASINSegmentForecast: "/api/bcg/asinSegmentForecast",
    calculateSegmentProjections: "/api/bcg/calculateSegmentProjections",
    changeASINProjection: "/api/bcg/changeASINProjection",
    fetchSearchTermsReport: "/api/ams/searchTerms/pull/data",
    fetchKeywordsReport: "/api/ams/keywords/pull/data",
    fetchDapiStatistics: "/api/stats/dapi",
    fetchCohesityOptions: "/api/cohesity/cohesityOptions",
    fetchCustomerConfig: "/api/customerConfig",
}
