import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Container,
  Button,
  CircularProgress,
  Backdrop,
  Link,
  FormControl,
  MenuItem,
  Select,
  InputLabel
} from "@material-ui/core";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchASINs, fetchOrderMetrics } from "../../http/api";

import DayHeatMap from "./components/Heatmap/DayHeatMap"
import HourHeatMap from "./components/Heatmap/HourHeatMap";

export default function Dashboard(props) {
  var classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var vendorState = useVendorState();

  const [pageType, setPageType] = useState('Daily');
  const [heatmapData, setHeatmapData] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [totalData, setTotalData] = useState([]);


  const [asinsList, setAsinsList] = useState([]);
  const [selectedAsin, setSelectedAsin] = useState('');

  useEffect(() => {
    const granularity = props?.match?.params?.type === 'Hourly' ? 'Hour' : 'Day';
    const reportType = granularity === 'Day' ? 'ordermetricsasinday' : 'ordermetricsasinhour'
    fetchASINs(vendorState?.selected, reportType).then((asins) => {
      if(asins && asins.length) {
        setAsinsList(asins);
        setSelectedAsin(asins[0])
      }
    }).catch((err) => {
      console.error('Failed to fetch ASINs!!', err.message || err);
      setAsinsList([]);
    });

    return () => {
      setAsinsList([]);
      setSelectedAsin('')
    }
  }, [vendorState?.selected, props?.match?.params?.type])

  useEffect(() => {

    if (props?.match?.params?.type) {
      setPageType(props?.match?.params?.type)
    }

    async function fetchData() {
      try {

        setfullPageLoader(true);

        const granularity = props?.match?.params?.type === 'Hourly' ? 'Hour' : 'Day';
        const { formattedData, filteredData, heatmapObj,
          dateRange } = await fetchOrderMetrics(vendorState.selected, granularity, selectedAsin)

        setDateRange(dateRange || []);

        setTotalData(formattedData || []);
        setmainTableData(filteredData || []);
        setHeatmapData(heatmapObj);
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if(vendorState.selected && selectedAsin) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
      setTotalData([]);
      setHeatmapData({});
      setDateRange([]);
    };

  }, [vendorState.selected, props?.match?.params?.type, selectedAsin]);

  function CalHeatMap() {
    if (!heatmapData) {
      return <h3 style={{ textAlign: "center" }}>
        ...No Data To Show...
      </h3>
    } else if (props?.match?.params?.type === 'Hourly' && heatmapData && Object.keys(heatmapData || {}).length) {
      return <HourHeatMap heatmapData={heatmapData}
        dateRange={dateRange} totalData={totalData} setmainTableData={setmainTableData} />
    } else if (heatmapData && Object.keys(heatmapData || {}).length) {
      return <DayHeatMap heatmapData={heatmapData}
        dateRange={dateRange} totalData={totalData} setmainTableData={setmainTableData} />
    }
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title={`Day Parting ASINs ${pageType}`}
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Sales
              </Link>
              <Link color="inherit" className={classes.link}>
                Goals & Performance
              </Link>
              <Typography className={classes.link} color="primary">
                {`Day Parting ASINs ${pageType}`}
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <FormControl variant="outlined" className={classes.campaignControl}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Select ASIN
                </InputLabel>
                <Select
                  value={selectedAsin}
                  onChange={(e) => {
                    setSelectedAsin(e.target.value);
                  }}
                  label="Select ASIN"
                  disabled={asinsList && asinsList.length === 0 ? true : false}
                >
                  {
                  asinsList ? (
                    asinsList.map((name, i) => {
                      return (
                        <MenuItem key={i} value={name}>
                          {name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem value={0}>
                      <i>No ASIN</i>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    {`Day Parting ASINs ${pageType}`}
                  </Typography>
                </div>
              }
            >
              {CalHeatMap()}
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    {`Day Parting ${pageType}`}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
