import moment from 'moment';

/**
 * Returns the format in which the date should be displayed in the table.
 * @param {string} granularity - Hour/Day/Month
 * @returns {string} Date Format
 */
const getTableDateFormat = (granularity) => {
    switch (granularity) {
        case 'Hour':
            return 'MM/DD/YYYY, h:mm a'

        case 'Day':
            return 'MM/DD/YYYY'

        default:
            return 'MM/DD/YYYY'
    }
}

/**
 * Filters the data either based on duration / min date and max date
 * @param {Array} data - Array of objects.(startDate required for the object)
 * @param {Object} duration - Object with two keys(amount and unit).
 * @param {string} minDate - Custom minimum date, By Default data's first date is considered as min date.
 * @param {string} maxDate - Custom maximum date.
 * @returns 
 */
export const filterDataByDate = (data, duration, minDate, maxDate) => {

    let startDate = minDate || moment(data?.at(-1)?.startDate).startOf('day');
    let endDate = maxDate || moment(startDate).add(duration.amount, duration.unit).endOf('day')
    return data.filter((ele) => {
        if ((moment(ele.startDate) >= moment(startDate)) && (moment(ele.startDate) < moment(endDate))) {
            return true;
        }
        return false;
    })
}

/**
 * Changes the format for a few fields in the Order Metrics Response.
 * @param {Array} data - Array of order metrics.
 * @param {string} granularity - Hour/Day. Decides the format of the date in the table.
 * @returns {Array} Returns formatted order metrics in an array format.
 */
export const modifyOrderMetricsForTable = (data, granularity) => {
    return (data || []).map((ele) => {
        return {
            ...ele,
            startDate: moment(ele.startDate).format(getTableDateFormat(granularity)),
            endDate: moment(ele.endDate).format(getTableDateFormat(granularity)),
            averageUnitPrice: `$${ele.averageUnitPrice}`,
            totalSales: `$${ele.totalSales}`
        }
    });
}

/**
 * Generate JSON Data to match format of the cal heatmap
 * Format: { (time: ms): (value: number) }.
 * @param {Array} data - Order Metrics data
 * @returns {Object} Heatmap data
 */
export const generateHeatMapData = (data) => {
    const heatmapObj = {};
    let key;
    (data || []).forEach((ele, i) => {
        key = Math.round(new Date(ele.startDate).getTime() / 1000)
        heatmapObj[key] = ele.orderItemCount;
    })
    return heatmapObj;
}

export const generateLineChartData = (data) => {
    let chartDataObj = {}, time, dateData, key;
    (data || []).forEach((ele, i) => {
        time = moment(ele?.startDate).format('MM/DD/YYYY');
        if(!chartDataObj[time]) {
            key = moment(ele?.startDate).format('h a')
            dateData = [{ name: key.toLocaleUpperCase(), orderItemCount: ele?.orderItemCount }];
            chartDataObj[time] = dateData 
        } else {
            key = moment(ele?.startDate).format('h a')
            dateData = [...dateData, { name: key.toLocaleUpperCase(), "Order Item Count": ele?.orderItemCount }]
            chartDataObj[time] = dateData 
        }
    })
    return chartDataObj;
}

/**
 * Calculates intervales between a two values;
 * @param {Array} values - Consists of min and max values.
 * @param {Number} number - Number of intervals needed
 * @returns {Array} Array of {number} intervals between min and max value.
 */
export const calculateIntervals = (values = [], number = 5) => {
    const sortedValues = values.sort((a, b) => a - b);
    const [start, end] = [sortedValues.at(0), sortedValues.at(-1)]
    return Array(number).fill(0).map((_, i) => {
        return Math.floor(start + (i * (end - start) / number))
    })
}