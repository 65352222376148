import React, { useState, useRef , forwardRef, useImperativeHandle } from "react";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'

import moment from 'moment';
// components

export default forwardRef ( function Tables(props,ref) {
  
  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  useImperativeHandle(ref, () => ({
    onBtnExport() {
        if(gridRef.current.api.getSelectedNodes().length)
            gridApi.exportDataAsCsv({onlySelected: true});
        else 
            gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };

  return (
    <div className="ag-theme-material" style={{height: 620, width: '100%'}} id="#grid-theme-wrapper">
        <AgGridReact
            ref={gridRef}
            rowData={props.tableData}
            suppressExcelExport={true}
            rowSelection="multiple"
            defaultColDef={{
                filter: 'agTextColumnFilter',
                resizable: true,
                sortable:true,
                headerComponentParams: {
                  template:
                    '<div class="ag-cell-label-container" role="presentation">' +
                    '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                    '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                    '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                    '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                    '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                    '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                    '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                    '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                    '  </div>' +
                    '</div>',
                },              
            }}            
            onGridReady={onGridReady}
            pagination={true}
            paginationPageSize={20}
            rowStyle={rowStyle}
            suppressDragLeaveHidesColumns={true}
        >
            <AgGridColumn headerName="Event Date" field="eventDate" width={150} />
            <AgGridColumn headerName="Notification Type" field="notificationType" width={300}/>
            <AgGridColumn headerName="Seller Id" field="sellerId" />
            <AgGridColumn headerName="Region" field="region" />  
            <AgGridColumn headerName="Brand Name" field="brandName" width={150}/> 
            <AgGridColumn headerName="ASIN" field="asin" width={160}/> 
            <AgGridColumn headerName="SKU" field="sku" width={260}/> 
            <AgGridColumn headerName="Status" field="status" width={180}/>             
			<AgGridColumn headerName="Severties" field="severties" width={260}/>   
            <AgGridColumn headerName="New Product Types" field="newProductTypes" width={500}/>                                 
            <AgGridColumn headerName="Attributes Changed" field="attributesChanged" width={260}/>   
            <AgGridColumn headerName="Enforcement Actions" field="enforcementActions" width={260}/>                                                    
        </AgGridReact>
    </div>
  );
})







