import React, { useState, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Select,
  FormControl,
  Container,
  Button,
  CircularProgress,
  Backdrop,
  Link,
  Box,
  TextField,
  InputAdornment,
  Avatar,
  Modal,
  Fade,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  List,
  ListItem,
  ListItemText,
  Snackbar,
} from "@material-ui/core";
import axios from "axios";

import * as csv from "csvtojson";

import Table from "./components/Table";
import CatalogTemplateFile from "../../healpers/assets/Catalog_Import_Template.csv";

//icons
import PrintIcon from "@material-ui/icons/Print";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import CancelIcon from "@material-ui/icons/Cancel";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";
import { useLayoutState } from "../../context/LayoutContext";
import {
  tokenConfig,
  useUserDispatch,
  useUserState,
} from "../../context/UserContext";

//helpers

import { BASE_URL } from "../../healpers/api";

import { Search } from "@material-ui/icons";
import VideoCard from "./components/VideoCard";
import { useMemo } from "react";
import { Alert } from "@material-ui/lab";
import { usePopupDispatch, usePopupState } from "../../context/PopupContext";
import {
  ADD_ERROR,
  ADD_NOTIFICATION,
  CLEAR_ERRORS,
  CLEAR_NOTIFICATION,
} from "../../context/types";

export default function CatalogManager(props) {
  var classes = useStyles();
  const userState = useUserState();
  const layoutState = useLayoutState();
  const vendorState = useVendorState();

  // useEffect(() => {
  //   const url =
  //     BASE_URL +
  //     `/api/product-catalog/${userState?.userData?.id}/profile-settings`;
  //   axios.get(url).then((res) => {
  //     console.log({ RES: res });
  //     if (!res.data?.data?.completed) {
  //       props.history.push("/app/profile/profileSettings");
  //     }
  //   });
  // }, [layoutState, vendorState]);

  //global
  var { selected } = useVendorState();
  var userDispatch = useUserDispatch();
  var popupDispatch = usePopupDispatch();
  var { err, notification } = usePopupState();

  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [openedRow, setOpenedRow] = useState(null);
  const [productImages, setProductImages] = useState([]);

  console.log({ err, notification });

  function filterTable() {
    try {
      setfullPageLoader(true);
      axios
        .get(
          BASE_URL + "/api/product-catalog/search",
          tokenConfig({ vendorCode: selected, q: query })
        )
        .then((result) => {
          if (result.data.data) {
            setmainTableData(result.data.data);
            setfullPageLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setfullPageLoader(false);
        });
    } catch (error) {
      console.log(error);
      setfullPageLoader(false);
    }
  }

  useEffect(() => {
    fetchAllProducts();
  }, [selected]);

  useEffect(() => {
    if (mainTableData?.length > 0 && query) {
      filterTable();
    } else {
      fetchAllProducts();
    }
  }, [query, selected]);

  const deleteImage = (imageName) => {
    setfullPageLoader(true);
    axios
      .delete(
        BASE_URL + "/api/product-catalog/images",
        tokenConfig({ vendorCode: selected, id: openedRow?.id, imageName })
      )
      .then((res) => {
        setfullPageLoader(false);
        setfullPageLoader(false);
        popupDispatch({ type: ADD_NOTIFICATION, payload: "Image deleted" });
        setTimeout(() => {
          popupDispatch({ type: CLEAR_NOTIFICATION });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setfullPageLoader(false);
        popupDispatch({ type: ADD_ERROR, payload: err.response.data.msg });
        setTimeout(() => {
          popupDispatch({ type: CLEAR_ERRORS });
        }, 2000);
      });
    const afterDeletion = productImages.filter(
      (image) => imageName != image.name
    );
    setProductImages(afterDeletion);
  };

  async function fetchAllProducts() {
    try {
      const url = BASE_URL + "/api/product-catalog";
      setfullPageLoader(true);
      axios
        .get(url, tokenConfig({ vendorCode: selected }))
        .then((result) => {
          if (result.data.data) {
            setmainTableData(result.data.data);
            setfullPageLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setfullPageLoader(false);
        });
    } catch (error) {
      console.log(error);
      setfullPageLoader(false);
    }
  }

  const canAddImage = useMemo(() => {
    if (productImages) {
      const TOTAL_IMAGES = 7;
      const nullImagesCount = TOTAL_IMAGES - productImages.length;
      const isDisabled = nullImagesCount === 0 ? true : false;
      return isDisabled;
    }
    return true;
  }, [productImages]);

  function uploadCSV(parsedCSVData) {
    setfullPageLoader(true);
    csv()
      .fromString(parsedCSVData)
      .then(function (data) {
        axios
          .post(BASE_URL + "/api/product-catalog/upload-csv", data, {
            params: { vendorCode: selected },
          })
          .then((res) => {
            fetchAllProducts();
            setfullPageLoader(false);
          })
          .catch((e) => {
            console.log(e);
            setfullPageLoader(false);
          });
      });
  }
  function isCSVFile(file) {
    return file.name.split(".")[1] === "csv";
  }

  function onQueryChange(e) {
    setQuery(e.target.value, filterTable);
  }

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!isCSVFile(file)) {
      return;
    }
    // setFile(file);

    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = function () {
      uploadCSV(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    e.target.value = null;
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    fetchAllProducts();
    setOpen(false);
    setProductImages([]);
  };

  const deleteVideo = () => {
    setOpenedRow({ ...openedRow, video: null });
    setfullPageLoader(true);
    console.log({ rowId: openedRow?.id });
    axios
      .delete(
        BASE_URL + "/api/product-catalog/video",
        tokenConfig({ id: openedRow?.id })
      )
      .then((res) => {
        setfullPageLoader(false);
        popupDispatch({ type: ADD_NOTIFICATION, payload: "Video deleted" });
        setTimeout(() => {
          popupDispatch({ type: CLEAR_NOTIFICATION });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setfullPageLoader(false);
        popupDispatch({ type: ADD_ERROR, payload: err.response.data.msg });
        setTimeout(() => {
          popupDispatch({ type: CLEAR_ERRORS });
        }, 2000);
      });
  };

  function setImages(tableRow) {
    const images = [];
    Object.keys(tableRow).forEach((key) => {
      if (key.startsWith("image") && tableRow[key]) {
        images.push({ src: tableRow[key], name: key });
      }
    });
    setProductImages(images);
  }

  const getImageName = () => {
    for (let i = 1; i <= 7; i++) {
      const imageName = `image${i}`;
      const isImagePresent = productImages.find(
        (image) => image.name === imageName
      );
      if (!isImagePresent) {
        return imageName;
      }
    }
  };

  function uploadVideo(file) {
    const url = BASE_URL + "/api/product-catalog/videos";
    const formData = new FormData();
    formData.append("file", file);
    formData.append("asin", openedRow?.asin);
    formData.append("id", openedRow?.id);
    setfullPageLoader(true);
    axios
      .post(url, formData)
      .then((response) => {
        setOpenedRow({ ...openedRow, video: response.data.data?.location });
        setfullPageLoader(false);
        popupDispatch({ type: ADD_NOTIFICATION, payload: "Video uploaded" });
        setTimeout(() => {
          popupDispatch({ type: CLEAR_NOTIFICATION });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setfullPageLoader(false);
        popupDispatch({ type: ADD_ERROR, payload: err.response.data.msg });
        setTimeout(() => {
          popupDispatch({ type: CLEAR_ERRORS });
        }, 2000);
      });
  }

  const onSelectVideo = async (event) => {
    const file = event.target.files[0];
    event.target.value = null;
    if (!file) {
      return;
    }
    const format = file.name.split(".")[1];
    const allowedFormats = ["mp4", "mov"];
    let isValidFile = true;
    const videoSizeLimitInBytes = 5368709120;
    if (!allowedFormats.includes(format)) {
      isValidFile = false;
      popupDispatch({ type: ADD_ERROR, payload: "Invalid video format" });
      setTimeout(() => {
        popupDispatch({ type: CLEAR_ERRORS });
      }, 2000);
    }
    if (file.size > videoSizeLimitInBytes) {
      isValidFile = false;
    }
    if (isValidFile) {
      uploadVideo(file);
    }
  };

  function uploadImage(file) {
    const url = BASE_URL + "/api/product-catalog/images";
    const imageName = getImageName();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("imageName", imageName);
    formData.append("asin", openedRow?.asin);
    formData.append("id", openedRow?.id);
    setfullPageLoader(true);
    axios
      .post(url, formData)
      .then((response) => {
        setProductImages([
          ...productImages,
          {
            src: response.data.data?.location,
            name: response.data.data?.imageName,
          },
        ]);
        setfullPageLoader(false);
        popupDispatch({ type: ADD_NOTIFICATION, payload: "Image uploaded" });
        setTimeout(() => {
          popupDispatch({ type: CLEAR_NOTIFICATION });
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        setfullPageLoader(false);
        popupDispatch({ type: ADD_ERROR, payload: err.response.data.msg });
        setTimeout(() => {
          popupDispatch({ type: CLEAR_ERRORS });
        }, 2000);
      });
  }
  const onSelectImage = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    event.target.value = null;
    uploadImage(file);
  };

  return (
    <Container maxWidth={false}>
      <PageTitle
        title="Catalog Manager"
        breadCrump={
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" className={classes.link}>
              Catalog
            </Link>
            <Link color="inherit" className={classes.link}>
              Catalog Manager
            </Link>
          </Breadcrumbs>
        }
      />

      <Grid item xs={12}>
        <Widget
          upperTitle
          noBodyPadding
          bodyClass={classes.tableOverflow}
          header={
            <div className={classes.mainChartHeader}>
              <Typography
                variant="h2"
                style={{
                  fontWeight: 600,
                  fontSize: 20,
                  color: "black",
                }}
              >
                Product Catalog
              </Typography>
              <Box
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                flexGrow={1}
              >
                <TextField
                  style={{ marginRight: "0.5rem" }}
                  size="small"
                  onChange={onQueryChange}
                  value={query}
                  variant="outlined"
                  className={classes.margin}
                  id="input-with-icon-textfield"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <a
                  href={CatalogTemplateFile}
                  download="Catalog_Import_Template.csv"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="small"
                    startIcon={<GetAppIcon />}
                    style={{ marginRight: ".5rem" }}
                  >
                    Download Template
                  </Button>
                </a>
                <a
                  href={
                    BASE_URL +
                    `/api/product-catalog/export-csv?vendorCode=${selected}`
                  }
                  download="product_catalog.csv"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="small"
                    startIcon={<PrintIcon />}
                  >
                    Export CSV
                  </Button>
                </a>
                <FormControl variant="outlined" className={classes.formControl}>
                  <label htmlFor="upload-csv">
                    <input
                      style={{ display: "none" }}
                      id="upload-csv"
                      name="upload-csv"
                      type="file"
                      accept=".csv"
                      onChange={handleFileChange}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                      size="small"
                      startIcon={<PublishIcon />}
                    >
                      Upload CSV
                    </Button>
                  </label>
                </FormControl>
              </Box>
            </div>
          }
        >
          <Table
            mainTableData={mainTableData}
            handleOpen={handleOpen}
            setImages={setImages}
            setOpenedRow={setOpenedRow}
          />
        </Widget>
      </Grid>

      <Backdrop
        className={classes.backdrop}
        open={fullPageLoader}
        onClick={() => setfullPageLoader(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box
              display="flex"
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                variant="h3"
                id="transition-modal-title"
                color="primary"
              >
                Product Details
              </Typography>
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                onClick={handleClose}
                endIcon={<CancelIcon />}
              >
                close
              </Button>
            </Box>
            <List dense={true} style={{ padding: 0, margin: 0, marginTop: 20 }}>
              <ListItem style={{ padding: 0, margin: 0 }}>
                <ListItemText primary={`ASIN - ${openedRow?.asin ?? null}`} />
              </ListItem>
              <ListItem style={{ padding: 0, margin: 0 }}>
                <ListItemText primary={`SKU - ${openedRow?.sku ?? null}`} />
              </ListItem>
              <ListItem style={{ padding: 0, margin: 0 }}>
                <ListItemText primary={`Title - ${openedRow?.title ?? null}`} />
              </ListItem>
            </List>

            <Box textAlign={"right"}>
              <label htmlFor="upload-image">
                <input
                  style={{ display: "none" }}
                  id="upload-image"
                  name="image"
                  type="file"
                  accept=".jpg, .jpeg,.tif,.tiff,.gif,.png"
                  onChange={onSelectImage}
                  disabled={canAddImage}
                />
                <Button
                  disabled={canAddImage}
                  size="medium"
                  color="primary"
                  variant="contained"
                  component="span"
                  startIcon={<AddIcon />}
                  style={{
                    padding: "8px 30px",
                  }}
                >
                  Add Image
                </Button>
              </label>
            </Box>
            <Typography variant="h4" color="primary">
              Images
            </Typography>
            <div
              className={classes.root}
              style={{ justifyContent: "flex-start" }}
            >
              <ImageList className={classes.imageList} rowHeight={160} gap={10}>
                {productImages?.map((image) => (
                  <ImageListItem
                    style={{
                      width: 200,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      backgroundSize: "contain",
                    }}
                    key={image.name}
                  >
                    <img
                      src={image.src}
                      alt={image.name}
                      style={{ maxHeight: 160, maxWidth: 220 }}
                    />
                    <ImageListItemBar
                      title={image.name}
                      classes={{
                        root: classes.titleBar,
                        title: classes.title,
                      }}
                      actionIcon={
                        <IconButton
                          aria-label={`remove ${image.name}`}
                          onClick={() => deleteImage(image.name)}
                        >
                          <DeleteIcon className={classes.title} color="error" />
                        </IconButton>
                      }
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </div>
            <Typography variant="h4" color="primary" style={{ marginTop: 15 }}>
              Video
            </Typography>
            <Box
              textAlign={"center"}
              display="flex"
              justifyContent="flex-start"
              alignItems={"center"}
            >
              <VideoCard src={openedRow?.video} />
              <Box
                display={"flex"}
                flexDirection="column"
                justifyContent={"center"}
              >
                <label htmlFor="upload-video">
                  <input
                    style={{ display: "none" }}
                    id="upload-video"
                    name="video"
                    type="file"
                    accept=".mp4, .mov"
                    onChange={onSelectVideo}
                  />
                  <Button
                    size="medium"
                    color="primary"
                    variant="contained"
                    component="span"
                    startIcon={<AddIcon />}
                    style={{
                      marginBottom: 20,
                      marginLeft: openedRow?.video ? 40 : 0,
                      padding: "8px 30px",
                      marginTop: openedRow?.video ? 0 : 40,
                    }}
                  >
                    {openedRow?.video ? "UPDATE VIDEO" : "ADD VIDEO"}
                  </Button>
                </label>
                {console.log({ openedRowRemoveBtn: openedRow })}
                {openedRow?.video && (
                  <Button
                    onClick={deleteVideo}
                    size="medium"
                    color="secondary"
                    variant="contained"
                    startIcon={<DeleteIcon />}
                    style={{ marginLeft: 40, padding: "8px" }}
                  >
                    Remove Video
                  </Button>
                )}
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
      {notification ? (
        <Alert
          severity="success"
          style={{ position: "fixed", top: 20, right: 600, zIndex: 9999 }}
        >
          {notification}
        </Alert>
      ) : (
        <></>
      )}
      {err ? (
        <Alert
          severity="error"
          style={{ position: "fixed", top: 20, right: 600, zIndex: 9999 }}
        >
          {err}
        </Alert>
      ) : (
        <></>
      )}
    </Container>
  );
}
