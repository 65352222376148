import React, { useState } from "react";
import {
  Container,
  Button,
  Breadcrumbs,
  Link,
  Typography,
  FormControl,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import * as XLSX from 'xlsx';

import { validateASINSKUMappingData } from '../../healpers/ASINSKU.helpers'

//icons
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import ConfirmDialog from "./components/ComfirmDialog/ConfirmDialog";
import SampleFile from "../../healpers/assets/SampleASINSKUMapping.xlsx";
import { writeASINSKUMapping } from "../../http/api"

// styles
import useStyles from "./styles";

export default function Page() {
  const classes = useStyles();

  // local
  const [file, setfile] = useState({ name: "No File uploaded" });
  const [confirmDialog, setconfirmDialog] = useState(false);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [count, setCount] = useState(null);
  const [notification, setnotification] = useState(null);
  const [err, seterr] = useState(null);

  async function uploadCSV(e) {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (evt) => {
      seterr(null)
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_row_object_array(ws, { header: 1 });

      console.log('Data: ', data)
      const { isValid, data: validData, message } = validateASINSKUMappingData(data);

      if (!isValid) {
        document.getElementById('upload-csv').value = '';
        setTimeout(() => {
          seterr(message)
        }, 2000);
        setfile({ name: "No File uploaded" });
        setconfirmDialog(false);
        setCount(null);
      } else {
        console.log(validData)
        const data = await writeASINSKUMapping(validData)
        setCount(data)
        setfile(file)
        setconfirmDialog(true);
      }

    };
    reader.readAsBinaryString(file);
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title="Upload File"
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                ASIN SKU Mapping
              </Link>
              <Typography className={classes.link} color="primary">
                Upload File
              </Typography>
            </Breadcrumbs>
          }
          rightAlignComponent={
            <>
              <a href={SampleFile} download="Sample ASIN SKU Mapping.xlsx" style={{ textDecoration: 'none' }}>
                <Button
                  color="primary"
                  variant="outlined"
                  component="span"
                  size="small"
                  startIcon={<GetAppIcon />}
                >
                  Download Sample File
                </Button>
              </a>
              <FormControl variant="outlined" className={classes.formControl} style={{ marginRight: '-5%' }}>
                <label htmlFor="upload-csv">
                  <input style={{ display: 'none' }}
                    id="upload-csv"
                    name="upload-csv"
                    type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onChange={(e) => uploadCSV(e)}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    component="span"
                    size="medium"
                    startIcon={<PublishIcon />}
                  >
                    Upload here
                  </Button>
                </label>
                <Typography className={classes.link} color="primary" style={{ fontSize: 12, margin: '5% 0% 0% 1%' }}>
                  {file.name}
                </Typography>
              </FormControl>
            </>
          }
        />
      </Container>
      <Backdrop className={classes.backdrop} open={fullPageLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ConfirmDialog open={confirmDialog} toggleDialog={() => setconfirmDialog(!confirmDialog)} file={file} setFile={setfile} count={count} setnotification={setnotification} seterr={seterr} />
      {
        notification ?
          <Alert severity="success" style={{ position: 'absolute', bottom: 30, left: '48%' }}>{notification}</Alert>
          :
          <></>
      }
      {
        err ?
          <Alert severity="error" style={{ position: 'absolute', bottom: 30, left: '48%' }}>{err}</Alert>
          :
          <></>
      }
    </>
  );
}
