import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { noCurve } from "../../../../healpers/noCurve";

export default function App({ data }) {

  return (
    <LineChart
      width={1000}
      height={500}
      data={data}
      margin={{
        top: 30,
        right: 0,
        left: 0,
        bottom: 30,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name"/>
      <YAxis />
      <Tooltip />
      <Line type={noCurve} dataKey={'Order Item Count'} stroke={'#82ca9d'} isAnimationActive={false} />
    </LineChart>
  );
}
