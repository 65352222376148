import moment from 'moment';

/**
 * Filters the data either based on duration / min date and max date
 * @param {Array} data - Array of objects.(startDate required for the object)
 * @param {Object} duration - Object with two keys(amount and unit).
 * @param {string} minDate - Custom minimum date, By Default data's first date is considered as min date.
 * @param {string} maxDate - Custom maximum date.
 * @returns 
 */
export const filterDataByNotificationType = (data) => {

    return (data || []).map((ele) => {
        return {
            ...ele,
            notificationType: `$${ele.notificationType}`
        }
    });
}


/**
 * Changes the format for a few fields in the Order Metrics Response.
 * @param {Array} data - Array of order metrics.
 * @param {string} granularity - Hour/Day. Decides the format of the date in the table.
 * @returns {Array} Returns formatted order metrics in an array format.
 */
export const modifyNotificationForTable = (data) => {
    return (data || []).map((ele) => {
        return {
            ...ele,
            eventDate: moment(ele.eventDate).format('MM/DD/YYYY'),
			notificationType: `${ele.notificationType}`,
            sellerId: `${ele.sellerId}`,
            region: `${ele.region}`,            
            newProductTypes: `${ele.newProductTypes}` 
        }
    });
}


// get serachTerms list
export const filterDataBySearchTerm = (data) => {

    return (data || []).map((ele) => {
        return {
            ...ele,
            searchTerm: `$${ele.searchTerm}`
        }
    });
}
