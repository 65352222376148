import { Grid, TextField } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import useStyles from "../styles";

export const SegmentFields = ({
  indx,
  segment,
  reportType,
  onValuesChange,
  disabled,
  onRemoveSegment,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={2}
      style={{
        paddingTop: "3%",
      }}
    >
      <Grid item xs={5}>
        <TextField
          value={segment.id}
          disabled={disabled}
          name="id"
          onChange={(e) => {
            onValuesChange(e, indx, reportType);
          }}
          label="Segment ID"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={segment.name}
          disabled={disabled}
          name="name"
          onChange={(e) => {
            onValuesChange(e, indx, reportType);
          }}
          label="Segment Name"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={1} style={{ position: "relative" }}>
        <CloseIcon
          fontSize="small"
          className={classes.segmentIconBtn}
          style={{
            cursor: disabled ? "default" : "pointer",
          }}
          color={disabled ? "disabled" : "error"}
          onClick={() => {
            if (disabled) return;
            onRemoveSegment(indx, reportType);
          }}
        />
      </Grid>
    </Grid>
  );
};
