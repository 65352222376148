import React, { useState, useRef, forwardRef, useImperativeHandle } from "react";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import './styles.css'
// components

export default forwardRef(function Tables(props, ref) {

  const gridRef = useRef(null);

  const [gridApi, setGridApi] = useState(null);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  let minUnitsSold = 1000000, maxUnitsSold = 0, minRevenue = 1000000, maxRevenue = 0;
  props.tableData.forEach((ele) => {

    if (minUnitsSold > Number(ele?.estimatedUnitsSold)) {
      minUnitsSold = Number(ele?.estimatedUnitsSold)
    }

    if (maxUnitsSold < Number(ele?.estimatedUnitsSold)) {
      maxUnitsSold = Number(ele?.estimatedUnitsSold)
    }

    if (minRevenue > Number(ele?.revenue.replace('$', '').replace(',', ''))) {
      minRevenue = Number(ele?.revenue.replace('$', '').replace(',', ''))
    }

    if (maxRevenue < Number(ele?.revenue.replace('$', '').replace(',', ''))) {
      maxRevenue = Number(ele?.revenue.replace('$', '').replace(',', ''))
    }

  })

   const getEstUnitsSoldClass = (params) => {
     /* The weight can be between 0 (when value === min) and 1 (when value === max) */
     let weight = (params.value - minUnitsSold) / (maxUnitsSold - minUnitsSold);
   // We want red === 255 at min, and 0 at max
     let red = (1 - weight) * 255;
     // Conversly, we want green === 0 at min, and 255 at max
     let green = weight * 255;
     // Return backgroundColor value
     return { backgroundColor: `rgb(${red}, ${green}, 0)` };
   }


  const getRevenueClass = (params) => {
    let weight = (params.value.replace('$', '').replace(',', '') - minRevenue) / (maxRevenue - minRevenue);
    // We want red === 255 at min, and 0 at max
    let red = (1 - weight) * 255;
    // Conversly, we want green === 0 at min, and 255 at max
    let green = weight * 255;
    
     // Return backgroundColor value
     return { backgroundColor: `rgb(${red}, ${green}, 0)` };
    }
  
  
/*
  //const getEstUnitsSoldClass = (params) => {
    /* The weight can be between 0 (when value === min) and 1 (when value === max) */
 /*   let weight = (params.value - minUnitsSold) / (maxUnitsSold - minUnitsSold);

    if(weight <= 0.25) {
      return { backgroundColor: 'red' }
    } else if(weight <= 0.50) {
      return { backgroundColor: 'yellow' }
    } else if(weight <= 0.75) {
      return { backgroundColor: 'orange' }
    } else if(weight <= 1) {
      return { backgroundColor: 'green' }
    }

  }

  const getRevenueClass = (params) => {
    let weight = (params.value.replace('$', '').replace(',', '') - minRevenue) / (maxRevenue - minRevenue);

    if(weight <= 0.25) {
      return { backgroundColor: 'red' }
    } else if(weight <= 0.50) {
      return { backgroundColor: 'yellow' }
    } else if(weight <= 0.75) {
      return { backgroundColor: 'orange' }
    } else if(weight <= 1) {
      return { backgroundColor: 'green' }
    }

  }
*/


  useImperativeHandle(ref, () => ({
    onBtnExport() {
      if (gridRef.current.api.getSelectedNodes().length)
        gridApi.exportDataAsCsv({ onlySelected: true });
      else
        gridApi.exportDataAsCsv();
    }
  }));

  const rowStyle = { background: 'white' };
  const decimalComparatorWithDollar = (valueA, valueB) => parseFloat(valueA.replace("$", "").replace(",", "")) - parseFloat(valueB.replace("$", "").replace(",", ""));

  return (
    <div className="ag-theme-material" style={{ height: 620, width: '100%' }} id="#grid-theme-wrapper">
      <AgGridReact
        ref={gridRef}
        rowData={props.tableData}
        suppressExcelExport={true}
        rowSelection="multiple"
        defaultColDef={{
          filter: 'agTextColumnFilter',
          resizable: true,
          sortable: true,
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              '  </div>' +
              '</div>',
          },
        }}
        onGridReady={onGridReady}
        pagination={true}
        paginationPageSize={10}
        rowStyle={rowStyle}
        suppressDragLeaveHidesColumns={true}
      >
        <AgGridColumn key={1} headerName={'ASIN'} field={'asin'} width={160} />
        <AgGridColumn key={2} headerName={'Title'} field={'productTitle'}width={360}  />
        <AgGridColumn key={3} headerName={'Brand'} field={'brand'} width={260} />
        <AgGridColumn cellStyle={(params) => getEstUnitsSoldClass(params)} key={4} headerName={'Estimated Units Sold'} field={'estimatedUnitsSold'} width={260} />
        <AgGridColumn cellStyle={(params) => getRevenueClass(params)} key={5} headerName={'Revenue'} field={'revenue'} comparator={decimalComparatorWithDollar} width={260} />
      </AgGridReact>
    </div>
  );
})