import {
  Equalizer as EqualizerIcon,
  Dashboard as DashboardIcon,
  AttachMoneyRounded as AttachMoneyRoundedIcon,
  Label as LabelIcon,
  ViewQuilt as ViewQuiltIcon,
  Create as CreateIcon,
  Person as PersonIcon,
  Assignment as AssignmentIcon,
  Repeat as RepeatIcon,
  Receipt as ReceiptIcon,
} from "@material-ui/icons";

export const structure = [
  { id: 0, type: "title", label: "MAIN", pageCode: "none" },
  {
    id: 1,
    label: "Sales",
    link: "/app/sales/sellerCentral",
    pageCode: "sales",
    icon: <AttachMoneyRoundedIcon />,
    children: [
      //     { label: "Sales Overview", link: "/app/sales/sellerCentral"},
      {
        label: "Shipped COGS - VC",
        link: "/app/sales/shippedCOGS",
        pageCode: "shippedCOGS",
      },
      {
        label: "Goals & Performance",
        link: "/app/sales/sellerCentral",
        pageCode: "goalsAndPeformance",
      },
      {
        label: "Geographic Insights",
        link: "/app/sales/geo",
        pageCode: "geographicInsights",
      },
      {
        label: "Ratings and Reviews",
        link: "/app/bi/productAndReview",
        pageCode: "ratingsAndReviews",
      },
      {
        label: "All Ratings and Reviews",
        link: "/app/bi/productRatingAndReviewAll",
        pageCode: "allRatingsAndReview",
      },
      {
        label: "Key Metrics VC",
        link: "/app/sales/keymetricsvc",
        pageCode: "keyMetricsVC",
      },
    ],
  },
  {
    id: 2,
    label: "Business Intelligence",
    link: "/app/bi/",
    pageCode: "businessIntelligence",
    icon: <EqualizerIcon />,
    children: [
      {
        label: "JS Segment",
        link:"/app/bi/jsSegment",
        pageCode:"jsSegment"
      },
      {
        label: "Competition Benchmarking",
        link: "/app/bi/benchmark",
        pageCode: "competitionBenchmarking",
      },
      {
        label: "Products and Brands",
        link: "/app/bi/jsProductsAndBrands",
        pageCode: "productsAndBrands",
      },
      {
        label: "Segments",
        link: "/app/bi/segments",
        pageCode: "segments",
      },
      {
        label: "Competition Detailed Sales",
        link: "/app/bi/competitionSalesASIN",
        pageCode: "competitionDetailedSales",
      },
      {
        label: "Share of Voice",
        link: "/app/bi/voice",
        pageCode: "shareOfVoice",
      },
      {
        label: "BCG Matrix",
        pageCode: "bcg",
        children: [
          {
            label: "BCG Overview",
            link: "/app/bi/bcgOverview",
            pageCode: "bcgOverview",
          },
          {
            label: "BCG Matrix",
            link: "/app/bi/bcgMatrix",
            pageCode: "bcgMatrix",
          },
        ]
      },
      {
        label: "Forecast",
        pageCode: "forecast",
        children: [
          {
            label: "Projections",
            link: "/app/bi/projections",
            pageCode: "projections",
          },
          {
            label: "Health Check",
            link: "/app/bi/healthCheck",
            pageCode: "healthCheck",
          },
          {
            label: "Segment Projections",
            link: "/app/bi/segmentProjections",
            pageCode: "segmentProjections",
          },
          {
            label: "Segment Forecast Health",
            link: "/app/bi/segmentForecastHealth",
            pageCode: "segmentForecastHealth",
          },
        ]
      },
    ],
  },
  {
    id: 3,
    label: "Creative",
    link: "/app/creative/",
    icon: <CreateIcon />,
    pageCode: "creative",
    children: [
      {
        label: "Detailed Pages",
        link: "/app/creative/dp",
        pageCode: "detailedPage",
      },
      { label: "A+ Pages", link: "/app/creative/ap", pageCode: "aPlusPages" },
      { label: "SEO", link: "/app/creative/cpyseo", pageCode: "seo" },
    ],
  },
  {
    id: 4,
    label: "Advertising",
    link: "/app/adv/",
    icon: <LabelIcon />,
    pageCode: "advertising",
    children: [
      {
        label: "Day Parting — Day",
        link: "/app/sales/orderMetrics/Daily",
        pageCode: "dayPartingDay",
      },
      {
        label: "Day Parting — Hour",
        link: "/app/sales/orderMetricsHour",
        pageCode: "dayPartingHour",
      },
      {
        label: "Day Parting — ASINs Day",
        link: "/app/sales/orderMetricsASINs/Daily",
        pageCode: "dayPartingAsinsDay",
      },
      {
        label: "Day Parting — ASINs Hour",
        link: "/app/sales/orderMetricsASINs/Hourly",
        pageCode: "dayPartingAsins",
      },
      { label: "Price War", link: "/app/bi/priceWar", pageCode: "priceWar" },
      {
        label: "Products Not Serving",
        link: "/app/adv/cp",
        pageCode: "productsNotServing",
      },
      {
        label: "Campaigns out of Budget",
        link: "/app/adv/cob",
        pageCode: "CampaignsOutOfBudget",
      },
    ],
  },
  {
    id: 6,
    label: "Operations",
    link: "/app/operations/",
    icon: <ViewQuiltIcon />,
    pageCode: "operations",
    children: [
      {
        label: "Search Suppressed ASINs",
        link: "/app/bi/merchantListingsReport",
        pageCode: "suppressedAsins",
      },
      {
        label: "Event Notifications",
        link: "/app/bi/notifications",
        pageCode: "eventNotifications",
      },
      {
        label: "Inventory Management",
        link: "/app/operations/invmgmt",
        pageCode: "sinventoryManagement",
      },
      {
        label: "Fulfilled by Amazon (FBA) Inventory Forecast",
        link: "/app/bi/FBAInventoryRep",
        pageCode: "fbaInventoryForecast",
      },
      { label: "3P Report", link: "/app/bi/threep", pageCode: "threePReport" },
      {
        label: "Map Violators",
        link: "/app/bi/mapViolaters",
        pageCode: "mapViolators",
      },
      {
        label: "Stranded Inventory",
        link: "/app/operations/stranded-inventory",
        pageCode: "strandedInventory",
      },
      {
        label: "Account Health",
        link: "/app/operations/account-health",
        pageCode: "accountHealth",
      },
      {
        label: "FBA Reimbursements",
        link: "/app/operations/fba-reimbursements",
        pageCode: "fbaReimbursements",
      }
    ],
  },
  {
    id: 7,
    label: "Invoices Reconciliation",
    link: "/app/operations/",
    icon: <ViewQuiltIcon />,
    pageCode: "invoiceReconciliations",
    children: [
      {
        label: "Seller Central Invoices",
        link: "/app/operations/scinvoicereconciliation",
        pageCode: "sellerCentralInvoices",
      },
      {
        label: "Vendor Central Invoices",
        link: "/app/operations/invoicesReconciliation",
        pageCode: "vendorCentralInvoices",
      },
    ],
  },
  {
    id: 8,
    label: "ShortageClaims And Chargebacks",
    link: "/app/sc_cb/",
    pageCode: "shortageclaimsAndChargebacks",
    icon: <DashboardIcon />,
    children: [
      { label: "Seller Central", pageCode: "sellerCentral" },
      {
        label: "Vendor Central",
        pageCode: "vendorCentral",
        children: [
          {
            label: "Shortage Claims",
            link: "/app/sc_cb/claims",
            pageCode: "vendorCentral",
          },
          {
            label: "Chargebacks",
            link: "/app/sc_cb/chargeBacks",
            pageCode: "vendorCentral",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    label: "Amazon Advertising",
    link: "/app/amazonAdvertising/",
    icon: <LabelIcon />,
    pageCode: "amazonAdvertising",
    children: [
      {
        label: "Settings and Optimisation",
        pageCode: "settingsAndOptimisation",
        children: [
          {
            label: "Create Campaigns",
            link: "/app/ams/createCampaign",
            pageCode: "createCampaign",
          },
          {
            label: "Campaigns Optimisation",
            link: "/app/ams/amsCampaigns",
            pageCode: "campaignsOptimisation",
          },
          {
            label: "Account Settings",
            link: "/app/ams/vendorSettings",
            pageCode: "campaignsSettings",
          },
          {
            label: "AMS Profiles",
            link: "/app/ams/amsProfiles",
            pageCode: "amsProfiles",
          },
          {
            label: "Day Parting Rules",
            link: "/app/ams/dayParting",
            pageCode: "dayPartingRules",
          }
        ],
      },
      {
        label: "Reports",
        pageCode: "reports",
        children: [
          {
            label: "Campaigns Performance",
            link: "/app/reports/campaignPerformance",
            pageCode: "campaignPerformance",
          },
          {
            label: "ASINs Performance",
            link: "/app/reports/asinPerformance",
            pageCode: "asinPerformance",
          },
          {
            label: "Keywords Performance",
            link: "/app/reports/keywordPerformance",
            pageCode: "keywordPerformance",
          },
          {
            label: "Search Terms Performance",
            link: "/app/reports/searchTermPerformance",
            pageCode: "searchTermPerformance",
          },
          {
            label: "Products Target Performance",
            link: "/app/reports/targetPerformance",
            pageCode: "targetPerformance",
          },
          {
            label: "Budget Changes",
            link: "/app/reports/budgetChanges",
            pageCode: "budgetChanges",
          },
          {
            label: "Placement Analysis",
            link: "/app/reports/placementAnalysis",
            pageCode: "placementAnalysis",
          },
          {
            label: "Reach Trend",
            link: "/app/reports/reachTrend",
            pageCode: "reachTrend",
          },
        ],
      },
    ]
  },
  {
    id: 31,
    label: "Uncategorised Reports",
    link: "/app/bi/",
    icon: <EqualizerIcon />,
    pageCode: "uncategorizedReports",
    children: [
      {
        label: "Brand Segments By Day",
        link: "/app/bi/brandSegments",
        pageCode: "brandSegmentsByDay",
      },
      {
        label: "Keywords By ASIN",
        link: "/app/bi/keywordsByASIN",
        pageCode: "keywordsByAsin",
      },
      {
        label: "Sales and Rank",
        link: "/app/bi/SalesAndRank",
        pageCode: "salesAndRank",
      }
    ],
  },

  {
    id: 61,
    label: "Catalog",
    link: "/app/catalog/",
    icon: <AssignmentIcon />,
    pageCode: "catalog",
    children: [
      {
        label: "Product Details",
        link: "/app/catalog/productDetails",
        pageCode: "productDetails",
      },
      {
        label: "Product Hi-Res Images",
        link: "/app/catalog/productHiResImages",
        pageCode: "productHiResImages",
      },
      {
        label: "ASIN SKU Mapping",
        link: "/app/catalog/asinSKUMapping",
        pageCode: "asingSkuMapping",
      },
      { label: "Brand Segments By Day", link: "/app/bi/brandSegments" },
      { label: "Keywords By ASIN", link: "/app/bi/keywordsByASIN" },
      { label: "Sales and Rank", link: "/app/bi/SalesAndRank" },
      {
        label: "Brand Finder",
        link: "/app/catalog/brandFinder",
        pageCode: "brandFinder",
      },
      {
        label: "Blocked Domains",
        link: "/app/catalog/blockedDomains",
        pageCode: "blockDomains",
      },
      {
        label: "Contact Finder",
        link: "/app/catalog/contactFinder",
        pageCode: "contactFinder",
      },
      {
        label: "All Brands",
        link: "/app/catalog/listBrands",
        pageCode: "listBrands",
      },
      {
        label: "All Contacts",
        link: "/app/catalog/listContacts",
        pageCode: "listContacts",
      },
      {
        label: "Search Contacts",
        link: "/app/catalog/searchContacts",
        pageCode: "searchContacts",
      },
      {
        label: "Empty Domains",
        link: "/app/catalog/listEmptyDomains",
        pageCode: "listEmptyDomains",
      },
      {
        label: "Brand Finder Global",
        link: "/app/catalog/brandFinderGlobal",
        pageCode: "brandFinderGlobal",
      },
      {
        label: "Catalog Items",
        link: "/app/catalog/catalogItems",
        pageCode: "catalogItems",
      },
      {
        label: "Catalog Activity",
        link: "/app/catalog/activity",
        pageCode: "catalogActivity",
      },
      {
        label: "Cohesity",
        link: "/app/catalog/cohesity",
        pageCode: "catalogActivity",
      },
    ],
  },
  { id: 71, type: "title", label: "SECONDARY", pageCode: "none" },
  {
    id: 9,
    label: "Users",
    link: "/app/users/",
    pageCode: "users",
    icon: <PersonIcon />,
    children: [
      { label: "Add User", link: "/app/users/register", pageCode: "usersAdd" },
      { label: "All User", link: "/app/users/list", pageCode: "usersList" },
      {
        label: "User Activity",
        link: "/app/users/activity",
        pageCode: "userActivity",
      },
    ],
  },
  {
    id: 10,
    label: "Onboarding",
    link: "/app/onboarding/",
    pageCode: "onboarding",
    icon: <RepeatIcon />,
    children: [
      {
        label: "Crons Management",
        link: "/app/onboarding/cronsManagement",
        pageCode: "cronsManagement",
      },
      {
        label: "Upload Historic Data",
        link: "/app/onboarding/uploadHistoricData",
        pageCode: "uploadHistoricData",
      },
      {
        label: "JS Segment",
        link: "/app/onboarding/jsSegment",
        pageCode: "jsSegment",
      },
      {
        label: "AMS Onboarding",
        link: "/app/onboarding/ams",
        pageCode: "amsOnboarding",
      },
      ...(process.env.REACT_APP_ENV === 'brand_finder' ? [{
        label: "D API Statistics",
        link: "/app/onboarding/dapiStatistics",
        pageCode: "dapiStatistics",
      }] : []),
    ],
  },
];
