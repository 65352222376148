import { GET_API } from "./axios"

import { filterDataBySearchTerm, filterDataByNotificationType, modifyNotificationForTable } from "../healpers/event.helpers";

import { tokenConfig } from '../context/UserContext';
import { BASE_URL } from "../healpers/api";
import api from "../healpers/apiRoutes";

/**
 * Fetches Notification data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of Notification data
 */
export const fetchEventNotifications = async (vendorCode) => {
    try {
    const url = BASE_URL + api.getEventNotifications;
    
    console.log("getEventNotification url is "+url);
    
    const config = {
        ...tokenConfig(),
        params: {
            vendorCode: vendorCode || 'UNK4S'
        }
    }
    const result = await GET_API(url, config.headers, config.params);
    const data = result?.data?.data || [];
    if(Array.isArray(data)) {
        if(!data.length) {
            return;
        } else {
            const formattedData = modifyNotificationForTable(data);
            const filteredData = filterDataBySearchTerm(formattedData)

            return { formattedData, filteredData };
        }
    } else {
        throw new Error("Failed to fetch data!");
    }
    } catch(err) {
        throw new Error(err.message || err)
    }
}


/* fetch event types
*/
export const fetchEventNotificationTypes = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getEventNotificationTypes;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        
        if (!data) {
            return {};
        } else {
            const filteredData = filterDataByNotificationType(data);           
            return { filteredData };
        }        
    
    } catch (err) {
        throw new Error(err.message || err)
    }
}


/**
 * Fetches Notification data from the server based on vendor code and event type
 * @param {string} vendorCode 
 * @param {string} notificationType 
 * @returns {Array} Array of Notification data
 */
export const fetchEventNotificationsTypeData = async (vendorCode, notificationType) => {
    try {
        const url = BASE_URL + api.getEventNotificationsTypeData;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, notificationType }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {
            const formattedData = modifyNotificationForTable(data);
            const filteredData = filterDataBySearchTerm(formattedData, {}, notificationType)

            return { filteredData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}

/**
 * Fetches Notification data from the server based on vendor code
 * @param {string} vendorCode 
 * @returns {Array} Array of Notification data
 */
 export const fetchCohesityNotifications = async (vendorCode) => {
    try {
    const url = BASE_URL + api.getCohesityNotifications;
    
    console.log("getCohesityNotification url is "+url);
    
    const config = {
        ...tokenConfig(),
        params: {
            vendorCode: vendorCode || 'UNK4S'
        }
    }
    const result = await GET_API(url, config.headers, config.params);
    const data = result?.data?.data || [];
    if(Array.isArray(data)) {
        if(!data.length) {
            return;
        } else {
            const formattedData = modifyNotificationForTable(data);
            const filteredData = filterDataBySearchTerm(formattedData)

            return { formattedData, filteredData };
        }
    } else {
        throw new Error("Failed to fetch data!");
    }
    } catch(err) {
        throw new Error(err.message || err)
    }
}


/* fetch event types
*/
export const fetchCohesityNotificationTypes = async (vendorCode) => {
    try {
        const url = BASE_URL + api.getCohesityNotificationTypes;

        const config = {
            ...tokenConfig(),
            params: { vendorCode }
        }
        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        
        if (!data) {
            return {};
        } else {
            const filteredData = filterDataByNotificationType(data);           
            return { filteredData };
        }        
    
    } catch (err) {
        throw new Error(err.message || err)
    }
}


/**
 * Fetches Notification data from the server based on vendor code and event type
 * @param {string} vendorCode 
 * @param {string} notificationType 
 * @returns {Array} Array of Notification data
 */
export const fetchCohesityNotificationsTypeData = async (vendorCode, notificationType) => {
    try {
        const url = BASE_URL + api.getCohesityNotificationsTypeData;

        const config = {
            ...tokenConfig(),
            params: { vendorCode, notificationType }
        }

        const result = await GET_API(url, config.headers, config.params);
        const data = result?.data?.data || [];
        if (!data) {
            return {};
        } else {
            const formattedData = modifyNotificationForTable(data);
            const filteredData = filterDataBySearchTerm(formattedData, {}, notificationType)

            return { filteredData };
        }
    } catch (err) {
        throw new Error(err.message || err)
    }
}