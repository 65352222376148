import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Breadcrumbs,
  Container,
  Button,
  CircularProgress,
  Backdrop,
  Link
} from "@material-ui/core";

//icons
import PrintIcon from "@material-ui/icons/Print";

// styles
import useStyles from "./styles";

// components
import Widget from "../../components/Widget/Widget";
import { Typography } from "../../components/Wrappers/Wrappers";
import Table from "./components/Table/Table";
import PageTitle from "../../components/PageTitle/PageTitle";

//context
import { useVendorState } from "../../context/VendorContext";

//helpers
import { fetchOrderMetricsHour } from "../../http/api";

// import DayHeatMap from "./components/Heatmap/DayHeatMap"
// import HourHeatMap from "./components/Heatmap/HourHeatMap";
import LineChart from "./components/LineChart/LineChart";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import moment from "moment";

import { 
  filterDataByDate
} from "../../healpers/orderMetrics.helpers";

const colors = [
  "#8884d8",
  "#82ca9d",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#e66959",
  "#eea065",
  "#edcb8b",
  "#d3e4a0",
  "#a6ddd3",
  "#79bae4",
  "#6f86ee",
  "#8a5a8c",
];

export default function Dashboard(props) {
  var classes = useStyles();
  const exportFunctionRef = useRef();

  //global
  var vendorState = useVendorState();

  const [totalLineChartData, setTotalLineChartData] = useState({});
  const [lineChartData, setLineChartData] = useState([]);
  const [currentDate, setCurrentDate] = useState('');
  const [mainTableData, setmainTableData] = useState([]);
  const [fullPageLoader, setfullPageLoader] = useState(false);
  const [totalData, setTotalData] = useState([]);

  useEffect(() => {

    async function fetchData() {
      try {

        setfullPageLoader(true);

        const { formattedData, filteredData, lineChartData } = await fetchOrderMetricsHour(vendorState?.selected, 'Hour')

        setTotalData(formattedData || []);
        setTotalLineChartData(lineChartData || {})
        setLineChartData(lineChartData[Object.keys(lineChartData || {})?.at(-1)] || []);
        setCurrentDate(Object.keys(lineChartData || {})?.at(-1))
        setmainTableData(filteredData || []);
      } catch (error) {
        console.error(error);
      } finally {
        setfullPageLoader(false);
      }
    }

    if(vendorState?.selected) {
      fetchData();
    }

    return () => {
      setmainTableData([]);
      setCurrentDate('')
      setTotalLineChartData({})
      setLineChartData([]);
    };

  }, [vendorState.selected]);

  const shouldDisplay = (icon) => {

    const index = Object.keys(totalLineChartData).findIndex(ele => ele === currentDate)
    console.log('Icon:: ', icon,  'Index: ', index, 'totalLineChartData: ', Object.keys(totalLineChartData).length)

    if (icon === 'left') {
      if (index <= 0) {
        return classes.arrowIconDisabled;
      }
    } else {
      if (index >= Object.keys(totalLineChartData)?.length - 1) {
        return classes.arrowIconDisabled;
      }
    }
    return classes.arrowIcon;
  }

  const updatePrevData = () => {
    const index = Object.keys(totalLineChartData).findIndex(ele => ele === currentDate)
    const nextDateRange = Object.keys(totalLineChartData)[index - 1]
    setLineChartData(totalLineChartData[nextDateRange]);
    setCurrentDate(nextDateRange)
    const prevDate = moment(nextDateRange);
    const nextDate = moment(nextDateRange).add(1, 'd');
    let filteredData = filterDataByDate(totalData, {}, prevDate, nextDate);
    setmainTableData(filteredData);
  }

  const updateNextData = () => {
    const index = Object.keys(totalLineChartData).findIndex(ele => ele === currentDate)
    const nextDateRange = Object.keys(totalLineChartData)[index + 1]
    setLineChartData(totalLineChartData[nextDateRange]);
    setCurrentDate(nextDateRange)
    const prevDate = moment(nextDateRange);
    const nextDate = moment(nextDateRange).add(1, 'd');
    let filteredData = filterDataByDate(totalData, {}, prevDate, nextDate);
    setmainTableData(filteredData);
  }

  return (
    <>
      <Container maxWidth={false}>
        <PageTitle
          title={`Order Metrics Hourly`}
          breadCrump={
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" className={classes.link}>
                Sales
              </Link>
              <Link color="inherit" className={classes.link}>
                Goals & Performance
              </Link>
              <Typography className={classes.link} color="primary">
                {`Order Metrics Hourly`}
              </Typography>
            </Breadcrumbs>
          }
        />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    {`Order Metrics Hourly - ${currentDate}`}
                  </Typography>
                </div>
              }
            >
              <div className={classes.arrowIcons}>
                <ChevronLeftIcon onClick={() => { updatePrevData() }} className={shouldDisplay('left')} />
                <ChevronRightIcon onClick={() => { updateNextData() }} className={shouldDisplay('right')}/>
              </div>
              <LineChart data = {lineChartData} colors={colors} />
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget
              upperTitle
              noBodyPadding
              bodyClass={classes.tableOverflow}
              header={
                <div className={classes.mainChartHeader}>
                  <Typography
                    variant="h2"
                    gutterBottom
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      color: "black",
                    }}
                  >
                    {`Order Metrics Hourly`}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<PrintIcon />}
                    onClick={() => exportFunctionRef.current.onBtnExport()}
                  >
                    Export
                  </Button>
                </div>
              }
            >
              <Table ref={exportFunctionRef} tableData={mainTableData} />
            </Widget>
          </Grid>
        </Grid>
        <Backdrop
          className={classes.backdrop}
          open={fullPageLoader}
        // onClick={() => setfullPageLoader(false)}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </>
  );
}
